import React, { useState } from 'react'
import { StyledH5 } from '../../../../components/Typo'
import { Card, Col, Row } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import ContactsList from '../ContactsList'
import styled from 'styled-components'
import Toggle from '../../../../components/Forms/Toggle/Toggle'
import { updateNetSuiteRequest } from '../../../../store/ns/actions'

const VendorsSettingTab = ({ loading, onSubmitted }) => {
  const dispatch = useDispatch()
  const data = useSelector((state) => state.ns.preData)
  const [syncContractors, setSyncContractors] = useState(
    data?.contractors_auto_sync,
  )

  const onSubmit = () => {
    dispatch(
      updateNetSuiteRequest({
        contractors_auto_sync: syncContractors,
        isUpdate: true,
      }),
    )
    onSubmitted()
  }

  return (
    <Col className='p-0 m-0'>
      <StyledCard className='p-4 m-0 mb-3'>
        <Row className='p-0 m-0 align-items-center'>
          <div className='mr-3'>
            <Toggle
              check={syncContractors}
              change={() => setSyncContractors((e) => !e)}
            />
          </div>
          <div>
            <StyledH5 className='font-size-16 rp-font-bold'>
              Contractors auto sync
            </StyledH5>
            <p className='text-muted m-0 p-0'>
              Automatically sync all new contractors with the integration.
            </p>
          </div>
        </Row>
      </StyledCard>
      <StyledCard className='p-0 m-0'>
        <ContactsList defaultValues={{}} platform='NetSuite' />
        <Row className='justify-content-end m-0 p-4 mt-4 border-top border-light'>
          <button onClick={onSubmit} className='btn btn-primary'>
            {loading && <i className='bx bx-loader bx-spin' />} Save
          </button>
        </Row>
      </StyledCard>
    </Col>
  )
}

const StyledCard = styled(Card)`
  background: #ffffff;
  box-shadow: 0px 1px 0px #dfe1e6;
  border-radius: 4px;
`

export default VendorsSettingTab
