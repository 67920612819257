import React from 'react'
import {
  Bank,
  Flag,
  GlobeSimple,
  IdentificationBadge,
  IdentificationCard,
  Textbox,
} from '@phosphor-icons/react'

import { DetailsInfoList } from '../review-center/review-layout-details-components'

export function DetailSectionTitle({ title, icon }) {
  return (
    <span className='tw-flex tw-items-center tw-text-base tw-font-bold tw-text-black'>
      {icon}
      {title}
    </span>
  )
}

function BankDetailValue({ value }) {
  return <span className='tw-font-semibold tw-text-black'>{value}</span>
}

export function BankInfo() {
  return (
    <DetailsInfoList
      title={
        <DetailSectionTitle
          title='Bank details'
          icon={<Bank size={20} className='tw-mr-2 tw-fill-primary' />}
        />
      }
      className='tw-p-0'
      items={[
        {
          label: 'Beneficiary name',
          icon: <Textbox size={24} className='tw-fill-text-60' />,
          value: <BankDetailValue value='MegaWave Innovations' />,
        },
        {
          label: 'Beneficiary type',
          icon: <IdentificationBadge size={24} className='tw-fill-text-60' />,
          value: <BankDetailValue value='Bussiness' />,
        },
        {
          label: 'Bank name',
          icon: <Bank size={24} className='tw-fill-text-60' />,
          value: <BankDetailValue value='Central bank' />,
        },
        {
          label: 'Swift/BIC code',
          icon: <GlobeSimple size={24} className='tw-fill-text-60' />,
          value: <BankDetailValue value='CBUKGB9B' />,
        },
        {
          label: 'IBAN',
          icon: <IdentificationCard size={24} className='tw-fill-text-60' />,
          value: <BankDetailValue value='GB2123 43235 7867 5343' />,
        },
        {
          label: 'Country',
          icon: <Flag size={24} className='tw-fill-text-60' />,
          value: <BankDetailValue value='United Kingdom' />,
        },
      ]}
    />
  )
}
