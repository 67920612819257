import { CalendarCheck, UserCircle } from '@phosphor-icons/react'
import React from 'react'

import { cn } from 'ui'
import Alert from '../../../../components/ui/alert'
import Loader from '../../../../components/ui/loader'
import { useFetch, usePermissions } from '../../../../helpers/hooks'
import permissions from '../../../../helpers/permissions'
import { getTimeOffPolicies } from '../../../../services/api-time-off-policies'
import TabEmpty from '../../../Contract/components/tab/tab-empty'
import {
  PolicyTypeIcon,
  policyTypeIconColors,
} from '../../../new-time-off-policy'
import { AddTimeOffPolicy } from '../manage-time-off-policy'
import {
  SettingSectionHeading,
  SettingSectionSubHeading,
} from '../settings-section-heading'
import { ManagePolicyWorkers } from './manage-policy-workers'
import { ViewPolicy } from './view-policy'

export function TimeOffPoliciesTab() {
  const { hasAccess } = usePermissions()
  const canManageCompanySettings = hasAccess(permissions.manageCompanySettings)

  return (
    <div className='tw-p-6'>
      <div className='tw-flex tw-flex-wrap tw-items-center tw-justify-between tw-gap-3 md:tw-flex-nowrap'>
        <div>
          <SettingSectionHeading
            learnMoreLink='https://help.remotepass.com/en/articles/9573740-default-time-off-time-off-policies#h_9ec797822e'
            learnMoreTitle='Learn more about time off policies'
          >
            Time Off Policies
          </SettingSectionHeading>

          <SettingSectionSubHeading className='tw-mb-0'>
            Create customized time off policies and assign them to your workers
          </SettingSectionSubHeading>
        </div>

        <AddTimeOffPolicy hasPermission={canManageCompanySettings} />
      </div>

      <div className='tw-mt-4'>
        <TimeOffPolicyList />
      </div>
    </div>
  )
}

function TimeOffPolicyList() {
  const {
    data: policies,
    isLoading: policiesLoading,
    completed: policiesCompleted,
    startFetch: refetchPolicies,
    error: policiesError,
  } = useFetch({
    action: getTimeOffPolicies,
    autoFetch: true,
  })

  const isEmpty = policies?.length <= 0

  const groupedPolicies = policies
    ?.reduce((acc, policy) => {
      const policyTypeId = policy?.type?.id

      const prevPolicies = acc.map((p) => p.type.id)

      if (prevPolicies.includes(policyTypeId)) {
        const foundIndex = acc.findIndex((p) => p.type.id === policyTypeId)
        acc[foundIndex].policies.push(policy)
      } else {
        acc.push({
          type: policy?.type,
          policies: [policy],
        })
      }

      return acc
    }, [])
    .sort((a, b) => a.type.order - b.type.order)

  return (
    <>
      {policiesError ? (
        <Alert color='danger' className='tw-mt-6' innerTag='div'>
          <h6 className='tw-mb-2 tw-text-sm tw-font-bold tw-text-current'>
            Something went wrong while loading the time off policies
          </h6>
          <code className='tw-mb-0 tw-bg-systemRed-100/10 tw-text-current'>
            {policiesError}
          </code>
        </Alert>
      ) : policiesLoading || !policiesCompleted ? (
        <Loader minHeight='max(50vh, 550px)' />
      ) : isEmpty ? (
        <TabEmpty
          title='No time off policy'
          subtitle='Time off policies will be listed here'
          icon={
            <CalendarCheck
              size={250}
              weight='duotone'
              className='tw-text-primary-100'
            />
          }
        />
      ) : (
        <div className='tw-mt-6'>
          {groupedPolicies?.map((group) => (
            <PolicyGroup
              key={group.type.id}
              title={group.type.name}
              policies={group.policies}
              refetchPolicies={refetchPolicies}
              companyPolicies={policies}
            />
          ))}
        </div>
      )}
    </>
  )
}

function PolicyGroup({ title, policies, refetchPolicies, companyPolicies }) {
  return (
    <div className='tw-border-t tw-border-surface-30 tw-py-6'>
      <SettingSectionHeading>{title}</SettingSectionHeading>

      <div className='tw-mt-4 tw-grid tw-gap-3 md:tw-grid-cols-3 xl:tw-grid-cols-4'>
        {policies?.map((item) => {
          const contractCount = item?.contracts ? item.contracts.length : 0
          return (
            <div
              key={item.id}
              className='tw-rounded tw-border tw-border-surface-30 tw-p-4'
            >
              <div className='tw-flex tw-flex-wrap tw-justify-between tw-gap-1'>
                <div
                  className={cn(
                    'tw-rounded tw-bg-systemGold-20 tw-p-2 tw-text-systemGold-110',
                    policyTypeIconColors({ typeId: item?.type?.id }),
                  )}
                >
                  <PolicyTypeIcon typeId={item?.type?.id} size={24} />
                </div>

                <ViewPolicy policy={item} refetchPolicies={refetchPolicies} />
              </div>

              <h4 className='tw-mb-0.5 tw-mt-4 tw-text-sm tw-font-bold'>
                {item.name}
              </h4>
              <p className='tw-mb-1 tw-text-xs tw-font-medium tw-text-text-80'>
                {item.type?.is_accrued === 1
                  ? 'Accrued time off balance'
                  : 'Flexible policy'}
              </p>

              <div className='tw-mt-2 tw-flex tw-items-center tw-gap-2'>
                <UserCircle size={16} className='tw-text-secondary-100' />
                <div className='tw-text-xs tw-font-semibold tw-text-text-80'>
                  {contractCount} worker{contractCount === 1 ? '' : 's'}{' '}
                  assigned
                </div>
              </div>

              <ManagePolicyWorkers
                policy={item}
                onSuccess={refetchPolicies}
                companyPolicies={companyPolicies}
              />
            </div>
          )
        })}
      </div>
    </div>
  )
}
