import { searchParamsFromObject } from '../utils/search-params-from-object'
import { api, getConfig } from './api'

export function getTimeOffPolicies(token, _, extra) {
  return api.get('/time_off/policies', getConfig(token, extra))
}

export function getContractTimeOff(token, data, extra) {
  return api.get(
    `/time_off/balances/contract/${data.contract_id}`,
    getConfig(token, extra),
  )
}

export function createTimeOffPolicy(token, data) {
  return api.post('/time_off/policies', data, getConfig(token))
}

export function deleteTimeOffPolicy(token, data) {
  return api.delete(`/time_off/policies/${data?.policyId}`, getConfig(token))
}

export function getUnassignedContracts(token, data, extra) {
  const { policyId, ...rest } = data
  const sp = searchParamsFromObject(rest)

  return api.get(
    `/time_off/policies/${policyId}/unassigned_contracts${sp}`,
    getConfig(token, extra),
  )
}

export function assignContractsToPolicy(token, data) {
  const { policyId, ...rest } = data
  return api.post(
    `/time_off/policies/${policyId}/assign_contracts`,
    rest,
    getConfig(token),
  )
}
export function adjustTimeOffBalance(token, data) {
  const { balance_id: balanceId, ...rest } = data
  return api.post(
    `/time_off/balances/${balanceId}/adjustment`,
    rest,
    getConfig(token),
  )
}

export function unassignContractsToPolicy(token, data) {
  const { policyId, ...rest } = data
  return api.post(
    `/time_off/policies/${policyId}/unassign_contracts`,
    rest,
    getConfig(token),
  )
}

export function editTimeOffPolicy(token, data) {
  const { id, ...rest } = data
  return api.put('time_off/policies/' + id, rest, getConfig(token))
}

export function getBalanceHistory(token, data, extra) {
  return api.get(
    `time_off/balances/${data.id}/history`,
    getConfig(token, extra),
  )
}

export function getPolicyCyclesPreview(token, data, extra) {
  const { id, ...rest } = data
  const sp = searchParamsFromObject(rest)

  return api.get(
    `time_off/policies/${id}/cycles/preview${sp}`,
    getConfig(token, extra),
  )
}

export function changeTimeOffPolicy(token, data) {
  return api.post('time_off/policies/change', data, getConfig(token))
}

export function getContractPaidAndUnPaidDays(token, id) {
  return api.get(`contract/timeoff/payments/${id}`, getConfig(token))
}
