import { api, getConfig, getFormData } from './api'

export const getEntities = (token, _, extra) => {
  return api.get('direct_employees/entities', getConfig(token, extra))
}

export const createEntity = (token, data) => {
  const formData = getFormData(data)
  return api.post(
    'direct_employees/entities',
    formData,
    getConfig(token, {}, { 'Content-Type': 'multipart/form-data' }),
  )
}

export const updateEntity = (token, data) => {
  const { id } = data
  return api.post(
    'direct_employees/entities/' + id + '?_method=PUT',
    data,
    getConfig(token, {}, { 'Content-Type': 'multipart/form-data' }),
  )
}

export const deleteEntity = (token, data) => {
  const { id } = data
  return api.delete('direct_employees/entities/' + id, getConfig(token))
}
