import React from 'react'

export default function EmptyState({ icon, description, title }) {
  return (
    <span className='tw-flex tw-flex-col tw-items-center tw-justify-center'>
      {icon}
      <span className='tw-mb-2 tw-text-2xl tw-font-medium'>{title}</span>
      <span className='tw-text-center tw-text-sm tw-font-semibold tw-text-text-80'>
        {description}
      </span>
    </span>
  )
}
