import { CaretDown, CaretUp } from '@phosphor-icons/react'
import React from 'react'
import {
  Col,
  Container,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Input,
  Label,
  Row,
} from 'reactstrap'

const FiltersDropdown = ({
  isOpen,
  toggle,
  tagsFilters,
  statusFilters,
  filterByStatus,
  filterByTag,
  clearFilters,
  tagQueries,
  statusQueries,
  ...props
}) => {
  return (
    <Dropdown isOpen={isOpen} toggle={toggle} {...props}>
      <DropdownToggle tag='div'>
        <div
          className='tw-flex tw-w-full tw-cursor-pointer tw-items-center tw-justify-between tw-rounded tw-border tw-border-surface-40 tw-bg-white tw-px-2'
          style={{ height: 'calc(1.5em + 1.3rem + 2px)' }}
        >
          <span className='text-gray-h font-size-14'>Filters</span>

          {isOpen ? <CaretUp size={12} /> : <CaretDown size={12} />}
        </div>
      </DropdownToggle>
      <DropdownMenu
        className='tw-w-full tw-rounded tw-border tw-border-surface-40 tw-bg-white tw-py-0 md:tw-w-[538px]'
        flip={false}
      >
        <div
          className='d-flex align-items-center justify-content-between w-100 px-3 border-bottom'
          style={{ height: 'calc(1.5em + 1.3rem + 2px)' }}
        >
          <span className='font-size-14 text-gray-h'>Filter by</span>
          <button
            className='rp-btn-nostyle text-muted font-size-12'
            onClick={() => clearFilters()}
          >
            Reset All
          </button>
        </div>

        <Container fluid>
          <Row>
            <Col className='py-3 border-right h-100' md={6}>
              <div className='mb-2 text-gray-h font-size-12'>Status</div>
              {statusFilters?.map((e, i) => {
                return (
                  <Label
                    key={`drop-${i}`}
                    className='cursor-pointer d-block text-gray-h font-size-14 pl-4'
                  >
                    <Input
                      checked={statusQueries.includes(e.value)}
                      type='checkbox'
                      onChange={(t) => {
                        toggle()
                        filterByStatus(t.target.checked, e.value)
                      }}
                    />{' '}
                    {e.label}
                  </Label>
                )
              })}
            </Col>
            <Col md={6} className='py-3'>
              <div className='mb-2 text-gray-h font-size-12'>Tags</div>
              {tagsFilters?.map((e, i) => {
                return (
                  <Label
                    key={`drop-${i}`}
                    className='cursor-pointer d-block text-gray-h font-size-14 pl-4 tw-break-words'
                  >
                    <Input
                      checked={tagQueries.includes(e.id)}
                      type='checkbox'
                      onChange={(t) => {
                        toggle()
                        filterByTag(t.target.checked, e.id)
                      }}
                    />{' '}
                    {e.name}
                  </Label>
                )
              })}
            </Col>
          </Row>
        </Container>
      </DropdownMenu>
    </Dropdown>
  )
}

export default FiltersDropdown
