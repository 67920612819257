export default {
  ViewCompaniesInfo: 'view companies info',
  UpdateCompanies: 'update companies',
  SetupPaymentMethods: 'setup payment methods',
  ViewPaymentMethodsInfo: 'view payment methods info',
  DeletePaymentMethods: 'delete payment methods',
  ViewPaymentMethods: 'view payment methods',
  ViewDefaultPaymentMethods: 'view default payment methods',
  UpdateDefaultPaymentMethods: 'update default payment methods',
  CreatePaymentMethods: 'create payment methods',
  ViewTransactionsMethods: 'view transactions methods',
  PrepareTransactions: 'prepare transactions',
  CreateTransferTransactions: 'create transfer transactions',
  CreateCcTransactions: 'create cc transactions',
  VerifyCcTransactions: 'verify cc transactions',
  CreateContracts: 'create contracts',
  createFixedContracts: 'create fixed contracts',
  createPAYGContracts: 'create payg contracts',
  createMilestoneContracts: 'create milestone contracts',
  createEorContracts: 'create eor contracts',
  createDeContracts: 'create direct employee contracts',
  UpdateContracts: 'update contracts',
  AmendContracts: 'amend contracts',
  TerminateContracts: 'terminate contracts',
  DeleteWorks: 'delete works',
  CancelInvitations: 'cancel invitations',
  CalculateContractsProrata: 'calculate contracts prorata',
  SubmitWorks: 'submit works',
  ApproveWorks: 'approve works',
  DeclineWorks: 'decline works',
  CreateMilestones: 'create milestones',
  SubmitMilestones: 'submit milestones',
  ApproveMilestones: 'approve milestones',
  DeclineMilestones: 'decline milestones',
  RequestContractsDocuments: 'request contracts documents',
  DeleteContractsDocuments: 'delete contracts documents',
  DownloadContractsDocuments: 'download contracts documents',
  InviteToContracts: 'invite to contracts',
  ViewContractsActivity: 'view contracts activity',
  InviteUsers: 'invite users',
  UpdateUsersStatus: 'update users status',
  UpdateUsersRole: 'update users role',
  ViewCompaniesUsers: 'view companies users',
  ViewContractsDocuments: 'view contracts documents',
  viewContractSettings: 'view contract settings',
  ViewCompaniesDocuments: 'view companies documents',
  DownloadCompaniesDocuments: 'download companies documents',
  ViewCompaniesContractors: 'view companies contractors',
  ViewContractsDetails: 'view contracts details',
  ViewContracts: 'view contracts',
  ViewInvoices: 'view invoices',
  ViewTransactions: 'view transactions',
  revertTransactions: 'revert transactions',
  ViewContractsPayments: 'view contracts payments',
  ViewUpcomingPayments: 'view upcoming payments',
  ViewUnpaidPayments: 'view unpaid payments',
  SignContracts: 'sign contracts',
  DownloadContracts: 'download contracts',
  assignSignatory: 'assign signatory',
  cancelPendingSignatory: 'cancel pending signatory',
  viewSignatories: 'view signatories',
  archiveContracts: 'archive contracts',
  cancelContracts: 'cancel contracts',
  cancelAmendment: 'cancel amendment',
  viewTimeOffs: 'view time-offs',
  addTimeOff: 'add time-off',
  approveTimeOff: 'approve time-off',
  rejectTimeOff: 'reject time-off',
  deleteTimeOff: 'delete time-off',
  viewExpenses: 'view expenses',
  addExpense: 'add expense',
  approveExpense: 'approve expense',
  rejectExpense: 'reject expense',
  deleteExpense: 'delete expense',
  manageContractSettings: 'manage contract settings',
  manageCompanySettings: 'manage company settings',
  switchAccount: 'switch account',
  viewDashboardGraph: 'view dashboard graph',
  viewCalendarPaydays: 'view calendar paydays',
  viewEquipments: 'view equipments',
  addEquipment: 'add equipment',
  updateEquipment: 'update equipment',
  deleteEquipment: 'delete equipment',
  signEquipment: 'sign equipment',
  downloadEquipment: 'download equipment',
  addEntity: 'add entity',
  addAdjustments: 'add adjustments',
  createDocument: 'create document',
  ManageIntegrations: 'manage integrations',
  ViewAttributes: 'view attributes',
  AddAttribute: 'add attribute',
  UpdateAttribute: 'update attribute',
  DeleteAttribute: 'delete attribute',
  DeleteAdjustment: 'delete adjustments',
  ViewDownloadReports: 'view download reports',
  ViewOrgChart: 'view organization chart',
  ManageOrgChart: 'manage organization chart',
}
