import cx from 'classnames'
import React from 'react'

export function Box({
  borderDashed,
  children,
  className,
  tag: Comp = 'div',
  noPadding = false,
}) {
  return (
    <Comp
      className={cx(
        'tw-rounded tw-border tw-border-surface-30 tw-text-sm',
        { 'tw-p-4': !noPadding },
        borderDashed ? 'tw-border-dashed' : '',
        className,
      )}
    >
      {children}
    </Comp>
  )
}
