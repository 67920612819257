import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Card, Nav, NavItem, NavLink, Row, TabPane } from 'reactstrap'
import classnames from 'classnames'
import { StyledH5 } from '../../../../components/Typo'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import Tab1 from '../HibobIntegration/Tab1'
import TabX from '../HibobIntegration/TabX'
import Tab4 from '../HibobIntegration/Tab4'
import {
  SaplingRequest,
  SaplingUpdateContractors,
} from '../../../../store/sapling/actions'
import { useFetch } from '../../../../helpers/hooks'
import {
  getSaplingEditData,
  saveSaplingContractors,
  updateDataToSapling,
} from '../../../../services/api'
import toastr from 'toastr'
import { useHistory } from 'react-router'
import { LoadingTab } from '../UpdateHibobIntegration'
import axios from 'axios'
import TabContent from '../../../../components/ui/tabs'

const UpdateSaplingIntegration = () => {
  const [activeTab, setActiveTab] = useState(0)
  const tab1Ref = useRef()

  const dispatch = useDispatch()
  const saplingRequest = useSelector((state) => state.sapling.saplingRequest)
  const history = useHistory()

  useEffect(() => {
    if (!saplingRequest?.session_id) {
      history.goBack()
    }
  }, [])

  const UpdateData = useFetch({
    action: updateDataToSapling,
    onComplete: (data) => {
      if (data?.user_id) {
        toastr.success('Settings updated successfully.')
      }
    },
    onError: (error) => {
      toastr.error(error)
    },
  })

  const callGetSaplingContractors = useFetch({
    action: getSaplingEditData,
    onComplete: (data) => {
      if (data?.settings) {
        const newContractType = saplingRequest?.contract_types?.map((type) =>
          data?.settings?.settings?.contract_types.includes(type.name)
            ? type
            : {
                ...type,
                status: false,
              },
        )
        const newFields = saplingRequest?.fields?.map((type) =>
          data?.settings?.settings?.fields.includes(type.name)
            ? {
                ...type,
                status: true,
              }
            : type,
        )
        dispatch(
          SaplingRequest({
            ...saplingRequest,
            ...data?.settings?.auth,
            contract_types: newContractType,
            fields: newFields,
          }),
        )
      }
      if (data?.contractors) {
        dispatch(SaplingUpdateContractors(data?.contractors))
      }
    },
    onError: (error) => {
      toastr.error(error)
    },
  })

  const refresh = useCallback(
    (cancel) => {
      if (cancel) cancel()
      if (callGetSaplingContractors.data?.contractors_queued > 0) {
        callGetSaplingContractors.startFetch(
          null,
          false,
          new axios.CancelToken((c) => (cancel = c)),
        )
      }
    },
    [callGetSaplingContractors.data],
  )

  useEffect(() => {
    if (activeTab === 1) {
      let cancel
      const interval = setInterval(() => refresh(cancel), 800)
      return () => {
        clearInterval(interval)
      }
    }
  }, [callGetSaplingContractors.data, activeTab])

  const saveContractors = useFetch({
    action: saveSaplingContractors,
    onComplete: (data) => {
      toastr.success('Settings updated successfully.')
      callGetSaplingContractors.startFetch()
    },
    onError: (error) => {
      toastr.error(error)
    },
  })

  useEffect(() => {
    callGetSaplingContractors.startFetch()
  }, [])

  const tabs = [
    {
      label: 'Settings',
      component: (
        <Card className='p-3'>
          {(saveContractors.isLoading ||
            callGetSaplingContractors.isLoading) && <LoadingTab />}
          <Tab1
            platform='sapling'
            mode='edit'
            ref={tab1Ref}
            onComplete={(data) => {
              dispatch(SaplingRequest({ ...saplingRequest, ...data }))
              const contractTypes = saplingRequest?.contract_types
                ?.filter((type) => type.status === true)
                .map((type) => type.name)
              const fields = saplingRequest?.fields
                ?.filter((type) => type.status === true)
                .map((type) => type.name)
              UpdateData.startFetch({
                ...saplingRequest,
                ...data,
                contract_types: contractTypes,
                fields,
              })
            }}
          />
          <TabX
            platform='sapling'
            mode='edit'
            context='tab3'
            onComplete={(data) => {
              dispatch(SaplingRequest({ ...saplingRequest, fields: data }))
            }}
          />
          <TabX
            platform='sapling'
            mode='edit'
            context='tab2'
            onComplete={(data) => {
              dispatch(
                SaplingRequest({ ...saplingRequest, contract_types: data }),
              )
            }}
          />
          <Row className='justify-content-end p-0 m-0 mt-4'>
            <button
              onClick={() => {
                tab1Ref.current.submit()
              }}
              className='btn btn-primary'
            >
              {UpdateData.isLoading && <i className='bx bx-loader bx-spin' />}{' '}
              Update
            </button>
          </Row>
        </Card>
      ),
    },
    {
      label: 'Team',
      component: (
        <Card className='p-3'>
          {(saveContractors.isLoading ||
            callGetSaplingContractors.isLoading) && <LoadingTab />}
          <Tab4
            platform='sapling'
            mode='edit'
            createContractor={(ContractorsToSubmit) => {
              saveContractors.startFetch({
                contractors: [ContractorsToSubmit[0]?.contractor_id],
              })
            }}
          />
        </Card>
      ),
    },
  ]
  return (
    <div className='page-content'>
      <>
        <div className='mb-0 mb-md-2 d-flex'>
          <StyledH5 min='22px' mid='28px' max='32px'>
            Sapling
          </StyledH5>
        </div>

        <StyledNav tabs className='nav-tabs-custom'>
          {tabs.map((nav, i) => (
            <NavItem key={`xeroIntegrationNav:${i}`}>
              <NavLink
                className={classnames({ active: activeTab === i })}
                onClick={() => {
                  setActiveTab(i)
                }}
              >
                <p className='font-size-14 mb-0'>{nav.label}</p>
              </NavLink>
            </NavItem>
          ))}
        </StyledNav>
        <TabContent activeTab={activeTab} className='mt-3'>
          {tabs.map((t, i) => (
            <TabPane key={`tab:${i}`} tabId={i}>
              {t.component}
            </TabPane>
          ))}
        </TabContent>
      </>
    </div>
  )
}

UpdateSaplingIntegration.propTypes = {}

export const StyledNav = styled(Nav)`
  flex-wrap: nowrap !important;
  overflow-x: auto;
  overflow-y: hidden;

  .nav-item {
    margin: 0 42px 0 0;

    .nav-link {
      white-space: nowrap;
      padding: 16px 0;

      font-weight: 400;
      font-size: 14px;

      &::after {
        height: 3px;
        background-color: var(--primary);
      }
    }

    .active {
      color: var(--primary) !important;
      font-weight: 600;
      background-color: #f4f5f7;
    }
  }

  @media (max-width: 768px) {
    .nav-item {
      margin: 0 24px 0 0 !important;
    }
  }
`

export default UpdateSaplingIntegration
