import { COINBASE } from '../../core/config/payment-methods'
import { currencyLocal } from './currency'

export const COINBASE_CURRENCY = {
  BTC: 'BTC',
  ETH: 'ETH',
  USDC: 'USDC',
}

export const weirdCurrencies = [COINBASE_CURRENCY.USDC]

export function weirdCurrenciesFormatter(currency, provider) {
  return {
    format: (value) => {
      const valueFormatted = new Intl.NumberFormat(currencyLocal, {
        minimumFractionDigits: 2,
        maximumSignificantDigits: provider === COINBASE.key ? 6 : undefined,
      }).format(value)

      return `${currency} ${valueFormatted}`
    },
  }
}
