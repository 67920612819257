import { AvForm } from 'availity-reactstrap-validation'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Col, Row, Tooltip } from 'reactstrap'
import toastr from 'toastr'

import { useFetch } from '../../../helpers/hooks'
import {
  changePassword,
  getClientInfo,
  getContractorInfo,
} from '../../../services/api'
import { updateUserInfo } from '../../../store/auth/register/actions'
import { updateUserProfileInfo } from '../../../store/profile/actions'
import { getIntercomData } from '../../../utils/analytics'
import { setCookie } from '../../../utils/cookies'
import { TFA_REMINDER } from '../../../utils/cookies/cookie-names'
import TextInput from '../../Forms/TextInput'
import Toggle from '../../Forms/Toggle/Toggle'
import { StyledH3, StyledH6 } from '../../Typo'
import Button from '../../ui/button'
import TwoFactorAuthentication from '../TwoFactorAuthentication'

const ChangePassword = () => {
  const ref = useRef(null)

  const user = useSelector((state) => state?.Account?.user)
  const profile = useSelector((state) => state?.userProfile?.userProfile)

  const [password, setPassword] = useState('')
  const [isValid, setIsValid] = useState('')
  const [isEnabled, setIsEnabled] = useState(false)
  const [show2FA, setShow2FA] = useState(false)

  const { startFetch: updateProfileInfo } = useFetch({
    action: user?.type === 'client' ? getClientInfo : getContractorInfo,
    autoFetch: false,
    onComplete: (data) => {
      dispatch(
        updateUserProfileInfo({
          is_2fa_enabled: data?.is_2fa_enabled,
          type_2fa: data?.type_2fa,
        }),
      )
    },
  })

  const dispatch = useDispatch()
  const change = useFetch({
    action: changePassword,
    onComplete: (data) => {
      toastr.success('Password changed successfully')
      ref.current.reset()
      const token = data?.token
      dispatch(updateUserInfo({ token }))
      window.analytics.track('Changed password', {
        email_id: user?.email,
      })
    },
    onError: (error) => {
      toastr.error(error)
    },
  })
  const handleValidSubmit = (e, v) => {
    change.startFetch(v)
  }
  useEffect(() => {
    window.analytics.track('Viewed password', {
      email_id: user?.email,
    })
  }, [])

  useEffect(() => {
    if (profile) {
      setIsEnabled(profile?.is_2fa_enabled)
    }
  }, [profile])

  function closeModalAndUpdateUser(data) {
    setShow2FA(false)

    updateProfileInfo()

    const intercomData = getIntercomData(data)
    window.analytics.identify(data?.id, intercomData)
  }

  return (
    <Col className='mb-3'>
      <StyledH3
        min='16px'
        max='30px'
        mid='18px'
        className='my-md-4 my-3 text-dark text-weight-bold'
      >
        Change Password
      </StyledH3>

      <AvForm
        ref={ref}
        className='form-horizontal w-100'
        onValidSubmit={(e, v) => {
          if (isValid) {
            handleValidSubmit(e, v)
          } else {
            toastr.error(
              'Weak password easy to guess, password should respect checklist condition',
            )
          }
        }}
      >
        <Row>
          <Col xs={12} md={6}>
            <div className='form-group'>
              <TextInput
                name='currentPassword'
                label='Current Password'
                type='password'
                required
                placeholder='Current Password'
                validate={{
                  required: {
                    value: true,
                    errorMessage: 'Current Password is required',
                  },
                }}
              />
            </div>
          </Col>
          <Col xs={12} md={6} />
          <Col xs={12} md={6}>
            <div className='form-group'>
              <TextInput
                id='passWord'
                name='newPassword'
                label='New Password'
                type='password'
                onChange={(e) => setPassword(e.target.value)}
                required
                placeholder='New Password'
                validate={{
                  required: {
                    value: true,
                    errorMessage: 'New Password is required',
                  },
                }}
              />

              <PasswordChecklistComp
                password={password}
                TooltipId='passWord'
                onChange={(v) => setIsValid(v)}
              />
            </div>
          </Col>
          <Col xs={12} md={6}>
            <div className='form-group'>
              <TextInput
                name='confirm_password'
                label='Confirm Password'
                type='password'
                required
                placeholder='Confirm Password'
                validate={{
                  match: {
                    value: 'newPassword',
                    errorMessage: 'Value must match with New Password',
                  },
                  required: {
                    value: true,
                    errorMessage: 'Confirm Password is required',
                  },
                }}
              />
            </div>
          </Col>
        </Row>
        <Button loading={change.isLoading} type='submit'>
          Change Password
        </Button>
      </AvForm>
      <div className='border-top divider w-100 my-3' />
      <StyledH3
        min='16px'
        max='30px'
        mid='18px'
        className='mb-md-4 mb-3 text-dark text-weight-bold'
      >
        Two-Factor Authentication
      </StyledH3>
      <label className='tw-mb-4 tw-inline-flex tw-cursor-pointer tw-items-center'>
        <Toggle check={isEnabled} change={() => setShow2FA(true)} />

        <span className='tw-text-sm'>{isEnabled ? 'Enabled' : 'Disabled'}</span>
      </label>

      <div className='tw-text-xs tw-text-text-60'>
        Require an authentication code when you log in with an email and
        password
      </div>

      {show2FA && (
        <TwoFactorAuthentication
          toggle={() => setShow2FA(false)}
          isOpen={show2FA}
          is2FAEnabled={isEnabled}
          defaultTfaType={profile?.type_2fa}
          onDisabled={(data) => {
            setIsEnabled(false)
            closeModalAndUpdateUser(data)
            setCookie(TFA_REMINDER, user?.token)
          }}
          onEnabled={(data) => {
            setIsEnabled(true)
            closeModalAndUpdateUser(data)
          }}
        />
      )}
    </Col>
  )
}

export const PasswordChecklistComp = (props) => {
  const { password, onChange, TooltipId } = props
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const toggle = () => setTooltipOpen(!tooltipOpen)
  const [containsUL, setContainsUL] = useState(false) // uppercase letter
  const [containsLL, setContainsLL] = useState(false) // lowercase letter
  const [containsN, setContainsN] = useState(false) // number
  const [contains8C, setContains8C] = useState(false) // min 8 characters
  // checks all validations are true
  const [allValid, setAllValid] = useState(false)

  useEffect(() => {
    // all validations passed
    validatePassword()
    if (containsUL && containsLL && containsN && contains8C) setAllValid(true)
    else setAllValid(false)
    if (allValid) {
      onChange(true)
    } else {
      onChange(false)
    }
  }, [containsUL, containsLL, containsN, contains8C, password, allValid])

  const validatePassword = () => {
    // has uppercase letter
    if (password.toLowerCase() !== password) setContainsUL(true)
    else setContainsUL(false)

    // has lowercase letter
    if (password.toUpperCase() !== password) setContainsLL(true)
    else setContainsLL(false)

    // has number
    if (/\d/.test(password)) setContainsN(true)
    else setContainsN(false)

    // has 8 characters
    if (password.length >= 8) setContains8C(true)
    else setContains8C(false)
  }
  // labels and state boolean corresponding to each validation

  const data = [
    ['An uppercase letter (A-Z)', containsUL],
    ['A lowercase letter (a-z)', containsLL],
    ['A number (0-9)', containsN],
    ['At least 8 characters', contains8C],
  ]

  return (
    <Tooltip
      className='my-tooltip'
      placement='right'
      isOpen={tooltipOpen}
      target={TooltipId}
      toggle={toggle}
    >
      {data.map((item, i) => (
        <div
          key={`rule${i}`}
          className='must-item d-flex align-items-center'
          style={{ minWidth: 240 }}
        >
          <i
            className={`bx ${
              item[1] ? 'text-success bx-check' : 'text-danger bx-x'
            } font-size-24 align-middle mr-1`}
          />
          <StyledH6
            className={` ${
              item[1] ? 'text-dark rp-font-bold' : 'text-secondary'
            } mb-0`}
          >
            {item[0]}
          </StyledH6>
        </div>
      ))}
    </Tooltip>
  )
}

export default ChangePassword
