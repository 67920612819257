import { yupResolver } from '@hookform/resolvers/yup'
import {
  CheckCircle,
  DownloadSimple,
  Eye,
  PaperPlaneRight,
  PencilSimple,
  Spinner,
  Warning,
} from '@phosphor-icons/react'
import { format, parse } from 'date-fns'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useFieldArray, useForm, useWatch } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { Route, Switch, useHistory, useParams } from 'react-router-dom'
import { Container, Modal, ModalBody } from 'reactstrap'
import toastr from 'toastr'
import * as yup from 'yup'

import { Stepper } from 'ui'
import ConfirmationModal from '../../../components/Common/ConfirmationModal'
import { ModalCloseButton } from '../../../components/Common/modal-close-button'
import ControlledDatePicker from '../../../components/ControlledDatePicker'
import ControlledInput from '../../../components/ControlledInput'
import ControlledSelect from '../../../components/ControlledSelect'
import Head from '../../../components/head'
import ModalHeader from '../../../components/ModalHeader'
import StepContainer from '../../../components/Steps/StepContainer'
import { PrimaryAlert } from '../../../components/ui/alert'
import Button from '../../../components/ui/button'
import DataTable from '../../../components/ui/data-table'
import Flag from '../../../components/ui/flag'
import { IconButtonSimple } from '../../../components/ui/icon-button-simple'
import { ControlledRadioButtonV2 } from '../../../components/ui/radio-button/controlled-radio-button-v2'
import { SideMenu } from '../../../components/ui/side-menu'
import { useFetch } from '../../../helpers/hooks'
import {
  getScheduledTaskStatus,
  getSignatoryList,
  uploadContractFileV2,
} from '../../../services/api'
import {
  getContractImportTemplate,
  processContractImport,
  sendContractImportInvites,
  validateContractImportTemplate,
} from '../../../services/api-bulk-operations'
import openFileV2 from '../../../utils/file/open-v2'
import { DATE_FORMAT } from '../../../utils/formatters/date-picker-date-format'
import { getFullName } from '../../../utils/get-full-name'
import {
  getOptionFromList,
  mapCountryToOption,
  mapCurrencyToOption,
  mapListToOption,
} from '../../../utils/map-to-option'
import { FIELD_CONTRACT_TYPES } from '../../CompanySetting/custom-fields/manage-custom-field'
import { scheduledTaskStatus } from '../../payInvoices'
import {
  FormSectionHr,
  FormSectionTitle,
} from '../CreateContract/components/form-section-title'
import { ContractorLabel } from '../CreateContract/create-contract-v3'
import { mapFrequencyToOption } from '../CreateContract/utils/map-frequency-to-option'
import useTriggerField from '../CreateContract/utils/use-trigger-field'
import {
  BulkCreationContextProvider,
  useBulkCreationFlowContext,
} from './bulk-creation-context'
import {
  BULK_CONTRACT_CREATION_EVENTS,
  useBulkContractActionEvents,
} from './events'
import { STATES, UploadFlow } from './upload-flow'

const BASE_PATH = '/contract/bulk-creation'

function getStepFormId(step) {
  return `bulk-creation-form-step-${step.key}`
}
function getPrevStepLink(step, steps) {
  const stepIndex = steps.findIndex((s) => s.key === step.key)
  const prevStepKey = steps[stepIndex - 1]?.key

  if (!prevStepKey) return null

  return `${BASE_PATH}/${prevStepKey}`
}
function getNextStepLink(step, steps) {
  const stepIndex = steps.findIndex((s) => s.key === step.key)
  const nextStepKey = steps[stepIndex + 1]?.key

  if (!nextStepKey) return null

  return `${BASE_PATH}/${nextStepKey}`
}

export function ContractBulkCreation() {
  return (
    <BulkCreationContextProvider>
      <ContractBulkCreationFlow />
    </BulkCreationContextProvider>
  )
}

function ContractBulkCreationFlow() {
  const history = useHistory()

  const { stepKey } = useParams()

  const stepperSteps = bulkCreationSteps.map((step) => step.name)
  const stepKeys = bulkCreationSteps.map((step) => step.key)
  const activeIndex = stepKeys.indexOf(stepKey ?? '')
  const activeStep = bulkCreationSteps[activeIndex]

  const isReviewStep = stepKey === 'review'

  const isLastStep = activeIndex === bulkCreationSteps.length - 1

  const pageTitleWithStepName = [activeStep?.name, 'Bulk Contract Import']
    .filter(Boolean)
    .join(' - ')

  function handlePrev(step) {
    const prevStepLink = getPrevStepLink(step, bulkCreationSteps)
    history.push(prevStepLink ?? BASE_PATH)
  }

  const [loading, setLoading] = useState(false)
  const [disabledNext, setDisabledNext] = useState(false)

  const disableNext = loading || disabledNext

  function handleNext(step) {
    const nextStepLink = getNextStepLink(step, bulkCreationSteps)
    if (nextStepLink) {
      history.push(nextStepLink)
    }
  }

  function handleReturn() {
    history.push(history.location.state?.backRoute ?? '/contracts')
  }

  return (
    <Container fluid className='px-0'>
      <Head title={pageTitleWithStepName} />

      <ModalHeader action={handleReturn}>
        <Stepper
          steps={stepperSteps}
          activeStep={activeIndex}
          className='tw-flex-grow'
        />
      </ModalHeader>

      <div className='tw-px-4 tw-pb-11'>
        <Switch>
          {bulkCreationSteps.map((step, index) => {
            const { key, Component, name } = step
            return (
              <Route path={`${BASE_PATH}/${key}`} key={index} exact>
                <StepContainer
                  title={name}
                  index={activeIndex}
                  total={bulkCreationSteps.length}
                  nextType='submit'
                  nextFormId={getStepFormId(step)}
                  nextText={isLastStep ? 'Save' : 'Continue'}
                  noBack={index === 0}
                  disableNext={disableNext}
                  loading={loading}
                  onBack={() => handlePrev(step)}
                  cardStyles={!isReviewStep ? null : { maxWidth: 1320 }}
                >
                  <Component
                    step={step}
                    goToNextStep={() => handleNext(step)}
                    setLoading={setLoading}
                    setDisabledNext={setDisabledNext}
                  />
                </StepContainer>
              </Route>
            )
          })}
        </Switch>
      </div>
    </Container>
  )
}

const bulkCreationSteps = [
  {
    name: 'Type',
    key: '',
    Component: Step1Type,
    next: 'upload',
  },
  {
    name: 'Upload',
    key: 'upload',
    Component: Step2Upload,
    next: 'review',
  },
  { name: 'Review', key: 'review', Component: Step3Review },
]

function Step1Type({ step, goToNextStep }) {
  const { bulkFormData, setBulkFormData } = useBulkCreationFlowContext()
  const { trackEvent } = useBulkContractActionEvents()

  const { handleSubmit, control } = useForm({
    defaultValues: { ...bulkFormData },
    resolver: yupResolver(
      yup.object().shape({
        type: yup
          .string()
          .oneOf(Object.values(FIELD_CONTRACT_TYPES))
          .required()
          .label('Contract type'),
      }),
    ),
  })

  function onSubmit(values) {
    setBulkFormData(values)

    goToNextStep()
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} id={getStepFormId(step)}>
      <ControlledRadioButtonV2
        control={control}
        label='Contract type'
        labelDescription='Choose the contract type you wish to upload'
        name='type'
        id='type'
        options={[
          { value: FIELD_CONTRACT_TYPES.fixed, label: 'Fixed' },
          { value: FIELD_CONTRACT_TYPES.milestone, label: 'Milestones' },
          { value: FIELD_CONTRACT_TYPES.payg, label: 'Pay as you go' },
        ]}
        transform={{
          output: (event) => {
            trackEvent(BULK_CONTRACT_CREATION_EVENTS.SELECTED_CONTRACT_TYPE, {
              contract_type: event.target.value,
            })
            return event
          },
        }}
      />
    </form>
  )
}

// Custom hook to make sure that we have data from the first step
// Otherwise, redirect back to the first step
function useCheckFirstStepData(formData, history) {
  useEffect(() => {
    // If we don't have the data from the first step, redirect back to the first step
    // Here we are checking with the name field
    if (!formData?.type) {
      history.push(BASE_PATH)
    }
  }, [formData, history])
}

const contractsKey = 'contracts'
function Step2Upload({ step, goToNextStep, setDisabledNext }) {
  const [uploadState, setUploadState] = useState(STATES.INITIAL)
  const [files, setFiles] = useState([])
  const [progress, setProgress] = useState(0)

  const history = useHistory()
  const { trackEvent } = useBulkContractActionEvents()

  const { bulkFormData, setBulkFormData } = useBulkCreationFlowContext()

  useCheckFirstStepData(bulkFormData, history)

  const contractType = bulkFormData?.type

  const { startFetch: downloadTemplate, isLoading: downloadingTemplate } =
    useFetch({
      action: getContractImportTemplate,
      onComplete: (data) => {
        openFileV2(data, {
          download: true,
          name: `contract-bulk-import-template-${contractType}.csv`,
          type: 'application/csv',
        })
        trackEvent(BULK_CONTRACT_CREATION_EVENTS.DOWNLOADED_TEMPLATE, {
          contract_type: contractType,
        })
      },
    })

  const { startFetch: uploadDocument, data: uploadedFileData } = useFetch({
    action: validateContractImportTemplate,
    onComplete: (data) => {
      if (data?.success === false) {
        toastr.error(data?.message)
      } else {
        setProgress(0)
        setUploadState(STATES.UPLOAD_SUCCESS)
        setDisabledNext(false)
      }
    },
    onError: (err) => {
      setProgress(0)
      toastr.error(err)
      setUploadState(STATES.UPLOAD_FAILED)
    },
  })
  function handleAcceptedFiles(acceptedFiles) {
    setUploadState(STATES.UPLOADING)

    const rawFile = acceptedFiles[0]

    setFiles([{ name: rawFile.name, size: rawFile.size, type: rawFile.type }])

    setDisabledNext(true)

    uploadDocument({
      template: rawFile,
      contract_type: contractType,
      progressFuncs: {
        onUploadProgress: (progressEvent) => {
          const progress = (progressEvent.loaded / progressEvent.total) * 50
          setProgress(progress)
        },
        onDownloadProgress: (progressEvent) => {
          const progress =
            50 + (progressEvent.loaded / progressEvent.total) * 50
          setProgress(progress)
        },
      },
    })
  }

  const { handleSubmit, control } = useForm({
    defaultValues: { ...bulkFormData },
    resolver: yupResolver(
      yup.object().shape({
        file: yup.mixed().required().label('CSV contracts file'),
      }),
    ),
  })

  const { contractor_types: contractorTypes } = useSelector(
    (state) => state.Layout?.staticData ?? {},
  )

  function onSubmit() {
    setBulkFormData({
      [contractsKey]: formatData(uploadedFileData, { contractorTypes }),
      fieldsOrder: uploadedFileData?.[0]?.map((item) => item.key),
    })

    goToNextStep()
  }

  return (
    <>
      <div className='tw-flex tw-items-center tw-justify-between tw-gap-3'>
        <div>
          <p className='tw-mb-0 tw-text-base tw-font-bold'>Empty CSV file</p>
          <p className='tw-mb-0 tw-text-sm tw-text-text-80'>
            Download the CSV template and fill it out.
          </p>
        </div>
        <Button
          color='link'
          className='!tw-px-0'
          icon={<DownloadSimple size={20} />}
          onClick={() => {
            downloadTemplate({ type: contractType })
          }}
          loading={downloadingTemplate}
          disabled={downloadingTemplate}
        >
          Download template
        </Button>
      </div>

      <FormSectionHr />

      <form onSubmit={handleSubmit(onSubmit)} id={getStepFormId(step)}>
        <FormSectionTitle
          title='Upload CSV'
          subTitle='Upload your updated CSV file.'
        />

        <UploadFlow
          control={control}
          name='file'
          state={uploadState}
          error='Something went wrong while uploading the file'
          progressValue={progress}
          files={files}
          startClassName='tw-text-black'
          title='Contracts'
          description='Upload your document'
          acceptLabel='CSV only'
          maxSize={null}
          accept={{ 'text/csv': ['.csv'], 'text/plain': ['.txt'] }}
          onClickUpload={handleAcceptedFiles}
          onClickRemove={() => {
            setUploadState(STATES.INITIAL)
            setFiles([])
          }}
        />
      </form>
    </>
  )
}

export const UPLOAD_STATUSES = {
  SUCCESS: scheduledTaskStatus.success,
  PROCESSING: scheduledTaskStatus.processing,
  FAILED: 'Failed',
  HAS_ERROR: 'Has error',
  READY_TO_UPLOAD: 'Ready to upload',
  READY_TO_SAVE: 'Ready to save',
}

function formatData(data, { contractorTypes }) {
  function getContractorId(contractorName) {
    return contractorTypes.find((c) => c.name === contractorName)?.id
  }

  return data.map((row, index) => {
    const errors = row
      .filter(({ is_valid: isValid }) => !isValid)
      .map(({ key }) => key)

    return row.reduce(
      (acc, curr) => {
        let value = curr.value
        // use id as value for currency, frequency, and tax_residence_id
        if (
          [
            'currency_id',
            'frequency_id',
            'tax_residence_id',
            'rate_id',
          ].includes(curr.key)
        ) {
          value = curr.id ? String(curr.id) : null
        } else if (curr.key === 'contractor_name') {
          if (!curr.value) {
            value = '1' // Defaults to contractor (value is 1)
            acc.errors = acc.errors.filter(
              (error) => error !== 'contractor_name',
            )
          } else {
            const foundId = getContractorId(curr.value)
            if (foundId) {
              value = String(foundId)
            }
          }
        }
        acc[curr.key] = value
        return acc
      },
      // use rp_template as default agreement type
      // add index & errors to the object
      { index, errors, agreement_type: 'rp_template' },
    )
  })
}

/**
 * Format error cells
 * [index, [columnName, columnName]]
 */
function formatErrors(data) {
  return data
    .filter((row) => row.errors.length > 0)
    .map((row) => [row.id, row.errors])
}

const columnHeader = {
  id: 'Status',
  worker_name: 'Name',
  tax_residence_id: 'Tax Country',
  worker_email: 'Email',
  contractor_name: 'Label',
  name: 'Job title',
  scope: 'Job Description',
  currency_id: 'Currency',
  amount: 'Payment amount',
  first_payment_date: 'First payment date',
  rate_id: 'Rate',
  frequency_id: 'Frequency',
  is_monthly_cycle: 'Monthly Cycle',
  start_date: 'Start date',
  end_date: 'End date',
  notice_period: 'Notice period',
  signatory_id: 'Signatory',
}

function Step3Review({ step, setDisabledNext, setLoading }) {
  const history = useHistory()
  const {
    countries,
    currencies,
    frequencies,
    rates,
    contractor_types: contractorTypes,
  } = useSelector((state) => state.Layout?.staticData ?? {})

  const { trackEvent } = useBulkContractActionEvents()

  const tableRef = useRef(null)

  function scrollTableToStart() {
    const table = tableRef?.current
    if (!table) return

    tableRef.current.parentElement.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }

  const { bulkFormData } = useBulkCreationFlowContext()
  useCheckFirstStepData(bulkFormData, history)
  const contractType = bulkFormData?.type
  const { handleSubmit, control } = useForm({
    defaultValues: { ...bulkFormData },
  })

  const isFixed = useMemo(
    () => contractType === FIELD_CONTRACT_TYPES.fixed,
    [contractType],
  )
  const isMilestone = useMemo(
    () => contractType === FIELD_CONTRACT_TYPES.milestone,
    [contractType],
  )
  const isPayg = useMemo(
    () => contractType === FIELD_CONTRACT_TYPES.payg,
    [contractType],
  )

  // Selected contract i.e. the one that is currently being edited
  const [selectedContractId, setSelectedContractId] = useState(null)
  const { fields, remove, update } = useFieldArray({
    control,
    name: contractsKey,
  })
  const errorCells = formatErrors(fields)
  const selectedContract = fields.find(
    (field) => field.id === selectedContractId,
  )

  useEffect(() => {
    const errorCounts = {}
    let totalErrors = 0
    errorCells.forEach(([, errorFields]) => {
      errorFields.forEach((field) => {
        if (errorCounts[columnHeader[field]]) {
          errorCounts[columnHeader[field]]++
        } else {
          errorCounts[columnHeader[field]] = 1
        }
        totalErrors++
      })
    })

    trackEvent(BULK_CONTRACT_CREATION_EVENTS.UPLOADED_CSV, {
      contract_type: contractType,
      contract_count: fields.length,
      total_errors: totalErrors,
      ...errorCounts,
    })
  }, [])

  const [showSendInviteModal, setShowSendInviteModal] = useState(false)

  const { startFetch: checkImportTaskStatus, data: importTaskStatusData } =
    useFetch({
      action: getScheduledTaskStatus,
      onComplete: (data) => {
        if (data?.success === false) {
          toastr.error('Failed to check import status')
        } else {
          const stillHaveProcessingItems = data?.processes?.some(
            (process) => process?.status === scheduledTaskStatus.processing,
          )

          toastr.clear()
          if (stillHaveProcessingItems) {
            setTimeout(() => checkImportStatus(data?.id), 500)
          } else {
            setShowSendInviteModal(true)
          }
        }
      },
      onError: (error) => {
        toastr.error(error)
      },
    })

  const successFullImports = importTaskStatusData?.processes?.filter(
    (process) => process.status === UPLOAD_STATUSES.SUCCESS,
  )

  const { startFetch: importContracts, isLoading: isImporting } = useFetch({
    action: processContractImport,
    onComplete: (data) => {
      if (data?.success === false) {
        toastr.error('Failed to import contracts')
      } else {
        toastr.success('Importing contracts...')
        scrollTableToStart()
        checkImportStatus(data?.id)
        trackEvent(BULK_CONTRACT_CREATION_EVENTS.SUBMITTED_REQUEST, {
          contract_type: contractType,
          contract_count: fields.length,
        })
      }
      setDisabledNext(false)
      setLoading(false)
    },
    onError: (error) => {
      setDisabledNext(false)
      setLoading(false)
      toastr.error(error)
    },
  })

  function checkImportStatus(id) {
    checkImportTaskStatus({ id })
  }

  const { startFetch: sendInvites, isLoading: isSendingInvites } = useFetch({
    action: sendContractImportInvites,
    onComplete: (data) => {
      if (data?.success === false) {
        toastr.error('Failed to send invites')
      } else {
        toastr.success(
          'Your workers will be notified via email',
          `${successFullImports?.length} invitations sent!`,
        )
        history.push('/contracts')
        setShowSendInviteModal(false)
      }
    },
    onError: (error) => {
      toastr.error(error)
    },
  })

  function onSubmit(values) {
    if (errorCells.length > 0) {
      toastr.error('Please fix the contract errors before saving')
      return
    }

    const formattedBody = {
      contract_type: values?.type,
      list: fields.map((field) => {
        const newValues = bulkFormData?.fieldsOrder?.map((key) => {
          switch (key) {
            case 'start_date':
            case 'end_date':
            case 'first_payment_date': {
              return formatDate(field[key])
            }

            case 'currency_id': {
              return getOptionFromList(currencies, Number(field[key]), 'id')
                ?.code
            }

            case 'frequency_id': {
              return getOptionFromList(frequencies, Number(field[key]), 'id')
                ?.name
            }

            case 'tax_residence_id': {
              return getOptionFromList(countries, Number(field[key]), 'id')
                ?.name
            }

            case 'rate_id': {
              return getOptionFromList(rates, Number(field[key]), 'id')?.name
            }

            case 'contractor_name': {
              return getOptionFromList(
                contractorTypes,
                Number(field[key]),
                'id',
              )?.name
            }

            default: {
              return field[key]
            }
          }
        })

        return [
          ...newValues,
          field.agreement_type === 'custom_contract'
            ? field.custom_contract_file
            : null,
        ]
      }),
    }

    setDisabledNext(true)
    setLoading(true)

    importContracts(formattedBody)
  }

  const columns = useMemo(() => {
    return [
      {
        Header: columnHeader.id,
        accessor: 'id',
        Cell: ({ rowData }) => {
          const processingStatus = importTaskStatusData?.processes?.find(
            (_, index) => index === rowData?.index,
          )

          const rowErrors = errorCells.find(([currentId]) => {
            return currentId === rowData?.id
          })
          const errorsLength = rowErrors?.[1]?.length
          const hasError = errorsLength > 0

          const status =
            processingStatus?.status ??
            (isImporting
              ? UPLOAD_STATUSES.PROCESSING
              : hasError
                ? UPLOAD_STATUSES.HAS_ERROR
                : UPLOAD_STATUSES.READY_TO_UPLOAD)

          return (
            <UploadStatus
              status={status}
              suffix={
                !processingStatus?.status && hasError ? `(${errorsLength})` : ''
              }
            />
          )
        },
      },
      {
        Header: columnHeader.worker_name,
        accessor: 'worker_name',
        className: 'tw-font-bold',
      },
      {
        Header: columnHeader.tax_residence_id,
        accessor: 'tax_residence_id',
        Cell: ({ cellData }) => {
          const foundCountry = countries.find(
            (country) => String(country.id) === String(cellData),
          )

          return (
            <div className='tw-flex tw-items-center tw-gap-2'>
              {!foundCountry ? null : (
                <Flag
                  url={foundCountry?.svg}
                  size='18px'
                  className='tw-flex-shrink-0'
                />
              )}
              <span>{foundCountry?.name || cellData || 'No value'}</span>
            </div>
          )
        },
      },
      { Header: columnHeader.worker_email, accessor: 'worker_email' },
      {
        Header: columnHeader.contractor_name,
        accessor: 'contractor_name',
        Cell: ({ cellData }) => {
          const foundType = contractorTypes.find(
            (type) => String(type.id) === String(cellData),
          )

          return foundType?.name ?? cellData
        },
      },
      { Header: columnHeader.name, accessor: 'name' },
      {
        Header: columnHeader.scope,
        accessor: 'scope',
        Cell: ({ cellData }) => {
          return (
            <div className='tw-flex tw-items-center'>
              <p className='tw-mb-0 tw-inline-block tw-max-w-[22ch] tw-truncate'>
                {cellData}
              </p>
              {cellData.length > 22 && (
                <ViewDescriptionButton text={cellData} />
              )}
            </div>
          )
        },
      },
      {
        Header: columnHeader.currency_id,
        accessor: 'currency_id',
        Cell: ({ cellData }) => {
          const foundCurrency = currencies.find(
            (currency) => String(currency.id) === String(cellData),
          )

          return foundCurrency?.code ?? cellData
        },
      },
      isMilestone ? null : { Header: columnHeader.amount, accessor: 'amount' },
      isMilestone
        ? null
        : {
            Header: columnHeader.first_payment_date,
            accessor: 'first_payment_date',
            Cell: dateCell,
          },
      isPayg && {
        Header: columnHeader.rate_id,
        accessor: 'rate_id',
        Cell: ({ cellData }) => {
          const foundRate = rates.find(
            (rate) => String(rate.id) === String(cellData),
          )

          return foundRate?.name ?? cellData
        },
      },
      isMilestone
        ? null
        : {
            Header: columnHeader.frequency_id,
            accessor: 'frequency_id',
            Cell: ({ cellData }) => {
              const foundFrequency = frequencies.find(
                (frequency) => String(frequency.id) === String(cellData),
              )

              return foundFrequency?.name ?? cellData
            },
          },
      isFixed && {
        Header: columnHeader.is_monthly_cycle + ' (Yes/No)',
        accessor: 'is_monthly_cycle',
      },
      isMilestone
        ? null
        : {
            Header: columnHeader.start_date,
            accessor: 'start_date',
            Cell: dateCell,
          },
      isMilestone
        ? null
        : {
            Header: columnHeader.end_date,
            accessor: 'end_date',
            Cell: dateCell,
          },
      { Header: columnHeader.notice_period, accessor: 'notice_period' },
      { Header: columnHeader.signatory_id, accessor: 'signatory_id' },
      {
        Header: '',
        accessor: 'action',
        Cell: ({ rowData }) => {
          return (
            <Button
              type='button'
              onClick={() => setSelectedContractId(rowData.id)}
              color='link'
              icon={<Eye size={16} />}
              className='!tw-p-0.5'
            >
              Details
            </Button>
          )
        },
      },
    ]
      .filter(Boolean)
      .map((column) => {
        if (['status', 'action'].includes(column.accessor)) {
          return column
        }

        const currentCell = column?.Cell ?? (({ cellData }) => cellData)

        return {
          ...column,
          Cell: (args) => {
            const { rowData } = args
            const hasError = errorCells.some(([currentId, cells]) => {
              return currentId === rowData.id && cells.includes(column.accessor)
            })

            return (
              <FailedEditableField
                hasError={hasError}
                onClick={() => {
                  setSelectedContractId(rowData?.id)
                }}
              >
                {currentCell(args)}
              </FailedEditableField>
            )
          },
        }
      })
  }, [
    contractorTypes,
    countries,
    currencies,
    errorCells,
    frequencies,
    importTaskStatusData?.processes,
    isFixed,
    isImporting,
    isMilestone,
    isPayg,
    rates,
  ])

  return (
    <>
      <PrimaryAlert className='tw-mb-4'>
        We will automatically generate localized contracts with RemotePass
        templates. You can always upload yours from the details section of each
        record.
      </PrimaryAlert>

      <DataTable
        data={fields}
        columns={columns}
        responsive
        striped
        fontClassName='!tw-text-text-100'
        className='tw-mt-6'
        tableRef={tableRef}
      />

      {!selectedContract ? null : (
        <BulkCreationSideMenu
          contractData={selectedContract}
          contractType={contractType}
          onSave={(...args) => {
            setSelectedContractId(null)
            update(...args)
          }}
          onDelete={(contractData) => {
            setSelectedContractId(null)
            remove(contractData.index)
          }}
          onClose={() => setSelectedContractId(null)}
        />
      )}

      <form onSubmit={handleSubmit(onSubmit)} id={getStepFormId(step)}></form>

      {!showSendInviteModal ? null : (
        <ConfirmationModal
          isOpen
          negativeCaption='Do it later'
          onCancel={() => {
            history.push('/contracts')
          }}
          caption='Invite all workers'
          confirmIcon={<PaperPlaneRight size={20} />}
          onConfirm={() => {
            sendInvites({ task_id: importTaskStatusData?.id })
          }}
          confirmLoading={isSendingInvites}
          content={
            <>
              <CheckCircle
                size={24}
                className='tw-mb-2 tw-fill-systemGreen-100'
              />

              <p className='tw-mb-2 tw-text-xl tw-font-semibold tw-text-secondary-120'>
                You imported {successFullImports?.length} contracts successfully
              </p>
              <p className='tw-text-sm tw-font-medium tw-text-text-80'>
                Do you want to send invites to all the workers to their
                respective emails?
              </p>
            </>
          }
        />
      )}
    </>
  )
}

function ViewDescriptionButton({ text }) {
  const [isOpen, setIsOpen] = useState(false)
  function toggle() {
    setIsOpen((open) => !open)
  }

  return (
    <>
      <IconButtonSimple
        icon={<Eye size={16} />}
        className='tw-inline-block'
        onClick={toggle}
      />

      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalBody>
          <ModalCloseButton toggle={toggle} className='!tw-float-none' />

          <p className='tw-mb-0 tw-text-sm tw-text-text-80'>{text}</p>
        </ModalBody>
      </Modal>
    </>
  )
}

export function UploadStatus({ status, suffix }) {
  if (status === UPLOAD_STATUSES.SUCCESS) {
    return (
      <div className='tw-flex tw-items-center tw-gap-2 tw-text-sm tw-capitalize tw-text-systemGreen-100'>
        <CheckCircle size={16} />
        {status}
        {suffix ? <span>{suffix}</span> : null}
      </div>
    )
  } else if (status === UPLOAD_STATUSES.PROCESSING) {
    return (
      <div className='tw-flex tw-items-center tw-gap-2 tw-text-sm tw-capitalize tw-text-primary-100'>
        <Spinner size={16} className='tw-animate-[spin_2s_linear_infinite]' />
        {status}
        {suffix ? <span>{suffix}</span> : null}
      </div>
    )
  } else if (
    [
      UPLOAD_STATUSES.HAS_ERROR,
      UPLOAD_STATUSES.FAILED,
      scheduledTaskStatus.failed,
    ].includes(status)
  ) {
    return (
      <div className='tw-flex tw-items-center tw-gap-2 tw-text-sm tw-capitalize tw-text-systemRed-100'>
        <Warning size={16} />
        {status}
        {suffix ? <span>{suffix}</span> : null}
      </div>
    )
  } else {
    return (
      <div className='tw-capitalize tw-text-secondary-80'>
        {status}
        {suffix ? (
          <span className='tw-text-sm tw-text-text-80'> {suffix}</span>
        ) : null}
      </div>
    )
  }
}

export function FailedEditableField({ children, onClick, hasError }) {
  if (!hasError) return children

  return (
    <div className='tw-flex tw-items-center tw-gap-2'>
      <Warning size={20} className='tw-text-systemRed-100' />
      <div className='tw-text-systemRed-100'>{children || 'No value'}</div>
      <IconButtonSimple
        icon={<PencilSimple size={16} />}
        className='-tw-ms-0.5'
        onClick={onClick}
      />
    </div>
  )
}

function formatDate(date) {
  const dateObject = new Date(date)
  const isValidDate = dateObject instanceof Date && !isNaN(dateObject)
  if (!date || !isValidDate) {
    return ''
  } else {
    return format(dateObject, DATE_FORMAT.YYYY_MM_DD)
  }
}
function dateCell({ cellData: date }) {
  return formatDate(date)
}

function yupTestDateFormat(value, { format = 'yyyy-MM-dd' } = {}) {
  const parsedDate = parse(value, format, new Date())
  return !isNaN(parsedDate.getTime()) // return true if parsed date is valid
}

function BulkCreationSideMenu({
  contractData,
  contractType,
  onSave,
  onDelete,
  onClose,
}) {
  const isOpen = !!contractData

  function _onClose() {
    if (isUploadingCustomContract) {
      return
    }
    onClose?.()
  }

  const isFixed = contractType === FIELD_CONTRACT_TYPES.fixed
  const isMilestone = contractType === FIELD_CONTRACT_TYPES.milestone
  const isPayg = contractType === FIELD_CONTRACT_TYPES.payg

  const {
    countries,
    currencies,
    frequencies,
    rates,
    contractor_types: contractorTypes,
  } = useSelector((state) => state.Layout?.staticData ?? {})

  // @todo: countries list should depend on the contract type
  const countriesOptions = countries?.map((c) =>
    mapCountryToOption(c, 'id', { stringifyValues: true }),
  )
  const currenciesOptions = currencies?.map((c) =>
    mapCurrencyToOption(c, 'id', { stringifyValues: true }),
  )
  const frequencyOptions = frequencies?.map((frequency) =>
    mapFrequencyToOption(frequency, { stringifyValues: true }),
  )
  const ratesOptions = rates?.map((rate) =>
    mapListToOption(rate, { stringifyValues: true }),
  )
  const contractorOptions = contractorTypes?.map((rate) =>
    mapListToOption(rate, { stringifyValues: true }),
  )

  const { data: signatories, isLoading: loadingSignatories } = useFetch({
    action: getSignatoryList,
    autoFetch: true,
  })
  const signatoryOptions = signatories?.map((signatory) => ({
    label: `${getFullName(signatory)} (${signatory?.email})`,
    value: signatory?.email,
  }))

  const { handleSubmit, control, watch, trigger, setValue } = useForm({
    defaultValues: { ...contractData },
    resolver: yupResolver(
      yup.object().shape({
        worker_name: yup.string().required().label('Full name'),
        tax_residence_id: yup.string().required().label('Tax country'),
        worker_email: yup.string().email().required().label('Email'),
        contractor_name: yup
          .string()
          .oneOf(
            contractorOptions.map((type) => type.value),
            `Label must be one of the following values: ${contractorOptions
              .map((type) => type?.label)
              .join(', ')}`,
          )
          .required()
          .label('Label'),
        name: yup.string().required().label('Job title'),
        scope: yup.string().required().label('Job description'),
        currency_id: yup.string().required().label('Currency'),
        amount: yup
          .number()
          // eslint-disable-next-line no-template-curly-in-string
          .typeError('${label} must be a number.')
          .when('$', {
            is: () => !isMilestone,
            then: (schema) => schema.required(),
            otherwise: (schema) => schema.notRequired().nullable(),
          })
          .label('Payment amount'),
        rate_id: yup
          .string()
          .when('$', {
            is: () => isPayg,
            then: (schema) =>
              schema
                .oneOf(
                  ratesOptions.map((option) => option.value),
                  `Rate must be one of the following values: ${ratesOptions
                    .map((option) => option?.label)
                    .join(', ')}`,
                )
                .required(),
            otherwise: (schema) => schema.notRequired().nullable(),
          })
          .label('Rate'),
        frequency_id: yup
          .string()
          .when('$', {
            is: () => !isMilestone,
            then: (schema) =>
              schema
                .oneOf(
                  frequencyOptions.map((option) => option.value),
                  `Frequency must be one of the following values: ${frequencyOptions
                    .map((option) => option?.label)
                    .join(', ')}`,
                )
                .required(),
            otherwise: (schema) => schema.notRequired().nullable(),
          })
          .label('Frequency'),
        is_monthly_cycle: yup
          .string()
          .label('Is monthly cycle')
          .when('$', {
            is: () => isFixed,
            then: (schema) => schema.oneOf(['Yes', 'No']).required(),
            otherwise: (schema) => schema.notRequired().nullable(),
          }),
        first_payment_date: yup
          .date()
          // eslint-disable-next-line no-template-curly-in-string
          .typeError('${label} must be a valid date.')
          .when('$', {
            is: () => !isMilestone,
            then: (schema) => schema.required(),
            otherwise: (schema) => schema.notRequired().nullable(),
          })
          .label('First payment date'),
        start_date: yup
          .string()
          // eslint-disable-next-line no-template-curly-in-string
          .typeError('${label} must be a valid date.')
          .test('date', 'Invalid date format', yupTestDateFormat)
          .when('$', {
            is: () => !isMilestone,
            then: (schema) => schema.required(),
            otherwise: (schema) => schema.notRequired().nullable(),
          })
          .label('Start date'),
        end_date: yup
          .string()
          .test('date', 'Invalid date format', (value) => {
            if (!value) {
              return true
            }
            return yupTestDateFormat(value)
          })
          .nullable()
          .label('End date'),
        notice_period: yup
          .number()
          .min(0, 'Notice period must be greater than 0')
          .typeError('Notice period must be a number')
          .required()
          .label('Notice period'),
        signatory_id: yup.string().email().label('Signatory').nullable(),
        agreement_type: yup
          .string()
          .oneOf(['rp_template', 'custom_contract'])
          .required()
          .label('Worker agreement'),
        custom_contract: yup.mixed().when('agreement_type', {
          is: 'custom_contract',
          then: (schema) => schema.required().label('Contract file'),
          otherwise: (schema) => schema.nullable(),
        }),
        custom_contract_file: yup.string().when('agreement_type', {
          is: 'custom_contract',
          then: (schema) => schema.required().label('Contract file'),
          otherwise: (schema) => schema.nullable(),
        }),
      }),
    ),
  })

  const {
    custom_contract: customContract,
    custom_contract_file: customContractFile,
  } = watch({ control })

  // #region Trigger field validation on open
  useEffect(() => {
    trigger(undefined, { shouldFocus: true })
  }, [trigger])
  // Trigger field validation on change
  useTriggerField({ watch, trigger })
  // #endregion

  const { agreement_type: agreementType } = useWatch({ control })
  const isCustomAgreement = agreementType === 'custom_contract'

  const {
    startFetch: uploadCustomContract,
    isLoading: isUploadingCustomContract,
    data: uploadedCustomContract,
    setData: setUploadedCustomContract,
    error: uploadError,
    setError: setUploadError,
  } = useFetch({
    action: uploadContractFileV2,
    onComplete: (data) => {
      if (data?.success === false) {
        toastr.error(data?.message)
      } else {
        // Setting the value to a hidden input
        setValue('custom_contract_file', data?.path)
      }
    },
  })

  function handleUploadCustomContract(acceptedFiles) {
    const file = acceptedFiles?.[0]
    uploadCustomContract({ file })
  }

  function handleResetCustomContract() {
    setValue('custom_contract', null)
    setValue('custom_contract_file', null)
    setUploadedCustomContract(null)
    setUploadError(null)
  }

  return (
    <SideMenu
      className='tw-max-w-[480px]'
      itemListClassName='tw-grid tw-grid-rows-[auto_1fr_91px] [&>*:nth-child(2)]:tw-overflow-auto [&>*:nth-child(2)]:tw-overscroll-contain'
      isOpen={isOpen}
      onClose={_onClose}
    >
      <SideMenu.Header toggle={_onClose}>{contractData?.name}</SideMenu.Header>
      <SideMenu.Body className='tw-space-y-6'>
        <div>
          <h4 className='tw-mb-0 tw-text-base tw-font-bold'>Worker details</h4>
          <p className='tw-mb-0 tw-text-sm tw-text-text-80'>
            Manage your worker details
          </p>
        </div>
        <ControlledInput
          control={control}
          name='worker_name'
          id='worker_name'
          label='Full name'
          placeholder='Full name'
        />

        <ControlledSelect
          control={control}
          name='tax_residence_id'
          label='Tax country'
          options={countriesOptions}
        />

        <ControlledInput
          control={control}
          name='worker_email'
          id='worker_email'
          label='Email'
          placeholder='Email'
        />

        <ControlledSelect
          control={control}
          name='contractor_name'
          label={<ContractorLabel />}
          options={contractorOptions}
        />

        <ControlledInput
          control={control}
          name='name'
          id='name'
          label='Job title'
          placeholder='Job title'
        />

        <ControlledInput
          control={control}
          name='scope'
          id='scope'
          label='Job description'
          placeholder='Job description'
          type='textarea'
          rows={10}
          className='tw-scroll-pb-3'
        />

        <SideMenu.Divider />

        <div>
          <h4 className='tw-mb-0 tw-text-base tw-font-bold'>Payment</h4>
          <p className='tw-mb-0 tw-text-sm tw-text-text-80'>
            Manage your payment details
          </p>
        </div>

        <ControlledSelect
          control={control}
          name='currency_id'
          label='Currency'
          placeholder='Currency'
          options={currenciesOptions}
        />

        {/* @todo: add currency suffix */}
        {isMilestone ? null : (
          <ControlledInput
            control={control}
            name='amount'
            id='amount'
            label='Payment amount'
            placeholder='amount'
          />
        )}

        {isMilestone ? null : (
          <ControlledDatePicker
            control={control}
            name='first_payment_date'
            id='first_payment_date'
            label='First payment date'
            placeholder='First payment date'
            dateFormat={DATE_FORMAT.YYYY_MM_DD}
          />
        )}

        {!isPayg ? null : (
          <ControlledSelect
            control={control}
            name='rate_id'
            label='Rate'
            placeholder='Rate'
            options={ratesOptions}
          />
        )}

        {isMilestone ? null : (
          <ControlledSelect
            control={control}
            name='frequency_id'
            label='Frequency'
            placeholder='Frequency'
            options={frequencyOptions}
          />
        )}

        {!isFixed ? null : (
          <div className='tw-rounded-se tw-rounded-ss tw-border tw-border-surface-30 tw-p-4'>
            <h4 className='tw-mb-6 tw-text-base tw-font-bold'>
              Is monthly cycle?
            </h4>

            <ControlledRadioButtonV2
              control={control}
              name='is_monthly_cycle'
              options={[
                { label: 'Yes', value: 'Yes' },
                { label: 'No', value: 'No' },
              ]}
              optionClassName='[&>:first-child]:tw-sr-only tw-flex-1 [&>:last-child]:tw-justify-center'
              optionsClassName='tw-flex-row'
            />
          </div>
        )}

        <SideMenu.Divider />

        <div>
          <h4 className='tw-mb-0 tw-text-base tw-font-bold'>Contract</h4>
          <p className='tw-mb-0 tw-text-sm tw-text-text-80'>
            Manage your contract details
          </p>
        </div>

        {isMilestone ? null : (
          <>
            <ControlledDatePicker
              control={control}
              name='start_date'
              id='start_date'
              label='Start date'
              placeholder='Start date'
              dateFormat={DATE_FORMAT.YYYY_MM_DD}
            />

            <ControlledDatePicker
              control={control}
              name='end_date'
              id='end_date'
              label='End date (optional)'
              placeholder='End date'
              dateFormat={DATE_FORMAT.YYYY_MM_DD}
              clearable
            />
          </>
        )}

        {/* @todo: add "days" suffix */}
        <ControlledInput
          control={control}
          name='notice_period'
          id='notice_period'
          label='Notice period'
          placeholder='Notice period'
          type='number'
          min={0}
        />

        <ControlledSelect
          control={control}
          name='signatory_id'
          label='Signatory (optional)'
          placeholder='Signatory'
          options={signatoryOptions}
          disabled={loadingSignatories}
          isLoading={loadingSignatories}
          isClearable
        />

        <SideMenu.Divider />

        <div>
          <div className='tw-rounded-se tw-rounded-ss tw-border tw-border-surface-30 tw-p-4'>
            <h4 className='tw-mb-6 tw-text-base tw-font-bold'>
              Worker agreement
            </h4>

            <ControlledRadioButtonV2
              control={control}
              name='agreement_type'
              options={[
                { label: 'RemotePass Template', value: 'rp_template' },
                { label: 'Upload my contract', value: 'custom_contract' },
              ]}
              optionClassName='[&>:first-child]:tw-sr-only tw-flex-1 [&>:last-child]:tw-justify-center'
              optionsClassName='tw-flex-row'
              transform={{
                output: (args) => {
                  handleResetCustomContract()
                  return args
                },
              }}
            />
          </div>

          <div className='tw-rounded-ee tw-rounded-es tw-border tw-border-t-0 tw-border-surface-30 tw-bg-surface-10 tw-p-4'>
            {isCustomAgreement ? (
              <>
                <UploadFlow
                  control={control}
                  name='custom_contract'
                  accept={{ 'application/pdf': ['.pdf'] }}
                  title='Worker agreement'
                  description='Upload your signed document'
                  endClassName='md:tw-text-start'
                  startClassName='tw-text-black'
                  state={
                    isUploadingCustomContract
                      ? STATES.UPLOADING
                      : uploadedCustomContract?.path || customContractFile
                        ? STATES.UPLOAD_SUCCESS
                        : uploadError
                          ? STATES.UPLOAD_FAILED
                          : STATES.INITIAL
                  }
                  onClickUpload={handleUploadCustomContract}
                  files={customContract}
                  onClickRemove={() => {
                    handleResetCustomContract()
                  }}
                />
              </>
            ) : (
              <p className='tw-mb-0 tw-text-sm tw-text-text-60'>
                RemotePass’ template agreement will be used.
              </p>
            )}
          </div>
        </div>
      </SideMenu.Body>
      <SideMenu.Footer>
        <Button
          color='link'
          className='tw-mr-auto !tw-px-0 !tw-text-systemRed-100'
          disabled={isUploadingCustomContract}
          onClick={() => onDelete(contractData)}
        >
          Delete
        </Button>
        <Button
          color='light'
          outline
          disabled={isUploadingCustomContract}
          onClick={_onClose}
        >
          Cancel
        </Button>
        <Button
          type='button'
          disabled={isUploadingCustomContract}
          onClick={handleSubmit(
            (values) => {
              onSave(contractData.index, { ...values, errors: [] })
            },
            () => {
              toastr.clear()
              toastr.error('Please fix the contract errors before saving')
            },
          )}
        >
          Save
        </Button>
      </SideMenu.Footer>
    </SideMenu>
  )
}
