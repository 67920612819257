import cx from 'classnames'
import React from 'react'
import { Badge } from 'reactstrap'

const sizeClassName = {
  xs: '!tw-text-[6px] 2xl:!tw-text-[8px]',
  sm: '!tw-text-[10px]',
  md: '!tw-text-xs',
  lg: '!tw-text-base',
}

export default function BadgeV2({
  status,
  textStatus = status,
  name,
  children,
  className,
  style,
  size = 'md',
  leftIcon,
  rightIcon,
  textClassName,
  noPadding = false,
  textStyle,
  pill = true,
  ...props
}) {
  return (
    <Badge
      className={cx(
        'tw-items-center !tw-font-bold tw-uppercase',
        sizeClassName[size],
        {
          [`badge-soft-${status}`]: status,
          [`text-${textStatus}`]: textStatus,
          '!tw-px-2 !tw-py-1': !noPadding,
          '!tw-inline-flex tw-gap-0.5': leftIcon || rightIcon,
        },
        className,
      )}
      style={style}
      pill={pill}
      {...props}
    >
      {!leftIcon ? null : leftIcon}

      <span style={textStyle} className={cx('tw-leading-4', textClassName)}>
        {name ?? children}
      </span>

      {!rightIcon ? null : rightIcon}
    </Badge>
  )
}
