import {
  ArrowRight,
  ArrowSquareOut,
  CheckCircle,
  DotsThreeCircleVertical,
  Info,
} from '@phosphor-icons/react'
import { format } from 'date-fns'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { UncontrolledTooltip } from 'reactstrap'

import { cn } from 'ui'
import BadgeV2 from '../../../components/ui/badge-v2'
import Button from '../../../components/ui/button'
import { WithTooltip } from '../../../components/ui/with-tooltip'
import { CONTRACT_STATUS } from '../../../helpers/enum'
import { useFetch, usePermissions } from '../../../helpers/hooks'
import { getCountryFeatures } from '../../../services/api-compliance'
import { DATE_FORMAT } from '../../../utils/formatters/date-picker-date-format'
import { getFullName } from '../../../utils/get-full-name'
import { ContractBadge } from '../../Contract/ContractDetail/contract-timeline'
import { RequestBgCheckModal } from '../components/request-bg-check-modal'
import permissions from '../../../helpers/permissions'

const LOG_STATUS = {
  SENT: 'SENT',
  DONE: 'DONE',
  PENDING: 'PENDING',
}

function LogIcon({ status, size = 20 }) {
  switch (status) {
    case LOG_STATUS.DONE:
    case LOG_STATUS.SENT: {
      return (
        <CheckCircle
          weight='bold'
          className='tw-text-systemGreen-100'
          size={size}
        />
      )
    }
    default: {
      return (
        <DotsThreeCircleVertical
          className='tw-text-systemGold-100'
          size={size}
        />
      )
    }
  }
}

function getStatusLabel({ type, status }) {
  if (type === 'workerConsent') {
    return 'Worker consent'
  }

  if (type === 'requestCheck') {
    return 'Request check'
  }

  if (type === 'report') {
    return 'Background check report'
  }

  return status
}

export function BackgroundCheckSection({
  activeItem,
  onCheckRequestSuccess,
  backgroundCheck,
}) {
  if (
    !backgroundCheck &&
    [
      CONTRACT_STATUS.PENDING_INVITE.value,
      CONTRACT_STATUS.CANCELLED.value,
      CONTRACT_STATUS.ENDED.value,
      CONTRACT_STATUS.TERMINATED.value,
    ].includes(activeItem.status.id)
  ) {
    return null
  }

  if (!backgroundCheck) {
    return (
      <RequestCheckSection
        activeItem={activeItem}
        onCheckRequestSuccess={onCheckRequestSuccess}
      />
    )
  }

  const logs = Object.entries(backgroundCheck?.userActivityLog)?.map(
    ([key, value]) => {
      const { status, date } = value

      const actor =
        key === 'requestCheck'
          ? backgroundCheck?.requester
          : key === 'workerConsent'
            ? backgroundCheck?.applicant
            : key === 'report'
              ? { first_name: 'Certn' }
              : null

      const isPendingContent = key === 'workerConsent' && status === 'PENDING'
      const dateLabel = isPendingContent ? (
        <BadgeV2
          status='warning'
          rightIcon={
            <WithTooltip tooltipText='The email invitation has been sent to the worker, and we are awaiting confirmation of consent to proceed with the checks.'>
              <Info weight='fill' />
            </WithTooltip>
          }
        >
          Pending
        </BadgeV2>
      ) : date ? (
        format(new Date(date), DATE_FORMAT.FORMAT_1)
      ) : null

      return {
        ...value,
        id: key,
        icon: <LogIcon status={status} />,
        statusLabel: getStatusLabel({ type: key, status }),
        actor,
        date: dateLabel,
        time: date ? format(new Date(date), 'hh:mm a') : null,
      }
    },
  )

  return (
    <div className='tw-px-6 tw-pt-3'>
      <div>
        {logs?.map((log) => {
          return (
            <LogItem
              key={log.id}
              icon={log.icon}
              statusLabel={log.statusLabel}
              actor={log.actor}
              date={log.date}
              time={log.time}
              className='tw-border-b tw-border-b-surface-30'
            />
          )
        })}
      </div>
    </div>
  )
}

function RequestCheckSection({ activeItem, onCheckRequestSuccess }) {
  const userProfile = useSelector((state) => state.userProfile?.userProfile)

  const { data: countryFeaturesData } = useFetch({
    action: getCountryFeatures,
    body: {
      limit: 500,
      isoCode: activeItem.contractor.country.iso3,
    },
    autoFetch: true,
  })

  return (
    <div className='tw-px-6 tw-pt-4'>
      <div className='tw-rounded tw-border tw-border-surface-30 tw-p-6'>
        <p className='tw-mb-2 tw-inline-flex tw-items-center tw-gap-1 tw-text-sm tw-font-medium tw-text-text-80'>
          <span className='tw-text-text-80'>Softcheck by Certn</span>
          <a
            href='https://help.remotepass.com/en/articles/9330637-how-to-run-a-background-check-for-contractors-and-employees'
            target='_blank'
            rel='noreferrer'
            className='tw-inline-flex tw-items-center tw-gap-1 tw-text-black'
          >
            <span>{userProfile.company?.background_check_fee}</span>
            <ArrowSquareOut size={20} />
          </a>
        </p>
        <h4 className='tw-text-2xl tw-font-bold'>Background check</h4>
        <p className='tw-mb-0 tw-text-sm tw-font-medium tw-text-text-80'>
          {activeItem?.contractor?.first_name ?? activeItem?.contractor?.email}{' '}
          will be notified to provide consent and the report will be available
          under Documents
        </p>

        <RequestCheck
          items={[
            {
              name: getFullName(activeItem?.contractor),
              status: (
                <ContractBadge
                  amended={activeItem?.amended}
                  status={activeItem?.status}
                  lastTimeline={
                    activeItem?.timeline?.[activeItem?.timeline?.length - 1]
                  }
                />
              ),
              raw: activeItem,
            },
          ]}
          onCheckRequestSuccess={onCheckRequestSuccess}
          isSupported={countryFeaturesData?.[0]?.background_check_enabled}
        />
      </div>
    </div>
  )
}

export function RequestCheck({
  items,
  onCheckRequestSuccess,
  customAction,
  isSupported,
}) {
  const [showActionModal, setShowActionModal] = useState(false)
  const { hasAccess } = usePermissions()

  function toggle() {
    setShowActionModal((open) => !open)
  }

  return (
    <>
      {customAction && typeof customAction === 'function' ? (
        customAction({ onClick: toggle })
      ) : (
        <div id='check' className='tw-w-fit'>
          <Button
            iconRight={<ArrowRight size={20} />}
            color='link'
            className='!tw-px-0 !tw-pb-2'
            onClick={toggle}
            disabled={
              !isSupported || !hasAccess(permissions.RequestContractsDocuments)
            }
          >
            Request
          </Button>
          {(!isSupported ||
            !hasAccess(permissions.RequestContractsDocuments)) && (
            <UncontrolledTooltip target='check'>
              {!hasAccess(permissions.RequestContractsDocuments)
                ? 'You do not have permission to manage documents. Please contact your administrator.'
                : 'Unsupported country'}
            </UncontrolledTooltip>
          )}
        </div>
      )}

      <RequestBgCheckModal
        isOpen={showActionModal}
        toggle={toggle}
        itemsToCheck={items}
        onSuccess={onCheckRequestSuccess}
      />
    </>
  )
}

function LogItem({ statusLabel, actor, date, time, className }) {
  return (
    <div
      className={cn('tw-flex tw-justify-between tw-gap-2 tw-py-4', className)}
    >
      <div className='tw-flex tw-flex-col'>
        <span className='tw-text-sm tw-font-semibold'>{statusLabel}</span>

        <div className='tw-text-text-80'>{getFullName(actor)}</div>
      </div>

      <div className='tw-text-end'>
        <div className='tw-text-text-100'>{date}</div>
        <div className='tw-text-text-60'>{time}</div>
      </div>
    </div>
  )
}
