import {
  CalendarBlank,
  ClockClockwise,
  Eye,
  Textbox,
  TreePalm,
  Warning,
} from '@phosphor-icons/react'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import toastr from 'toastr'

import { cn } from 'ui'
import ConfirmationModal from '../../../../components/Common/ConfirmationModal'
import { ModalCloseButton } from '../../../../components/Common/modal-close-button'
import PolicyRequestSettings from '../../../../components/policy-request-settings'
import Button from '../../../../components/ui/button'
import { IconButtonSimple } from '../../../../components/ui/icon-button-simple'
import {
  SideMenu,
  SideMenuBody,
  SideMenuFooter,
  SideMenuHeader,
} from '../../../../components/ui/side-menu'
import WorkingDaysView from '../../../../components/working-days-view'
import { useFetch } from '../../../../helpers/hooks'
import { deleteTimeOffPolicy } from '../../../../services/api-time-off-policies'
import { formatDays } from '../../../../utils/formatters/format-days'
import { DetailsInfoList } from '../../../review-center/review-layout-details-components'
import PolicyAccrualInfo from '../../../../components/policy-accrual-info'

export function ViewPolicy({ policy, refetchPolicies }) {
  const [isOpen, setIsOpen] = useState()
  function toggleMenu() {
    setIsOpen((open) => !open)
  }

  const isAccrued = policy?.type?.is_accrued === 1

  return (
    <>
      <IconButtonSimple
        icon={<Eye size={24} className='tw-text-secondary-100' />}
        onClick={toggleMenu}
        ariaLabel='View policy'
      />

      <SideMenu
        itemListClassName='tw-grid [&>*:nth-child(2)]:tw-overflow-auto [&>*:nth-child(2)]:tw-overscroll-contain tw-grid-rows-[minmax(auto,max-content)_1fr_91px]'
        isOpen={isOpen}
        onClose={toggleMenu}
        className='xl:tw-min-w-[35%]'
      >
        <SideMenuHeader toggle={toggleMenu}>{policy?.name}</SideMenuHeader>
        <SideMenuBody className='tw-flex-grow !tw-p-0 !tw-pb-5'>
          <DetailsList
            title='Basic details'
            items={[
              {
                icon: <Textbox size={24} />,
                label: 'Name',
                value: policy?.name,
              },
              {
                icon: <TreePalm size={24} />,
                label: 'Type',
                value: policy?.type?.name,
              },
              isAccrued
                ? {
                    icon: <CalendarBlank size={24} />,
                    label: 'Time off amount',
                    value: formatDays(policy?.accrual_days) + ' / Year',
                  }
                : {
                    icon: <ClockClockwise size={24} />,
                    label: 'Accrual settings',
                    value: 'Non-accrual',
                  },
            ]}
          />

          <hr className='tw-my-6' />
          <WorkingDaysView className='tw-mx-6' days={policy.working_days} />

          {isAccrued && (
            <>
              <hr className='tw-my-6' />
              <PolicyAccrualInfo policy={policy} className='tw-mx-6' />
            </>
          )}

          <PolicyRequestSettings
            policy={policy}
            className='tw-mx-6 tw-w-[fill-available]'
          />
        </SideMenuBody>

        {!policy.is_default && (
          <SideMenuFooter>
            <DeleteAction policy={policy} onSuccess={refetchPolicies} />

            <Button type='button' onClick={toggleMenu} color='light' outline>
              Close
            </Button>
            <EditAction policy={policy} />
          </SideMenuFooter>
        )}
      </SideMenu>
    </>
  )
}

export function DetailsList({ title, items, titleClassName, bodyClassName }) {
  return (
    <div>
      {!title ? null : (
        <h4
          className={cn(
            'tw-z-[1] tw-mb-1 tw-mt-6 tw-flex tw-items-center tw-gap-1 tw-px-6 tw-text-base tw-font-bold',
            titleClassName,
          )}
        >
          <span>{title}</span>
        </h4>
      )}

      <DetailsInfoList
        className={cn(title && '-tw-my-6', bodyClassName)}
        items={items}
      />
    </div>
  )
}

function DeleteAction({ policy, onSuccess }) {
  const [isModalOpen, setIsModalOpen] = useState()
  function toggle() {
    setIsModalOpen((open) => !open)
  }

  const { startFetch: deletePolicy, isLoading: deletingPolicy } = useFetch({
    action: deleteTimeOffPolicy,
    onComplete: (data) => {
      if (data?.success === false) {
        toastr.error('Failed to delete policy')
      } else {
        toastr.success('Policy deleted successfully')
        toggle()
        onSuccess?.()
      }
    },
    onError: (error) => {
      toastr.error(error)
    },
  })

  return (
    <>
      <Button
        type='button'
        onClick={toggle}
        color='link'
        className='tw-mr-auto !tw-px-0 !tw-text-systemRed-100 focus:!tw-ring focus:!tw-ring-systemRed-20'
      >
        Delete
      </Button>

      <ConfirmationModal
        isOpen={isModalOpen}
        toggle={toggle}
        negativeCaption='No, close'
        caption='Yes, delete'
        buttonColor='danger'
        onConfirm={() => {
          deletePolicy({ policyId: policy?.id })
        }}
        confirmLoading={deletingPolicy}
        content={
          <>
            <div className='tw-relative tw-flex tw-items-center tw-justify-between tw-pb-2'>
              <Warning size={24} className='tw-text-systemRed-100' />
              <ModalCloseButton toggle={toggle} />
            </div>
            <h4 className='tw-mb-2 tw-text-xl tw-font-semibold tw-text-secondary-120'>
              Are you sure you want to delete “{policy?.name}” policy?
            </h4>
            <p className='tw-mb-0 tw-text-sm tw-text-text-80'>
              Neither existing nor future contracts will be able to use this
              policy
            </p>
          </>
        }
      />
    </>
  )
}

function EditAction({ policy }) {
  const [isModalOpen, setIsModalOpen] = useState()
  const history = useHistory()

  function toggle() {
    setIsModalOpen((open) => !open)
  }

  return (
    <>
      <Button type='button' onClick={toggle}>
        Edit
      </Button>

      <ConfirmationModal
        isOpen={isModalOpen}
        toggle={toggle}
        negativeCaption='No, close'
        caption='Yes, continue'
        onConfirm={() => history.push('/time-off-policies/add', policy)}
        confirmLoading={false}
        content={
          <>
            <div className='tw-relative tw-flex tw-items-center tw-justify-between tw-pb-2'>
              <Warning size={24} className='tw-text-systemGold-100' />
              <ModalCloseButton toggle={toggle} />
            </div>
            <h4 className='tw-mb-2 tw-text-xl tw-font-semibold tw-text-secondary-120'>
              Are you sure you want to edit this policy?
            </h4>
            <p className='tw-mb-0 tw-text-sm tw-text-text-80'>
              The updates will be applied to all contracts assigned to this
              policy
            </p>
          </>
        }
      />
    </>
  )
}
