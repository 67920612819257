import { yupResolver } from '@hookform/resolvers/yup'
import { Check, CircleNotch, X } from '@phosphor-icons/react'
import classNames from 'classnames'
import { format } from 'date-fns'
import isArray from 'lodash/isArray'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import {
  Card,
  Col,
  Container,
  InputGroup,
  InputGroupText,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  UncontrolledTooltip,
} from 'reactstrap'
import toastr from 'toastr'
import * as yup from 'yup'
import ControlledInput from '../../../../components/ControlledInput'
import ControlledSelect from '../../../../components/ControlledSelect'
import CustomSelect from '../../../../components/Forms/CustomSelect/CustomSelect'
import Toggle from '../../../../components/Forms/Toggle/Toggle'
import ControlledCheckbox from '../../../../components/controlled-checkbox'
import Button from '../../../../components/ui/button'
import DataTable from '../../../../components/ui/data-table'
import Loader from '../../../../components/ui/loader'
import Pagination from '../../../../components/ui/pagination'
import { useFetch } from '../../../../helpers/hooks'
import {
  createEORCountryConfig,
  createEORStateConfig,
  getEORCountriesConfig,
  getInsuranceProviders,
  getPartners,
  getQuestionnaires,
  updateEORCountryConfig,
  updateEORStateConfig,
} from '../../../../services/api'
import { getCurrencyFormatter } from '../../../../utils/formatters/currency'
import { mapCountryToOption } from '../../../../utils/map-to-option'
import { useFilters } from '../cards/use-filters'
import { TaxesEdit } from '../../../Contract/RequestContract/FullTimeForm'
import SearchBar from '../../../../components/SearchBar'

const numberValidator = (message, label, isInteger = true) => {
  let regex = /^[0-9]+(\.[0-9]{1,2})?$/
  if (isInteger) regex = /^[0-9]+$/
  return yup
    .string()
    .typeError('Invalid value')
    .label(label)
    .matches(
      regex,
      ` ${label} must be a positive ${isInteger ? 'integer ' : ''} number`,
    )
    .required(message)
}
const countriesWithStates = ['US', 'CA', 'AU', 'CH', 'DE']

function ConfigModal({
  show,
  toggle,
  onUpdate,
  providers,
  questionnaires,
  partners,
}) {
  const [country, setCountry] = useState(null)
  const showState =
    country?.states?.length && countriesWithStates.includes(country?.iso2)
  const countries = useSelector((state) => state.Layout?.staticData?.countries)
  const schema = yup.object().shape({
    country: yup.string().required('Country is required'),
    state: yup.string().when('country', {
      is: (country) => {
        const countryFound = countries.find((c) => c.id?.toString() === country)
        return (
          countryFound?.states?.length &&
          countriesWithStates.includes(countryFound?.iso2)
        )
      },
      then: (schema) => schema.required('State is required'),
      otherwise: (schema) => schema.nullable(),
    }),
    min_annual_salary: numberValidator(
      'Minimum salary is required',
      'Minimum salary',
      false,
    ),
    min_annual_leave_days: numberValidator(
      'Minimum annual leave is required',
      'Minimum annual leave',
    ),
    max_probation_months: numberValidator(
      'Maximum probation is required',
      'Maximum probation',
    ),
    annual_management_fees: numberValidator(
      'Annual management fees is required',
      'Annual management',
      false,
    ),
    work_visa_questionnaire_id: yup.string().when('is_immigration_supported', {
      is: true,
      then: (schema) => schema.required('Questionnaire is required'),
      otherwise: (schema) => schema.nullable(),
    }),
    employee_data_collection_form_id: yup.string().notRequired(),
    partner_id: yup.string().required('Partner is required'),
  })
  const {
    handleSubmit: handleFormSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      country: null,
      state: null,
      supported_insurance_providers: [],
      min_annual_salary: '',
      min_annual_leave_days: '',
      max_probation_months: '',
      max_sick_leave_days: '',
      work_hours_per_week: '',
      is_immigration_supported: false,
      is_healthcare_required: false,
      is_salary_payable_in_usd: false,
      annual_management_fees: '',
      work_visa_cost: '',
      partner_id: null,
      is_enabled: false,
    },
    resolver: yupResolver(schema),
  })
  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === 'country') {
        setCountry(countries.find((c) => c.id === value?.country) ?? null)
        setValue('state', null)
      }
    })
    return () => subscription.unsubscribe()
  }, [watch, countries, setValue])

  const { startFetch: createConfig, isLoading: isCreateLoading } = useFetch({
    action:
      watch('state') !== null ? createEORStateConfig : createEORCountryConfig,
    withAdminAccess: true,
    onComplete: () => {
      onUpdate?.()
    },
    onError: (err) => {
      toastr.error(err ?? err?.data?.error ?? 'Something went wrong')
    },
  })
  function onSubmit(submitData) {
    if (!submitData.max_sick_leave_days) {
      delete submitData.max_sick_leave_days
    }
    if (!submitData.work_hours_per_week) {
      delete submitData.work_hours_per_week
    }
    if (submitData.state !== null) {
      createConfig({ ...submitData, stateId: submitData.state })
    } else {
      createConfig({ ...submitData, countryId: submitData.country })
    }
  }
  return (
    <Modal isOpen={show} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        {country?.eor_configuration ? 'Update' : 'Create'} Config
      </ModalHeader>
      <ModalBody>
        <form id='create-config-form' onSubmit={handleFormSubmit(onSubmit)}>
          <Row>
            <Col>
              <ControlledSelect
                control={control}
                wrapperClassName='mb-3'
                required
                label='Country'
                name='country'
                options={
                  countries
                    ? countries.map((country) => mapCountryToOption(country))
                    : []
                }
              />
            </Col>
          </Row>

          {showState ? (
            <Row>
              <Col>
                <ControlledSelect
                  wrapperClassName='mb-3'
                  control={control}
                  required={showState}
                  label='State'
                  name='state'
                  options={
                    country?.states
                      ? country?.states?.map((state) =>
                          mapCountryToOption(state),
                        )
                      : []
                  }
                />
              </Col>
            </Row>
          ) : null}
          <Row>
            <Col>
              <ControlledSelect
                control={control}
                wrapperClassName='mb-3'
                label='Insurance Providers'
                isMulti
                name='supported_insurance_providers'
                options={
                  providers
                    ? providers.map((provider) => ({
                        label: provider.name,
                        value: provider.id,
                      }))
                    : []
                }
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <ControlledSelect
                control={control}
                wrapperClassName='mb-3'
                label='Work visa Questionnaire'
                name='work_visa_questionnaire_id'
                options={
                  questionnaires
                    ? questionnaires.map((questionnaire) => ({
                        label: questionnaire.name,
                        value: questionnaire.id,
                      }))
                    : []
                }
              />
            </Col>
            <Col>
              <ControlledSelect
                control={control}
                wrapperClassName='mb-3'
                label='Employee Data Collection Form'
                name='employee_data_collection_form_id'
                options={
                  questionnaires
                    ? questionnaires.map((questionnaire) => ({
                        label: questionnaire.name,
                        value: questionnaire.id,
                      }))
                    : []
                }
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Label>
                Minimum yearly salary{' '}
                <span className='tw-text-sm tw-text-red'>*</span>
              </Label>
              <InputGroup className={classNames('tw-flex !tw-flex-nowrap', {})}>
                <ControlledInput
                  name='min_annual_salary'
                  wrapperClassName='tw-flex-grow'
                  showError={false}
                  className={classNames({
                    '!tw-rounded-r-none !tw-border-r-0': country,
                    '!tw-border-red': errors?.min_annual_salary,
                  })}
                  control={control}
                  type='number'
                />
                {country ? (
                  <InputGroupText
                    className={classNames('!tw-rounded-l-none', {
                      '!tw-border-red': errors?.min_annual_salary,
                    })}
                  >
                    {country.currency || 'USD'}
                  </InputGroupText>
                ) : null}
              </InputGroup>
              {errors?.min_annual_salary && (
                <span className='tw-mt-2 tw-text-[11px] tw-text-red'>
                  {errors.min_annual_salary.message}
                </span>
              )}
            </Col>
            <Col>
              <Label>
                Vacation Min. (working days){' '}
                <span className='tw-text-sm tw-text-red'>*</span>
              </Label>
              <ControlledInput
                control={control}
                name='min_annual_leave_days'
                type='number'
              />
            </Col>
          </Row>
          <Row>
            <Col className='py-3'>
              <Label>
                Probation Max (Months){' '}
                <span className='tw-text-sm tw-text-red'>*</span>
              </Label>
              <ControlledInput
                control={control}
                name='max_probation_months'
                type='number'
              />
            </Col>
            <Col className='py-3'>
              <Label>Sick leave max (Days)</Label>
              <ControlledInput control={control} name='max_sick_leave_days' />
            </Col>
          </Row>
          <Row>
            <Col>
              <ControlledSelect
                control={control}
                wrapperClassName='mb-3'
                label='Partner'
                name='partner_id'
                options={
                  partners
                    ? partners.map((partner) => ({
                        label: partner.company_name,
                        value: partner.id,
                      }))
                    : []
                }
              />
            </Col>
          </Row>
          <Row>
            <Col className='py-3'>
              <Label>
                Work Visa Cost <span className='tw-text-sm tw-text-red'>*</span>
              </Label>
              <ControlledInput
                control={control}
                name='work_visa_cost'
                type='number'
              />
            </Col>
          </Row>
          <Row>
            <Col className='py-3'>
              <Label>Work hour per week</Label>
              <ControlledInput
                control={control}
                name='work_hours_per_week'
                type='number'
              />
            </Col>
            <Col className='py-3'>
              <Label>
                Annual Management Fees{' '}
                <span className='tw-text-sm tw-text-red'>*</span>
              </Label>
              <ControlledInput
                control={control}
                name='annual_management_fees'
                type='number'
              />
            </Col>
          </Row>
          <Row>
            <Col className='py-3'>
              <ControlledCheckbox
                control={control}
                name='is_salary_payable_in_usd'
                id='salary_payable_in_usd'
                label='Ability to Pay salary in USD'
              />
            </Col>
            <Col className='py-3'>
              <Label>
                <ControlledCheckbox
                  control={control}
                  name='is_healthcare_required'
                  id='healthcare_required'
                  label='Healthcare required'
                />
              </Label>
            </Col>
          </Row>
          <Row>
            <Col>
              <Label>
                <ControlledCheckbox
                  control={control}
                  name='is_immigration_supported'
                  id='immigration_supported'
                  label='Immigration supported'
                />
              </Label>
            </Col>
            <Col>
              <Label>
                <ControlledCheckbox
                  control={control}
                  name='is_enabled'
                  id='enabled'
                  label='Enabled'
                />
              </Label>
            </Col>
          </Row>

          <Row>
            <Col>
              <Label>
                <ControlledCheckbox
                  control={control}
                  name='is_expense_internal_payout_enabled'
                  id='expense_internal_payout_enabled'
                  label='Expense Internal Payout Enabled'
                />
              </Label>
            </Col>
          </Row>
        </form>
      </ModalBody>
      <ModalFooter>
        <Button
          loading={isCreateLoading}
          disabled={isCreateLoading}
          formId='create-config-form'
        >
          Create
        </Button>
      </ModalFooter>
    </Modal>
  )
}
function EditableField({
  cellData,
  fieldName,
  rowData,
  isUpdatingExactField,
  handleEditConfig,
  isBoolean,
  isSelect,
  isMulti,
  options,
  text,
  type = 'text',
}) {
  const [editable, setEditable] = useState(false)
  const [value, setValue] = useState(cellData)
  const handleUpdate = (ev) => {
    if (parseInt(value) < 0) {
      toastr.error('Value must be a positive integer number')
      return
    }
    setEditable(false)

    if (isBoolean) {
      handleEditConfig(fieldName, ev.target.checked, rowData)
    } else {
      if (value !== cellData) {
        handleEditConfig(fieldName, value, rowData)
      }
    }
  }
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleUpdate()
    }
  }
  const handleWheel = (e) => {
    e.preventDefault()
  }
  const isLoading = isUpdatingExactField(fieldName, rowData.region_id)
  return editable ? (
    isBoolean ? (
      <div
        onMouseLeave={() => setEditable(false)}
        className='tw-flex tw-items-center tw-p-2'
      >
        <span className='tw-mr-2 tw-text-red-80'>No</span>
        <Toggle
          check={value}
          marginRight={false}
          change={(ev) => {
            setValue(ev.target.checked)
            // for animation to complete
            setTimeout(() => {
              handleUpdate(ev)
            }, 300)
          }}
        />
        <span className='tw-ml-2 tw-text-green-80'>Yes</span>
      </div>
    ) : isSelect ? (
      <div className='tw-flex tw-items-center tw-gap-3'>
        <CustomSelect
          value={value}
          wrapperClassName='tw-min-w-[220px]'
          isMulti={isMulti}
          options={options}
          onChange={(vals) => {
            setValue(vals)
          }}
        />
        <Button
          onClick={handleUpdate}
          outline
          size='sm'
          className='tw-h-6 tw-w-6'
        >
          <Check size={20} />
        </Button>
        <Button
          onClick={() => {
            setEditable(false)
            setValue(cellData)
          }}
          color='danger'
          outline
          size='sm'
          className='tw-h-6 tw-w-6'
        >
          <X size={20} />
        </Button>
      </div>
    ) : (
      <div className='tw-relative tw-flex tw-gap-1'>
        <input
          value={value}
          autoFocus
          type={type}
          className='tw-block tw-h-full tw-w-full tw-min-w-[150px] tw-p-3'
          onChange={(ev) => setValue(ev.target.value)}
          onKeyDown={handleKeyPress}
          onWheel={handleWheel}
        />
        <div className='tw-absolute tw-right-2 tw-top-2 tw-flex tw-gap-1'>
          <Button
            onClick={handleUpdate}
            outline
            size='sm'
            className='tw-h-6 tw-w-6'
          >
            <Check size={20} />
          </Button>
          <Button
            onClick={() => {
              setEditable(false)
              setValue(cellData)
            }}
            color='danger'
            outline
            size='sm'
            className='tw-h-6 tw-w-6'
          >
            <X size={20} />
          </Button>
        </div>
      </div>
    )
  ) : (
    <>
      <UncontrolledTooltip target={fieldName}>
        Click to update
      </UncontrolledTooltip>
      <button
        className={classNames(
          'tw-flex tw-h-full tw-min-h-[44px] tw-w-full tw-items-center tw-justify-between tw-p-3 tw-text-left hover:tw-bg-primary-10',
        )}
        id={fieldName}
        disabled={isLoading}
        onClick={() => setEditable(true)}
      >
        <span>{text || cellData}</span>
        <span>
          {isLoading && <CircleNotch size={20} className='tw-animate-spin' />}
        </span>
      </button>
    </>
  )
}

function Countries() {
  const [filters, handleFiltersChange] = useFilters({
    page: 1,
    search_key: '',
  })
  const [updatingField, setUpdatingField] = useState(null)
  const [editTaxes, setEditTaxes] = useState(null)

  const { data: providers } = useFetch({
    action: getInsuranceProviders,
    autoFetch: true,
    withAdminAccess: true,
  })

  const { data: questionnaires } = useFetch({
    action: getQuestionnaires,
    autoFetch: true,
    withAdminAccess: true,
  })

  const { data: partners } = useFetch({
    action: getPartners,
    autoFetch: true,
    withAdminAccess: true,
  })

  const { data, isLoading, paginator, startFetch } = useFetch(
    {
      action: getEORCountriesConfig,
      autoFetch: true,
      withAdminAccess: true,
      onComplete: () => {
        setUpdatingField(null)
      },
      body: filters,
    },
    [filters],
  )
  const { startFetch: updateCountryConfig } = useFetch({
    action: updateEORCountryConfig,
    withAdminAccess: true,
    onComplete: () => {
      startFetch()
    },
    onError: (err) => {
      toastr.error(err)
      setUpdatingField(null)
    },
  })
  const { startFetch: updateStateConfig } = useFetch({
    action: updateEORStateConfig,
    withAdminAccess: true,
    onComplete: () => {
      startFetch()
    },
    onError: (err) => {
      toastr.error(err)
      setUpdatingField(null)
    },
  })
  const handleEditConfig = (field, value, row) => {
    if (field === 'is_tax_breakdown_enabled' && value === true) {
      if (!row.taxes) {
        toastr.error('Please configure taxes first')
        return
      }
    }
    setUpdatingField({ field, id: row.region_id })
    const newVal = isArray(value) ? value.map((v) => v.value) : value
    if (row.region_type === 'country') {
      updateCountryConfig({
        [field]: newVal,
        countryId: row.region_id,
      })
    } else {
      updateStateConfig({
        [field]: newVal,
        stateId: row.region_id,
      })
    }
  }
  const isUpdatingExactField = (field, id) => {
    return (
      updatingField && updatingField.id === id && updatingField.field === field
    )
  }
  const columns = [
    {
      Header: 'Name',
      accessor: 'region.name',
      Cell: ({ cellData, rowData }) =>
        `${cellData} ${
          rowData.region?.country ? ' - ' + rowData.region?.country?.name : ''
        }`,
    },
    {
      Header: 'Country ISO2',
      accessor: 'region.iso2',
    },
    {
      Header: 'Enabled',
      accessor: 'is_enabled',
      Cell: ({ cellData, rowData }) => (
        <EditableField
          cellData={cellData}
          text={
            cellData === false ? (
              <span className='tw-text-red-80'>No</span>
            ) : cellData === true ? (
              <span className='tw-text-green-80'>Yes</span>
            ) : (
              '-'
            )
          }
          rowData={rowData}
          isBoolean
          fieldName='is_enabled'
          handleEditConfig={handleEditConfig}
          isUpdatingExactField={isUpdatingExactField}
        />
      ),
    },
    {
      Header: 'Immigration',
      accessor: 'is_immigration_supported',
      className: '!tw-p-0',
      Cell: ({ cellData, rowData }) => (
        <EditableField
          cellData={cellData}
          text={
            cellData === false ? (
              <span className='tw-text-red-80'>No</span>
            ) : cellData === true ? (
              <span className='tw-text-green-80'>Yes</span>
            ) : (
              '-'
            )
          }
          rowData={rowData}
          isBoolean
          fieldName='is_immigration_supported'
          handleEditConfig={handleEditConfig}
          isUpdatingExactField={isUpdatingExactField}
        />
      ),
    },
    {
      Header: 'Work hour per week',
      accessor: 'work_hours_per_week',
      className: '!tw-p-0',
      Cell: ({ cellData, rowData }) => (
        <EditableField
          cellData={cellData}
          rowData={rowData}
          fieldName='work_hours_per_week'
          handleEditConfig={handleEditConfig}
          isUpdatingExactField={isUpdatingExactField}
        />
      ),
    },
    {
      Header: 'Minimum yearly salary',
      accessor: 'min_annual_salary',
      className: '!tw-p-0',
      Cell: ({ cellData, rowData }) => {
        const currencyCode = rowData.region.country
          ? rowData.region.country.currency
          : rowData.region.currency
        return (
          <EditableField
            cellData={cellData}
            rowData={rowData}
            text={getCurrencyFormatter(currencyCode).format(cellData)}
            fieldName='min_annual_salary'
            handleEditConfig={handleEditConfig}
            isUpdatingExactField={isUpdatingExactField}
          />
        )
      },
    },
    {
      Header: 'Currency',
      accessor: 'region.data',
      Cell: ({ rowData }) => {
        return rowData.region.country
          ? rowData.region.country.currency
          : rowData.region.currency
      },
    },
    {
      Header: 'Vacation Min. (working days)',
      accessor: 'min_annual_leave_days',
      className: '!tw-p-0',
      Cell: ({ cellData, rowData }) => (
        <EditableField
          cellData={cellData}
          rowData={rowData}
          fieldName='min_annual_leave_days'
          handleEditConfig={handleEditConfig}
          isUpdatingExactField={isUpdatingExactField}
        />
      ),
    },
    {
      Header: 'Probation Max. (Months)',
      accessor: 'max_probation_months',
      className: '!tw-p-0',
      Cell: ({ cellData, rowData }) => (
        <EditableField
          cellData={cellData}
          rowData={rowData}
          fieldName='max_probation_months'
          handleEditConfig={handleEditConfig}
          isUpdatingExactField={isUpdatingExactField}
        />
      ),
    },
    {
      Header: 'Sick leave max(Days)',
      accessor: 'max_sick_leave_days',
      className: '!tw-p-0',
      Cell: ({ cellData, rowData }) => (
        <EditableField
          cellData={cellData}
          type='text'
          rowData={rowData}
          fieldName='max_sick_leave_days'
          handleEditConfig={handleEditConfig}
          isUpdatingExactField={isUpdatingExactField}
        />
      ),
    },
    {
      Header: 'Annual Management Fees',
      accessor: 'annual_management_fees',
      className: '!tw-p-0',
      Cell: ({ cellData, rowData }) => (
        <EditableField
          cellData={cellData}
          type='text'
          text={getCurrencyFormatter('USD').format(cellData)}
          rowData={rowData}
          fieldName='annual_management_fees'
          handleEditConfig={handleEditConfig}
          isUpdatingExactField={isUpdatingExactField}
        />
      ),
    },
    {
      Header: 'Visa Cost',
      accessor: 'work_visa_cost',
      className: '!tw-p-0',
      Cell: ({ cellData, rowData }) => (
        <EditableField
          cellData={cellData}
          type='text'
          text={getCurrencyFormatter('USD').format(cellData)}
          rowData={rowData}
          fieldName='work_visa_cost'
          handleEditConfig={handleEditConfig}
          isUpdatingExactField={isUpdatingExactField}
        />
      ),
    },
    {
      Header: 'Ability to Pay salary in USD',
      accessor: 'is_salary_payable_in_usd',
      className: '!tw-p-0',
      Cell: ({ cellData, rowData }) => (
        <EditableField
          cellData={cellData}
          text={
            cellData === false ? (
              <span className='tw-text-red-80'>No</span>
            ) : cellData === true ? (
              <span className='tw-text-green-80'>Yes</span>
            ) : (
              '-'
            )
          }
          rowData={rowData}
          isBoolean
          fieldName='is_salary_payable_in_usd'
          handleEditConfig={handleEditConfig}
          isUpdatingExactField={isUpdatingExactField}
        />
      ),
    },
    {
      Header: 'Healthcare required',
      accessor: 'is_healthcare_required',
      className: '!tw-p-0',
      Cell: ({ cellData, rowData }) => (
        <EditableField
          cellData={cellData}
          text={
            cellData === false ? (
              <span className='tw-text-red-80'>No</span>
            ) : cellData === true ? (
              <span className='tw-text-green-80'>Yes</span>
            ) : (
              '-'
            )
          }
          rowData={rowData}
          isBoolean
          fieldName='is_healthcare_required'
          handleEditConfig={handleEditConfig}
          isUpdatingExactField={isUpdatingExactField}
        />
      ),
    },
    {
      Header: 'Insurance Providers',
      accessor: 'supported_insurance_providers',
      className: '!tw-p-0',
      Cell: ({ cellData, rowData }) => (
        <EditableField
          cellData={cellData.map((p) => {
            const provider = providers?.find((pr) => pr.id === p.id)
            return { value: provider.id, label: provider.name }
          })}
          text={cellData
            ?.map((p) => providers?.find((pr) => pr.id === p.id)?.name)
            .join(', ')}
          rowData={rowData}
          isSelect
          isMulti
          options={providers?.map((pr) => ({
            ...pr,
            value: pr.id,
            label: pr.name,
          }))}
          fieldName='supported_insurance_providers'
          handleEditConfig={handleEditConfig}
          isUpdatingExactField={isUpdatingExactField}
        />
      ),
    },
    {
      Header: 'Work visa Questionnaire',
      accessor: 'work_visa_questionnaire_id',
      className: '!tw-p-0',
      Cell: ({ cellData, rowData }) => {
        const questionnaire = questionnaires?.find((qr) => qr.id === cellData)
        return (
          <EditableField
            cellData={{ value: questionnaire?.id, label: questionnaire?.name }}
            text={questionnaire?.name ?? '-'}
            rowData={rowData}
            isSelect
            options={[
              {
                label: 'None',
                id: null,
                value: null,
              },
              ...(questionnaires?.map((qr) => ({
                ...qr,
                value: qr.id,
                label: qr.name,
              })) ?? []),
            ]}
            fieldName='work_visa_questionnaire_id'
            handleEditConfig={(field, value, row) =>
              handleEditConfig(field, value.id, row)
            }
            isUpdatingExactField={isUpdatingExactField}
          />
        )
      },
    },
    {
      Header: 'Employee Data Collection Form',
      accessor: 'employee_data_collection_form_id',
      className: '!tw-p-0',
      Cell: ({ cellData, rowData }) => {
        const questionnaire = questionnaires?.find((qr) => qr.id === cellData)
        return (
          <EditableField
            cellData={{ value: questionnaire?.id, label: questionnaire?.name }}
            text={questionnaire?.name ?? '-'}
            rowData={rowData}
            isSelect
            options={[
              {
                label: 'None',
                id: null,
                value: null,
              },
              ...(questionnaires?.map((qr) => ({
                ...qr,
                value: qr.id,
                label: qr.name,
              })) ?? []),
            ]}
            fieldName='employee_data_collection_form_id'
            handleEditConfig={(field, value, row) =>
              handleEditConfig(field, value.id, row)
            }
            isUpdatingExactField={isUpdatingExactField}
          />
        )
      },
    },
    {
      Header: 'Is tax breakdown enabled',
      accessor: 'is_tax_breakdown_enabled',
      className: '!tw-p-0',
      Cell: ({ cellData, rowData }) => (
        <EditableField
          cellData={cellData}
          text={
            cellData === false ? (
              <span className='tw-text-red-80'>No</span>
            ) : cellData === true ? (
              <span className='tw-text-green-80'>Yes</span>
            ) : (
              '-'
            )
          }
          rowData={rowData}
          isBoolean
          fieldName='is_tax_breakdown_enabled'
          handleEditConfig={handleEditConfig}
          isUpdatingExactField={isUpdatingExactField}
        />
      ),
    },
    {
      Header: 'Is Expense Internal Payout enabled',
      accessor: 'is_expense_internal_payout_enabled',
      className: '!tw-p-0',
      Cell: ({ cellData, rowData }) => (
        <EditableField
          cellData={cellData}
          text={
            cellData === false ? (
              <span className='tw-text-red-80'>No</span>
            ) : cellData === true ? (
              <span className='tw-text-green-80'>Yes</span>
            ) : (
              '-'
            )
          }
          rowData={rowData}
          isBoolean
          fieldName='is_expense_internal_payout_enabled'
          handleEditConfig={handleEditConfig}
          isUpdatingExactField={isUpdatingExactField}
        />
      ),
    },
    {
      Header: 'Is quotation automation enabled',
      accessor: 'is_quotation_automation_enabled',
      className: '!tw-p-0',
      Cell: ({ cellData, rowData }) => (
        <EditableField
          cellData={cellData}
          text={
            cellData === false ? (
              <span className='tw-text-red-80'>No</span>
            ) : cellData === true ? (
              <span className='tw-text-green-80'>Yes</span>
            ) : (
              '-'
            )
          }
          rowData={rowData}
          isBoolean
          fieldName='is_quotation_automation_enabled'
          handleEditConfig={handleEditConfig}
          isUpdatingExactField={isUpdatingExactField}
        />
      ),
    },
    {
      Header: 'Partner',
      accessor: 'partner_id',
      className: '!tw-p-0',
      Cell: ({ cellData, rowData }) => {
        const partner = partners?.find((qr) => qr.id === cellData)
        return (
          <EditableField
            cellData={{ value: partner?.id, label: partner?.company_name }}
            text={partner?.company_name ?? '-'}
            rowData={rowData}
            isSelect
            options={partners?.map((qr) => ({
              ...qr,
              value: qr.id,
              label: qr.company_name,
            }))}
            fieldName='partner_id'
            handleEditConfig={(field, value, row) =>
              handleEditConfig(field, value.id, row)
            }
            isUpdatingExactField={isUpdatingExactField}
          />
        )
      },
    },
    {
      Header: 'Updated at',
      accessor: 'updated_at',
      Cell: ({ cellData }) => {
        return cellData
          ? format(new Date(cellData), 'yyyy-MM-dd hh:mm a')
          : null
      },
    },
    {
      Header: 'Actions',
      accessor: '',
      Cell: ({ rowData }) => (
        <>
          <Button
            size='sm'
            outline={rowData?.taxes}
            onClick={() => {
              setEditTaxes(rowData)
            }}
          >
            {rowData?.taxes ? 'Edit Taxes' : 'Configure Taxes'}
          </Button>
        </>
      ),
    },
  ]
  function handleSearch(query) {
    handleFiltersChange('search_key', query)
  }
  const showPaginator = paginator?.first_page_url !== paginator?.last_page_url

  const [showModal, setShowModal] = useState({ show: false, config: null })
  return (
    <div className='page-content'>
      <Container fluid className='p-0 m-0'>
        <div className='tw-mb-8 tw-flex tw-items-center tw-justify-between'>
          <h1>Regional Configurations</h1>
          <Button onClick={() => setShowModal({ show: true, config: null })}>
            Add
          </Button>
        </div>
        <Card>
          <div className='d-flex p-3'>
            <SearchBar
              name='search'
              placeholder='Search region'
              className='w-100'
              // style={{ maxWidth: 320 }}
              value={filters.search_key}
              onQueryChanged={handleSearch}
            />
          </div>
          {isLoading && !updatingField ? (
            <Loader style={{ minHeight: 300 }} className='w-100' />
          ) : data?.length === 0 ? (
            <div className='d-flex w-100 p-5 justify-content-center align-items-center font-size-20'>
              No Adjustments Pending
            </div>
          ) : (
            <DataTable
              className='!tw-block !tw-pb-20'
              data={data}
              responsive
              columns={columns}
            />
          )}
          {showPaginator && !isLoading && !updatingField && (
            <div className='ml-auto px-3 my-3 w-100 d-flex justify-content-end'>
              <Pagination
                activePage={filters.page}
                itemsCountPerPage={paginator?.per_page ?? 10}
                totalItemsCount={paginator?.total ?? 0}
                onChange={(newPage) => handleFiltersChange('page', newPage)}
              />
            </div>
          )}
        </Card>
      </Container>
      {showModal.show && (
        <ConfigModal
          show={showModal.show}
          providers={providers}
          questionnaires={questionnaires}
          partners={partners}
          onUpdate={() => {
            setShowModal({ show: false, config: null })
            startFetch({ page: 1 })
          }}
          toggle={() => setShowModal({ show: false, config: null })}
          country={showModal.config}
        />
      )}
      {editTaxes !== null ? (
        <TaxesEdit
          countryCode={editTaxes.region?.iso2}
          show={editTaxes !== null}
          toggle={() => setEditTaxes(null)}
          onSubmitCompleted={() => {
            setEditTaxes(null)
            startFetch({ page: paginator?.current_page ?? 1 })
          }}
          config={editTaxes}
        />
      ) : null}
    </div>
  )
}

export default Countries
