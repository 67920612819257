import React from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

import { cn } from 'ui'
import Button from '../ui/button'
import Loader from '../ui/loader'
import { ModalCloseButton } from './modal-close-button'

const ConfirmationModal = ({
  isOpen,
  toggle,
  onClosed = () => {},
  title,
  message,
  onConfirm,
  loading,
  confirmLoading,
  content,
  caption = 'Ok',
  negativeCaption = 'Cancel',
  onCancel,
  buttonColor = 'primary',
  confirmIcon,
  withCancel = true,
  centered = true,
  className,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      centered={centered}
      toggle={toggle}
      onClosed={onClosed}
      className={cn(className, 'tw-text-sm')}
    >
      {!title ? null : (
        <ModalHeader
          close={<ModalCloseButton toggle={toggle} />}
          className='tw-capitalize'
        >
          {title}
        </ModalHeader>
      )}
      {content || message ? (
        <ModalBody style={{ minHeight: '80px' }} className='!tw-p-6'>
          {loading ? (
            <Loader minHeight='80px' />
          ) : (
            (content ?? <p>{message}</p>)
          )}
        </ModalBody>
      ) : null}
      <ModalFooter>
        {!withCancel ? null : (
          <Button
            type='button'
            color='light'
            outline
            onClick={onCancel ?? toggle}
            disabled={loading || confirmLoading}
          >
            {negativeCaption}
          </Button>
        )}
        <Button
          type='button'
          color={buttonColor}
          onClick={onConfirm}
          disabled={loading || confirmLoading}
          loading={loading || confirmLoading}
          icon={confirmIcon}
          className='tw-capitalize'
        >
          {caption}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default ConfirmationModal
