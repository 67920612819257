import React from 'react'

import { cn } from 'ui'

export default function StyledTd(props) {
  const { children, colSpan, myStyle, className = '' } = props
  return (
    <td
      style={myStyle}
      colSpan={colSpan}
      className={cn('tw-px-3 tw-py-2 tw-text-sm', className)}
    >
      {children}
    </td>
  )
}
