import { yupResolver } from '@hookform/resolvers/yup'
import { AvForm } from 'availity-reactstrap-validation'
import classnames from 'classnames'
import { addDays, format, parseISO } from 'date-fns'
import React, { forwardRef, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { useFieldArray, useForm, useWatch } from 'react-hook-form'
import ReactQuill from 'react-quill'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import {
  Col,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Popover,
  PopoverBody,
  Row,
} from 'reactstrap'
import toastr from 'toastr'
import * as yup from 'yup'

import ControlledCurrencyInput from '../../../components/ControlledCurrencyInput'
import ControlledDatePicker from '../../../components/ControlledDatePicker'
import ControlledInput from '../../../components/ControlledInput'
import ControlledSelect from '../../../components/ControlledSelect'
import Toggle from '../../../components/Forms/Toggle/Toggle'
import Button from '../../../components/ui/button'
import { UploadFile } from '../../../helpers/SvgIcons'
import { useFetch, useResize } from '../../../helpers/hooks'
import {
  employmentTermOptions,
  eorEmploymentTypeOptions,
  qualificationOptions,
} from '../../../helpers/lists'
import {
  downloadFullTimeOfferLetter,
  downloadFullTimeOfferLetterAdmin,
  getAdminEORRegionConfig,
  getEORRegionConfig,
  getLocalCurrencies,
  uploadTempFileAdmin,
} from '../../../services/api'
import {
  mapCountryToOption,
  mapCurrencyToOption,
} from '../../../utils/map-to-option'
import { AllTitles } from '../components/Forms/InfoForm'
import { customOption, customSingleValue } from './FullTimeForm'
import {
  CheckCircle,
  Circle,
  FastForward,
  PlusCircle,
  Trash,
} from '@phosphor-icons/react'
import DynamicForm, {
  formatValues,
  buildYupSchema,
  getDefaultValues as getDynamicFormDefaultValues,
  removeFormFields,
} from '../../../components/Forms/dynamic-form'

function createValidationSchema(questionsForm, immigrationSupported, visa) {
  const schema = yup.object().shape({
    employee_first_name: yup.string().required('First Name is required'),
    employee_middle_name: yup.string(),
    employee_last_name: yup.string().required('Last Name is required'),
    employee_email: yup.string().required('Email is required').email(),
    employee_nationality_country_id: yup.string().required(),
    working_from_country_id: yup.string().required(),
    working_from_state: yup.string(),
    work_visa: yup.boolean(),
    start_date: yup.string().required('Start Date is required'),
    job_title: yup.string().required('Job Title is required'),
    job_description: yup.string(),
    currency_id: yup.number().required(),
    employment_type: yup.string().required(),
    qualification: yup.string().required(),
    employment_term: yup.string().required(),
    amount: yup.number().required('Amount is required'),
    trial_period: yup.string().required('Probation Period is required'),
    annual_leave_days: yup
      .number()
      .integer()
      .min(0, 'Must be greater than or equal to 0')
      .typeError('Must be an integer')
      .required('Vacation days is required'),
    insurance: yup.boolean(),
    end_date: yup.string().when('employment_term', {
      is: (employmentTerm) => employmentTerm === 'Definite',
      then: (schema) => schema.required('End Date is required'),
      otherwise: (schema) => schema.notRequired(),
    }),
    insurance_provider_id: yup.string(),
    allowances: yup.array().of(
      yup.object().shape({
        name: yup.string().required('Allowance Name is required'),
        amount: yup
          .number()
          .required('Allowance Amount is required')
          .typeError('Allowance Amount must be a number'),
      }),
    ),
    signing_bonus_amount: yup
      .number()
      .min(0, 'Must be greater than or equal to 0')
      .typeError('Signing Bonus Amount must be a number'),
    ...buildYupSchema({
      form: questionsForm,
      condition:
        visa && questionsForm?.form_fields?.length > 0 && immigrationSupported,
    }),
  })
  return schema
}

function getDefaultValues(initialData) {
  const employmentTermDefaultValue = employmentTermOptions.find(
    (item) => item.label === initialData?.employment_term,
  )?.value

  return {
    employee_first_name: initialData?.employee?.first_name,
    employee_middle_name: initialData?.employee?.middle_name || '',
    employee_last_name: initialData?.employee?.last_name,
    employee_email: initialData?.employee?.email,
    employee_nationality_country_id: initialData?.employee?.citizen?.id,
    working_from_state: initialData?.employee?.working_from_state?.id,
    work_visa: !!initialData?.work_visa,
    start_date:
      !initialData?.start_date || isNaN(Date.parse(initialData?.start_date))
        ? ''
        : format(new Date(initialData?.start_date), 'yyyy-MM-dd'),
    end_date:
      !initialData?.end_date || isNaN(Date.parse(initialData?.end_date))
        ? ''
        : format(new Date(initialData?.end_date), 'yyyy-MM-dd'),
    job_title: initialData?.name,
    job_description: initialData?.scope,
    employment_type: initialData?.employment_type,
    qualification: initialData?.qualification,
    employment_term: employmentTermDefaultValue,
    amount: initialData?.amount,
    annual_leave_days: initialData?.annual_leave_days,
    insurance_provider_id: initialData?.insurance_provider?.id,
    trial_period: initialData?.trial_period || 'No probation',
    bonus_amount: initialData?.bonus_amount,
    bonus_details: initialData?.bonus_details,
    signing_bonus_amount: initialData?.signing_bonus_amount || '0.00',
    ...getDynamicFormDefaultValues(
      initialData?.work_visa_questionnaire?.form_fields,
    ),
  }
}

const FullTimeDetailsForm = forwardRef(function FullTimeDetailsForm(
  { onComplete, quote, initialData, readOnly },
  ref,
) {
  const {
    countries,
    other_countries: otherCountries,
    eor_countries: eorCountries,
    job_titles: titles,
  } = useSelector((state) => state.Layout.staticData ?? {})
  const location = useLocation()
  const isAdmin = location.pathname.startsWith('/admin')
  const isMobile = useResize()
  const [offerLetterPath, setOfferLetterPath] = useState(null)

  const {
    working_from_state: regionState,
    working_from_country: regionCountry,
  } = initialData?.employee ?? {}
  const { data: regionConfig } = useFetch(
    {
      action: isAdmin ? getAdminEORRegionConfig : getEORRegionConfig,
      autoFetch: regionCountry?.id || regionState?.id,
      withAdminAccess: isAdmin,
      body: {
        region_id: regionState?.id ?? regionCountry?.id,
        region_type: regionState?.id ? 'state' : 'country',
      },
    },
    [regionCountry, regionState],
  )

  const { acceptedFiles, getRootProps, getInputProps, inputRef } = useDropzone({
    acceptedFiles: 'application/pdf',
    onDrop: (acceptedFiles) => {
      if (acceptedFiles?.length > 0) {
        if (acceptedFiles[0].size > 2000000) {
          toastr.error('The file may not be greater than 2MB')
          removeAll()
        } else {
          uploadLetter.startFetch({
            file: acceptedFiles[0],
            type: 'offer_letters',
          })
        }
      }
    },
  })
  const files = acceptedFiles.map((file) => (
    <p className='mb-0' key={file.path}>
      {file.path}
    </p>
  ))

  const removeAll = () => {
    if (inputRef && inputRef.current) {
      acceptedFiles.length = 0
      acceptedFiles.splice(0, acceptedFiles.length)
      inputRef.current.value = ''
    }
  }
  const downloadContract = useFetch({
    action: downloadFullTimeOfferLetter,
    onComplete: (data) => {
      downloadFile(data, `${initialData?.ref}-offer-letter.pdf`)
    },
  })
  const downloadContractAdmin = useFetch({
    action: downloadFullTimeOfferLetterAdmin,
    withAdminAccess: true,
    onComplete: (data) => {
      downloadFile(data, `${initialData?.ref}-offer-letter.pdf`)
    },
  })

  const downloadFile = (data, name) => {
    const url = window.URL.createObjectURL(new Blob([data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', name)
    document.body.appendChild(link)
    link.click()
  }
  const handleDownloadOfferLetter = () => {
    if (isAdmin) {
      downloadContractAdmin.startFetch({ contract_id: initialData?.id })
    } else {
      downloadContract.startFetch({ contract_id: initialData?.id })
    }
  }

  const nationalities = countries
    ? [...countries, ...otherCountries].map((c) => mapCountryToOption(c))
    : []
  const regionQuestions = regionConfig?.work_visa_questionnaire
  const contractQuestions = initialData?.work_visa_questionnaire

  const [visa, setVisa] = useState(!!initialData?.work_visa)

  const questionsForm = contractQuestions ?? regionQuestions
  const {
    handleSubmit,
    control,
    reset,
    setValue,
    clearErrors,
    watch,
    formState: { errors },
  } = useForm({
    shouldFocusError: true,
    mode: 'onChange',
    resolver: yupResolver(
      createValidationSchema(
        questionsForm,
        regionConfig?.is_immigration_supported,
        visa,
      ),
    ),
    defaultValues: getDefaultValues(initialData),
  })

  const {
    working_from_country_id: countryId,
    job_title: watchingJobTitle,
    currency_id: currencyId,
    employment_term: employmentTerm,
    start_date: startDate,
    insurance_provider_id: insuranceProvider,
  } = useWatch({ control })

  useEffect(() => {
    reset(getDefaultValues(initialData))
  }, [initialData])

  const onSubmit = (v) => {
    const values = { ...v }

    // Validation for scope/ job description
    if (!scope) {
      toastr.error('Job description is required')
      return
    }

    // Start to construct the payload
    const data = {
      ...values,
      insurance: insurance ? 1 : 0,
      work_visa: visa ? 1 : 0,
      job_description: scope,
      work_visa_questionnaire_answers: formatValues(values)?.form_inputs,
    }
    if (data.employee_middle_name === '') {
      delete data.employee_middle_name
    }
    if (data.work_visa_questionnaire_answers?.length === 0 || !data.work_visa) {
      delete data.work_visa_questionnaire_answers
    }
    if (!data.bonus_details) {
      delete data.bonus_details
    }
    if (data.employment_term === 'Indefinite') {
      delete data.end_date
    }
    if (data.insurance_provider_id === 'later') {
      delete data.insurance_provider_id
    }

    // Add offer letter path payload
    if (offerLetterPath) {
      data.offer_letter = offerLetterPath
    }

    onComplete(removeFormFields(data))
  }

  const currencies = useFetch({
    action: getLocalCurrencies,
    autoFetch: true,
    initResult: [],
  })
  const currencyOptions = currencies?.data?.map((c) =>
    mapCurrencyToOption(c, 'id'),
  )

  const uploadLetter = useFetch({
    action: uploadTempFileAdmin,
    withAdminAccess: isAdmin,
    onComplete: (data) => {
      if (data?.path) {
        setOfferLetterPath(data.path)
      }
    },
    onError: (err) => {
      toastr.error(err)
    },
  })

  useEffect(() => {
    setValue('working_from_country_id', quote?.country_id)
    if (isAdmin) {
      setValue('currency_id', quote?.currency_id)
    } else {
      setValue(
        'currency_id',
        currencies?.data?.filter(
          (item) =>
            item.code ===
            eorCountries?.filter(
              (country) => country.id === quote?.country_id,
            )[0]?.currency,
        )[0]?.id,
      )
    }
    setValue('amount', quote?.amount)
  }, [quote])

  const [insurance, setInsurance] = useState(!!initialData?.insurance)
  const [showDesc, setShowDesc] = useState(false)
  const [scope, setScope] = useState(initialData?.scope || '')
  const toggleDesc = () => setShowDesc((open) => !open)

  useEffect(() => {
    setValue('job_description', scope)
  }, [scope, setValue])
  const {
    fields: allowancesFields,
    append: appendAllowance,
    remove: removeAllowance,
  } = useFieldArray({
    control,
    name: 'allowances',
  })

  useEffect(() => {
    removeAllowance()
    if (initialData?.allowances?.length > 0) {
      initialData?.allowances?.forEach((allowance) => {
        appendAllowance({
          id: allowance?.id,
          name: allowance?.name,
          amount: allowance?.amount,
        })
      })
    }
  }, [appendAllowance, removeAllowance, initialData])

  const currencyCode = currencies?.data?.find((e) => e.id === currencyId)?.code

  function handleValidationError(err) {
    const messages = Object.entries(err)?.reduce((acc, [, value]) => {
      return [...acc, value?.message]
    }, [])

    toastr.error(messages.join('<br/>'), 'Error', { allowHtml: true })
  }
  const isDefinite = employmentTerm === 'Definite'

  const states = eorCountries?.find((c) => c.id === countryId)?.states
  const selectedState = initialData?.employee?.working_from_state?.id
  return (
    <div>
      <AvForm
        ref={ref}
        autoComplete='off'
        className='form-horizontal m-0'
        onSubmit={handleSubmit(onSubmit, handleValidationError)}
      >
        <Col className='p-0'>
          <fieldset disabled={readOnly}>
            <Label className='text-gray-h font-size-16 mb-4' tag='div'>
              Employee Info
            </Label>
            <Row>
              <Col md={6} sm={12} className='mb-3'>
                <ControlledInput
                  control={control}
                  label={
                    <>
                      First Name
                      <span className='text-danger font-size-16 mx-1'>*</span>
                    </>
                  }
                  name='employee_first_name'
                  error={errors.employee_first_name}
                />
              </Col>
              <Col md={6} sm={12} className='mb-3'>
                <ControlledInput
                  control={control}
                  label={
                    <>
                      Last Name
                      <span className='text-danger font-size-16 mx-1'>*</span>
                    </>
                  }
                  name='employee_last_name'
                  error={errors.employee_last_name}
                />
              </Col>
              <Col md={6} sm={12} className='mb-3'>
                <ControlledInput
                  control={control}
                  label='Middle Name'
                  name='employee_middle_name'
                  error={errors.employee_middle_name}
                />
              </Col>
              <Col md={6} sm={12} className='mb-3'>
                <ControlledInput
                  control={control}
                  label={
                    <>
                      Personal Email
                      <span className='text-danger font-size-16 mx-1'>*</span>
                    </>
                  }
                  name='employee_email'
                  error={errors.employee_email}
                  disabled
                />
              </Col>
              <Col md={6} sm={12} className='mb-3'>
                <ControlledSelect
                  options={nationalities}
                  control={control}
                  label={
                    <>
                      Nationality
                      <span className='text-danger font-size-16 mx-1'>*</span>
                    </>
                  }
                  name='employee_nationality_country_id'
                  error={errors.employee_nationality_country_id}
                  isDisabled={readOnly}
                />
              </Col>
              <Col md={6} sm={12} className='mb-3'>
                <ControlledSelect
                  options={eorCountries?.map((c) => mapCountryToOption(c))}
                  label={
                    <>
                      Country Of Employment
                      <span className='text-danger font-size-16 ml-1'>*</span>
                    </>
                  }
                  control={control}
                  name='working_from_country_id'
                  error={errors.working_from_country_id}
                  isDisabled
                />
              </Col>
              {selectedState ? (
                <Col md={6} sm={12} className='mb-3'>
                  <ControlledSelect
                    control={control}
                    label={
                      <>
                        State
                        <span className='text-danger font-size-16 ml-1'>*</span>
                      </>
                    }
                    options={states?.map((state) => mapCountryToOption(state))}
                    name='working_from_state'
                    isDisabled
                  />
                </Col>
              ) : null}
            </Row>
            <Row className='tw-mb-4'>
              <Col>
                <label className='tw-mt-3 tw-flex tw-items-center'>
                  <Toggle
                    id='1'
                    check={visa}
                    change={(e) => {
                      setVisa(e.target.checked)
                    }}
                    disabled={readOnly}
                  />
                  <span className='text-secondary tw-mb-0'>
                    Will the hire require a work visa?
                  </span>
                </label>
              </Col>
            </Row>
            <Row>
              {visa &&
                regionQuestions?.form_fields?.length > 0 &&
                regionConfig?.is_immigration_supported && (
                  <DynamicForm
                    fields={questionsForm?.form_fields}
                    clearErrors={clearErrors}
                    setValue={setValue}
                    conditions={regionQuestions?.conditions}
                    control={control}
                    errors={errors}
                    watch={watch}
                    filesDownloadable
                    newUI={false}
                  />
                )}
            </Row>

            <hr className='mx-n4' />
            <Label className='text-gray-h font-size-16 my-4' tag='div'>
              Job Info
            </Label>
            <Row>
              <Col md={6} sm={12} className='mb-3'>
                <ControlledSelect
                  options={qualificationOptions}
                  control={control}
                  name='qualification'
                  label={
                    <>
                      Qualification
                      <span className='text-danger font-size-16 mx-1'>*</span>
                    </>
                  }
                  error={errors.qualification}
                  isDisabled={readOnly}
                />
              </Col>
              <Col md={6} sm={12} className='mb-3'>
                <ControlledSelect
                  options={eorEmploymentTypeOptions}
                  control={control}
                  label={
                    <>
                      Type
                      <span className='text-danger font-size-16 mx-1'>*</span>
                    </>
                  }
                  name='employment_type'
                  error={errors.employment_type}
                  isDisabled={readOnly}
                />
              </Col>
              <Col md={6} sm={12} className='mb-3'>
                <ControlledSelect
                  options={employmentTermOptions}
                  control={control}
                  label={
                    <>
                      Term
                      <span className='text-danger font-size-16 mx-1'>*</span>
                    </>
                  }
                  name='employment_term'
                  error={errors.employment_term}
                  isDisabled={readOnly}
                />
              </Col>
              <Col md={6} sm={12} className='mb-3'>
                <ControlledInput
                  control={control}
                  name='trial_period'
                  label={
                    <>
                      Probation Period
                      <span className='text-danger font-size-16 mx-1'>*</span>
                    </>
                  }
                  placeholder='Probation period'
                  error={errors.trial_period}
                />
              </Col>
              <Col md={6} sm={12} className='mb-3'>
                <ControlledInput
                  control={control}
                  name='annual_leave_days'
                  label={
                    <>
                      Vacation days
                      <span className='text-danger font-size-16 mx-1'>*</span>
                    </>
                  }
                  placeholder='Vacation days'
                  error={errors.annual_leave_days}
                />
              </Col>
              <Col md={12} sm={12} className='mb-3'>
                <ControlledInput
                  watchState={watchingJobTitle}
                  autoComplete={titles}
                  control={control}
                  label={
                    <>
                      Job Title
                      <span className='text-danger font-size-16 mx-1'>*</span>
                    </>
                  }
                  name='job_title'
                  error={errors.job_title}
                  placeholder='i.e. Website Design, Content generation'
                />
              </Col>
              <Col md={12} sm={12} className='mb-3'>
                <FormGroup id='scope-contract-info'>
                  <Label className='font-size-14'>
                    Job Description
                    <span className='text-danger font-size-16 ml-1'>*</span>
                  </Label>
                  <ReactQuill
                    formats={[
                      'font',
                      'size',
                      'bold',
                      'italic',
                      'underline',
                      'strike',
                      'blockquote',
                      'indent',
                      'image',
                      'video',
                    ]}
                    readOnly={readOnly}
                    id='scopeEdit'
                    modules={{
                      toolbar: null,
                    }}
                    className={errors.job_description ? 'quill-error' : ''}
                    value={scope}
                    onChange={setScope}
                    placeholder='Job description'
                    style={{ whiteSpace: 'pre-line' }}
                  />
                  {readOnly ? null : (
                    <div className='mt-2 ml-0'>
                      <button
                        type='button'
                        className='rp-btn-nostyle mx-n1 px-1 text-primary'
                        id='DisabledAutoHideExample'
                        onClick={toggleDesc}
                      >
                        Predefined descriptions {'>>'}
                      </button>
                      <Popover
                        className='my-popover'
                        placement='top'
                        isOpen={showDesc}
                        trigger='legacy'
                        target='DisabledAutoHideExample'
                        toggle={toggleDesc}
                      >
                        <PopoverBody>
                          <Row className='p-0 m-0' style={{ minWidth: 640 }}>
                            <AllTitles
                              click={(text) => {
                                setScope(text)
                                setShowDesc(false)
                              }}
                            />
                          </Row>
                        </PopoverBody>
                      </Popover>
                    </div>
                  )}
                </FormGroup>
              </Col>

              <Col md={12} sm={12} className='mb-3'>
                <Label>Offer Letter</Label>
                <section className='p-0'>
                  <div
                    className={` ${
                      (!!acceptedFiles && acceptedFiles?.length > 0) ||
                      initialData?.offer_letter
                        ? ''
                        : 'dropzone border-primary'
                    } `}
                  >
                    {initialData?.offer_letter ? (
                      <div className='py-2'>
                        {uploadLetter.isLoading && (
                          <i className='bx bx-loader bx-spin text-primary mx-2' />
                        )}

                        <Button
                          size='sm'
                          onClick={handleDownloadOfferLetter}
                          icon={<i className='bx bx-download font-size-16' />}
                          loading={
                            downloadContractAdmin.isLoading ||
                            downloadContract.isLoading
                          }
                          disabled={
                            downloadContractAdmin.isLoading ||
                            downloadContract.isLoading
                          }
                        >
                          Download
                        </Button>
                      </div>
                    ) : (
                      <div
                        className='dz-message needsclick bg-soft-primary rounded'
                        {...getRootProps()}
                        onClick={
                          uploadLetter.error ? () => {} : getRootProps().onClick
                        }
                      >
                        <input {...getInputProps()} />

                        {!!acceptedFiles && acceptedFiles?.length > 0 ? (
                          <div className='font-size-14 d-flex justify-content-between align-items-start'>
                            <div className='d-flex flex-column gap-4 align-items-start'>
                              {acceptedFiles?.length > 0
                                ? files
                                : 'File uploaded'}
                            </div>

                            <button
                              onClick={removeAll}
                              type='button'
                              className='rp-btn-nostyle d-flex p-1'
                            >
                              {uploadLetter.isLoading && (
                                <i className='bx bx-loader bx-spin text-primary mx-2' />
                              )}

                              <i className='bx bx-trash text-danger' />
                            </button>
                          </div>
                        ) : (
                          <>
                            <div className='py-4 tw-flex tw-items-center tw-justify-center'>
                              <UploadFile />
                            </div>
                            <Row>
                              <Col className='justify-content-center'>
                                {isMobile ? (
                                  <h4>Click to upload files.</h4>
                                ) : (
                                  <h3>Drop files here or click to upload.</h3>
                                )}
                                <p className='text-muted font-size-12'>
                                  *PDF only. Max file size 2MB
                                </p>
                              </Col>
                            </Row>
                          </>
                        )}
                      </div>
                    )}
                  </div>
                </section>
              </Col>
            </Row>
            <hr className='mx-n4' />
            <Label className='text-gray-h font-size-16 my-4' tag='div'>
              Compensation
            </Label>
            <Row>
              <Col md={6} sm={12} className='mb-3'>
                <FormGroup className='mb-0'>
                  <Label className='col-form-label p-0 m-0 mb-2 font-size-14'>
                    Gross Salary
                    <span className='text-danger font-size-16 ml-1'>*</span>
                  </Label>
                  <InputGroup className='!tw-flex-nowrap' style={{ zIndex: 6 }}>
                    <ControlledCurrencyInput
                      control={control}
                      name='amount'
                      error={errors.amount}
                      isDisabled={readOnly}
                    />
                    <InputGroupAddon
                      style={{ width: '125px' }}
                      addonType='append'
                    >
                      <InputGroupText
                        className={classnames({
                          'p-0': true,
                          'border-danger': !!errors.currency_id,
                        })}
                        style={{ width: '100%', border: 0 }}
                      >
                        <div className='w-100'>
                          <ControlledSelect
                            error={errors.currency_id}
                            control={control}
                            name='currency_id'
                            options={currencyOptions}
                            classNamePrefix='RS-Addon'
                            customComponents={{
                              Option: customOption,
                              Value: customSingleValue,
                              SingleValue: customSingleValue,
                            }}
                            isDisabled={readOnly}
                          />
                        </div>
                      </InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md={6} sm={12} className='mb-3'>
                <FormGroup className='mb-0'>
                  <Label className='col-form-label p-0 m-0 mb-2 font-size-14'>
                    Start Date
                    <span className='text-danger font-size-16 mx-1'>*</span>
                  </Label>
                  <ControlledDatePicker
                    control={control}
                    name='start_date'
                    error={errors.start_date}
                    transform={{
                      output: (date) => {
                        setValue('end_date', null)
                        return format(date, 'yyyy-MM-dd')
                      },
                    }}
                    disabled={readOnly}
                  />
                </FormGroup>
              </Col>
              {isDefinite ? (
                <Col md={6} sm={12} className='mb-3'>
                  <FormGroup className='mb-0'>
                    <Label className='col-form-label p-0 m-0 mb-2 font-size-14'>
                      End Date
                      <span className='text-danger font-size-16 mx-1'>*</span>
                    </Label>
                    {/* add one day to start date as minDate */}
                    <ControlledDatePicker
                      control={control}
                      name='end_date'
                      minDate={
                        startDate
                          ? new Date(addDays(parseISO(startDate), 1))
                          : null
                      }
                      error={errors.end_date}
                      disabled={readOnly}
                    />
                  </FormGroup>
                </Col>
              ) : null}
            </Row>

            <hr className='mx-n4' />
            <Label className='text-gray-h font-size-16 my-4' tag='div'>
              Allowances
            </Label>
            {allowancesFields?.map((item, index) => (
              <div
                className='tw-flex tw-w-full tw-flex-col tw-items-stretch sm:tw-flex-row sm:tw-items-start sm:tw-gap-6'
                key={item.id}
              >
                <FormGroup className='tw-grow'>
                  <Label>
                    Name <span className='tw-text-red'>*</span>
                  </Label>
                  <ControlledInput
                    control={control}
                    name={`allowances.${index}.name`}
                    error={errors.allowances?.[index]?.name}
                    placeholder='Name'
                  />
                </FormGroup>
                <FormGroup className='tw-flex tw-grow'>
                  <div className='tw-flex tw-grow tw-flex-col'>
                    <Label>
                      Gross amount <span className='tw-text-red'>*</span>
                    </Label>
                    <div className='tw-flex tw-grow'>
                      <ControlledInput
                        control={control}
                        type='number'
                        showError={false}
                        className={classnames(
                          '!tw-rounded-br-none !tw-rounded-tr-none',
                          {
                            '!tw-border-red !tw-border-r-surface-30':
                              errors.allowances?.[index]?.amount?.message,
                          },
                        )}
                        wrapperClassName='tw-grow'
                        name={`allowances.${index}.amount`}
                        placeholder='Gross amount'
                      />
                      <span
                        className={classnames(
                          'tw-flex tw-items-center tw-justify-center tw-rounded-br tw-rounded-tr tw-border tw-border-l-0 tw-border-surface-30 tw-px-4 tw-text-sm tw-text-secondary-70',
                          {
                            '!tw-border-red !tw-border-l-surface-30':
                              errors.allowances?.[index]?.amount?.message,
                          },
                        )}
                      >
                        {currencyCode}
                      </span>
                      <button
                        className='tw-flex tw-shrink-0 tw-items-center tw-justify-center tw-border tw-border-surface-30 tw-p-4 sm:tw-ml-6 sm:tw-h-10 sm:tw-w-10 sm:tw-rounded-full sm:tw-p-0'
                        onClick={() => {
                          removeAllowance(index)
                        }}
                      >
                        <Trash size={20} color='red' />
                      </button>
                    </div>
                    {errors.allowances?.[0]?.amount && (
                      <span className='tw-mt-3 tw-text-xs tw-text-red'>
                        {errors.allowances?.[0]?.amount?.message}
                      </span>
                    )}
                  </div>
                </FormGroup>
              </div>
            ))}
            <Row>
              <Col>
                <button
                  className='tw-mb-4 tw-mt-6 tw-flex tw-w-full tw-items-center tw-gap-2 tw-rounded tw-border tw-border-dashed tw-border-primary tw-bg-primary-20 tw-p-4 tw-text-sm tw-font-semibold tw-text-primary'
                  type='button'
                  onClick={() => {
                    appendAllowance({ name: '', amount: '' })
                  }}
                >
                  <PlusCircle size={24} />
                  Add Monthly Allowance
                </button>
              </Col>
            </Row>
            <hr className='mx-n4' />
            <Label className='text-gray-h font-size-16 my-4' tag='div'>
              Health Insurance
            </Label>
            <Row>
              <Col
                className='d-flex align-items-center font-size-14 mb-3'
                style={{ gap: 8 }}
              >
                <label className='align-items-center d-flex hover:bg-light mb-0 pl-1 pr-2 py-1 rounded-pill cursor-pointer'>
                  <Toggle
                    id='2'
                    check={insurance}
                    change={(e) => {
                      setInsurance(e.target.checked)
                    }}
                    disabled={readOnly}
                  />
                  <span className='text-secondary'>
                    Would you like to offer premium health insurance?
                  </span>
                </label>
              </Col>
            </Row>
            {!isAdmin &&
              insurance &&
              regionConfig?.supported_insurance_providers?.length > 0 && (
                <div className='tw-grid tw-grid-cols-2 tw-gap-4 tw-rounded-bl tw-rounded-br tw-border-b tw-border-l tw-border-r tw-border-surface-30 tw-bg-surface-10 tw-p-4 sm:tw-grid-cols-3'>
                  {[
                    ...regionConfig.supported_insurance_providers,
                    !initialData?.insurance_provider?.id && { id: 'later' },
                  ]
                    .filter(Boolean)
                    ?.map((item) => {
                      const isSelected = insuranceProvider === item.id
                      return (
                        <div className='tw-p-2' key={item.id}>
                          <label
                            onClick={() =>
                              setValue('insurance_provider_id', item.id)
                            }
                            className='tw-block tw-h-full tw-w-full tw-cursor-pointer'
                          >
                            <div
                              className={classnames(
                                'tw-h-full tw-rounded tw-p-4 tw-outline',
                                isSelected
                                  ? 'tw-bg-primary-10 tw-outline-2 tw-outline-primary'
                                  : 'tw-bg-white tw-outline-1 tw-outline-surface-30',
                              )}
                            >
                              <div className='tw-flex tw-h-full tw-items-start tw-justify-between'>
                                <div className='tw-flex tw-h-full tw-flex-col tw-justify-center'>
                                  {item.id === 'later' ? (
                                    <>
                                      <FastForward
                                        size={24}
                                        color='var(---secondary)'
                                      />
                                      <div className='tw-mt-2 tw-font-semibold'>
                                        Choose Later
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <div>
                                        <img
                                          className='tw-mb-2 tw-max-h-6 tw-w-auto'
                                          src={item.logo}
                                          alt={item.name}
                                        />
                                      </div>
                                      <div className='tw-mb-2 tw-text-sm tw-font-semibold tw-text-black'>
                                        {item.title}
                                      </div>
                                      <div className='tw-font-semibold'>
                                        {item.name}
                                      </div>
                                      <div className='tw-font-semibold'>
                                        {item.starting_price}
                                      </div>
                                    </>
                                  )}
                                </div>
                                {isSelected ? (
                                  <CheckCircle
                                    size={20}
                                    weight='fill'
                                    color='var(--primary)'
                                  />
                                ) : (
                                  <Circle
                                    size={20}
                                    color='var(--secondary-50)'
                                  />
                                )}
                              </div>
                            </div>
                          </label>
                        </div>
                      )
                    })}
                </div>
              )}
            <Label
              className='rp-font-bold font-size-14 text-muted my-3'
              tag='div'
            >
              Bonus (Optional)
            </Label>
            <Row>
              <Col>
                <FormGroup>
                  <Label className='font-size-14' for='bonus_amount'>
                    Bonus Amount
                  </Label>
                  <InputGroup>
                    <ControlledCurrencyInput
                      control={control}
                      name='bonus_amount'
                      id='bonus_amount'
                      isDisabled
                    />
                    <InputGroupAddon addonType='append'>
                      {currencyCode}
                    </InputGroupAddon>
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Label className='font-size-14' for='signing_bonus_amount'>
                    Signing Bonus Amount
                  </Label>
                  <InputGroup>
                    <ControlledCurrencyInput
                      control={control}
                      name='signing_bonus_amount'
                      id='signing_bonus_amount'
                    />
                    <InputGroupAddon addonType='append'>
                      {currencyCode}
                    </InputGroupAddon>
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Label className='font-size-14' for='bonus_details'>
                    Bonus Details
                  </Label>
                  <ControlledInput
                    control={control}
                    type='textarea'
                    name='bonus_details'
                    id='bonus_details'
                    maxLength='300'
                  />
                </FormGroup>
              </Col>
            </Row>
          </fieldset>
        </Col>
      </AvForm>
    </div>
  )
})

export default FullTimeDetailsForm
