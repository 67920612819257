import { Nav, NavItem, NavLink } from 'reactstrap'
import React from 'react'
import { cn } from 'ui'
import Shimmer from './ui/shimmer'

export default function AdminTabNav({
  items = [],
  loading,
  tag,
  props,
  className,
}) {
  return (
    <Nav className={cn('tw-h-10', className)}>
      {items.map((item) => {
        const key = item.label?.toLowerCase()?.replaceAll(' ', '_')
        if (loading) {
          return <Shimmer width='152px' height='35px' key={key + '-shimmer'} />
        }

        return (
          <NavItem key={key} className='tw-mr-8 tw-bg-white'>
            <NavLink
              className={cn(
                'tw-cursor-pointer tw-bg-white !tw-px-0 tw-py-[6px] tw-text-sm tw-font-normal tw-text-disabled hover:tw-border hover:tw-border-x-surface-20 hover:tw-border-t-surface-20 hover:tw-text-disabled',
                {
                  'active tw-border-b tw-border-b-primary tw-font-semibold tw-text-primary hover:tw-border-x-0 hover:tw-border-b-2 hover:tw-border-t-0 hover:tw-text-primary':
                    item.isActive,
                },
              )}
              onClick={item.onClick}
              tag={tag ?? 'button'}
              {...props}
            >
              {item.label}
            </NavLink>
          </NavItem>
        )
      })}
    </Nav>
  )
}
