import cx from 'classnames'
import React from 'react'
import { Table } from 'reactstrap'

const TableComp = ({ children, className, tag, responsive }) => {
  const Tag = tag || Table

  return (
    <Tag
      responsive={responsive}
      className={cx(
        'mb-0 table table-nowrap text-muted position-relative',
        className,
      )}
    >
      {children}
    </Tag>
  )
}

export default TableComp
