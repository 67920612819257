import { PaperPlaneTilt } from '@phosphor-icons/react'
import React from 'react'
import { Button as RAButton, TooltipTrigger } from 'react-aria-components'
import { Link } from 'react-router-dom'

import { Avatar, cn } from 'ui'
import { CONTRACT_STATUS } from '../../../helpers/enum'
import { usePermissions } from '../../../helpers/hooks'
import permissions from '../../../helpers/permissions'
import { getFullName } from '../../../utils/get-full-name'
import { TooltipV2 } from '../../../components/VerticalLayout/tooltip-v2'

export function WorkerName({ item }) {
  const fullName = getFullName(item?.contractor)

  const { hasAccess } = usePermissions()

  const hasViewPermission = hasAccess(permissions.ViewContractsDetails)

  if (!hasViewPermission) {
    return fullName
  }

  const { photo, email } = item.contractor || {}

  const nameOrEmail = fullName || item.worker_name || email

  const isPendingInvite =
    item.status?.id === CONTRACT_STATUS.PENDING_INVITE.value

  const pendingInviteText = ['Invite', !nameOrEmail ? null : `(${nameOrEmail})`]
    .filter(Boolean)
    .join(' ')

  return (
    <TooltipTrigger delay={500}>
      <RAButton>
        <Link
          className={cn(
            'tw-flex tw-gap-2 tw-text-start',
            !isPendingInvite && 'tw-text-text-100',
          )}
          to={`/contract/detail?id=${item.ref}`}
        >
          {!photo && isPendingInvite ? (
            <span className='tw-flex tw-size-10 tw-items-center tw-justify-center tw-rounded-full tw-bg-primary-30'>
              <PaperPlaneTilt size={20} />
            </span>
          ) : (
            <Avatar photo={photo} name={nameOrEmail} size='lg' />
          )}

          <div className='tw-flex tw-flex-col'>
            <span className={cn('tw-max-w-[20ch] tw-truncate tw-font-bold')}>
              {isPendingInvite ? pendingInviteText : nameOrEmail}
            </span>
            <span className='tw-text-left tw-text-xs tw-font-medium tw-text-text-80'>
              {item.ref}
            </span>
          </div>
        </Link>
      </RAButton>

      {(
        isPendingInvite
          ? pendingInviteText?.length > 20
          : nameOrEmail?.length > 20
      ) ? (
        <TooltipV2 placement='top'>{nameOrEmail}</TooltipV2>
      ) : null}
    </TooltipTrigger>
  )
}
