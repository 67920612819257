import { yupResolver } from '@hookform/resolvers/yup'
import {
  Briefcase,
  CornersOut,
  ImageSquare,
  Info,
  Percent,
  PlusCircle,
  WarningOctagon,
  XCircle,
} from '@phosphor-icons/react'
import { format, isDate, parseISO } from 'date-fns'
import React, { useEffect, useMemo, useState } from 'react'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { Route, Switch, useHistory, useParams } from 'react-router-dom'
import {
  Container,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader as RSModalHeader,
} from 'reactstrap'
import toastr from 'toastr'
import * as yup from 'yup'

import { Stepper, cn } from 'ui'
import ControlledDatePicker from '../../components/ControlledDatePicker'
import ControlledInput from '../../components/ControlledInput'
import ControlledPhoneInput from '../../components/ControlledPhoneInput'
import ControlledSelect from '../../components/ControlledSelect'
import ModalHeader from '../../components/ModalHeader'
import StepContainer from '../../components/Steps/StepContainer'
import ControlledDropzoneInput from '../../components/controlled-dropzone-input'
import Head from '../../components/head'
import InputFeedback from '../../components/ui/input-feedback'
import Loader from '../../components/ui/loader'
import { contractorTypes, userTypes } from '../../helpers/enum'
import { toBase64 } from '../../helpers/helper'
import { useFetch } from '../../helpers/hooks'
import { getContractorInfo, uploadTempFile } from '../../services/api'
import { createExternalInvoice } from '../../services/api-external-invoices'
import { getErrorMessage } from '../../utils/get-errors'
import { getFullName } from '../../utils/get-full-name'
import {
  mapCountryToOption,
  mapCurrencyToOption,
} from '../../utils/map-to-option'
import { ConfirmFormField } from '../Contract/CreateContract/components/confirm-field'
import {
  FormSectionHr,
  FormSectionTitle,
} from '../Contract/CreateContract/components/form-section-title'
import { ClientsList } from './clients-list'
import { InvoiceItems, UNIT, invoiceItemsFieldName } from './invoice-items'
import {
  InvoiceDocumentPreview,
  PreviewInvoice,
  PreviewModal,
} from './preview-invoice'
import Button from '../../components/ui/button'
import { datePickerDateFormat } from '../../utils/formatters/date-picker-date-format'

export function NewExternalInvoice() {
  const history = useHistory()
  const { stepKey } = useParams()
  const [cancelModal, setCancelModal] = useState(false)

  const userType = useSelector((state) => state?.Account?.user?.type)

  useEffect(() => {
    if (userType !== userTypes.CONTRACTOR) {
      history.push('/activity')
    }
  }, [history, userType])

  const [formData, setFormData] = useState({})
  function handleSetData(data) {
    setFormData((prevData) => ({ ...prevData, ...data }))
  }

  const { isLoading: loadingContractorInfo } = useFetch({
    action: getContractorInfo,
    autoFetch: true,
    onComplete: (userProfile) => {
      if (activeIndex !== 0) {
        // We want to update in the the user is in the first step
        return
      }
      handleSetData({
        is_business:
          userProfile?.contractor_type === contractorTypes.INDIVIDUAL
            ? 'no'
            : 'yes',
        name: getFullName(userProfile),
        tax_id: userProfile?.Tax_ID,
        address_line_1: userProfile?.address,
        country_id: userProfile?.country?.id,
        city: userProfile?.city,
        email: userProfile?.email,
        phone_number: userProfile?.phone,
      })
    },
  })

  function handleCancel() {
    history.push('/invoices?tab=external-invoices')
  }

  function handlePrev(step) {
    const prevStepLink = getPrevStepLink(step, steps)
    history.push(prevStepLink ?? '/external-invoice/create')
  }

  const stepperSteps = steps.map((step) => step.name)
  const stepKeys = steps.map((step) => step.key)
  const activeIndex = stepKeys.indexOf(stepKey ?? '')

  const disableNext = !formData?.clientItem && activeIndex === 1 // add more conditions here if needed

  return (
    <Container fluid className='px-0'>
      <Head title='Create an external invoice' />

      <ModalHeader
        action={() => {
          setCancelModal(true)
        }}
      >
        <Stepper
          steps={stepperSteps}
          activeStep={activeIndex}
          className='tw-flex-grow'
        />
      </ModalHeader>

      <div className='tw-px-4'>
        <Switch>
          {steps.map((step, index) => {
            const { key, Component, name } = step
            return (
              <Route path={`/external-invoice/create/${key}`} key={index} exact>
                <StepContainer
                  title={name}
                  index={activeIndex}
                  total={steps.length}
                  nextType='submit'
                  nextFormId={getStepFormId(step)}
                  noBack={index === 0}
                  disableNext={disableNext}
                  onBack={() => handlePrev(step)}
                >
                  {loadingContractorInfo && index === 0 ? (
                    <Loader minHeight='450px' />
                  ) : (
                    <Component
                      step={step}
                      formData={formData}
                      setFormData={handleSetData}
                    />
                  )}
                </StepContainer>
              </Route>
            )
          })}
        </Switch>
      </div>
      <Modal isOpen={cancelModal} toggle={() => setCancelModal(false)}>
        <RSModalHeader
          className='!tw-border-none'
          toggle={() => setCancelModal(false)}
        >
          <WarningOctagon color='gold' size={24} />
        </RSModalHeader>
        <ModalBody className='!tw-pt-0'>
          <div className='tw-mb-2 tw-text-xl tw-font-semibold'>
            Are you you want to quit?
          </div>
          <div>All the information entered will be lost</div>
        </ModalBody>
        <ModalFooter>
          <Button color='light' outline onClick={() => setCancelModal(false)}>
            No, stay on this page
          </Button>
          <Button color='primary' onClick={handleCancel}>
            Yes, Quit
          </Button>
        </ModalFooter>
      </Modal>
    </Container>
  )
}

function getStepFormId(step) {
  return `form-step-${step.key}`
}
function getNextStepLink(step, steps) {
  const stepIndex = steps.findIndex((s) => s.key === step.key)
  const nextStepKey = steps[stepIndex + 1]?.key

  if (!nextStepKey) return null

  return `/external-invoice/create/${nextStepKey}`
}
function getPrevStepLink(step, steps) {
  const stepIndex = steps.findIndex((s) => s.key === step.key)
  const prevStepKey = steps[stepIndex - 1]?.key

  if (!prevStepKey) return null

  return `/external-invoice/create/${prevStepKey}`
}

const phoneRegExp = /^[+]?[(]?[0-9]{1,4}[)]?[-\s/0-9]*$/
const step1DetailsSchema = yup.object().shape({
  is_business: yup
    .string()
    .oneOf(['yes', 'no'])
    .label('Business type')
    .required(),
  name: yup.string().required('Please enter your name or business name'),
  vat_id: yup.string().required('Please enter your VAT ID'),
  tax_id: yup.string().required('Please enter your Tax ID').max(50),
  address_line_1: yup.string().required('Please enter your address'),
  country_id: yup.string().required('Please enter your country'),
  city: yup.string().required('Please enter your city'),
  email: yup.string().email().required('Please enter your email'),
  phone_number: yup
    .string()
    .transform((value) => value.replace(/ {2}/g, ' '))
    .matches(phoneRegExp, 'Phone number is not valid')
    .required('Please enter your phone number'),
})
function Step1Details({ step, setFormData, formData }) {
  const countries = useSelector((state) => state.Layout?.staticData?.countries)

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm({
    resolver: yupResolver(step1DetailsSchema),
    defaultValues: {
      ...formData,
      country_id: Number(formData?.country_id),
    },
  })

  const countryId = useWatch({ control, name: 'country_id' })
  const countrySelected = countries?.find((e) => e.id === countryId)
  const countryStates = countrySelected?.states

  const history = useHistory()

  function onSubmit(values) {
    setFormData(values)

    const nextStepLink = getNextStepLink(step, steps)
    if (nextStepLink) {
      history.push(nextStepLink)
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} id={getStepFormId(step)}>
      <FormSectionTitle title='Basic info' />

      <div className='tw-grid tw-gap-4 md:tw-grid-cols-2 md:tw-gap-6'>
        <ConfirmFormField
          control={control}
          error={errors.is_business}
          name='is_business'
          icon={<Briefcase size={32} className='tw-text-text-30' />}
          title='Are you registered as a business?'
          description='Select yes if you are operating as an established business'
          fieldOptions={[
            { label: 'Yes', value: 'yes' },
            { label: 'No', value: 'no' },
          ]}
          className='md:tw-col-span-2'
        />

        <ControlledInput
          control={control}
          name='name'
          label='Your name or Registered Business name'
        />

        <ControlledInput
          control={control}
          name='vat_id'
          label='VAT ID or Equivalent'
        />

        <div>
          <ControlledInput
            control={control}
            name='tax_id'
            label='Tax ID number'
          />

          <small className='tw-mt-1 tw-flex tw-items-center tw-gap-1 tw-text-text-80'>
            <Info size={16} />
            You can find this in the bank statement at the top right corner.
          </small>
        </div>
      </div>

      <FormSectionHr />
      <FormSectionTitle title='Your address' />

      <div className='tw-grid tw-gap-4 md:tw-grid-cols-2 md:tw-gap-6'>
        <ControlledInput
          control={control}
          name='address_line_1'
          label='Address 1'
          wrapperClassName='md:tw-col-span-2'
        />

        <ControlledSelect
          control={control}
          label='Country'
          name='country_id'
          options={countries?.map((c) => mapCountryToOption(c))}
          placeholder='Select country'
          transform={{
            output: (value) => {
              setValue('state_id', null)
              return value
            },
          }}
        />

        {countryStates?.length <= 0 || !countryStates ? null : (
          <ControlledSelect
            control={control}
            label='State'
            name='state_id'
            options={countryStates?.map((c) => mapCountryToOption(c))}
            placeholder='Select state'
          />
        )}

        <ControlledInput control={control} name='city' label='City' />
      </div>

      <FormSectionHr />
      <FormSectionTitle title='Contact info' />

      <div className='tw-grid tw-gap-4 md:tw-grid-cols-2 md:tw-gap-6'>
        <ControlledInput control={control} name='email' label='Email' />

        <ControlledPhoneInput
          control={control}
          name='phone_number'
          label='Phone Number'
          defaultCountry={countrySelected?.iso2?.toLowerCase()}
        />
      </div>
    </form>
  )
}

// Custom hook to make sure that we have data from the first step
// Otherwise, redirect back to the first step
function useCheckFirstStepData(formData, history) {
  useEffect(() => {
    // If we don't have the data from the first step, redirect back to the first step
    // Here we are checking with the name field
    if (!formData.name) {
      history.push('/external-invoice/create')
    }
  }, [formData, history])
}

function Step2ClientDetails({ step, formData, setFormData }) {
  const { handleSubmit, control } = useForm({
    defaultValues: {
      external_invoice_client_id: Number(formData.external_invoice_client_id),
    },
    resolver: yupResolver(
      yup.object().shape({
        external_invoice_client_id: yup
          .string()
          .required('Please select a client'),
      }),
    ),
  })

  const history = useHistory()
  useCheckFirstStepData(formData, history)

  function onSubmit(values) {
    setFormData(values)

    const nextStepLink = getNextStepLink(step, steps)
    if (nextStepLink) {
      history.push(nextStepLink)
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} id={getStepFormId(step)}>
      <Controller
        control={control}
        name='external_invoice_client_id'
        render={({ field: { value, onChange }, fieldState: { error } }) => {
          return (
            <>
              <ClientsList
                onClientSelect={(client) => {
                  setFormData({ clientItem: client?.raw })
                  onChange(client?.id)
                }}
                selectedClient={value}
              />

              {!error ? null : (
                <InputFeedback className='tw-mt-1'>
                  {getErrorMessage(error)}
                </InputFeedback>
              )}
            </>
          )
        }}
      />
    </form>
  )
}

const PRODUCT_TYPE = {
  // PRODUCTS: 'PRODUCTS',
  SERVICES: 'SERVICES',
}
function Step3InvoiceDetails({ step, formData, setFormData }) {
  const { currencies } = useSelector((state) => state.Layout?.staticData ?? {})

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: { ...formData },
    resolver: yupResolver(
      yup.object().shape({
        product_type: yup
          .string()
          .oneOf([PRODUCT_TYPE.SERVICES])
          .required('Please select product type'),
        currency_id: yup.string().required('Please select currency'),
        [invoiceItemsFieldName]: yup
          .array()
          .of(
            yup.object().shape({
              description: yup.string().required('Please enter description'),
              rate: yup
                .number()
                .typeError('Please enter a valid number')
                .min(1, 'Must be > 0')
                .required('Please enter rate'),
              quantity: yup
                .number()
                .min(1, 'Must be > 0')
                .typeError('Please enter a valid number')
                .required('Please enter quantity'),
              unit: yup
                .string()
                .oneOf(Object.values(UNIT), 'Please select unit')
                .required('Please select unit'),
            }),
          )
          .min(1, 'Please add at least one item'),
      }),
    ),
  })

  const history = useHistory()
  useCheckFirstStepData(formData, history)

  const { startFetch: uploadInvoiceLogo } = useFetch({
    action: uploadTempFile,
    onComplete: (data) => {
      if (data?.success === false) {
        toastr.error(data?.message)
      } else {
        setFormData({ logo: data?.path })
      }
    },
    onError: (error) => {
      toastr.error(error)
    },
  })

  function onSubmit(values) {
    setFormData(values)

    const nextStepLink = getNextStepLink(step, steps)
    if (nextStepLink) {
      history.push(nextStepLink)
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} id={getStepFormId(step)}>
      <FormSectionTitle title='Basic info' />

      <div className='tw-grid tw-gap-4 md:tw-grid-cols-2 md:tw-gap-6'>
        <div className='tw-flex tw-flex-wrap tw-items-start tw-gap-4 md:tw-col-span-2'>
          <div className='tw-min-h-[122px] tw-flex-grow tw-rounded tw-border tw-border-surface-30 tw-p-4'>
            <h6 className='tw-mb-1 tw-text-base tw-font-bold'>
              Add your logo to the invoice
            </h6>
            <p className='tw-mb-2 tw-text-sm tw-text-text-60'>
              This will be displayed in your invoice. (Optional)
            </p>
            <small className='tw-flex tw-items-center tw-gap-1.5 tw-text-xs tw-text-secondary-80'>
              <Info size={16} />
              Recommended dimensions: square, at least 300x300 pixels. JPG or
              PNG
            </small>
          </div>
          <div className='tw-text-center'>
            {!formData?.imagePreview ? (
              <ControlledDropzoneInput
                control={control}
                name='invoiceLogo'
                unstyled
                wrapperClassName='tw-group'
                accept={{ 'image/*': ['.png', '.jpg', '.jpeg', '.gif'] }}
                onDropAccepted={async (files) => {
                  const imageFile = files[0]
                  uploadInvoiceLogo({
                    file: imageFile,
                    type: 'external_invoices',
                  })
                  const base64File = await toBase64(imageFile)

                  setFormData({ imagePreview: base64File })
                }}
              >
                <div className='tw-flex tw-aspect-square tw-w-[122px] tw-cursor-pointer tw-items-center tw-justify-center tw-rounded-md tw-border tw-border-dashed tw-border-surface-40 tw-bg-primary-10'>
                  <div className='tw-relative tw-size-11 tw-text-surface-90'>
                    <ImageSquare size={44} />
                    <PlusCircle
                      weight='fill'
                      className='tw-absolute tw-right-0 tw-top-0 tw-size-4 -tw-translate-y-0.5 tw-translate-x-0.5 tw-rounded-full tw-bg-primary-10'
                    />
                  </div>
                </div>
              </ControlledDropzoneInput>
            ) : (
              <>
                <div className='tw-relative tw-flex tw-aspect-square tw-w-[122px] tw-items-center tw-justify-center tw-rounded-md tw-border tw-border-dashed tw-border-surface-40 tw-bg-primary-10 tw-p-4'>
                  <img
                    src={formData.imagePreview}
                    className='tw-aspect-square tw-w-full tw-max-w-28 tw-object-contain'
                  />

                  <button
                    className='tw-group tw-absolute tw-right-0 tw-top-0 -tw-translate-y-1/2 tw-translate-x-1/2 tw-rounded-full tw-text-secondary-100'
                    type='button'
                    onClick={() => {
                      setValue('invoiceLogo', null)
                      setFormData({ imagePreview: null })
                    }}
                  >
                    <XCircle
                      weight='fill'
                      className='tw-size-5 tw-transition-transform tw-duration-200 tw-ease-in-out group-hover:tw-scale-110'
                    />
                  </button>
                </div>

                <PreviewInvoice imagePreview={formData.imagePreview} />
              </>
            )}
          </div>
        </div>

        <ControlledSelect
          control={control}
          label='Product type'
          name='product_type'
          options={[
            // { label: 'Product', value: PRODUCT_TYPE.PRODUCTS },
            { label: 'Service', value: PRODUCT_TYPE.SERVICES },
          ]}
          placeholder='Select product type'
        />

        <ControlledSelect
          control={control}
          label='Currency'
          name='currency_id'
          options={currencies?.map((c) => mapCurrencyToOption(c, 'id'))}
          placeholder='Currency'
        />
      </div>

      <FormSectionHr />
      <FormSectionTitle title='Invoice items' />

      <InvoiceItems control={control} />
      {errors[invoiceItemsFieldName] && (
        <span className='tw-mt-2 tw-text-xs tw-text-red'>
          {errors[invoiceItemsFieldName].message}
        </span>
      )}

      <div className='tw-mt-4 tw-grid tw-gap-y-4 tw-rounded tw-border tw-border-surface-30 tw-p-4 md:tw-grid-cols-2'>
        <div className='tw-flex tw-gap-4'>
          <Percent size={32} className='tw-text-text-30' />

          <div>
            <h6 className='tw-mb-1 tw-text-base tw-font-bold'>Add tax rate</h6>
            <p className='tw-mb-0 tw-text-sm tw-text-text-60'>
              Leave empty if no tax applies
            </p>
          </div>
        </div>

        <div>
          <label>VAT/GST</label>

          <InputGroup>
            <ControlledInput
              control={control}
              name='vat'
              className='!tw-rounded-r-none'
              wrapperClassName='tw-flex-grow'
              type='number'
              min={0}
            />
            <InputGroupAddon addonType='append'>
              <InputGroupText className='rounded-right'>%</InputGroupText>
            </InputGroupAddon>
          </InputGroup>
        </div>
      </div>

      <InvoiceItemsSummary control={control} />
    </form>
  )
}

function financial(x) {
  if (isNaN(x)) return x

  return Number.parseFloat(x).toFixed(2)
}
function InvoiceItemsSummary({ control }) {
  const { currencies } = useSelector((state) => state.Layout?.staticData ?? {})
  const {
    [invoiceItemsFieldName]: invoiceItems,
    currency_id: currencyId,
    vat,
  } = useWatch({ control })

  const currencySelected = currencies?.find((c) => c.id === currencyId)
  const currencySymbol = currencySelected?.symbol ?? ''

  const subtotal =
    invoiceItems?.length <= 0 || !invoiceItems
      ? 0
      : financial(
          invoiceItems?.reduce((acc, item) => {
            return acc + Number(item.rate) * Number(item.quantity)
          }, 0),
        )
  const vatTotal = vat <= 0 || !vat ? 0 : financial((subtotal * vat) / 100)
  const total = financial(Number(subtotal) + Number(vatTotal))

  function withCurrency(value) {
    return `${currencySymbol} ${value}`
  }

  return (
    <div className='tw-mt-4 tw-space-y-4 tw-rounded tw-border-b tw-border-surface-30 tw-bg-surface-10 tw-p-4'>
      {[
        { label: 'Subtotal', value: withCurrency(subtotal) },
        { label: 'VAT', value: `(${vat ?? 0}%) ${withCurrency(vatTotal)}` },
        {
          label: 'Total amount',
          value: withCurrency(total),
          labelClassName: 'tw-text-sm tw-font-bold tw-text-black',
          valueClassName: 'tw-text-3xl tw-font-bold tw-text-black',
        },
      ].map((item) => (
        <CalculationItem key={item.label} {...item} />
      ))}
    </div>
  )
}

const PAYMENT_STATUS = { UNPAID: 'UNPAID', PAID: 'PAID' }
function Step4PaymentTerms({ step, formData, setFormData }) {
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: { ...formData },
    resolver: yupResolver(
      yup.object().shape({
        invoice_date: yup.date().required('Please select invoice date'),
        due_date: yup.date().required('Please select due date'),
        project_name: yup.string().nullable(),
        project_started_at: yup
          .date()
          .required('Please select project start date'),
        project_ended_at: yup.date().required('Please select project end date'),
        status: yup
          .string()
          .oneOf([PAYMENT_STATUS.PAID, PAYMENT_STATUS.UNPAID])
          .required('Please select payment status'),
        paid_at: yup.date().when('status', {
          is: PAYMENT_STATUS.PAID,
          then: (schema) => schema.required('Please select payment date'),
          otherwise: (schema) => schema.nullable(),
        }),
        details: yup.string().nullable(),
      }),
    ),
  })

  const {
    status,
    project_started_at: projectStartDate,
    invoice_date: invoiceDate,
  } = useWatch({ control })

  const history = useHistory()
  useCheckFirstStepData(formData, history)

  function onSubmit(values) {
    setFormData(values)

    const nextStepLink = getNextStepLink(step, steps)
    if (nextStepLink) {
      history.push(nextStepLink)
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} id={getStepFormId(step)}>
      <div className='tw-grid tw-gap-4 md:tw-grid-cols-2 md:tw-gap-6'>
        <ControlledDatePicker
          control={control}
          name='invoice_date'
          label='Invoice date'
          transform={{
            output: (value) => {
              setValue('due_date', null)
              return datePickerDateFormat(value)
            },
          }}
          placeholder='Select invoice date'
        />

        <ControlledDatePicker
          control={control}
          name='due_date'
          label='Due date'
          minDate={parseISO(invoiceDate) ?? null}
          placeholder='Select due date'
        />

        <ControlledDatePicker
          control={control}
          name='project_started_at'
          label='Project start date'
          transform={{
            output: (value) => {
              setValue('project_ended_at', null)
              return datePickerDateFormat(value)
            },
          }}
          placeholder='Select start date'
        />
        <ControlledDatePicker
          control={control}
          name='project_ended_at'
          label='Project end date'
          placeholder='Select end date'
          minDate={parseISO(projectStartDate) ?? null}
        />

        <ControlledInput
          control={control}
          name='project_name'
          label='Project name (Optional)'
        />

        <ConfirmFormField
          control={control}
          name='status'
          error={errors.status}
          title='Payment status'
          description='Is this invoice paid or unpaid'
          fieldOptions={[
            { label: 'Unpaid', value: PAYMENT_STATUS.UNPAID },
            { label: 'Paid', value: PAYMENT_STATUS.PAID },
          ]}
          className='md:tw-col-span-2'
        />

        {status === PAYMENT_STATUS.UNPAID ? (
          <ControlledInput
            control={control}
            name='details'
            label='Enter payment details (Optional)'
            placeholder='Enter payment details (Optional)'
            type='textarea'
            wrapperClassName='md:tw-col-span-2'
            rows={6}
          />
        ) : (
          <ControlledDatePicker
            control={control}
            name='paid_at'
            label='Payment date'
            placeholder='Select payment date'
            wrapperClassName='md:tw-col-span-2'
          />
        )}
      </div>
    </form>
  )
}

function CalculationItem({
  label,
  value,
  className,
  valueClassName,
  labelClassName,
}) {
  return (
    <div
      className={cn(
        'tw-flex tw-items-center tw-justify-between tw-gap-4',
        className,
      )}
    >
      <div className={cn('tw-text-xs tw-font-medium', labelClassName)}>
        {label}
      </div>
      <div
        className={cn(
          'tw-text-sm tw-font-medium tw-text-text-80',
          valueClassName,
        )}
      >
        {value}
      </div>
    </div>
  )
}

function formatInvoiceDate(date) {
  if (!date || !isDate(new Date(date))) {
    return date
  }
  return format(new Date(date), 'yyyy-MM-dd')
}

function Step5Preview({ step, formData, setFormData }) {
  const { handleSubmit, control } = useForm({
    defaultValues: { ...formData },
  })

  const history = useHistory()
  useCheckFirstStepData(formData, history)

  const { startFetch: createInvoice } = useFetch({
    action: createExternalInvoice,
    onComplete: (data) => {
      if (data?.success === false) {
        toastr.error('Failed to create invoice')
      } else {
        toastr.success('Invoice created successfully')
        history.push('/invoices?tab=external-invoices')
      }
    },
    onError: (error) => {
      toastr.error(error)
    },
  })

  function onSubmit(values) {
    setFormData(values)

    const nextStepLink = getNextStepLink(step, steps)
    if (nextStepLink) {
      history.push(nextStepLink)
    } else {
      const body = {
        external_invoice_client_id: formData.external_invoice_client_id,
        vat: Number(formData.vat) || undefined,
        details: formData.details,
        project_started_at: formatInvoiceDate(formData.project_started_at),
        project_ended_at: formatInvoiceDate(formData.project_ended_at),
        status: formData.status,
        project_name: formData.project_name,
        product_type: formData.product_type,
        due_date: formatInvoiceDate(formData.due_date),
        currency_id: formData.currency_id,
        invoice_date: formatInvoiceDate(formData.invoice_date),
        items: formData.items.map((item) => ({
          description: item.description,
          rate: Number(item.rate),
          qty: Number(item.quantity),
          unit: item.unit,
        })),
        logo: formData.logo,
        paid_at: formData.paid_at
          ? formatInvoiceDate(formData.paid_at)
          : undefined,
        contractor: {
          vat_id: formData.vat_id,
          tax_id: formData.tax_id,
          name: formData.name,
          is_business: formData.is_business === 'yes',
          country_id: formData.country_id,
          city: formData.city,
          address: formData.address_line_1,
          address_line_1: formData.address_line_1,
          address_line_2: formData.address_line_2,
          email: formData.email,
          phone_number: formData.phone_number,
        },
      }

      createInvoice(body)
    }
  }

  const { currencies } = useSelector((state) => state.Layout?.staticData ?? {})
  const { currency_id: currencyId } = useWatch({ control })
  const currencySelected = currencies?.find((c) => c.id === Number(currencyId))
  const currencySymbol = currencySelected?.symbol ?? ''

  const previewDataProps = {
    // Invoice is not created yet, so we are using dummy reference
    reference: 'REF-XXXX-0001',
    issueOn: formatInvoiceDate(new Date(), 'yyyy-MM-dd'),
    dueDate: formData
      ? `Due ${formatInvoiceDate(new Date(formData?.due_date), 'yyyy-MM-dd')}`
      : '',
    clientDetails: [
      getFullName(formData?.clientItem),
      formData?.clientItem?.email,
      [formData?.clientItem?.address_line_1, formData?.clientItem?.city]
        .filter(Boolean)
        .join(', '),
      formData?.clientItem?.phone_number,
    ].filter(Boolean),
    invoiceItems: formData?.items?.map((item) => ({
      description: item.description,
      rate: item.rate,
      quantity: item.quantity,
      unit: item.unit,
      amount: Number(item.quantity) * Number(item.rate),
    })),
    taxRate: Number(formData.vat),
    imagePreview: formData.imagePreview,
    currencySymbol,
  }

  const formStepID = useMemo(() => getStepFormId(step), [step])

  const sendClientCopy = useWatch({ control, name: 'sendClientCopy' })

  // Change submit button text based on the sendClientCopy value
  // This is a workaround because we can't change the button text from the Parent component
  // since we don't have access to the hook form state
  useEffect(() => {
    const submitBtn = document.querySelector(
      `button[type="submit"][form="${formStepID}"]`,
    )
    if (sendClientCopy) {
      submitBtn.textContent = 'Save & Send'
    } else {
      submitBtn.textContent = 'Save'
    }
  }, [formStepID, sendClientCopy])

  return (
    <form onSubmit={handleSubmit(onSubmit)} id={formStepID}>
      <div
        className='tw-relative tw-bg-surface-20 tw-p-6'
        style={{
          '--preview-window-height': 530,
          height: 'calc(var(--preview-window-height)*1px)',
        }}
      >
        <InvoiceDocumentPreview
          className='tw-absolute tw-left-1/2 tw-top-6 tw-mx-auto tw-origin-top-left -tw-translate-x-1/2'
          style={{
            '--preview-height': 842,
            minWidth: '595px',
            '--scale':
              'scale(calc(calc(var(--preview-window-height) - 48) / var(--preview-height)))',
            '--translate-x': 'translateX(-50%)',
            transform: 'var(--scale) var(--translate-x)',
          }}
          {...previewDataProps}
        />

        <PreviewInvoiceLastStep>
          <InvoiceDocumentPreview {...previewDataProps} />
        </PreviewInvoiceLastStep>
      </div>
    </form>
  )
}

function PreviewInvoiceLastStep({ children }) {
  const [isModalOpen, setIsModalOpen] = useState(false)
  function toggleModal() {
    setIsModalOpen((isOpen) => !isOpen)
  }

  return (
    <>
      <button
        type='button'
        className='tw-absolute tw-bottom-0 tw-right-0 tw-rounded tw-border tw-border-surface-30 tw-bg-white tw-p-2 tw-shadow-sm tw-transition-colors hover:tw-bg-surface-10 sm:tw-bottom-6 sm:tw-right-6'
        onClick={toggleModal}
        aria-label='Open invoice modal'
      >
        <CornersOut size={20} aria-hidden='true' />
      </button>

      <div>
        <PreviewModal isModalOpen={isModalOpen} toggleModal={toggleModal}>
          {children}
        </PreviewModal>
      </div>
    </>
  )
}

const steps = [
  {
    name: 'Your details',
    key: '',
    Component: Step1Details,
    next: 'client-details',
  },
  {
    name: 'Client details',
    Component: Step2ClientDetails,
    key: 'client-details',
    next: 'invoice-details',
  },
  {
    name: 'Invoice Details',
    Component: Step3InvoiceDetails,
    key: 'invoice-details',
    next: 'payment-terms',
  },
  {
    name: 'Payment Terms',
    Component: Step4PaymentTerms,
    key: 'payment-terms',
    next: 'preview',
  },
  { name: 'Preview', Component: Step5Preview, key: 'preview' },
]
