import { HourglassHigh, ListChecks, Money, Wallet } from '@phosphor-icons/react'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Col, Container, Row } from 'reactstrap'
import toastr from 'toastr'

import { cn } from 'ui'
import AlmostThereItem1SVG from '../../assets/images/onboarding-modal/almost-there-item-1.svg'
import AlmostThereItem2SVG from '../../assets/images/onboarding-modal/almost-there-item-2.svg'
import AlmostThereItem3SVG from '../../assets/images/onboarding-modal/almost-there-item-3.svg'
import AlmostThereItem4SVG from '../../assets/images/onboarding-modal/almost-there-item-4.svg'
import AlmostTherePersonPng from '../../assets/images/onboarding-modal/almost-there-person.png'
import CardSVG from '../../assets/images/onboarding-modal/card.svg'
import HealthInsuranceSVG from '../../assets/images/onboarding-modal/health-insurance.svg'
import MobileApp from '../../assets/images/onboarding-modal/mobile.svg'
import PaymentMethodsSVG from '../../assets/images/onboarding-modal/payment-methods.svg'
import ActionCenter from '../../components/ActionCenter'
import { StyledH4, StyledLink } from '../../components/Typo'
import Loader from '../../components/ui/loader'
import SliderModal, {
  ModalFooterBtn,
} from '../../components/ui/slider-modal/slider-modal'
import { BE_CONTRACT_CATEGORY, userTypes } from '../../helpers/enum'
import { useFetch } from '../../helpers/hooks'
import {
  getBanners,
  getEarnings,
  getOverview,
  getToDoList,
} from '../../services/api'
import { track } from '../../utils/analytics'
import { getCurrencyFormatter } from '../../utils/formatters/currency'
import { formatDays } from '../../utils/formatters/format-days'
import ColumnWithDataLabels from '../AllCharts/apex/ColumnWithDataLabels'
import { tagSession } from './center-overview-client'
import GenericBanner from './generic-banner'
import MiniWidget from './mini-widget'

function StyledPrice({ children }) {
  return (
    <h3 className='tw-my-4 tw-text-2xl tw-font-bold tw-tracking-wide'>
      {children}
    </h3>
  )
}

function Dimmer({ children }) {
  return (
    <div className='tw-absolute tw-left-0 tw-top-0 tw-z-[999] tw-flex tw-size-full tw-items-center tw-justify-center'>
      {children}
    </div>
  )
}

function ContractorOverview() {
  const [reports, setReports] = useState([])
  const [loading, setLoading] = useState(true)
  const [earnings, setEarnings] = useState({
    currency: {},
    months: [],
    labels: [],
    values: [],
    current: {},
    last: {},
    isEmpty: null,
  })

  const [isOnboardingOpen, setIsOnboardingOpen] = useState(false)

  const user = useSelector((state) => state.Account?.user)
  const userProfile = useSelector((state) => state?.userProfile?.userProfile)

  const isContractor = userProfile?.type === userTypes.CONTRACTOR
  const isDirectEmployee =
    userProfile?.contractor_type === BE_CONTRACT_CATEGORY.DIRECT_EMPLOYEE

  const isContractorNotDe = isContractor && !isDirectEmployee

  const history = useHistory()

  const todo = useFetch(
    {
      action: getToDoList,
      initResult: [],
      autoFetch: true,
      onError: (err) => toastr.error(err),
      onComplete: () => {
        tagSession(user)
      },
    },
    [],
  )

  const { data: bannerData, isLoading: bannersLoading } = useFetch({
    action: getBanners,
    onError: (err) => toastr.error(err),
    autoFetch: true,
  })

  useEffect(() => {
    if (localStorage.getItem('show_contractor_onboarding')) {
      if (isContractorNotDe) {
        track('ContractorInAppOnboardingStarted')
        setIsOnboardingOpen(true)
      }
      localStorage.removeItem('show_contractor_onboarding')
    }
  }, [])

  useEffect(() => {
    try {
      getEarnings(user?.token)
        .then((r) => {
          const xAxis = []
          const xAxisLong = []
          const values = []
          if (r.data.success) {
            r.data.data.months.map((element, i) => {
              xAxis[i] = element.month.short
              xAxisLong[i] = element.month.long
              values[i] = element.total
              return element
            })
            setEarnings({
              currency: r.data.data.currency,
              months: xAxis,
              labels: xAxisLong,
              values,
              current: r.data.data.current,
              last: r.data.data.last,
              isEmpty: r.data.data.is_empty,
            })
          }
        })
        .catch((e) => {
          // eslint-disable-next-line no-console
          console.warn(e)
          setLoading(false)
        })
    } catch (e) {
      setLoading(false)
    }
    try {
      getOverview(user?.token)
        .then((r) => {
          setLoading(false)

          if (r.data.success) {
            const overviewData = r.data.data
            const daysLeft = overviewData?.time_offs_days_left

            setReports(
              [
                !isDirectEmployee && {
                  customIcon: (
                    <Wallet className='w-100 h-100' weight='duotone' />
                  ),
                  link: '/history',
                  title: 'Balance',
                  value: overviewData.balance,
                  badgeValue: '6',
                  color: 'success',
                  desc: 'Contracts',
                  track: () => {
                    window.analytics.track('Clicked withdraw', {
                      email_id: user?.email,
                    })
                  },
                },
                isDirectEmployee
                  ? {
                      customIcon: (
                        <HourglassHigh
                          className='w-100 h-100'
                          weight='duotone'
                        />
                      ),
                      title: 'Time off',
                      href: '/time-off',
                      hrefText: 'Request',
                      value: {
                        amount: daysLeft
                          ? formatDays(daysLeft) + ' left'
                          : 'No balance available',
                      },
                      disableHref: daysLeft === 0,
                      info:
                        daysLeft === 0
                          ? 'Not enough balance to request time off'
                          : '',
                      hrefTip:
                        'You can’t request time off unless you have a policy assigned from your client/employer.',
                    }
                  : {
                      customIcon: (
                        <ListChecks className='w-100 h-100' weight='duotone' />
                      ),
                      link: '/contracts',
                      title: 'Active contracts',
                      value: overviewData.active_contracts,
                      badgeValue: '6',
                      color: 'success',
                      desc: 'Contracts',
                      track: () => {
                        window.analytics.track(
                          'Clicked view active contracts',
                          {
                            email_id: user?.email,
                          },
                        )
                      },
                    },
                {
                  customIcon: (
                    <Money className='w-100 h-100' weight='duotone' />
                  ),
                  link: '/payment',
                  title: isDirectEmployee ? 'Upcoming Payroll' : 'Next Payment',
                  value: isDirectEmployee
                    ? { amount: overviewData.next_payment_date }
                    : overviewData.upcoming_payment,
                  badgeValue: '--',
                  color: 'success',
                  desc: 'Contracts',
                  info: 'List of payments in the current cycle for active contracts. For Pay as you Go and Milestone contracts, a payment is created only when your submitted work is approved by the client',
                  track: () => {
                    window.analytics.track('Clicked upcoming payment', {
                      email_id: user?.email,
                    })
                  },
                },
              ].filter(Boolean),
            )
          }
        })
        .catch((e) => {
          // eslint-disable-next-line no-console
          console.warn(e)
          setLoading(false)
        })
    } catch (e) {
      setLoading(false)
    }
  }, [])

  const formatter = getCurrencyFormatter(earnings?.currency?.code)

  return (
    <>
      <div className='page-content'>
        {loading || todo.isLoading || bannersLoading ? (
          <Loader minHeight='calc(100vh - 215px)' />
        ) : (
          <Container fluid className='p-0 m-0'>
            <GenericBanner bannerData={bannerData} />

            <h1 className='tw-mb-1 tw-text-2xl sm:tw-text-3xl lg:tw-text-4xl'>
              Hi, <span translate='no'>{user?.first_name}</span>
            </h1>
            <h2 className='tw-mb-6 tw-text-base tw-font-normal tw-text-text-80'>
              Here is a recap of your activity
            </h2>

            {todo?.data?.length > 0 && (
              <ActionCenter data={todo.data} updateTodoList={todo.startFetch} />
            )}

            <Col className='p-0 m-0'>
              <div style={{ marginBottom: 32 }}>
                <MiniWidget
                  reports={reports}
                  simple
                  withdraw={!isDirectEmployee}
                />
              </div>
            </Col>

            <div className='rp-shadow-2 tw-rounded tw-bg-white'>
              <div className='d-flex justify-content-between align-items-center tw-p-4 md:tw-px-6 md:tw-py-8'>
                <StyledH4 min='16px' max='24px' className='mb-0 text-dark'>
                  Earnings Per Month
                </StyledH4>
              </div>

              <Container fluid>
                <Row className='tw-relative tw-gap-y-3 tw-p-4 md:tw-px-6 md:tw-py-8'>
                  {earnings?.isEmpty && <Dimmer>No data available</Dimmer>}

                  <Col
                    lg={4}
                    className={cn(
                      '!tw-px-0 lg:!tw-pr-4',
                      earnings?.isEmpty && 'tw-blur-sm',
                    )}
                  >
                    <h5>This Month</h5>
                    <div className='d-flex align-items-center'>
                      <StyledPrice>
                        {formatter &&
                          formatter.format(earnings?.current?.total ?? 0)}
                      </StyledPrice>
                      <span
                        className={`font-size-12 ml-2 rounded badge badge-white badge-pill bg-soft-${
                          earnings?.current?.growth < 0 ? 'danger' : 'success'
                        }`}
                      >
                        <p
                          className={`font-size-14 mb-0 text-${
                            earnings?.current?.growth < 0 ? 'danger' : 'success'
                          }`}
                        >
                          {earnings && earnings?.current?.growth}%
                        </p>
                      </span>
                    </div>
                    <StyledLink
                      max='14px'
                      min='12px'
                      to='/transactions'
                      className='text-primary d-flex align-items-center'
                    >
                      View details{' '}
                      <i className='bx text-primary bx-chevron-right font-size-6 align-middle ml-9' />
                    </StyledLink>
                    <hr
                      style={{
                        borderColor: '#E7E8F2',
                        marginTop: '20px',
                        marginBottom: '20px',
                      }}
                    />
                    <h5>Last Month</h5>
                    <div className='d-flex align-items-center'>
                      <StyledPrice>
                        {formatter && formatter.format(earnings?.last?.total)}
                      </StyledPrice>
                      <span
                        className={`font-size-12 ml-2 rounded badge badge-white badge-pill bg-soft-${
                          earnings?.last.growth < 0 ? 'danger' : 'success'
                        }`}
                      >
                        <p
                          className={`font-size-14 mb-0 text-${
                            earnings?.last?.growth < 0 ? 'danger' : 'success'
                          }`}
                        >
                          {earnings && earnings?.last?.growth}%
                        </p>
                      </span>
                    </div>
                    <StyledLink
                      max='14px'
                      min='12px'
                      to='/transactions'
                      className='text-primary d-flex align-items-center'
                    >
                      View details{' '}
                      <i className='bx text-primary bx-chevron-right font-size-6 align-middle ml-9' />
                    </StyledLink>
                  </Col>
                  <Col
                    lg={8}
                    className={cn(
                      '!tw-px-0 lg:!tw-pl-4',
                      earnings?.isEmpty && 'tw-blur-sm',
                    )}
                  >
                    <ColumnWithDataLabels
                      values={earnings?.values}
                      months={earnings?.months}
                      labels={earnings?.labels}
                      currency={earnings?.currency}
                    />
                  </Col>
                </Row>
              </Container>
            </div>
          </Container>
        )}
      </div>
      <SliderModal
        isOpen={isOnboardingOpen}
        onIndexChange={(index) => {
          track('ContractorInAppOnboardingStepViewed', {
            stepNumber: index + 1,
          })
        }}
        onNext={(index) => {
          track('ContractorInAppOnboardingStepCompleted', { stepNumber: index })
        }}
        slides={slides}
        onClose={(index) => {
          track('ContractorInAppOnboardingDismissed', { stepNumber: index + 1 })
          setIsOnboardingOpen(false)
        }}
        customFooter={{
          index: 4,
          wrapperClassname: 'border-top-0 mt-n4',
          component: (
            <div className='d-flex justify-content-center align-items-center w-100'>
              <ModalFooterBtn
                outline
                onClick={() => {
                  track('ContractorInAppOnboardingDismissed')
                  setIsOnboardingOpen(false)
                }}
              >
                Do it later
              </ModalFooterBtn>
              <ModalFooterBtn
                className='ml-2'
                onClick={() => {
                  track('ContractorInAppOnboardingCompleted')
                  setIsOnboardingOpen(false)
                  history.push('settings#profile')
                }}
              >
                Complete my profile
              </ModalFooterBtn>
            </div>
          ),
          dots: false,
        }}
      />
    </>
  )
}
const slides = [
  {
    src: PaymentMethodsSVG,
    title: 'Unlock Flexible Payment',
    description:
      'Unlock flexible payments. Get paid with ease using any of our seven different payout methods, in over 90 currencies worldwide.',
    key: 1,
  },
  {
    src: CardSVG,
    title: 'Get the RemotePass Card',
    description:
      'Receive payments in USD, hold funds indefinitely, and enjoy global offline and online spending!',
    key: 2,
  },
  {
    src: HealthInsuranceSVG,
    title: 'Access Health Insurance',
    description:
      'Access premium insurance plans for you and your dependents, no matter where you are.',
    key: 3,
  },
  {
    src: MobileApp,
    title: 'Manage your work on the Go',
    description:
      'Manage your contracts, cards, expenses, and time off right at your fingertips.',
    key: 4,
  },
  {
    content: (
      <div className='tw-relative tw-bg-[#F1FDFD]'>
        <img src={AlmostThereItem1SVG} alt='' className='tw-top-0' />
        <img
          src={AlmostThereItem3SVG}
          alt=''
          className='tw-absolute tw-top-0'
        />
        <img
          src={AlmostTherePersonPng}
          alt=''
          className='tw-absolute tw-left-1/2 tw-top-1/2 -tw-translate-x-1/2 -tw-translate-y-1/2'
        />
        <img
          src={AlmostThereItem2SVG}
          alt=''
          className='tw-absolute tw-top-0'
        />
        <img
          src={AlmostThereItem4SVG}
          alt=''
          className='tw-absolute tw-top-0'
        />
      </div>
    ),
    title: 'Almost there!',
    description:
      'You’re almost there! Complete your profile to unlock all benefits.',
    wrapperClassname: 'text-center mx-5',
    key: 5,
  },
]

export default ContractorOverview
