import { CaretDown, CaretUp, ClockCountdown } from '@phosphor-icons/react'
import React, { useState } from 'react'
import {
  Card,
  CardBody,
  Col,
  Container,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from 'reactstrap'

import BadgeX from '../../../components/Table/BadgeX'
import StyledTd from '../../../components/Table/StyledTd'
import StyledTh from '../../../components/Table/StyledTh'
import TableComp from '../../../components/Table/TableComp'
import Loader from '../../../components/ui/loader'
import { useFetch, useResize } from '../../../helpers/hooks'
import { getContractHistory } from '../../../services/api'
import { useFilters } from '../../AdminPanel/pages/cards/use-filters'
import TabCardHeader from '../components/tab/tab-card-header'
import TabEmpty from '../components/tab/tab-empty'
import Shimmer from '../../../components/ui/shimmer'
import CustomDateRange from '../../../components/Forms/custom-date-range'
import SearchBar from '../../../components/SearchBar'
import { format } from 'date-fns'
import isNill from '../../../utils/is-nill'

const categoryFilters = [
  { label: 'Contract', value: 'Contract' },
  { label: 'Expense', value: 'Expense' },
  { label: 'Recurring Expense', value: 'Recurring Expense' },
  { label: 'Time Off', value: 'Time Off' },
  { label: 'Equipment', value: 'Equipment' },
  { label: 'Payment', value: 'Payment' },
  { label: 'Document', value: 'Document' },
  { label: 'Work', value: 'Work' },
  { label: 'Milestone', value: 'Milestone' },
  { label: 'Time Off Balance', value: 'Time Off Balance' },
]

const descriptionFilters = [
  { label: 'CREATED', value: 'CREATED' },
  { label: 'UPDATED', value: 'UPDATED' },
  { label: 'AMENDED', value: 'AMENDED' },
  { label: 'EDITED', value: 'EDITED' },
  { label: 'TERMINATED', value: 'TERMINATED' },
  { label: 'ENDED', value: 'ENDED' },
  { label: 'SIGNED', value: 'SIGNED' },
  { label: 'CANCELLED', value: 'CANCELLED' },
  { label: 'DELETED', value: 'DELETED' },
  { label: 'APPROVED', value: 'APPROVED' },
  { label: 'DECLINED', value: 'DECLINED' },
  { label: 'REVERTED', value: 'REVERTED' },
  { label: 'ADDED', value: 'ADDED' },
  { label: 'RETURNED', value: 'RETURNED' },
  { label: 'AMENDMENT CANCELLED', value: 'AMENDMENT_CANCELLED' },
  { label: 'EDIT CANCELLED', value: 'EDIT_CANCELLED' },
  { label: 'REQUESTED', value: 'REQUESTED' },
  { label: 'DOWNLOADED', value: 'DOWNLOADED' },
  { label: 'INVITATION SENT', value: 'INVITATION_SENT' },
]

const userTypeFilters = [
  { label: 'Client', value: 'Client' },
  { label: 'Contractor', value: 'Contractor' },
  { label: 'System', value: 'System' },
  { label: 'Admin', value: 'Admin' },
]

const customToTitleCase = (string) => {
  return string?.replace(/_/g, ' ')?.replace(/\b\w/g, (s) => s?.toUpperCase())
}

const ContractHistory = ({ contract, isAdmin, contractLoading }) => {
  const [filters, handleFiltersChange] = useFilters({
    fromDate: null,
    toDate: null,
    userType: null,
    searchTerm: '',
    limit: 20,
  })
  const [showFilters, setShowFilters] = useState(false)
  const [historyList, setHistoryList] = useState([])
  const [isLoadingMore, setIsLoadingMore] = useState(false)
  const isMobile = useResize()
  const autoFetchHistory = !!contract?.id
  const {
    isLoading: isLoadingHistory,
    completed: completedLoadingHistory,
    data: contractHistory,
  } = useFetch(
    {
      action: getContractHistory,
      body: { ...filters, id: contract?.id },
      autoFetch: autoFetchHistory,
      onComplete: (data) => {
        if (data?.items) {
          if (isLoadingMore) {
            // eslint-disable-next-line no-unsafe-optional-chaining
            setHistoryList([...historyList, ...data?.items])
            setIsLoadingMore(false)
          } else {
            setHistoryList(data?.items)
          }
        }
      },
    },
    [contract?.id, filters],
  )

  const loadingHistory = autoFetchHistory
    ? !completedLoadingHistory
    : isLoadingHistory

  const filterByCategory = (checked, value) => {
    if (filters?.cursor) handleFiltersChange('cursor', null)
    if (checked) {
      handleFiltersChange('itemType', value)
    } else {
      handleFiltersChange('itemType', null)
    }
  }
  const filterByUserType = (checked, value) => {
    if (filters?.cursor) handleFiltersChange('cursor', null)
    if (checked) {
      handleFiltersChange('userType', value)
    } else {
      handleFiltersChange('userType', null)
    }
  }
  const filterByDescription = (checked, value) => {
    if (filters?.cursor) handleFiltersChange('cursor', null)
    if (checked) {
      handleFiltersChange('action', value) // action
    } else {
      handleFiltersChange('action', null)
    }
  }
  return (loadingHistory && historyList.length < 10) || contractLoading ? (
    <Loader minHeight='30rem' />
  ) : (
    <>
      <Card>
        <TabCardHeader title='History' />
        <Container fluid className='p-3 px-md-4'>
          <div
            className='d-flex flex-sm-row flex-column'
            style={{ gap: '0.5rem' }}
          >
            <>
              {loadingHistory ? (
                <Shimmer width={160} height='42px' className='mr-1' />
              ) : (
                <FilterByDropdown
                  style={{ minWidth: 160 }}
                  className='rounded'
                  isOpen={showFilters}
                  toggle={() => setShowFilters((t) => !t)}
                  filterByCategory={filterByCategory}
                  filterByUserType={filterByUserType}
                  filterByDescription={filterByDescription}
                  clearFilters={() => {
                    setShowFilters(false)
                    if (filters?.cursor) handleFiltersChange('cursor', null)
                    if (filters?.itemType) handleFiltersChange('itemType', null)
                    if (filters?.userType) handleFiltersChange('userType', null)
                    if (filters?.action) handleFiltersChange('action', null)
                    // onUpdate([], [], 'clearF')
                  }}
                  categoryFilters={categoryFilters}
                  userTypeFilters={userTypeFilters}
                  descriptionFilters={descriptionFilters}
                  filters={filters}
                />
              )}
            </>
            <CustomDateRange
              wrapperStyles={{
                maxWidth: isMobile ? '100%' : 240,
                minWidth: 220,
              }}
              wrapperClassName='w-100'
              name='dateRange'
              value={[filters.fromDate, filters.toDate]}
              placeholder='Select date range'
              clearable
              handleClear={() => {
                if (filters?.cursor) handleFiltersChange('cursor', null)
                handleFiltersChange('fromDate', null, { action: 'clear' })
                handleFiltersChange('toDate', null, { action: 'clear' })
              }}
              onChange={(newDates) => {
                const start = newDates?.[0]
                const end = newDates?.[1]
                if (filters?.cursor) handleFiltersChange('cursor', null)
                handleFiltersChange('fromDate', start, {
                  action: 'clear',
                })
                handleFiltersChange('toDate', end, {
                  action: 'clear',
                })
              }}
            />
            <SearchBar
              className='flex-grow-1 rounded'
              placeholder='Search by Name, Email, Category or IP Address'
              query={filters?.searchTerm}
              onQueryChanged={(e) => {
                if (filters?.cursor) handleFiltersChange('cursor', null)
                handleFiltersChange('searchTerm', e)
              }}
            />
          </div>
        </Container>

        {historyList?.length === 0 ? (
          <TabEmpty
            title='No history'
            subtitle='Nothing to show here yet'
            icon={
              <ClockCountdown
                size={250}
                weight='duotone'
                color='var(--primary)'
              />
            }
          />
        ) : (
          <CardBody className='px-0'>
            <div className='table-responsive'>
              {isMobile ? (
                <div className='p-3' style={{ minHeight: '70vh' }}>
                  {React.Children.toArray(
                    historyList?.map((order, key) => (
                      <Line key={key} item={order} />
                    )),
                  )}
                </div>
              ) : (
                <TableComp className='table-centered'>
                  <thead className='thead-light'>
                    <tr>
                      <StyledTh>Date</StyledTh>
                      <StyledTh>Category</StyledTh>
                      <StyledTh className='text-center'>Actor</StyledTh>
                      <StyledTh>Description</StyledTh>
                      <StyledTh>User Type</StyledTh>
                      <StyledTh>IP Address</StyledTh>
                      <StyledTh>Action</StyledTh>
                    </tr>
                  </thead>
                  <tbody>
                    {React.Children.toArray(
                      historyList?.map((order, key) => (
                        <Line key={key} item={order} />
                      )),
                    )}
                  </tbody>
                </TableComp>
              )}
            </div>

            {loadingHistory ? (
              <Loader minHeight='3rem' />
            ) : contractHistory.pagination?.hasMore ? (
              <div className='mt-2 mt-md-0 d-flex justify-content-center p-4.5'>
                <button
                  className='rp-btn-nostyle text-primary font-size-12 mt-1 p-0 text-left'
                  onClick={() => {
                    setIsLoadingMore(true)
                    handleFiltersChange(
                      'cursor',
                      contractHistory.pagination?.nextCursor,
                    )
                  }}
                >
                  Load more
                </button>
              </div>
            ) : null}
          </CardBody>
        )}
      </Card>
    </>
  )
}

const getStatusColor = (status) => {
  switch (status) {
    case 'REVERTED':
    case 'AMENDED':
    case 'REQUESTED':
      return 'warning'
    case 'AMENDMENT_CANCELLED':
    case 'EDIT_CANCELLED':
    case 'RETURNED':
    case 'DECLINED':
    case 'DELETED':
    case 'TERMINATED':
    case 'CANCELLED':
    case 'ENDED':
      return 'danger'
    default:
      return 'primary'
  }
}

const removeHtmlTags = (str) => {
  return typeof str === 'string' && str ? str?.replace(/<[^>]*>/g, '') : str
}

const Line = ({ item }) => {
  const [show, setShow] = useState(false)

  const isMobile = useResize()

  const info = [
    { label: 'User name', value: item?.userName ?? '-' },
    { label: 'User Type', value: item?.userType },
    { label: 'IP Address', value: item?.ipAddress ?? '-' },
  ]

  const getChangesSummaryText = (item) => {
    if (Array.isArray(item?.details?.changes)) {
      const TextSummaryElement = ({ text }) => {
        return (
          <span
            className='text-secondary-100 font-size-12 ml-1 mt-1 mb-0'
            style={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              maxWidth: '180px',
              whiteSpace: 'nowrap',
            }}
          >
            {text}
          </span>
        )
      }
      const changesLength = item?.details?.changes.length

      const firstValue = item?.details?.changes?.[0]
      const nameValue = item?.details?.changes?.find(
        (change) => change.name === 'name',
      )
      const descriptionValue = item?.details?.changes?.find(
        (change) => change.name === 'description',
      )
      const statusValue = item?.details?.changes?.find(
        (change) => change.name === 'status',
      )
      const contractorValue = item?.details?.changes?.find(
        (change) => change.name === 'contractor',
      )

      let appendText =
        changesLength > 1
          ? `, +${changesLength - 1} more item${
              changesLength - 1 > 1 ? 's' : ''
            }`
          : ''

      const AppendTextSummaryElement = ({ appendText }) => {
        return appendText ? (
          <button
            className='rp-btn-nostyle text-primary font-size-12 mt-1 p-0 text-left'
            onClick={() => {
              setShow(true)
            }}
          >
            {appendText}
          </button>
        ) : null
      }

      let textSummary = removeHtmlTags(
        `${customToTitleCase(firstValue?.name)}: ${
          firstValue?.newValue ?? firstValue?.oldValue
        }`,
      )

      switch (item.action) {
        case 'CREATED':
          if (['payment', 'allowance'].includes(item.itemType.toLowerCase())) {
            return (
              <>
                <TextSummaryElement text={textSummary} />
                <AppendTextSummaryElement appendText={appendText} />
              </>
            )
          }
          if (item.itemType.toLowerCase() === 'work') {
            textSummary = `${customToTitleCase(statusValue?.name)}: ${
              statusValue?.newValue
            }`
            return (
              <>
                <TextSummaryElement text={textSummary} />
                <AppendTextSummaryElement appendText={appendText} />
              </>
            )
          }
          return (
            <>
              <TextSummaryElement text={firstValue?.newValue} />
              <AppendTextSummaryElement appendText={appendText} />
            </>
          )
        case 'UPDATED':
          textSummary = `${changesLength} value${
            changesLength > 1 ? 's' : ''
          } changed`
          return <TextSummaryElement text={textSummary} />
        case 'APPROVED':
          return item?.details?.message
        case 'ADDED':
          if (item.itemType.toLowerCase() === 'equipment') {
            return (
              <>
                <TextSummaryElement text={descriptionValue?.newValue} />
                <AppendTextSummaryElement appendText={appendText} />
              </>
            )
          } else if (item.itemType.toLowerCase() === 'time off') {
            const fromDateValue = item?.details?.changes?.find(
              (change) => change.name === 'from',
            )
            const toDateValue = item?.details?.changes?.find(
              (change) => change.name === 'to',
            )
            const dateFormat = 'MMM dd, yyyy'
            textSummary = `${format(
              new Date(fromDateValue?.newValue),
              dateFormat,
            )} to ${format(new Date(toDateValue?.newValue), dateFormat)}`
            appendText = `, +${changesLength - 2} more items`
            return (
              <>
                <TextSummaryElement text={textSummary} />
                <AppendTextSummaryElement appendText={appendText} />
              </>
            )
          } else {
            appendText = `, +${changesLength - 1} value${
              changesLength > 1 ? 's' : ''
            } added`
            return (
              <>
                <TextSummaryElement text={nameValue?.newValue} />
                <AppendTextSummaryElement appendText={appendText} />
              </>
            )
          }
        case 'REQUESTED':
          return <TextSummaryElement text={nameValue?.newValue} />
        case 'INVITATION_SENT':
          return <TextSummaryElement text={contractorValue?.newValue} />
        case 'SIGNED':
          return <TextSummaryElement text={statusValue?.newValue} />
        case 'DELETED':
          textSummary = `${customToTitleCase(firstValue?.name)}: ${
            firstValue?.oldValue
          }`
          return (
            <>
              <TextSummaryElement text={textSummary} />
              <AppendTextSummaryElement appendText={appendText} />
            </>
          )
        case 'DOWNLOADED':
          textSummary = `At ${format(
            new Date(item?.timestamp),
            'MMM dd, yyyy H:mm:ss',
          )}`
          return <TextSummaryElement text={textSummary} />
        default:
          return (
            <>
              <TextSummaryElement text={textSummary} />
              <AppendTextSummaryElement appendText={appendText} />
            </>
          )
      }
    } else {
      return item?.userType
    }
  }

  return (
    <>
      <DetailsModal
        isOpen={show}
        title={`${item?.details?.message} by ${
          ['System', 'Admin'].includes(item?.userType)
            ? item?.userType
            : item?.userName
        }`}
        toggle={() => setShow(false)}
        changes={item?.details?.changes}
        time={item?.timestamp}
      />
      {isMobile ? (
        <div className='bg-white border mb-3 position-relative py-0 rounded rp-shadow-2'>
          <Row className='p-3 m-0 border-bottom'>
            <Col xs={8} className='p-0 m-0'>
              <p className='font-size-14 rp-font-bold mb-0'>
                {format(new Date(item?.timestamp), 'MMM dd, yyyy')}
              </p>
              <p className='text-gray-h font-size-12 mb-0'>
                {format(new Date(item?.timestamp), 'H:mm:ss')}
              </p>
            </Col>
            <Col
              xs={4}
              className='p-0 m-0 justify-content-end align-items-center d-flex'
            >
              <BadgeX
                status={getStatusColor(item?.action)}
                textStatus={getStatusColor(item?.action)}
                name={customToTitleCase(item?.action)}
              />
            </Col>
          </Row>

          <div className='p-3 d-flex flex-column gap-12'>
            {info.map(({ label, value }, key) => {
              return (
                <div
                  key={key}
                  className='d-flex align-items-center justify-content-between align-items-center'
                >
                  <h6 className='text-dark font-weight-normal mb-0 font-size-14'>
                    {label}
                  </h6>
                  <h6
                    className={`mb-0 text-dark font-weight-normal font-size-14 w-75 text-right ${
                      value === '-' ? 'mr-3' : 'mr-0'
                    }`}
                  >
                    {value}
                  </h6>
                </div>
              )
            })}
          </div>
          <div className='d-flex align-items-center justify-content-center p-2'>
            <button
              className='rp-btn-nostyle rp-font-medium text-primary font-size-14 mt-1 p-0 text-left'
              onClick={() => {
                setShow(true)
              }}
            >
              Details
            </button>
          </div>
        </div>
      ) : (
        <>
          <tr>
            <StyledTd>
              <p className='rp-font-medium text-gray-h font-size-14 mb-1'>
                {format(new Date(item?.timestamp), 'MMM dd, yyyy')}
              </p>
              <p className='text-gray-h font-size-12 mb-0'>
                {format(new Date(item?.timestamp), 'H:mm:ss')}
              </p>
            </StyledTd>
            <StyledTd className='rp-font-medium text-gray-h font-size-14'>
              {item?.itemType}
            </StyledTd>
            <StyledTd>
              <p className='rp-font-medium text-gray-h font-size-14 mb-1 text-center'>
                {item?.userName ?? '-'}
              </p>
              <p className='text-gray-h font-size-12 mb-0'>{item?.userEmail}</p>
            </StyledTd>
            <StyledTd>
              <BadgeX
                status={getStatusColor(item?.action)}
                textStatus={getStatusColor(item?.action)}
                name={customToTitleCase(item?.action)}
              />
              <p className='d-flex text-secondary-100 font-size-12 ml-1 mt-1 mb-0'>
                {getChangesSummaryText(item)}
              </p>
            </StyledTd>
            <StyledTd className='rp-font-medium text-gray-h font-size-14'>
              {item?.userType}
            </StyledTd>
            <StyledTd className='rp-font-medium text-center text-gray-h font-size-14'>
              {item?.ipAddress ?? '-'}
            </StyledTd>
            <StyledTd className='rp-font-medium text-gray-h font-size-14'>
              <button
                className='rp-btn-nostyle rp-font-medium text-primary font-size-14 mt-1 p-0 text-left'
                onClick={() => {
                  setShow(true)
                }}
              >
                Details
              </button>
            </StyledTd>
          </tr>
        </>
      )}
    </>
  )
}

export default ContractHistory

function FilterByDropdown({
  isOpen,
  toggle,
  filterByCategory,
  filterByDescription,
  filterByUserType,
  clearFilters,
  categoryFilters,
  descriptionFilters,
  userTypeFilters,
  filters,
  ...props
}) {
  return (
    <Dropdown isOpen={isOpen} toggle={toggle} {...props}>
      <DropdownToggle tag='div'>
        <div
          className='tw-flex tw-w-full tw-cursor-pointer tw-items-center tw-justify-between tw-rounded tw-border tw-border-surface-40 tw-bg-white tw-px-2'
          style={{ height: 'calc(1.5em + 1.3rem + 2px)' }}
        >
          <span className='text-gray-h font-size-14'>Filters</span>

          {isOpen ? <CaretUp size={12} /> : <CaretDown size={12} />}
        </div>
      </DropdownToggle>
      <DropdownMenu
        className='tw-w-full tw-rounded tw-border tw-border-surface-40 tw-bg-white tw-py-0 md:tw-w-[538px]'
        flip={false}
      >
        <div
          className='d-flex align-items-center justify-content-between w-100 px-3 border-bottom'
          style={{ height: 'calc(1.5em + 1.3rem + 2px)' }}
        >
          <span className='font-size-14 text-gray-h'>Filter by</span>
          <button
            className='rp-btn-nostyle text-muted font-size-12'
            onClick={() => clearFilters()}
          >
            Reset All
          </button>
        </div>

        <Container fluid>
          <Row>
            <Col className='py-3 border-right h-100' md={4}>
              <div className='mb-2 text-gray-h font-size-12'>Category</div>
              {categoryFilters?.map((e, i) => {
                return (
                  <Label
                    key={`drop-${i}`}
                    className='cursor-pointer d-block text-gray-h font-size-14 pl-4'
                  >
                    <Input
                      checked={filters?.itemType === e.value}
                      type='checkbox'
                      onChange={(t) => {
                        toggle()
                        filterByCategory(t.target.checked, e.value)
                      }}
                    />{' '}
                    {e.label}
                  </Label>
                )
              })}
            </Col>
            <Col md={4} className='py-3 border-right'>
              <div className='mb-2 text-gray-h font-size-12'>User type</div>
              {userTypeFilters?.map((e, i) => {
                return (
                  <Label
                    key={`drop-${i}`}
                    className='cursor-pointer d-block text-gray-h font-size-14 pl-4'
                  >
                    <Input
                      checked={filters?.userType === e.value}
                      type='checkbox'
                      onChange={(t) => {
                        toggle()
                        filterByUserType(t.target.checked, e.value)
                      }}
                    />{' '}
                    {e.label}
                  </Label>
                )
              })}
            </Col>
            <Col md={4} className='py-3'>
              <div className='mb-2 text-gray-h font-size-12'>
                Description status
              </div>
              {descriptionFilters?.map((e, i) => {
                return (
                  <Label
                    key={`drop-${i}`}
                    className='cursor-pointer d-block text-gray-h font-size-14 pl-4'
                  >
                    <Input
                      checked={filters?.action === e.value}
                      type='checkbox'
                      onChange={(t) => {
                        toggle()
                        filterByDescription(t.target.checked, e.value)
                      }}
                    />{' '}
                    {e.label}
                  </Label>
                )
              })}
            </Col>
          </Row>
        </Container>
      </DropdownMenu>
    </Dropdown>
  )
}

function DetailsModal({
  isOpen,
  toggle,
  onClosed = () => {},
  title,
  centered = true,
  changes,
  time,
}) {
  const appendText = (itemName) => {
    if (itemName === 'vat') {
      return ' %'
    }
    return ''
  }
  return (
    <Modal
      isOpen={isOpen}
      centered={centered}
      toggle={toggle}
      onClosed={onClosed}
    >
      <ModalHeader toggle={toggle}>
        <div>
          <p className='rp-font-semibold font-size-20 mb-0'>{title}</p>
          <p className='text-secondary-80 font-size-14 mb-1'>
            {format(new Date(time), 'MMM dd, yyyy H:mm:ss')}
          </p>
        </div>
      </ModalHeader>
      <ModalBody>
        {changes
          .filter((change) => change?.name?.toLowerCase() !== 'photo')
          .map((item, index) => {
            return (
              <div
                key={index}
                className={`d-flex border-bottom py-2 ${
                  !isNill(item?.oldValue) && !isNill(item?.newValue)
                    ? 'flex-column'
                    : 'flex-row align-items-center'
                }`}
              >
                <p
                  className={`${
                    !isNill(item?.oldValue) && !isNill(item?.newValue)
                      ? 'text-text-black rp-font-semibold'
                      : 'text-text-100'
                  } mb-0 font-size-14 flex-1`}
                >
                  {customToTitleCase(item?.name)}
                </p>
                {!isNill(item?.oldValue) && !isNill(item?.newValue) ? (
                  <div className='d-flex' style={{ columnGap: '1rem' }}>
                    <div className='flex-1'>
                      <p className='mb-2 text-text-100 font-size-14'>
                        Old value
                      </p>
                      <p className='mb-0 text-text-black font-size-14 rp-font-semibold'>
                        {removeHtmlTags(item?.oldValue) +
                          appendText(item?.name)}
                      </p>
                    </div>
                    <div className='flex-1'>
                      <p className='mb-2 text-text-100 font-size-14'>
                        New value
                      </p>
                      <p className='mb-0 text-text-black font-size-14 rp-font-semibold'>
                        {removeHtmlTags(item?.newValue) +
                          appendText(item?.name)}
                      </p>
                    </div>
                  </div>
                ) : (
                  <p className='mb-0 text-text-black font-size-14 rp-font-semibold flex-1'>
                    {removeHtmlTags(
                      isNill(item?.newValue) ? item?.oldValue : item?.newValue,
                    ) + appendText(item?.name)}
                  </p>
                )}
              </div>
            )
          })}
      </ModalBody>
    </Modal>
  )
}
