import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react'
import { Plus, TrashSimple, Warning, X } from '@phosphor-icons/react'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import toastr from 'toastr'

import { cn } from 'ui'
import { getMenuItemsOrigin } from 'ui/dist/components/actions-dropdown'
import ConfirmationModal from '../../../components/Common/ConfirmationModal'
import { ModalCloseButton } from '../../../components/Common/modal-close-button'
import ControlledInput from '../../../components/ControlledInput'
import { useFetch } from '../../../helpers/hooks'
import { addContractTag, deleteContractTag } from '../../../services/api'

const MAX_TAG_ERROR = 'Max of 2 tags per contract reached.'
const defaultTagColor = '#F7F7F8'

export function TagsV2({ tags, tagsList, contract, onUpdate, updateTags }) {
  const tagIds = tags?.map((tag) => tag?.id)

  const { startFetch: deleteTag, isLoading: deletingUserRole } = useFetch({
    action: deleteContractTag,
    onComplete: (data) => {
      if (data?.success === false) {
        toastr.error(data?.message || 'An error occurred', 'Error')
      } else {
        toastr.success(data?.message || 'Tag deleted successfully')

        onUpdate([], [], '', { loading: false })
      }
    },
    onError: (error) => {
      toastr.error(error)
    },
  })

  function handleDelete(tag) {
    deleteTag({ contract_id: contract.id, tag_id: tag.id })
  }

  return (
    <Popover>
      <PopoverButton>
        {tags?.length > 0 ? (
          <div className='tw-flex tw-gap-2'>
            <TagItem tag={tags[0]} />
            {tags.length > 1 ? (
              <TagItem
                tag={{ name: `+${tags.length - 1}`, color: defaultTagColor }}
                className='tw-text-black'
              />
            ) : (
              <TagItem
                tag={{
                  name: <Plus size={12} weight='bold' />,
                  color: defaultTagColor,
                }}
                className='tw-text-black'
              />
            )}
          </div>
        ) : (
          <TagItem
            tag={{
              name: ['Add tag', <Plus size={12} weight='bold' key='number' />],
              color: defaultTagColor,
            }}
          />
        )}
      </PopoverButton>
      <PopoverPanel
        portal
        transition
        anchor={{ to: 'bottom' }}
        className={cn(
          'tw-w-64 tw-rounded tw-border tw-border-surface-40 tw-bg-white tw-py-2 tw-shadow-lg tw-transition tw-duration-200 tw-ease-in-out data-[closed]:-tw-translate-y-1 data-[closed]:tw-opacity-0',
          getMenuItemsOrigin('bottom'),
        )}
        style={{ '--anchor-gap': '-26px', zIndex: 1020 }}
      >
        {tags.length <= 0 ? null : (
          <div className='tw-mb-2 tw-border-b tw-border-surface-30 tw-px-4 tw-py-3'>
            <h5 className='tw-text-[10px] tw-font-bold tw-uppercase tw-text-text-60'>
              Selected Tags
            </h5>
            <div className='tw-flex tw-flex-wrap tw-items-center tw-gap-2'>
              {tags.map((tag) => {
                return (
                  <TagItem
                    key={tag?.id}
                    tag={{
                      ...tag,
                      name: [
                        tag.name,
                        <button
                          type='button'
                          onClick={
                            deletingUserRole ? null : () => handleDelete(tag)
                          }
                          className='tw-flex tw-size-4 tw-items-center tw-justify-center tw-rounded-full tw-transition-colors hover:tw-bg-black/10'
                          key={'action-button' + tag.id}
                        >
                          <X size={12} weight='bold' />
                        </button>,
                      ],
                    }}
                  />
                )
              })}
            </div>
          </div>
        )}

        <div>
          {tagsList
            .filter((tag) => !tagIds.includes(tag?.id))
            .map((tag) => {
              return (
                <TagMenuItem
                  tags={tags}
                  contract={contract}
                  key={tag?.id}
                  tag={tag}
                  onUpdate={onUpdate}
                  updateTags={updateTags}
                />
              )
            })}

          <AddTag
            tags={tags}
            contract={contract}
            onUpdate={onUpdate}
            updateTags={updateTags}
          />
        </div>
      </PopoverPanel>
    </Popover>
  )
}

function TagMenuItem({ tags, tag, contract, onUpdate, updateTags }) {
  const [confirmDelete, setConfirmDelete] = useState(false)
  function toggleDeleteConfirm() {
    setConfirmDelete((open) => !open)
  }

  const { startFetch: deleteTag, isLoading: deletingUserRole } = useFetch({
    action: deleteContractTag,
    onComplete: (data) => {
      if (data?.success === false) {
        toastr.error(data?.message || 'An error occurred', 'Error')
      } else {
        toastr.success(data?.message || 'Tag deleted successfully')

        updateTags({ loading: false })
        toggleDeleteConfirm()
        onUpdate([], [], '')
        setConfirmDelete(false)
      }
    },
    onError: (error) => {
      toastr.error(error)
    },
  })

  const { startFetch: addTagToContract } = useFetch({
    action: addContractTag,
    onComplete: (data) => {
      if (data?.success === false) {
        toastr.error(data?.message || 'An error occurred', 'Error')
      } else {
        toastr.success(data?.message || 'Tag created successfully')
        onUpdate?.([], [], '', { loading: false })
        updateTags?.({ loading: false })
      }
    },
    onError: (error) => {
      toastr.error(error)
    },
  })

  function handleAddTag(tag) {
    if (tags.length >= 2) {
      toastr.error(MAX_TAG_ERROR)
    } else {
      addTagToContract({ contract_id: contract.id, tag: tag.name })
    }
  }

  return (
    <>
      <div className='tw-flex tw-items-center tw-justify-between tw-px-4 tw-py-3 hover:tw-bg-surface-10'>
        <TagItem
          tag={tag}
          className='tw-transition-opacity hover:tw-opacity-90'
          Component='button'
          type='button'
          onClick={() => handleAddTag(tag)}
        />

        <button
          type='button'
          className='tw-flex tw-size-6 tw-items-center tw-justify-center tw-rounded-sm tw-text-systemRed-100 tw-transition-colors hover:tw-bg-systemRed-10'
          onClick={(event) => {
            event.stopPropagation()
            toggleDeleteConfirm()
          }}
        >
          <TrashSimple size={16} />
        </button>
      </div>

      {!confirmDelete ? null : (
        <ConfirmationModal
          isOpen={confirmDelete}
          toggle={(event) => {
            event?.stopPropagation?.()
            toggleDeleteConfirm()
          }}
          content={
            <>
              <div className='tw-mb-2 tw-flex tw-items-center tw-justify-between'>
                <Warning size={24} className='tw-fill-red' />

                <ModalCloseButton toggle={() => setConfirmDelete(false)} />
              </div>

              <p className='tw-mb-2 tw-text-xl tw-font-semibold tw-text-secondary-120'>
                Are you sure you want to delete “{tag?.name}” tag?
              </p>
              <p className='tw-text-sm tw-text-text-80'>
                This tag will be removed from all contracts in which it is
                assigned.
              </p>
            </>
          }
          confirmLoading={deletingUserRole}
          onConfirm={() => {
            deleteTag({ tag_id: tag.id })
          }}
          caption='Delete'
          buttonColor='danger'
        />
      )}
    </>
  )
}

function TagItem({ tag, className, Component = 'div', ...props }) {
  return (
    <Component
      className={cn(
        'tw-flex tw-items-center tw-gap-2 tw-rounded-full tw-px-2 tw-py-1 tw-text-[10px]/[18px] tw-font-bold tw-uppercase tw-text-text-100',
        className,
      )}
      style={{ backgroundColor: tag?.color }}
      {...props}
    >
      {tag?.name}
    </Component>
  )
}

function AddTag({ tags, contract, onUpdate, updateTags }) {
  const [editing, setEditing] = useState(false)
  function toggleEditing() {
    setEditing((open) => !open)
  }

  const { startFetch: addTag } = useFetch({
    action: addContractTag,
    onComplete: (data) => {
      if (data?.success === false) {
        toastr.error(data?.message || 'An error occurred', 'Error')
      } else {
        toastr.success(data?.message || 'Tag created successfully')
        toggleEditing()
        onUpdate?.([], [], '', { loading: false })
        updateTags?.({ loading: false })
      }
    },
    onError: (error) => {
      toastr.error(error)
    },
  })

  const { control, handleSubmit } = useForm({ defaultValues: { tag: '' } })

  function onSubmit(data) {
    if (tags.length >= 2) {
      toastr.error(MAX_TAG_ERROR)
    } else {
      addTag({ contract_id: contract.id, tag: data.tag })
    }
  }

  return (
    <div className={cn('tw-px-4', editing ? 'tw-py-2' : 'tw-py-4')}>
      {editing ? (
        <form onSubmit={handleSubmit(onSubmit)} className='tw-w-full'>
          <ControlledInput
            control={control}
            name='tag'
            id='tag'
            placeholder='Create new tag'
            transform={{
              output: (event) => {
                event.stopPropagation()
                if (event.key === 'Escape') {
                  toggleEditing()
                }
                return event
              },
            }}
          />
        </form>
      ) : (
        <TagItem
          tag={{
            name: (
              <TagNameWithIcon
                name='Create new tag'
                icon={<Plus size={12} />}
              />
            ),
            color: defaultTagColor,
          }}
          Component='button'
          type='button'
          onClick={() => toggleEditing()}
          className='tw-transition-colors hover:!tw-bg-surface-30'
        />
      )}
    </div>
  )
}

function TagNameWithIcon({ name, icon }) {
  return (
    <div className='tw-flex tw-items-center tw-gap-2'>
      {name}
      {icon}
    </div>
  )
}
