import React from 'react'

export default function TabEmpty({
  tag: Tag = 'div',
  minHeight = '30rem',
  icon,
  title,
  subtitle,
  children,
}) {
  return (
    <Tag
      className='tw-flex tw-flex-col tw-items-center tw-justify-center tw-bg-white tw-p-12'
      style={{ minHeight }}
    >
      {!icon ? null : icon}
      {!title ? null : <h4 className='tw-text-2xl tw-font-medium'>{title}</h4>}
      {!subtitle ? null : (
        <p className='tw-mb-2 tw-text-sm tw-font-semibold tw-text-text-80'>
          {subtitle}
        </p>
      )}
      {children}
    </Tag>
  )
}
