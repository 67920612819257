import { Eye, EyeClosed } from '@phosphor-icons/react'
import { AvField } from 'availity-reactstrap-validation'
import React, { useState } from 'react'

const TextInput = ({
  disabled,
  value,
  name,
  id,
  label,
  required,
  type,
  placeholder,
  validate,
  autocomplete,
  onChange,
  onKeyUp,
  onBlur,
  translate,
  labelClassName,
  labelFor,
}) => {
  const [inputType, setInputType] = useState(type)
  const passwordEye =
    inputType === 'password' ? (
      <Eye weight='fill' size={18} />
    ) : (
      <EyeClosed size={18} />
    )

  return (
    <div>
      {!label ? null : (
        <label className={labelClassName} htmlFor={id ?? labelFor}>
          {label}
          {required ? (
            <span
              className='text-danger font-size-16 ml-1'
              style={{ lineHeight: 1 }}
            >
              *
            </span>
          ) : null}
        </label>
      )}

      <div className='position-relative'>
        <AvField
          disabled={disabled}
          autoComplete={autocomplete}
          validate={validate}
          required={required}
          type={inputType || 'text'}
          placeholder={placeholder || ''}
          name={name}
          value={value}
          id={id}
          onChange={onChange}
          onKeyUp={onKeyUp}
          onBlur={onBlur}
          translate={translate}
        />

        {type === 'password' && (
          <button
            style={{ right: 8, top: 21, transform: 'translateY(-50%)' }}
            className='rp-btn-nostyle p-1 d-flex position-absolute bg-white'
            type='button'
            onClick={() => {
              inputType === 'password'
                ? setInputType('text')
                : setInputType('password')
            }}
          >
            {passwordEye}
          </button>
        )}
      </div>
    </div>
  )
}

export default TextInput
