import { yupResolver } from '@hookform/resolvers/yup'
import {
  Baby,
  Briefcase,
  CalendarBlank,
  CalendarDots,
  CalendarPlus,
  CalendarX,
  CircleHalf,
  CirclesThreePlus,
  ClockClockwise,
  ClockCountdown,
  ClockCounterClockwise,
  DotsThreeCircle,
  Flag,
  HeartBreak,
  HourglassLow,
  HourglassMedium,
  MinusCircle,
  Siren,
  Star,
  Textbox,
  Thermometer,
  TreePalm,
} from '@phosphor-icons/react'
import React, { useEffect } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { useSelector } from 'react-redux'
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams,
} from 'react-router-dom'
import {
  Container,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap'
import toastr from 'toastr'
import * as yup from 'yup'

import { Stepper, cn } from 'ui'
import ControlledInput from '../../components/ControlledInput'
import ModalHeader from '../../components/ModalHeader'
import StepContainer from '../../components/Steps/StepContainer'
import Head from '../../components/head'
import { PrimaryAlert } from '../../components/ui/alert'
import Loader from '../../components/ui/loader'
import FEATURE_FLAGS from '../../config/feature-flags'
import { useFetch } from '../../helpers/hooks'
import {
  createTimeOffPolicy,
  editTimeOffPolicy,
} from '../../services/api-time-off-policies'
import { track } from '../../utils/analytics'
import { formatDays } from '../../utils/formatters/format-days'
import isNill from '../../utils/is-nill'
import { ConfirmFormField } from '../Contract/CreateContract/components/confirm-field'
import {
  FormSectionHr,
  FormSectionTitle,
} from '../Contract/CreateContract/components/form-section-title'
import ControlledTypeSelect from '../Contract/CreateContract/fields/type-select'
import { TimeOffPolicyCarryOverTypes } from '../Contract/utils/constants'
import ControlledWorkingDays from './controlled-working-days'
import { TIMEOFF_EVENTS } from './events'
import {
  TimeOffFlowContextProvider,
  useTimeOffFlowContext,
} from './time-off-flow-context'
import {
  CARRY_OVER_DATE_TYPE,
  getCarryOverDateType,
} from './utils/get-carry-over-type'

const BASE_PATH = '/time-off-policies/add'
const VACATION_POLICY_TYPE = 2
const DEFAULT_POLICY_TYPE = 8

function getStepFormId(step) {
  return `time-off-form-step-${step.key}`
}
function getPrevStepLink(step, steps) {
  const stepIndex = steps.findIndex((s) => s.key === step.key)
  const prevStepKey = steps[stepIndex - 1]?.key

  if (!prevStepKey) return null

  return `${BASE_PATH}/${prevStepKey}`
}
function getNextStepLink(step, steps) {
  const stepIndex = steps.findIndex((s) => s.key === step.key)
  const nextStepKey = steps[stepIndex + 1]?.key

  if (!nextStepKey) return null

  return `${BASE_PATH}/${nextStepKey}`
}

export function NewTimeOffPolicy() {
  const location = useLocation()
  const { state: policy } = location

  return (
    <TimeOffFlowContextProvider
      defaultData={
        !policy || policy?.fromTimeOffTab
          ? { working_days: [1, 2, 3, 4, 5] }
          : {
              // Step 1
              name: policy.name,
              policy_type: policy.type.id,
              amount: policy.accrual_days,
              working_days: policy?.working_days,
              // Step 2
              earning_days: policy.earning_start_days,
              accrual_frequency: policy.accrual_frequency,
              earning_date: policy.earning_start_days
                ? 'after_days'
                : 'start_date',
              carryover_days: policy.carryover_days,
              carryover_expiration_days: policy.carryover_expiration_days,
              carryover_date_type: policy.carryover_date_type,
              carryover:
                policy.carryover_type ===
                TimeOffPolicyCarryOverTypes.USE_OR_LOSS
                  ? 'use_it_or_lose_it'
                  : policy.carryover_days
                    ? 'limited'
                    : 'unlimited',
              carryover_expiration: policy.carryover_expiration_days
                ? 'expires'
                : 'never_expires',
              // Step 3
              half_day_request: policy.can_request_half_day ? 'yes' : 'no',
              max_sequential_days: policy.request_max_days ? 'yes' : 'no',
              max_sequential_days_amount: policy.request_max_days,
              retrospective_request: policy.is_retrospective_enabled
                ? 'yes'
                : 'no',
              waiting_time: policy.days_after_hiring ? 'yes' : 'no',
              waiting_time_amount: policy.days_after_hiring,
              negative_balance_enabled: policy.negative_balance_enabled
                ? 'yes'
                : 'no',
            }
      }
    >
      <NewTimeOffPolicyFlow />
    </TimeOffFlowContextProvider>
  )
}

function NewTimeOffPolicyFlow() {
  const history = useHistory()
  const { policyFormData } = useTimeOffFlowContext()
  const { stepKey } = useParams()
  const location = useLocation()
  const { state: policy } = location

  useEffect(() => {
    if (!FEATURE_FLAGS.TIME_OFF_POLICIES) {
      history.push('/settings')
    }
  }, [history])

  const isVacationType =
    policyFormData?.policy_type === VACATION_POLICY_TYPE ||
    policy?.type?.id === VACATION_POLICY_TYPE

  const filteredSteps = [
    allPossibleSteps[0],
    isVacationType && allPossibleSteps[1],
    allPossibleSteps[2],
    allPossibleSteps[3],
  ].filter(Boolean)

  const updatedSteps = filteredSteps.map((step, index) => {
    const isLastStep = index === filteredSteps.length - 1
    return {
      ...step,
      next: isLastStep ? null : filteredSteps[index + 1]?.key,
    }
  })

  const stepperSteps = updatedSteps.map((step) => step.name)
  const stepKeys = updatedSteps.map((step) => step.key)
  const activeIndex = stepKeys.indexOf(stepKey ?? '')
  const activeStep = updatedSteps[activeIndex]

  const pageTitleWithStepName = [activeStep?.name, 'Create a time off policy']
    .filter(Boolean)
    .join(' - ')

  function handlePrev(step) {
    const prevStepLink = getPrevStepLink(step, updatedSteps)
    history.push(prevStepLink ?? BASE_PATH, policy)
  }

  const loading = policyFormData?.status === 'pending'
  const disableNext = loading

  function handleNext(step) {
    const nextStepLink = getNextStepLink(step, updatedSteps)
    if (nextStepLink) {
      history.push(nextStepLink, policy)
    }
  }

  return (
    <Container fluid className='px-0'>
      <Head title={pageTitleWithStepName} />

      <ModalHeader
        action={() =>
          history.push(
            policy?.fromTimeOffTab
              ? `/contract/detail?id=${policy.contractRef}&tab=timeOff`
              : '/settings/time-off-policies',
          )
        }
      >
        <Stepper
          steps={stepperSteps}
          activeStep={activeIndex}
          className='tw-flex-grow'
        />
      </ModalHeader>

      <div className='tw-px-4'>
        <Switch>
          {updatedSteps.map((step, index) => {
            const { key, Component, name } = step
            return (
              <Route path={`${BASE_PATH}/${key}`} key={index} exact>
                <StepContainer
                  title={name}
                  index={activeIndex}
                  total={updatedSteps.length}
                  nextType='submit'
                  nextFormId={getStepFormId(step)}
                  noBack={index === 0}
                  disableNext={disableNext}
                  loading={loading}
                  onBack={() => handlePrev(step)}
                >
                  <Component
                    step={step}
                    goToNextStep={() => handleNext(step)}
                  />
                </StepContainer>
              </Route>
            )
          })}
        </Switch>
      </div>
    </Container>
  )
}

const allPossibleSteps = [
  {
    name: 'Policy details',
    key: '',
    Component: Step1PolicyDetails,
    next: 'accrual',
  },
  {
    name: 'Accrual & carryover',
    key: 'accrual',
    Component: Step2Accrual,
    next: 'requests',
  },
  {
    name: 'Requests rules',
    key: 'requests',
    Component: Step3RequestsRules,
    next: 'review',
  },
  { name: 'Review', key: 'review', Component: Step4Review },
]

function Step1PolicyDetails({ step, goToNextStep }) {
  const { policyFormData, setPolicyFormData } = useTimeOffFlowContext()

  const timeOffTypes = useSelector(
    (state) => state?.Layout?.staticData?.time_off_types ?? [],
  )

  const policyTypeOptions = timeOffTypes.reduce((acc, type) => {
    if (type.id !== DEFAULT_POLICY_TYPE) {
      acc.push({
        value: type.id,
        label: type.name,
        description:
          type?.is_accrued === 1 ? 'Accrual time off' : 'Non-accrual time off',
        icon: () => <PolicyTypeIcon typeId={type.id} />,
      })
    }
    return acc
  }, [])

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(
      yup.object().shape({
        name: yup.string().required(),
        policy_type: yup
          .number()
          .label('Policy type')
          .oneOf(timeOffTypes.map((type) => type.id))
          .required(),
        amount: yup.number().when('policy_type', {
          is: VACATION_POLICY_TYPE,
          then: (schema) => schema.required('Time off days is required'),
          otherwise: (schema) => schema.notRequired(),
        }),
        working_days: yup
          .array()
          .of(yup.number())
          .required('Working day is required')
          .min(1, 'Working day is required'),
      }),
    ),
    defaultValues: { ...policyFormData },
  })

  const { policy_type: policyType } = useWatch({ control })
  const isVacationPolicy = policyType === VACATION_POLICY_TYPE

  function onSubmit(values) {
    setPolicyFormData(values)

    goToNextStep()
  }

  if (!timeOffTypes || timeOffTypes.length <= 0) {
    return <Loader minHeight='max(50vh, 550px)' />
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} id={getStepFormId(step)}>
      <FormSectionTitle title='Insert a title' />

      <ControlledInput
        control={control}
        name='name'
        id='name-input'
        placeholder='Policy name'
      />

      <FormSectionHr />
      <FormSectionTitle title='Choose the type' />

      <ControlledTypeSelect
        control={control}
        name='policy_type'
        error={errors?.policy_type}
        transform={{
          output: (event) => {
            setPolicyFormData({ policy_type: event.value })
            return event?.value
          },
        }}
        types={policyTypeOptions}
        cols={3}
        horizontal
      />

      <PrimaryAlert className='!tw-mb-2 tw-mt-6'>
        Accrual time off accumulates over time based on the employee’s work days
      </PrimaryAlert>

      {!isVacationPolicy ? null : (
        <>
          <FormSectionHr />
          <div>
            <div className='tw-flex tw-rounded-t tw-border tw-border-surface-30 tw-p-6'>
              <CalendarBlank
                weight='duotone'
                size={32}
                className='tw-mr-4 tw-text-text-30'
              />
              <div>
                <div className='tw-text-base tw-font-bold tw-text-black'>
                  Time off amount
                </div>
                <span className='tw-text-sm tw-text-text-60'>
                  How much time off does the worker get per year?
                </span>
              </div>
            </div>
            <div className='tw-rounded-b tw-border tw-border-t-0 tw-border-surface-30 tw-bg-surface-10 tw-p-4'>
              <InputGroup className='max-md:!tw-flex-nowrap'>
                <ControlledInput
                  control={control}
                  name='amount'
                  id='amount-input'
                  placeholder='Insert amount'
                  type='number'
                  min={1}
                  wrapperClassName='tw-flex-grow'
                  className='!tw-rounded-r-none'
                />
                <InputGroupAddon addonType='append'>
                  <InputGroupText>Working days</InputGroupText>
                </InputGroupAddon>
              </InputGroup>
            </div>
          </div>
        </>
      )}

      <FormSectionHr />
      <div className='tw-flex tw-flex-col tw-gap-6 tw-rounded tw-border tw-border-surface-30 tw-p-6'>
        <span className='tw-flex'>
          <CalendarDots size={32} className='tw-mr-4 tw-fill-text-30' />
          <span className='tw-flex tw-flex-col'>
            <span className='tw-text-base tw-font-bold tw-text-black'>
              Choose your working days
            </span>
            <span className='tw-text-sm tw-font-normal tw-text-text-60'>
              Only the working days will be included in time off requests
            </span>
          </span>
        </span>

        <ControlledWorkingDays
          control={control}
          name='working_days'
          inActiveClassName='tw-font-semibold tw-bg-white tw-border-surface-30 '
        />
      </div>
    </form>
  )
}

// Custom hook to make sure that we have data from the first step
// Otherwise, redirect back to the first step
function useCheckFirstStepData(formData, history) {
  useEffect(() => {
    // If we don't have the data from the first step, redirect back to the first step
    // Here we are checking with the name field
    if (!formData?.name) {
      history.push(BASE_PATH)
    }
  }, [formData, history])
}

function Step2Accrual({ step, goToNextStep }) {
  const history = useHistory()
  const { policyFormData, setPolicyFormData } = useTimeOffFlowContext()

  useCheckFirstStepData(policyFormData, history)

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(
      yup.object().shape({
        earning_date: yup
          .string()
          .label('Earning date')
          .oneOf(['start_date', 'after_days'])
          .required(),
        earning_days: yup
          .number()
          .label('Earning days')
          .when('earning_date', {
            is: 'after_days',
            then: (schema) => schema.required(),
            otherwise: (schema) =>
              schema
                .notRequired()
                .transform((value) =>
                  isNaN(value) || isNill(value) ? 0 : value,
                ),
          }),
        accrual_frequency: yup
          .string()
          .label('Accrual frequency')
          .oneOf(['weekly', 'bi_weekly', 'monthly', 'yearly'])
          .required(),
        carryover: yup
          .string()
          .label('Time off carryover')
          .oneOf(['use_it_or_lose_it', 'limited', 'unlimited'])
          .required(),
        carryover_days: yup
          .number()
          .label('Carryover days')
          .when('carryover', {
            is: 'limited',
            then: (schema) => schema.required(),
            otherwise: (schema) => schema.notRequired(),
          }),
        carryover_expiration: yup
          .string()
          .label('Carryover expiration')
          .oneOf(['never_expires', 'expires'])
          .when('carryover', {
            is: 'limited',
            then: (schema) => schema.required(),
            otherwise: (schema) => schema.notRequired(),
          }),
        carryover_expiration_days: yup
          .number()
          .label('Carryover expiration days')
          .when('carryover_expiration', {
            is: 'expires',
            then: (schema) => schema.required().typeError('Invalid value'),
            otherwise: (schema) =>
              schema
                .notRequired()
                .transform((value) =>
                  isNaN(value) || isNill(value) ? 0 : value,
                ),
          }),
        carryover_date_type: yup
          .string()
          .label('Carryover review')
          .oneOf([
            CARRY_OVER_DATE_TYPE.CALENDAR_YEAR,
            CARRY_OVER_DATE_TYPE.NON_CALENDAR_YEAR,
          ])
          .when('carryover', {
            is: 'limited',
            then: (schema) => schema.required(),
            otherwise: (schema) => schema.notRequired(),
          }),
      }),
    ),
    defaultValues: { ...policyFormData },
  })

  const {
    earning_date: earningDate,
    carryover,
    carryover_expiration: carryoverExpiration,
  } = useWatch({ control })
  const isEarningAfterDays = earningDate === 'after_days'
  const isLimitedCarryover = carryover === 'limited'
  const isCarryoverExpires = carryoverExpiration === 'expires'

  function onSubmit(values) {
    setPolicyFormData(values)

    goToNextStep()
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} id={getStepFormId(step)}>
      <FormSectionTitle title='Accrual' />

      <ConfirmFormField
        control={control}
        error={errors.earning_date}
        name='earning_date'
        icon={
          <CalendarPlus
            weight='duotone'
            size={32}
            className='tw-text-text-30'
          />
        }
        title='Earning date'
        description='When does the worker start earning?'
        fieldOptions={[
          { label: 'Start date', value: 'start_date' },
          { label: 'After number of days', value: 'after_days' },
        ]}
        className='md:tw-col-span-2'
        innerClassName={isEarningAfterDays && 'tw-rounded-b-none'}
        transform={{
          output: (event) => {
            if (event.target.value === 'start_date') {
              setPolicyFormData({ earning_days: 0 })
            }
            return event
          },
        }}
      />
      {!isEarningAfterDays ? null : (
        <div className='tw-rounded-b tw-border tw-border-t-0 tw-border-surface-30 tw-bg-surface-10 tw-p-4'>
          <InputGroup>
            <ControlledInput
              control={control}
              name='earning_days'
              id='earning_days-input'
              placeholder='Insert amount'
              type='number'
              min={1}
              wrapperClassName='tw-flex-grow'
              className='!tw-rounded-r-none'
            />
            <InputGroupAddon addonType='append'>
              <InputGroupText>Calendar days</InputGroupText>
            </InputGroupAddon>
          </InputGroup>
        </div>
      )}

      <ConfirmFormField
        control={control}
        error={errors.accrual_frequency}
        name='accrual_frequency'
        icon={
          <ClockClockwise
            weight='duotone'
            size={32}
            className='tw-text-text-30'
          />
        }
        title='Accrual frequency'
        description='How often does the time off balance increase?'
        fieldOptions={[
          { label: 'Weekly', value: 'weekly' },
          { label: 'Bi-Weekly', value: 'bi_weekly' },
          { label: 'Monthly', value: 'monthly' },
          // Hide yearly accrual till the calculation issue is resolved on the backend
          // { label: 'Yearly', value: 'yearly' },
        ]}
        className='tw-mt-4 md:tw-col-span-2'
      />

      <FormSectionHr />
      <FormSectionTitle title='Carryover' />

      <ConfirmFormField
        control={control}
        error={errors.carryover}
        name='carryover'
        icon={
          <CirclesThreePlus
            weight='duotone'
            size={32}
            className='tw-text-text-30'
          />
        }
        title='Time off carryover'
        description='Should the worker be allowed to carry over unused time off to the next year?'
        fieldOptions={[
          { label: 'No, it’s use it or lose it', value: 'use_it_or_lose_it' },
          { label: 'Limited carryover', value: 'limited' },
          { label: 'Unlimited carryover', value: 'unlimited' },
        ]}
        className='tw-mt-4 md:tw-col-span-2'
        innerClassName={isLimitedCarryover && 'tw-rounded-b-none'}
      />

      {!isLimitedCarryover ? null : (
        <div className='tw-rounded-b tw-border tw-border-t-0 tw-border-surface-30 tw-bg-surface-10 tw-p-4'>
          <InputGroup>
            <ControlledInput
              control={control}
              name='carryover_days'
              id='carryover_days-input'
              placeholder='Insert amount'
              type='number'
              min={1}
              wrapperClassName='tw-flex-grow'
              className='!tw-rounded-r-none'
            />
            <InputGroupAddon addonType='append'>
              <InputGroupText>Working days</InputGroupText>
            </InputGroupAddon>
          </InputGroup>
        </div>
      )}

      {isLimitedCarryover && (
        <ConfirmFormField
          control={control}
          error={errors.carryover_date_type}
          name='carryover_date_type'
          icon={
            <HourglassLow
              weight='duotone'
              size={32}
              className='tw-text-text-30'
            />
          }
          title='Carryover review'
          description='When should the review of unused time off take place?'
          fieldOptions={[
            {
              label: 'At the start of a new calendar year',
              value: CARRY_OVER_DATE_TYPE.CALENDAR_YEAR,
            },
            {
              label: 'Every 12 months after earning date',
              value: CARRY_OVER_DATE_TYPE.NON_CALENDAR_YEAR,
            },
          ]}
          className='tw-mt-4 md:tw-col-span-2'
        />
      )}

      {!isLimitedCarryover ? null : (
        <>
          <ConfirmFormField
            control={control}
            error={errors.carryover_expiration}
            name='carryover_expiration'
            icon={
              <Briefcase
                weight='duotone'
                size={32}
                className='tw-text-text-30'
              />
            }
            title='Carryover expiration'
            description='Should carried-over time off expire?'
            fieldOptions={[
              { label: 'No, it never expires', value: 'never_expires' },
              {
                label: 'Yes, expires after an amount of days',
                value: 'expires',
              },
            ]}
            className='tw-mt-4 md:tw-col-span-2'
            innerClassName={isCarryoverExpires && 'tw-rounded-b-none'}
          />
          {!isCarryoverExpires ? null : (
            <div className='tw-rounded-b tw-border tw-border-t-0 tw-border-surface-30 tw-bg-surface-10 tw-p-4'>
              <InputGroup>
                <ControlledInput
                  control={control}
                  name='carryover_expiration_days'
                  id='carryover_expiration_days-input'
                  placeholder='Insert amount'
                  type='number'
                  min={1}
                  wrapperClassName='tw-flex-grow'
                  className='!tw-rounded-r-none'
                />
                <InputGroupAddon addonType='append'>
                  <InputGroupText>Calendar days</InputGroupText>
                </InputGroupAddon>
              </InputGroup>
            </div>
          )}
        </>
      )}
    </form>
  )
}

function Step3RequestsRules({ step, goToNextStep }) {
  const history = useHistory()
  const { policyFormData, setPolicyFormData } = useTimeOffFlowContext()

  const isAccrued = policyFormData?.policy_type === VACATION_POLICY_TYPE

  useCheckFirstStepData(policyFormData, history)

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(
      yup.object().shape({
        half_day_request: yup
          .string()
          .oneOf(['yes', 'no'])
          .label('Half day request')
          .required(),
        max_sequential_days: yup
          .string()
          .label('Max sequential days')
          .oneOf(['yes', 'no'])
          .required(),
        max_sequential_days_amount: yup
          .number()
          .label('Time off maximum')
          .when('max_sequential_days', {
            is: 'yes',
            then: (schema) => schema.required(),
            otherwise: (schema) =>
              schema
                .nullable()
                .notRequired()
                .transform((value) =>
                  isNaN(value) || isNill(value) ? 0 : value,
                ),
          }),
        retrospective_request: yup
          .string()
          .label('Retrospective request')
          .oneOf(['yes', 'no'])
          .required(),
        waiting_time: yup
          .string()
          .label('Waiting time')
          .oneOf(['yes', 'no'])
          .required(),
        waiting_time_amount: yup
          .number()
          .label('Waiting time amount')
          .when('waiting_time', {
            is: 'yes',
            then: (schema) => schema.typeError('Invalid value').required(),
            otherwise: (schema) => schema.notRequired(),
          }),
        negative_balance_enabled: yup
          .string()
          .label('Negative balance')
          .oneOf(['yes', 'no'])
          .required(),
      }),
    ),
    defaultValues: { ...policyFormData },
  })

  const { max_sequential_days: maxSequentialDays, waiting_time: waitingTime } =
    useWatch({ control })
  const isMaxSequentialDays = maxSequentialDays === 'yes'
  const isWaitingTime = waitingTime === 'yes'

  function onSubmit(values) {
    setPolicyFormData(values)

    goToNextStep()
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} id={getStepFormId(step)}>
      <ConfirmFormField
        control={control}
        error={errors.half_day_request}
        name='half_day_request'
        icon={
          <CircleHalf weight='duotone' size={32} className='tw-text-text-30' />
        }
        title='Half day request'
        description='Can a worker request a half day off?'
        fieldOptions={[
          { label: 'Yes', value: 'yes' },
          { label: 'No', value: 'no' },
        ]}
      />

      <ConfirmFormField
        control={control}
        error={errors.max_sequential_days}
        name='max_sequential_days'
        icon={<Siren weight='duotone' size={32} className='tw-text-text-30' />}
        title='Time off maximum'
        description='Is there a maximum number of sequential days per time off request?'
        fieldOptions={[
          { label: 'Yes', value: 'yes' },
          { label: 'No', value: 'no' },
        ]}
        className='tw-mt-4'
        innerClassName={isMaxSequentialDays && 'tw-rounded-b-none'}
      />
      {!isMaxSequentialDays ? null : (
        <div className='tw-rounded-b tw-border tw-border-t-0 tw-border-surface-30 tw-bg-surface-10 tw-p-4'>
          <InputGroup>
            <ControlledInput
              control={control}
              name='max_sequential_days_amount'
              id='max_sequential_days_amount-input'
              placeholder='Insert amount'
              type='number'
              min={1}
              wrapperClassName='tw-flex-grow'
              className='!tw-rounded-r-none'
            />
            <InputGroupAddon addonType='append'>
              <InputGroupText>Calendar days</InputGroupText>
            </InputGroupAddon>
          </InputGroup>
        </div>
      )}

      <ConfirmFormField
        control={control}
        error={errors.retrospective_request}
        name='retrospective_request'
        icon={
          <ClockCounterClockwise
            weight='duotone'
            size={32}
            className='tw-text-text-30'
          />
        }
        title='Retrospective request'
        description='Can a worker submit a request for a past period?'
        fieldOptions={[
          { label: 'Yes', value: 'yes' },
          { label: 'No', value: 'no' },
        ]}
        className='tw-mt-4'
      />

      <ConfirmFormField
        control={control}
        error={errors.waiting_time}
        name='waiting_time'
        icon={
          <ClockCountdown
            weight='duotone'
            size={32}
            className='tw-text-text-30'
          />
        }
        title='Request eligibility'
        description='Is there a waiting time to request time off after date of hire?'
        fieldOptions={[
          { label: 'Yes', value: 'yes' },
          { label: 'No', value: 'no' },
        ]}
        className='tw-mt-4'
        wrapperClassName={isWaitingTime && 'tw-rounded-b-none'}
      />
      {!isWaitingTime ? null : (
        <div className='tw-rounded-b tw-border tw-border-t-0 tw-border-surface-30 tw-bg-surface-10 tw-p-4'>
          <InputGroup>
            <ControlledInput
              control={control}
              name='waiting_time_amount'
              id='waiting_time_amount-input'
              placeholder='Insert amount'
              type='number'
              min={1}
              wrapperClassName='tw-flex-grow'
              className='!tw-rounded-r-none'
            />
            <InputGroupAddon addonType='append'>
              <InputGroupText>Calendar days</InputGroupText>
            </InputGroupAddon>
          </InputGroup>
        </div>
      )}

      {!isAccrued ? null : (
        <ConfirmFormField
          control={control}
          error={errors.negative_balance_enabled}
          name='negative_balance_enabled'
          icon={
            <MinusCircle
              weight='duotone'
              size={32}
              className='tw-text-text-30'
            />
          }
          title='Negative Balance'
          description='Can a worker request time off even if they don’t have a balance?'
          fieldOptions={[
            { label: 'Yes', value: 'yes' },
            { label: 'No', value: 'no' },
          ]}
          className='tw-mt-4'
        />
      )}
    </form>
  )
}

function getTrackingData(policyFormData) {
  return {
    policy_type:
      policyFormData.policy_type === VACATION_POLICY_TYPE
        ? 'accrued'
        : 'non-accrued',
    accrual_frequency: policyFormData.accrual_frequency,
    carryover_used: policyFormData?.carryover === 'limited',
  }
}

function Step4Review({ step }) {
  const history = useHistory()
  const { policyFormData, setPolicyFormData } = useTimeOffFlowContext()
  const location = useLocation()
  const { state: policy } = location
  const carryOverEnabled = policyFormData?.carryover === 'limited'

  useCheckFirstStepData(policyFormData, history)

  const { handleSubmit } = useForm({
    defaultValues: { ...policyFormData },
  })

  const { startFetch: createPolicy } = useFetch({
    action: createTimeOffPolicy,
    onComplete: (data) => {
      if (data?.success === false) {
        toastr.error('Failed to create policy')
      } else {
        toastr.success('Policy created successfully')
        history.push('/settings/time-off-policies')
        track(TIMEOFF_EVENTS.SAVED_POLICY, getTrackingData(policyFormData))
      }
    },
    onError: (error) => {
      toastr.error(error || 'Something went wrong while creating policy')
      setPolicyFormData({ status: 'error' })
    },
  })

  const { startFetch: editPolicy } = useFetch({
    action: editTimeOffPolicy,
    onComplete: (data) => {
      if (data?.success === false) {
        toastr.error('Failed to edit policy')
      } else {
        toastr.success('Policy edited successfully')
        history.push('/settings/time-off-policies')
        track(TIMEOFF_EVENTS.EDITED_POLICY, getTrackingData(policyFormData))
      }
    },
    onError: (error) => {
      toastr.error(error || 'Something went wrong while editing policy')
      setPolicyFormData({ status: 'error' })
    },
  })

  function onSubmit() {
    let body = {
      name: policyFormData?.name,
      time_off_type_id: policyFormData?.policy_type,
      can_request_half_day: policyFormData?.half_day_request === 'yes',
      request_max_days:
        policyFormData?.max_sequential_days === 'yes'
          ? policyFormData?.max_sequential_days_amount
          : null,
      is_retrospective_enabled: policyFormData?.retrospective_request === 'yes',
      days_after_hiring:
        policyFormData?.waiting_time === 'yes'
          ? policyFormData?.waiting_time_amount
          : 0,
      working_days: policyFormData.working_days,
      negative_balance_enabled:
        policyFormData?.negative_balance_enabled === 'yes',
    }

    if (policyFormData?.policy_type === VACATION_POLICY_TYPE) {
      body = {
        ...body,
        accrual_days: policyFormData?.amount,
        accrual_frequency: policyFormData?.accrual_frequency,
        earning_start_days:
          policyFormData?.earning_date === 'start_date'
            ? 0
            : policyFormData.earning_days,
        carryover_days: !carryOverEnabled
          ? '0'
          : String(policyFormData.carryover_days),
        carryover_expiration_days: !carryOverEnabled
          ? 0
          : policyFormData.carryover_expiration === 'never_expires'
            ? 0
            : policyFormData.carryover_expiration_days,
        carryover_type: carryOverEnabled
          ? TimeOffPolicyCarryOverTypes.LIMITED
          : policyFormData?.carryover === 'unlimited'
            ? TimeOffPolicyCarryOverTypes.UNLIMITED
            : TimeOffPolicyCarryOverTypes.USE_OR_LOSS,
        carryover_date_type: policyFormData?.carryover_date_type,
      }
    }

    setPolicyFormData({ status: 'pending' })
    if (policy?.id) {
      body.id = policy.id
      editPolicy(body)
    } else {
      createPolicy(body)
    }
  }

  const timeOffTypes = useSelector(
    (state) => state?.Layout?.staticData?.time_off_types ?? [],
  )
  const formTimeOffType = timeOffTypes.find(
    (type) => type.id === policyFormData?.policy_type,
  )
  const timeOffTypeName = formTimeOffType?.name
  const isAccruedType = formTimeOffType?.is_accrued === 1

  const frequency = policyFormData?.accrual_frequency
  const timeOffAmount = policyFormData?.amount
  const accrualSettings = getAccrualSettings(frequency, timeOffAmount)

  return (
    <form onSubmit={handleSubmit(onSubmit)} id={getStepFormId(step)}>
      <FormSectionTitle title='Here is a recap of your policy:' />

      <div className='tw-rounded tw-border tw-border-surface-30 tw-px-6 tw-py-4'>
        {[
          {
            label: 'Name',
            value: policyFormData?.name,
            icon: <Textbox size={24} />,
          },
          {
            label: 'Type',
            value: timeOffTypeName,
            icon: <TreePalm size={24} />,
          },
          !isAccruedType
            ? null
            : {
                label: 'Time off amount',
                value: formatDays(policyFormData?.amount) + ' / Year',
                icon: <CalendarBlank size={24} />,
              },
          {
            label: 'Accrual settings',
            value: !isAccruedType ? 'Non-accrual' : accrualSettings,
            icon: <ClockClockwise size={24} />,
          },
          !isAccruedType
            ? null
            : {
                label: 'Carryover',
                value:
                  policyFormData?.carryover === 'use_it_or_lose_it'
                    ? 'Use it or lose it'
                    : carryOverEnabled
                      ? `Limited to ${formatDays(
                          policyFormData?.carryover_days,
                        )}`
                      : 'Unlimited',
                icon: <CirclesThreePlus size={24} />,
              },
          isAccruedType &&
            carryOverEnabled && {
              label: 'Carryover review',
              value: getCarryOverDateType(policyFormData?.carryover_date_type),
              icon: <HourglassMedium size={24} />,
            },
          !isAccruedType || policyFormData?.carryover !== 'limited'
            ? null
            : {
                label: 'Carryover expiration',
                value: policyFormData?.carryover_expiration_days
                  ? formatDays(policyFormData?.carryover_expiration_days) +
                    ' after carryover'
                  : 'Never expires',
                icon: <CalendarX size={24} />,
              },
        ]
          .filter(Boolean)
          .map((item, index) => {
            return (
              <div
                key={index}
                className={cn(
                  'tw-flex tw-w-full tw-flex-wrap tw-items-center tw-justify-between tw-gap-3 tw-py-4',
                  index !== 0 && 'tw-border-t tw-border-surface-30',
                )}
              >
                <div className='tw-flex tw-items-center tw-gap-4'>
                  <div className='tw-size-6 tw-flex-shrink-0 tw-text-text-50'>
                    {item.icon}
                  </div>
                  <h6 className='tw-mb-0 tw-text-sm tw-text-text-100'>
                    {item.label}
                  </h6>
                </div>
                <p className='tw-mb-0 tw-text-sm tw-font-semibold'>
                  {item.value}
                </p>
              </div>
            )
          })}
      </div>

      <PrimaryAlert className='tw-mt-6'>
        You can always edit these information later and assign workers to this
        policy.
      </PrimaryAlert>
    </form>
  )
}

export function PolicyTypeIcon({ typeId, weight = 'duotone', size = 32 }) {
  switch (typeId) {
    // Public holiday
    case 1: {
      return <Flag weight={weight} size={size} />
    }
    // Vacation
    case 2: {
      return <TreePalm weight={weight} size={size} />
    }
    // Sick leave
    case 3: {
      return <Thermometer weight={weight} size={size} />
    }
    // Parental leave
    case 4: {
      return <Baby weight={weight} size={size} />
    }
    // Religious
    case 5: {
      return <Star weight={weight} size={size} />
    }
    case 6: {
      return <HeartBreak weight={weight} size={size} />
    }
    case 8: {
      return <CalendarDots weight={weight} size={size} />
    }
    // Other & unknown
    case 7:
    default: {
      return <DotsThreeCircle weight={weight} size={size} />
    }
  }
}

export function policyTypeColor({ typeId }) {
  switch (typeId) {
    case 1: {
      return 'secondary'
    }
    case 2: {
      return 'systemGold'
    }
    case 3: {
      return 'systemRed'
    }
    case 4: {
      return 'green'
    }
    case 5: {
      return 'systemBlue'
    }
    case 6: {
      return 'cyan'
    }
    case 7:
    default: {
      return 'surface'
    }
  }
}

export function policyTypeIconColors({ typeId }) {
  const colors = {
    secondary: 'tw-bg-secondary-10 tw-text-secondary-100',
    systemGold: 'tw-bg-systemGold-20 tw-text-systemGold-110',
    systemRed: 'tw-bg-systemRed-10 tw-text-systemRed-100',
    green: 'tw-bg-green-10 tw-text-green-100',
    systemBlue: 'tw-bg-systemBlue-10 tw-text-systemBlue-100',
    cyan: 'tw-bg-cyan-20 tw-text-cyan-100',
    surface: 'tw-bg-surface-10 tw-text-surface-100',
  }

  return colors[policyTypeColor({ typeId })]
}

function floorDays(days) {
  return Number(days).toFixed(2)
}
function getAccrualSettings(frequency, yearlyAmount) {
  switch (frequency) {
    case 'weekly': {
      return `${floorDays(yearlyAmount / 52)} days / Weekly`
    }
    case 'bi_weekly': {
      return `${floorDays(yearlyAmount / 26)} days / Bi-Weekly`
    }
    case 'monthly': {
      return `${floorDays(yearlyAmount / 12)} days / Monthly`
    }
    case 'yearly': {
      return `${floorDays(yearlyAmount)} days / Yearly`
    }
    default: {
      return 'Non-accrual'
    }
  }
}
