import { PlusCircle } from '@phosphor-icons/react'
import cx from 'classnames'
import { format } from 'date-fns'
import React from 'react'
import { useSelector } from 'react-redux'
import { Card, CardBody, Col, Media, Row } from 'reactstrap'
import styled from 'styled-components'

import DataTable from '../../../../components/ui/data-table'

const FulltimeDetailBlocks = ({
  canAmend,
  canEdit,
  setEditInfo,
  setEditEmpInfo,
  setEditAllowances,
  amendData,
  setEditPayment,
  ...props
}) => {
  const contract = useSelector((state) => state.Contract?.details)
  const countries = useSelector((state) => state.Layout?.staticData?.countries)
  const salaryFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: contract?.salary_currency?.code || 'USD',
  })
  const salaryIsAmended =
    amendData?.amount &&
    parseFloat(amendData?.amount) !== parseFloat(contract?.amount)

  const allowances = contract?.allowances
  const insuranceProvider = contract?.insurance_provider
  const allowancesColumns = [
    { Header: 'Title', accessor: 'name' },
    {
      Header: 'Amount',
      accessor: 'amount',
      Cell: ({ cellData }) => salaryFormatter.format(cellData),
    },
    { Header: 'Frequency', accessor: 'frequency', Cell: () => 'Monthly' },
  ]
  const visaQuestionnaireComponent =
    contract?.work_visa_questionnaire?.questions?.map((question) => {
      if (!question.full_time_contract_answer) {
        return null
      }
      if (question.type === 'country_dropdown') {
        const country = countries?.find(
          (c) => c.id.toString() === question.full_time_contract_answer.input,
        )
        return (
          <Col key={question.id} md={4} sm={12}>
            <h6 className='tw-mb-1 tw-text-sm tw-text-text-60'>
              {question.text}
            </h6>

            <div className='tw-flex tw-items-center tw-gap-2'>
              <img
                src={
                  !country?.iso2
                    ? undefined
                    : `/flags/${country.iso2?.toLowerCase()}.svg`
                }
                className='mx-1'
                style={{
                  width: '1rem',
                  height: '1rem',
                  borderRadius: '0.5rem',
                  marginRight: 10,
                  objectFit: 'cover',
                }}
                alt=''
              />
              <p className='tw-mb-0 tw-text-sm tw-text-secondary'>
                {country?.name}
              </p>
            </div>
          </Col>
        )
      } else if (question.type === 'dropdown') {
        return (
          <Col className='tw-mb-4' key={question.id} md={4} sm={12}>
            <h6 className='tw-mb-1 tw-text-sm tw-text-text-60'>
              {question.text}
            </h6>
            <p className='tw-mb-0 tw-text-sm tw-text-secondary'>
              {question.full_time_contract_answer.input}
            </p>
          </Col>
        )
      }
      // other types to be added here if added later
      return null
    })
  const amendedNationality = countries?.find(
    (c) => c.id.toString() === amendData?.employee_country_id,
  )
  const nationality = amendedNationality || contract?.employee?.citizen
  return (
    <>
      <StyledCard className='p-3 p-md-4 m-0 mb-3' {...props}>
        <CardBody className='p-0 m-0'>
          <StyledDiv>
            <Media>
              <Media className='overflow-hidden' body>
                <Col className='p-0 m-0'>
                  <Row className='justify-content-between align-items-start p-0 m-0'>
                    <h5 className='tw-mb-6 tw-text-xl tw-font-semibold tw-text-secondary'>
                      Employee info
                    </h5>
                    {canEdit && (
                      <button
                        className='btn btn-link text-primary p-0 font-size-14'
                        onClick={() => setEditEmpInfo(true)}
                      >
                        Edit
                      </button>
                    )}
                  </Row>
                </Col>
              </Media>
            </Media>
            <Row className='tw-mb-6'>
              <Col md={4} sm={12}>
                <h6 className='tw-mb-1 tw-text-sm tw-text-text-60'>
                  First name
                </h6>
                <p className='tw-mb-0 tw-text-sm tw-text-secondary'>
                  {amendData?.employee_first_name ||
                    contract?.employee?.first_name}
                </p>
              </Col>
              <Col md={4} sm={12}>
                <h6 className='tw-mb-1 tw-text-sm tw-text-text-60'>
                  Middle name
                </h6>
                <p className='tw-mb-0 tw-text-sm tw-text-secondary'>
                  {(amendData?.employee_middle_name ||
                    contract?.employee?.middle_name) ??
                    '-'}
                </p>
              </Col>
              <Col md={4} sm={12}>
                <h6 className='tw-mb-1 tw-text-sm tw-text-text-60'>
                  Last name
                </h6>
                <p className='tw-mb-0 tw-text-sm tw-text-secondary'>
                  {(amendData?.employee_last_name ||
                    contract?.employee?.last_name) ??
                    '-'}
                </p>
              </Col>
            </Row>
            <Row>
              <Col md={4} sm={12}>
                <h6 className='tw-mb-1 tw-text-sm tw-text-text-60'>
                  Personal email
                </h6>
                <p className='tw-mb-0 tw-text-sm tw-text-secondary'>
                  {amendData?.employee_email || contract?.employee?.email}
                </p>
              </Col>
              <Col md={4} sm={12}>
                <h6 className='tw-mb-1 tw-text-sm tw-text-text-60'>
                  Nationality
                </h6>

                <div className='tw-flex tw-items-center tw-gap-2'>
                  <img
                    src={
                      !nationality
                        ? undefined
                        : `/flags/${nationality?.iso2?.toLowerCase()}.svg`
                    }
                    className='mx-1'
                    style={{
                      width: '1rem',
                      height: '1rem',
                      borderRadius: '0.5rem',
                      marginRight: 10,
                      objectFit: 'cover',
                    }}
                    alt=''
                  />
                  <p className='tw-mb-0 tw-text-sm tw-text-secondary'>
                    {nationality?.name}
                  </p>
                </div>
              </Col>
            </Row>
          </StyledDiv>
        </CardBody>
      </StyledCard>
      {visaQuestionnaireComponent?.length > 0 && contract?.work_visa ? (
        <Card className='!tw-mb-6 tw-p-6'>
          <h5 className='tw-mb-6 tw-text-xl tw-font-semibold tw-text-secondary'>
            Work Visa / Permit
          </h5>
          <Row>{visaQuestionnaireComponent}</Row>
        </Card>
      ) : null}
      <StyledCard className='p-3 p-md-4 m-0 mb-3'>
        <CardBody className='p-0 m-0'>
          <StyledDiv>
            <Media>
              <Media className='overflow-hidden' body>
                <Col className='p-0 m-0'>
                  <Row className='justify-content-between align-items-start p-0 m-0'>
                    <h5 className='tw-mb-6 tw-text-xl tw-font-semibold tw-text-secondary'>
                      Job info
                    </h5>
                    {(canEdit || canAmend) && (
                      <button
                        className='btn btn-link text-primary p-0 font-size-14'
                        onClick={() => setEditInfo(true)}
                      >
                        Edit
                      </button>
                    )}
                  </Row>
                </Col>
              </Media>
            </Media>
            <Row className='tw-mb-6'>
              <Col md={4} sm={12}>
                <h6 className='tw-mb-2 tw-text-sm tw-text-text-60'>
                  Start date
                </h6>
                <p className='tw-mb-0 tw-text-sm tw-text-secondary'>
                  {contract?.start_date &&
                    format(new Date(contract?.start_date), 'dd/MM/yyyy')}
                </p>
              </Col>
              <Col md={4} sm={12}>
                <h6 className='tw-mb-2 tw-text-sm tw-text-text-60'>
                  Job title
                </h6>
                <p className='tw-mb-0 tw-text-sm tw-text-secondary'>
                  {(amendData?.name || contract?.name) ?? '-'}
                </p>
              </Col>
              <Col md={4} sm={12}>
                <h6 className='tw-mb-2 tw-text-sm tw-text-text-60'>
                  Qualification
                </h6>
                <p className='tw-mb-0 tw-text-sm tw-text-secondary'>
                  {contract?.qualification ?? '-'}
                </p>
              </Col>
            </Row>
          </StyledDiv>
        </CardBody>
      </StyledCard>{' '}
      <StyledCard className='p-3 p-md-4 m-0 mb-3'>
        <CardBody className='p-0 m-0'>
          <StyledDiv>
            <Media>
              <Media className='overflow-hidden' body>
                <Col className='p-0 m-0'>
                  <Row className='justify-content-between align-items-start p-0 m-0'>
                    <h5 className='tw-mb-6 tw-text-xl tw-font-semibold tw-text-secondary'>
                      Compensation
                    </h5>
                    {(canEdit || canAmend) && (
                      <button
                        className='btn btn-link text-primary p-0'
                        onClick={() => setEditPayment(true)}
                      >
                        Edit
                      </button>
                    )}
                  </Row>
                </Col>
              </Media>
            </Media>
            <Row className='tw-mb-6'>
              <Col md={4} sm={12}>
                <h6 className='tw-mb-2 tw-text-sm tw-text-text-60'>Salary</h6>
                <p className='tw-mb-0 tw-text-sm tw-text-secondary'>
                  <span className='text-success d-block'>
                    {salaryIsAmended &&
                      salaryFormatter.format(amendData?.amount)}
                  </span>
                  {salaryIsAmended ? (
                    <span className={cx({ 'text-danger': salaryIsAmended })}>
                      {salaryFormatter.format(contract?.amount)}
                    </span>
                  ) : (
                    <span className={cx({ 'text-danger': salaryIsAmended })}>
                      {salaryFormatter.format(contract?.amount / 12)} / month
                    </span>
                  )}
                </p>
              </Col>
              <Col md={4} sm={12}>
                <h6 className='tw-mb-2 tw-text-sm tw-text-text-60'>Term</h6>
                <p className='tw-mb-0 tw-text-sm tw-text-secondary'>
                  {contract?.employment_term ?? '-'}
                </p>
              </Col>
              <Col md={4} sm={12}>
                <h6 className='tw-mb-2 tw-text-sm tw-text-text-60'>Type</h6>
                <p className='tw-mb-0 tw-text-sm tw-text-secondary'>
                  {contract?.employment_type ?? '-'}
                </p>
              </Col>
            </Row>
          </StyledDiv>
        </CardBody>
      </StyledCard>
      {parseFloat(contract?.bonus_amount) && contract?.signing_bonus_amount ? (
        <StyledCard className='p-3 p-md-4 m-0 mb-3'>
          <CardBody className='p-0 m-0'>
            <StyledDiv>
              <Media>
                <Media className='overflow-hidden' body>
                  <Col className='p-0 m-0'>
                    <Row className='justify-content-between align-items-start p-0 m-0'>
                      <h5 className='tw-mb-6 tw-text-xl tw-font-semibold tw-text-secondary'>
                        Bonus
                      </h5>
                    </Row>
                  </Col>
                </Media>
              </Media>
              <DataTable
                data={[
                  parseFloat(contract?.bonus_amount) && {
                    amount: salaryFormatter.format(contract?.bonus_amount),
                    type: 'Bonus',
                    details: contract?.bonus_details,
                  },
                  contract?.signing_bonus_amount && {
                    amount: salaryFormatter.format(
                      contract?.signing_bonus_amount,
                    ),
                    type: 'Signing Bonus',
                  },
                ].filter(Boolean)}
                columns={[
                  { Header: 'Amount', accessor: 'amount' },
                  {
                    Header: 'Type',
                    accessor: 'type',
                  },
                  {
                    Header: 'Details',
                    accessor: 'details',
                  },
                ]}
              />
            </StyledDiv>
          </CardBody>
        </StyledCard>
      ) : null}
      {(allowances?.length > 0 || canEdit || canAmend) && (
        <StyledCard className='p-3 p-md-4 m-0 mb-3'>
          <CardBody className='p-0 m-0'>
            <StyledDiv>
              <Media>
                <Media className='overflow-hidden' body>
                  <Row className='justify-content-between align-items-start p-0 m-0'>
                    <h5 className='tw-mb-6 tw-text-xl tw-font-semibold tw-text-secondary'>
                      Allowances
                    </h5>
                    {(canEdit || canAmend) && allowances.length > 0 && (
                      <button
                        className='btn btn-link text-primary p-0'
                        onClick={() => setEditAllowances(true)}
                      >
                        Edit
                      </button>
                    )}
                  </Row>
                  <DataTable data={allowances} columns={allowancesColumns} />
                  {(canEdit || canAmend) && allowances.length === 0 && (
                    <button
                      className='tw-flex tw-w-full tw-items-center tw-gap-2 tw-rounded tw-border tw-border-dashed tw-border-primary tw-bg-primary-20 tw-p-4 tw-text-sm tw-font-semibold tw-text-primary'
                      onClick={() => setEditAllowances(true)}
                    >
                      <PlusCircle size={24} />
                      <span>Add Monthly Allowance</span>
                    </button>
                  )}
                </Media>
              </Media>
            </StyledDiv>
          </CardBody>
        </StyledCard>
      )}
      {insuranceProvider && (
        <StyledCard className='p-3 p-md-4 m-0 mb-3'>
          <CardBody className='p-0 m-0'>
            <StyledDiv>
              <Media>
                <Media className='overflow-hidden' body>
                  <h5 className='tw-mb-6 tw-text-xl tw-font-semibold tw-text-secondary'>
                    Health Insurance
                  </h5>

                  <Row className='tw-mb-6'>
                    <Col md={4} sm={12}>
                      <h6 className='tw-mb-2 tw-text-sm tw-text-text-60'>
                        Insurance provider
                      </h6>
                      <p className='tw-mb-0 tw-text-sm tw-text-secondary'>
                        {insuranceProvider.name}
                      </p>
                    </Col>
                  </Row>
                  {/*
                  // TODO: Will be implemented later
                  {(canEdit || canAmend) && (
                    <button className='tw-flex tw-w-full tw-items-center tw-gap-2 tw-rounded tw-border tw-border-dashed tw-border-primary tw-bg-primary-20 tw-p-4 tw-text-sm tw-font-semibold tw-text-primary'>
                      <PlusCircle size={24} />
                      <span>Add Health Insurance</span>
                    </button>
                  )} */}
                </Media>
              </Media>
            </StyledDiv>
          </CardBody>
        </StyledCard>
      )}
    </>
  )
}

const StyledDiv = styled.div`
  @media (max-width: 768px) {
    padding: 16px;
    background-color: #ffffff;
    box-shadow: 0px 1px 0px #dfe1e6;
    border-radius: 4px;
    margin-bottom: 16px;
  }
`
const StyledCard = styled(Card)`
  @media (max-width: 768px) {
    padding: 0 !important;
    background-color: #f4f5f7;
    box-shadow: none;
  }
`
export default FulltimeDetailBlocks
