import { yupResolver } from '@hookform/resolvers/yup'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import { Card, Container, Form, TabPane } from 'reactstrap'
import toastr from 'toastr'
import * as yup from 'yup'

import { ReactComponent as CardPendingIllustration } from '../../../assets/images/card-pending-2.svg'
import ModalHeader from '../../../components/ModalHeader'
import Steps from '../../../components/Steps'
import StepContainer from '../../../components/Steps/StepContainer'
import Button from '../../../components/ui/button'
import Loader from '../../../components/ui/loader'
import WizardReviewStep from '../../../components/wizard-review-step'
import { useFetch } from '../../../helpers/hooks'
import {
  cardsPreConditions,
  getCardEnumerations,
  requestNewCard,
} from '../../../services/api'
import { usdFormatter } from '../../../utils/formatters/currency'
import useTriggerField from '../../Contract/CreateContract/utils/use-trigger-field'
import { FormContentRenderer } from '../components/form-content-renderer'
import { mapCountryToOptionCards } from '../components/request-new-card'
import { commonAddressSchema } from '../utils/common-address-field-schema'
import { formatPhysicalRequestData } from '../utils/format-physical-request-data'
import { getFullPhoneNumber } from '../utils/get-full-phone-number'
import { showInputErrors } from './cards-kyc'
import TabContent from '../../../components/ui/tabs'

export const shippingFields = [
  {
    label: 'Address line 1',
    name: 'address_1',
    type: 'text',
    rules: { required: 'This field is required' },
  },
  {
    label: 'Address line 2',
    name: 'address_2',
    type: 'text',
    rules: { required: 'This field is required' },
  },
  {
    label: 'City',
    name: 'city',
    type: 'text',
    rules: { required: 'This field is required' },
  },
  {
    label: 'State',
    name: 'state',
    type: 'text',
    rules: { required: 'This field is required' },
  },
  {
    label: 'Country',
    name: 'country',
    type: 'country',
    rules: { required: 'This field is required' },
  },
  {
    label: 'Zip Code',
    name: 'zipcode',
    type: 'text',
    rules: { required: 'This field is required' },
  },
  {
    label: 'Mobile phone',
    name: 'mobile',
    type: 'tel',
    rules: { required: 'This field is required' },
  },
]

const allFields = [{ title: '', fields: shippingFields }]

function ShippingAddress({ enumerations, control, errors }) {
  return (
    <FormContentRenderer
      fieldData={enumerations ?? {}}
      formFields={allFields}
      control={control}
      errors={errors}
    />
  )
}

function ReviewAndConfirm({ values, preConditions }) {
  const physicalCardFees =
    preConditions?.card_configurations?.order_physical_card_fees

  const infos = allFields.map(({ title, fields }) => {
    return {
      title,
      info: fields.map((field) => {
        return {
          label: field.label,
          value: values[field.name]?.label ?? values[field.name],
        }
      }),
    }
  })

  return (
    <WizardReviewStep
      infos={[
        ...infos,
        {
          info: [
            {
              label: 'Shipping fee',
              value: usdFormatter.format(physicalCardFees),
            },
            { label: 'Shipping time', value: '3-5 business days' },
          ],
        },
      ]}
    />
  )
}

function ConfirmationStep() {
  return (
    <div className='d-flex flex-column justify-content-center align-items-center gap-12 py-5'>
      <CardPendingIllustration className='mb-3' />
      <h1 className='text-center text-gray-h'>
        Your physical card is on its way
      </h1>
      <p
        className='font-size-16 text-center text-muted'
        style={{ maxWidth: '36ch' }}
      >
        You will be notified by email when you card is shipped.
      </p>

      <Button tag={Link} to='/cards'>
        Got to my cards
      </Button>
    </div>
  )
}

const stepsDetails = {
  shippingAddress: {
    id: 0,
    label: 'Address',
    title: 'What’s your delivery address?',
    key: 'shippingAddress',
    Step: ShippingAddress,
  },
  reviewAndConfirm: {
    id: 1,
    label: 'Review',
    title: 'Review & Confirm',
    key: 'reviewAndConfirm',
    Step: ReviewAndConfirm,
  },
  confirmation: {
    id: 2,
    label: null,
    noFooter: true,
    title: 'RemotePass Cards',
    key: 'confirmation',
    Step: ConfirmationStep,
  },
}

const steps = [
  { ...stepsDetails.shippingAddress },
  { ...stepsDetails.reviewAndConfirm },
  { ...stepsDetails.confirmation },
]

export default function CardsRequestPhysical() {
  const history = useHistory()

  const [activeStep, setActiveStep] = useState(stepsDetails.shippingAddress.id)

  const { data: enumerations, isLoading: isLoadingEnumerations } = useFetch({
    action: getCardEnumerations,
    autoFetch: true,
  })

  const { data: preConditions } = useFetch({
    action: cardsPreConditions,
    autoFetch: true,
  })

  const loadingData = isLoadingEnumerations || !enumerations

  const headerSteps = steps.filter((step) => !!step?.label)

  return (
    <Container fluid>
      <ModalHeader action={() => history.push('/cards')}>
        {headerSteps?.length <= 1 ? null : (
          <Steps
            className='d-none d-md-flex'
            activeTab={activeStep}
            data={headerSteps.map((step) => step.label)}
            noLastAction
          />
        )}
      </ModalHeader>

      <Form className='d-flex flex-column' style={{ marginBottom: '9rem' }}>
        <TabContent
          activeTab={activeStep}
          className='twitter-bs-wizard-tab-content'
        >
          {loadingData ? (
            <Card style={{ maxWidth: 820, margin: '80px auto 0px auto' }}>
              <Loader minHeight='63vh' />
            </Card>
          ) : (
            <PhysicalCardRequestForm
              steps={steps}
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              enumerations={enumerations}
              preConditions={preConditions}
            />
          )}
        </TabContent>
      </Form>
    </Container>
  )
}

const schema = commonAddressSchema.concat(
  yup.object().shape({
    mobile: yup.string().required('The Mobile Phone field is required'),
  }),
)

function PhysicalCardRequestForm({
  activeStep,
  setActiveStep,
  steps,
  enumerations,
  preConditions,
}) {
  const [validatingPhone, setValidatingPhone] = useState(false)

  const user = useSelector((state) => state?.userProfile?.userProfile)
  const accountUser = useSelector((state) => state.Account?.user)

  const { startFetch: requestCard, isLoading: requestingPhysicalCard } =
    useFetch({
      action: requestNewCard,
      onComplete: () => {
        setActiveStep((step) => step + 1)
      },
      onError: (resp) => {
        toastr.error(resp?.error?.messages.join(', ') ?? 'Something went wrong')
      },
    })

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    getValues,
    setError,
    trigger,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { mobile: user?.phone },
  })

  useTriggerField({ watch, trigger })

  useEffect(() => {
    const countryCode = user?.country?.iso3
    const countryFound = enumerations?.countries?.find(
      (n) => n.iso3 === countryCode,
    )
    const defaultCountry = mapCountryToOptionCards(countryFound)

    setValue('country', defaultCountry)
    setValue('address_1', user.address)
    setValue('city', user.city)
    setValue('state', user.state?.name)
  }, [
    enumerations?.nationalities,
    enumerations?.countries,
    setValue,
    user?.Country_of_Citizenship?.iso3,
    user?.country?.iso3,
    user.address,
    user.state?.name,
    user.city,
  ])

  async function onSubmit(data) {
    const enrichedData = { ...data }

    // Add mobile country code
    const { valid, mobile, mobileCountryCode } = await getFullPhoneNumber({
      mobile: enrichedData?.mobile,
      token: accountUser?.token,
      setLoading: setValidatingPhone,
    })

    if (valid) {
      enrichedData.mobile = mobile
      enrichedData.mobile_country_code = mobileCountryCode
    } else {
      toastr.error('Your phone number is not valid')
      return
    }

    // Format body data
    const formattedData = formatPhysicalRequestData(enrichedData)

    // Request physical card
    requestCard(formattedData)
  }

  function onError(error) {
    // eslint-disable-next-line no-console
    console.log('error', error)
  }

  const submitAllSteps = handleSubmit(onSubmit, onError)

  function handleNext() {
    if (activeStep === stepsDetails.reviewAndConfirm.id) {
      submitAllSteps()
    } else {
      schema
        .validate(getValues(), { abortEarly: false })
        .then(() => {
          setActiveStep((step) => step + 1)
        })
        .catch(({ inner }) => showInputErrors(inner, setError))
    }
  }

  function handleBack() {
    setActiveStep((step) => step - 1)
  }

  const nextIsLoadingAndDisabled =
    activeStep === steps[steps.length - 1].id &&
    (validatingPhone || requestingPhysicalCard)

  return (
    <>
      {steps.map((step) => {
        return (
          <TabPane tabId={step.id} key={step.id}>
            <StepContainer
              total={steps.length}
              index={step.id}
              title={step.title}
              loading={nextIsLoadingAndDisabled}
              disableNext={nextIsLoadingAndDisabled}
              nextText={
                activeStep === stepsDetails.reviewAndConfirm.id
                  ? 'Submit'
                  : 'Next'
              }
              onNext={handleNext}
              onBack={handleBack}
              noFooter={step?.noFooter}
            >
              <div className='px-2' style={{ minHeight: '50vh' }}>
                <step.Step
                  enumerations={enumerations}
                  preConditions={preConditions}
                  values={watch()}
                  control={control}
                  errors={errors}
                />
              </div>
            </StepContainer>
          </TabPane>
        )
      })}
    </>
  )
}
