import { Check, Warning, X } from '@phosphor-icons/react'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import { cn } from 'ui'
import Button from '../../components/ui/button'
import {
  SideMenu,
  SideMenuBody,
  SideMenuFooter,
  SideMenuHeader,
} from '../../components/ui/side-menu'
import { userTypes } from '../../helpers/enum'
import { usePermissions } from '../../helpers/hooks'
import permissions from '../../helpers/permissions'
import { DetailsInfoList } from '../review-center/review-layout-details-components'
import {
  DeductTimeOffForm,
  formatTimeOffDate,
  getDeductionValue,
  pendingApprovalId,
} from './time-off-list'
import { getTimeOffStatusColor } from './helpers'
import BadgeX from '../../components/Table/BadgeX'
import ConfirmationModal from '../../components/Common/ConfirmationModal'
import { ModalCloseButton } from '../../components/Common/modal-close-button'
import { formatDays } from '../../utils/formatters/format-days'

const timeOffApproveFormId = 'time-off-approve-form'

export function TimeOffItemDetailsMenu({
  isOpen,
  toggle,
  item,
  contract,
  canManageTimeOff,
  isLoading,
  onCancel,
  onSubmit,
  onDecline,
  onDelete,
}) {
  const userProfile = useSelector((state) => state?.userProfile?.userProfile)
  const user = useSelector((state) => state.Account?.user)

  const isContractor = userProfile?.type === userTypes.CONTRACTOR
  const isClient = user?.type === userTypes.COMPANY

  const { hasAccess } = usePermissions()

  function hasPermission(permission) {
    return isClient && hasAccess(permission)
  }

  const isPending = item?.status?.id === pendingApprovalId

  const noActions = isPending
    ? isClient
      ? !hasPermission(permissions.deleteTimeOff) &&
        !hasPermission(permissions.rejectTimeOff) &&
        !hasPermission(permissions.approveTimeOff)
      : false
    : !item?.can_cancel

  return (
    <SideMenu
      onClose={toggle}
      isOpen={isOpen}
      className='!tw-w-full tw-max-w-[480px] tw-text-black'
      itemListClassName={cn(
        'tw-grid [&>*:nth-child(2)]:tw-overflow-auto [&>*:nth-child(2)]:tw-overscroll-contain',
        noActions ? 'tw-grid-rows-[auto_1fr]' : 'tw-grid-rows-[auto_1fr_auto]',
      )}
    >
      <SideMenuHeader toggle={toggle}>Time off</SideMenuHeader>
      <SideMenuBody className='!tw-px-0'>
        <div>
          <div className='tw-px-4 tw-text-base tw-font-bold md:tw-px-6'>
            Details
          </div>

          <DetailsInfoList
            items={[
              {
                label: 'Status',
                value: (
                  <BadgeX
                    className='px-2 py-1.5'
                    status={getTimeOffStatusColor(item?.status?.name)}
                  >
                    {item?.status?.name}
                  </BadgeX>
                ),
              },
              { label: 'Policy', value: item?.policy?.name },
              {
                label: 'Start date',
                value: formatTimeOffDate({
                  date: item?.from,
                  isHalf: item?.is_half_start_date,
                }),
              },
              {
                label: 'End date',
                value: formatTimeOffDate({
                  date: item?.to,
                  isHalf: item?.is_half_end_date,
                }),
              },
              { label: 'Total days', value: formatDays(item?.days) },
              {
                label: 'Deduction',
                value: getDeductionValue({
                  currencyCode: item?.currency?.code,
                  total: item?.total,
                }),
              },
            ]}
          />
        </div>
        {/* @todo: timeline data is not the same as we used to get from the backend */}
        {/* Reported and waiting for reply */}
        {/* <div className='tw-border-t tw-border-surface-30 tw-py-4'>
          <div className='tw-px-4 tw-text-base tw-font-bold md:tw-px-6'>
            Timeline
          </div>

          <Timeline
            flowTimeline={item?.timeline}
            isDeclined={item?.status?.id === 4}
          />
        </div> */}

        {isPending && isClient ? (
          <div className='tw-flex tw-border-t tw-border-surface-30 tw-px-4 tw-py-4 md:tw-px-6'>
            <DeductTimeOffForm
              item={item}
              contract={contract}
              onSubmit={onSubmit}
              formId={timeOffApproveFormId}
              className='tw-flex tw-min-w-0 tw-max-w-full tw-flex-col tw-gap-4 tw-rounded tw-border tw-border-surface-30 tw-p-4'
            />
          </div>
        ) : null}
      </SideMenuBody>

      {noActions ? null : (
        <SideMenuFooter className='tw-flex-wrap'>
          {!item?.can_cancel ? null : (
            <CancelAction onCancel={onCancel} isLoading={isLoading} />
          )}

          {!isPending ? null : isClient ? (
            <>
              {!hasPermission(permissions.deleteTimeOff) ? null : (
                <Button
                  color='link'
                  className='tw-mr-auto !tw-px-0 !tw-text-systemRed-100'
                  onClick={onDelete}
                  disabled={
                    isLoading ||
                    !(
                      hasPermission(permissions.deleteTimeOff) ||
                      canManageTimeOff
                    )
                  }
                >
                  Delete
                </Button>
              )}

              <Button
                color='danger'
                onClick={onDecline}
                disabled={
                  isLoading ||
                  !(
                    hasPermission(permissions.rejectTimeOff) || canManageTimeOff
                  )
                }
                icon={<X size={18} weight='bold' />}
                className='!tw-px-4'
              >
                Decline
              </Button>

              <Button
                type='submit'
                color='success'
                formId={timeOffApproveFormId}
                disabled={
                  isLoading ||
                  !(
                    hasPermission(permissions.approveTimeOff) ||
                    canManageTimeOff
                  )
                }
                icon={<Check size={18} weight='bold' />}
                className='!tw-px-4'
              >
                Approve
              </Button>
            </>
          ) : isContractor ? (
            <Button
              color='danger'
              onClick={onDelete}
              disabled={isLoading}
              loading={isLoading}
            >
              Delete
            </Button>
          ) : null}
        </SideMenuFooter>
      )}
    </SideMenu>
  )
}

function CancelAction({ onCancel, isLoading }) {
  const [isModalOpen, setIsModalOpen] = useState(false)
  function toggleModal() {
    setIsModalOpen((open) => !open)
  }

  return (
    <>
      <Button type='button' onClick={toggleModal} outline color='danger'>
        Cancel
      </Button>

      <ConfirmationModal
        isOpen={isModalOpen}
        toggle={toggleModal}
        negativeCaption='No, close'
        caption='Yes, cancel'
        buttonColor='danger'
        onConfirm={onCancel}
        content={
          <>
            <div className='tw-relative tw-flex tw-items-center tw-justify-between tw-pb-2'>
              <Warning size={24} className='tw-text-systemRed-100' />
              <ModalCloseButton toggle={toggleModal} />
            </div>
            <h4 className='tw-mb-2 tw-text-xl tw-font-semibold tw-text-secondary-120'>
              Do you want to cancel this time off?
            </h4>
            <p className='tw-mb-0 tw-text-sm tw-text-text-80'>
              This action cannot be undone.
            </p>
          </>
        }
        confirmLoading={isLoading}
      />
    </>
  )
}
