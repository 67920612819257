import React, { useState } from 'react'
import {
  ArrowLeft,
  Buildings,
  EnvelopeSimple,
  Eye,
  Info,
  Invoice,
  Notebook,
  Plus,
} from '@phosphor-icons/react'
import { Link } from 'react-router-dom'

import Head from '../../../../components/head'
import PageHeading from '../../../../components/ui/page-heading'
import Button from '../../../../components/ui/button'
import SearchBar from '../../../../components/SearchBar'
import CustomSelect from '../../../../components/Forms/CustomSelect/CustomSelect'
import Shimmer from '../../../../components/ui/shimmer'
import EmptyState from '../../empty-state'
import DataTable from '../../../../components/ui/data-table'
import {
  SideMenu,
  SideMenuBody,
  SideMenuFooter,
  SideMenuHeader,
} from '../../../../components/ui/side-menu'
import { DetailsInfoList } from '../../../review-center/review-layout-details-components'
import { FormSectionHr } from '../../../Contract/CreateContract/components/form-section-title'
import { BankInfo, DetailSectionTitle } from '../../detail-section-title'

const PageHeader = () => (
  <PageHeading className='tw-mb-6'>
    <PageHeading.Title className='tw-text-2xl'>
      <PageHeading.ActionGroup className='tw-flex-col tw-gap-0'>
        <Button
          icon={<ArrowLeft size={24} />}
          color='transparent'
          className='!tw-block !tw-p-0'
          tag={Link}
          to='/bills'
        />
        Vendors
      </PageHeading.ActionGroup>
    </PageHeading.Title>
    <Button icon={<Plus size={20} />} className='!tw-h-14'>
      Add Vendor
    </Button>
  </PageHeading>
)

const BodyHeader = () => (
  <span className='tw-flex tw-gap-2 [&>*]:tw-flex-1'>
    <SearchBar placeholder='Search by name' />

    <CustomSelect
      options={[
        { label: 'Moscow', value: 'mos' },
        { label: 'Mecca', value: 'mec' },
      ]}
      placeholder='Category'
    />
  </span>
)

const DATA = [
  {
    name: 'MegaWave Innovations',
    email: 'Admin@megawave.com',
    category: 'Consulting',
  },
  {
    name: 'EcoTech Ventures',
    email: 'Admin@ecotechventures.com',
    category: 'Marketing',
  },
  {
    name: 'NovaWave Services',
    email: 'Admin@novawaveservices.com',
    category: 'Utilities',
  },
  {
    name: 'AeroTech Innovations',
    email: 'Admin@aerotechinnovations.com',
    category: 'Software Subscriptions',
  },
]

const DetailsButton = ({ item }) => {
  const [showDetails, setShowDetails] = useState(false)
  return (
    <>
      <Button
        color='link'
        onClick={() => setShowDetails(true)}
        className='tw-text-sm !tw-text-primary-100'
        icon={<Eye size={16} />}
      >
        Details
      </Button>

      {showDetails && (
        <SideMenu isOpen onClose={() => setShowDetails(false)}>
          <SideMenuHeader toggle={() => setShowDetails(false)}>
            <div className='tw-mr-1 tw-text-xl tw-text-black'>{item.name}</div>
            <span className='tw-text-sm tw-font-medium tw-text-text-80'>
              Vendor
            </span>
          </SideMenuHeader>

          <SideMenuBody>
            <DetailsInfoList
              items={[
                {
                  label: 'Vendor',
                  value: item.name,
                  icon: <Buildings size={24} className='tw-fill-text-60' />,
                },
                {
                  label: 'Email',
                  value: item.email,
                  icon: (
                    <EnvelopeSimple size={24} className='tw-fill-text-60' />
                  ),
                },
                {
                  label: 'Category',
                  value: item.category,
                  icon: <Notebook size={24} className='tw-fill-text-60' />,
                },
              ]}
              className='tw-p-0'
              title={
                <DetailSectionTitle
                  title='Details'
                  icon={<Info size={20} className='tw-mr-2 tw-fill-primary' />}
                />
              }
            />
            <FormSectionHr className='tw-my-6' />

            <BankInfo />
          </SideMenuBody>

          <SideMenuFooter className='tw-justify-between'>
            <Button color='transparent' className='!tw-px-0 !tw-text-systemRed'>
              Delete
            </Button>
            <span>
              <Button
                outline
                className='tw-mr-2 !tw-border-surface-30 !tw-bg-white !tw-text-black'
                icon={<Invoice size={20} />}
              >
                View Bills
              </Button>

              <Button>Edit</Button>
            </span>
          </SideMenuFooter>
        </SideMenu>
      )}
    </>
  )
}

const Body = ({ isLoading }) => {
  const columns = [
    { Header: 'Vendor Name', accessor: 'name' },
    { Header: 'Recipient Email', accessor: 'email' },
    { Header: 'Category', accessor: 'category' },
    { Cell: ({ rowData }) => <DetailsButton item={rowData} /> },
  ]
  return (
    <div className='tw-flex tw-flex-col tw-gap-6 tw-rounded tw-bg-white tw-px-6 tw-pb-16 tw-pt-6'>
      <BodyHeader />
      {isLoading ? (
        <Shimmer width='100%' height={250} />
      ) : DATA.length === 0 ? (
        <EmptyState />
      ) : (
        <DataTable columns={columns} data={DATA} striped responsive />
      )}
    </div>
  )
}

export default function Vendors() {
  return (
    <div className='page-content'>
      <Head title='Vendors' />
      <PageHeader />
      <Body />
    </div>
  )
}
