import {
  DownloadSimple,
  Eye,
  FunnelSimple,
  Info,
  Invoice,
  ListChecks,
  ListDashes,
  Money,
  Plus,
  UserCircleGear,
  X,
} from '@phosphor-icons/react'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Input } from 'reactstrap'

import { cn } from 'ui'
import Head from '../../components/head'
import { PageNav, useActiveTab } from '../../components/page-nav'
import Button from '../../components/ui/button'
import PageHeading from '../../components/ui/page-heading'
import Shimmer from '../../components/ui/shimmer'
import CustomDatePicker from '../../components/Forms/CustomDatePicker/CustomDatePicker'
import CustomSelect from '../../components/Forms/CustomSelect/CustomSelect'
import DataTable from '../../components/ui/data-table'
import BadgeX from '../../components/Table/BadgeX'
import { CheckItem } from '../../components/ui/check-item'
import {
  SideMenu,
  SideMenuBody,
  SideMenuFooter,
  SideMenuHeader,
} from '../../components/ui/side-menu'
import { DetailsInfoList } from '../review-center/review-layout-details-components'
import { FormSectionHr } from '../Contract/CreateContract/components/form-section-title'
import { TimelineType2 } from '../Contract/ContractPage/item-timeline'
import EmptyState from './empty-state'
import { BankInfo, DetailSectionTitle } from './detail-section-title'

const PAYMENT_STATUS = {
  UNPAID: 'unpaid',
  PAID: 'paid',
  IN_PROGRESS: 'in_progress',
  CANCELED: 'canceled',
}

const PageHeader = () => (
  <PageHeading className='tw-mb-4'>
    <PageHeading.Title>Bills</PageHeading.Title>
    <PageHeading.ActionGroup className='tw-flex-wrap tw-gap-x-2'>
      <Button
        className='!tw-border-0 !tw-bg-white !tw-text-black'
        icon={<UserCircleGear size={20} />}
        to='/bills/vendors'
        tag={Link}
      >
        Manage Vendors
      </Button>

      <Button icon={<Plus size={20} />}>Add Invoice</Button>
    </PageHeading.ActionGroup>
  </PageHeading>
)

const tabsData = [
  {
    label: 'Unpaid',
    key: PAYMENT_STATUS.UNPAID,
    count: 0,
  },
  {
    label: 'In progress',
    key: PAYMENT_STATUS.IN_PROGRESS,
    count: 1,
  },
  { label: 'Paid', key: PAYMENT_STATUS.PAID, count: 2 },
  { label: 'Canceled', key: PAYMENT_STATUS.CANCELED, count: 3 },
]

const TabHeader = ({ activeTab }) => (
  <PageNav className='!tw-mb-4 !tw-flex-wrap tw-rounded tw-bg-white'>
    {tabsData.map((data) => (
      <PageNav.Item key={data.key}>
        <PageNav.Link
          to={`/bills?tab=${data.key}`}
          tag={Link}
          isActive={activeTab === data.key}
          count={{ state: 'show', value: 0 }}
          className='!tw-flex tw-items-center tw-gap-2'
        >
          {data.label} <PageNav.Count value={data.count} />
        </PageNav.Link>
      </PageNav.Item>
    ))}
  </PageNav>
)

const DATA = [
  {
    id: 'INV-5403',
    created_on: '12/01/2024',
    due_date: '12/01/2024',
    vendor: 'MegaWave Innovations',
    amount: '$ 12,000.00',
    status: 'unpaid',
    approvals: 'approved',
  },
  {
    id: 'INV-5404',
    created_on: '12/01/2024',
    due_date: '12/01/2024',
    vendor: 'MegaWave Innovations',
    amount: '$ 12,000.00',
    status: 'unpaid',
    approvals: 'approved',
  },
  {
    id: 'INV-5405',
    created_on: '12/01/2024',
    due_date: '12/01/2024',
    vendor: 'MegaWave Innovations',
    amount: '$ 12,000.00',
    status: 'unpaid',
    approvals: 'approved',
  },
]

const getBadgeColor = (status) => {
  switch (status) {
    case 'unpaid':
      return 'danger'
    case 'approved':
      return 'success'
  }
}

const InfoText = ({ top, bottom }) => (
  <span className='tw-text-sm tw-font-normal tw-text-text-60'>
    <div className='tw-text-text'>{top}</div>
    {bottom}
  </span>
)

const DetailsButton = ({ item }) => {
  const [showDetails, setShowDetails] = useState(false)
  return (
    <>
      <Button
        color='link'
        onClick={() => setShowDetails(true)}
        className='tw-text-sm !tw-text-primary-100'
        icon={<Eye size={16} />}
      >
        Details
      </Button>

      {showDetails && (
        <SideMenu isOpen onClose={() => setShowDetails(false)}>
          <SideMenuHeader toggle={() => setShowDetails(false)}>
            <div className='tw-flex tw-items-center'>
              <span className='tw-mr-1 tw-text-xl tw-text-black'>
                {item.vendor}
              </span>
              <StatusBadge status={item.status} size='sm' />
            </div>
            <span className='tw-text-sm tw-font-medium tw-text-text-80'>
              {item.created_on}
            </span>
          </SideMenuHeader>

          <SideMenuBody>
            <DetailsInfoList
              items={[
                { label: 'Created on', value: item.created_on },
                { label: 'Due date', value: item.due_date },
                { label: 'Amount', value: item.amount },
              ]}
              className='tw-p-0'
              title={
                <DetailSectionTitle
                  title='Details'
                  icon={<Info size={20} className='tw-mr-2 tw-fill-primary' />}
                />
              }
            />
            <FormSectionHr className='tw-my-6' />

            <DetailsInfoList
              items={[
                {
                  label: (
                    <span className='tw-flex tw-items-center'>
                      <img
                        src='https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/No-Image-Placeholder.svg/660px-No-Image-Placeholder.svg.png?20200912122019'
                        alt='invoice'
                        className='tw-mr-4 tw-size-12 tw-rounded tw-border tw-border-surface-30'
                      />
                      <InfoText top='Invoice' bottom='invoice12_RT.PDF' />
                    </span>
                  ),
                  value: (
                    <Button
                      iconRight={
                        <DownloadSimple className='tw-ml-2' size={20} />
                      }
                      color='link'
                      className='!tw-px-0'
                    >
                      Download
                    </Button>
                  ),
                },
              ]}
              className='tw-p-0'
              title={
                <DetailSectionTitle
                  title='Files'
                  icon={
                    <Invoice size={20} className='tw-mr-2 tw-fill-primary' />
                  }
                />
              }
            />
            <FormSectionHr className='tw-my-6' />

            <DetailsInfoList
              items={[
                {
                  label: (
                    <InfoText top='Website redesign' bottom='GL Account' />
                  ),
                  value: <InfoText top='$ 10,000.00' bottom='Amount' />,
                },
                {
                  label: (
                    <InfoText top='Tool integration' bottom='GL Account' />
                  ),
                  value: <InfoText top='$ 1,000.00' bottom='Amount' />,
                },
                {
                  label: <InfoText top='Extras' bottom='GL Account' />,
                  value: <InfoText top='$ 11,000.00' bottom='Amount' />,
                },
              ]}
              title={
                <DetailSectionTitle
                  title='Line items'
                  icon={
                    <ListDashes size={20} className='tw-mr-2 tw-fill-primary' />
                  }
                />
              }
              className='tw-p-0'
            />
            <FormSectionHr className='tw-my-6' />

            <BankInfo />
            <FormSectionHr className='tw-my-6' />

            <TimelineType2
              flowTimeline={[
                {
                  date: '2024-10-07T11:23:34.969771Z',
                  actor: 'Slahudeen Rasheed',
                  status: 'Created',
                  actor_id: 2793,
                },
                {
                  date: '2024-10-07T11:23:34.969771Z',
                  actor: 'Slahudeen Rasheed',
                  status: 'Pending approval',
                  actor_id: 2793,
                },
                {
                  actor: 'Slahudeen Rasheed',
                  status: 'Pending approval',
                  actor_id: 2793,
                  reminder: true,
                },
              ]}
              title={
                <span className='tw-flex tw-items-center tw-text-base tw-font-bold tw-text-black'>
                  <ListChecks size={20} className='tw-mr-2 tw-fill-primary' />
                  Approvals
                </span>
              }
            />
          </SideMenuBody>

          {item.status === PAYMENT_STATUS.UNPAID && (
            <SideMenuFooter className='tw-justify-between'>
              <FooterLeft status={item.status} isApproved={true} />

              <span>
                <Button
                  outline
                  className='tw-mr-2 !tw-border-surface-30 !tw-bg-white !tw-text-black'
                >
                  Edit
                </Button>

                <FooterRight
                  status={item.status}
                  isApproved={true}
                  amount={item.amount}
                />
              </span>
            </SideMenuFooter>
          )}
        </SideMenu>
      )}
    </>
  )
}

const FooterLeft = ({ status, isApproved }) => {
  if (status === PAYMENT_STATUS.UNPAID && !isApproved) {
    return (
      <Button className='!tw-border-systemRed !tw-bg-systemRed'>Decline</Button>
    )
  }
  return (
    <Button
      className='!tw-border-0 !tw-bg-transparent !tw-px-0 !tw-text-systemRed'
      icon={<X size={20} />}
    >
      Cancel
    </Button>
  )
}

const FooterRight = ({ status, isApproved, amount }) => {
  if (status === PAYMENT_STATUS.UNPAID && !isApproved) {
    return <Button className='!tw-border-0 !tw-bg-systemGreen'>Approve</Button>
  }
  return <Button>Pay {amount}</Button>
}

const StatusBadge = ({ status, size }) => (
  <BadgeX
    className={cn('!tw-rounded-[32px] tw-px-2 tw-py-1 tw-uppercase')}
    status={getBadgeColor(status)}
    textClassName={cn({ '!tw-text-[10px]': size === 'sm' })}
  >
    {status}
  </BadgeX>
)

const Body = ({ isLoading }) => {
  const [selectedItems, setSelectedItems] = useState([])
  const [isAllSelected, setIsAllSelected] = useState(false)

  const columns = [
    {
      Header: 'Invoice',
      accessor: 'id',
      Cell: ({ cellData }) => (
        <span className='tw-flex tw-items-center tw-gap-x-2'>
          <DownloadSimple className='tw-size-5 tw-fill-primary' /> {cellData}
        </span>
      ),
    },
    {
      Header: (
        <span className='tw-flex tw-items-center tw-gap-x-2'>
          Created on
          <FunnelSimple size={20} className='tw-fill-text-60' />
        </span>
      ),
      accessor: 'created_on',
    },
    {
      Header: (
        <span className='tw-flex tw-items-center tw-gap-x-2'>
          Due date
          <FunnelSimple size={20} className='tw-fill-text-60' />
        </span>
      ),
      accessor: 'due_date',
    },
    { Header: 'Vendor', accessor: 'vendor' },
    {
      Header: (
        <span className='tw-flex tw-items-center tw-gap-x-2'>
          Amount
          <FunnelSimple size={20} className='tw-fill-text-60' />
        </span>
      ),
      accessor: 'amount',
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: ({ cellData }) => <StatusBadge status={cellData} />,
    },
    {
      Header: 'Approvals',
      accessor: 'approvals',
      Cell: ({ cellData }) => <StatusBadge status={cellData} />,
    },
    { Cell: ({ rowData }) => <DetailsButton item={rowData} /> },
    {
      Header: (
        <Button
          color='link'
          onClick={() => {
            setIsAllSelected(!isAllSelected)
            setSelectedItems([])
          }}
        >
          {isAllSelected ? `Deselect ` : 'Select '} All
        </Button>
      ),
      Cell: ({ rowData }) => {
        const isItemSelected = selectedItems.find(
          (item) => item.id === rowData.id,
        )
        return (
          <span className='tw-flex tw-justify-center'>
            <CheckItem
              className='tw-w-fit'
              checked={isAllSelected || isItemSelected}
              onChange={() => {
                setSelectedItems(
                  isItemSelected
                    ? [
                        ...selectedItems.filter(
                          (item) => item.id !== rowData.id,
                        ),
                      ]
                    : [...selectedItems, rowData],
                )
              }}
            />
          </span>
        )
      },
    },
  ]

  return (
    <div className='tw-flex tw-flex-col tw-gap-6 tw-rounded tw-bg-white tw-px-6 tw-pb-16 tw-pt-6'>
      <BodyHeader isLoading={isLoading} />
      <FilterRow isLoading={isLoading} />
      {isLoading ? (
        <Shimmer width='100%' height={250} />
      ) : DATA.length === 0 ? (
        <EmptyState
          title='You have no bills to show'
          description='You can click Add Invoice button and create one.'
          icon={
            <Invoice
              weight='duotone'
              className='tw-mb-4 tw-fill-primary'
              size={250}
            />
          }
        />
      ) : (
        <DataTable columns={columns} data={DATA} striped responsive />
      )}
    </div>
  )
}

const BodyHeader = ({ isLoading }) => {
  const { activeTab } = useActiveTab({ defaultTab: PAYMENT_STATUS.UNPAID })

  return (
    <span className='tw-flex tw-flex-wrap tw-items-center tw-justify-between tw-gap-y-2'>
      <span>
        {!isLoading ? (
          <>
            <div className='tw-text-2xl'>
              {activeTab === PAYMENT_STATUS.UNPAID ? 'Unpaid' : 'Ready to pay'}
            </div>
            <span className='tw-text-sm tw-text-text-80'>$ 1,560.00</span>
          </>
        ) : (
          <>
            <Shimmer className='tw-mb-2' width={130} />
            <Shimmer height={32} />
          </>
        )}
      </span>

      <Button disabled={isLoading || true} icon={<Money />}>
        Select To Pay
      </Button>
    </span>
  )
}

const FilterRowWrapper = ({ children, className }) => (
  <span
    className={cn(
      'tw-flex tw-flex-wrap tw-gap-x-2 tw-gap-y-4 [&>*]:!tw-w-full [&>*]:md:!tw-min-w-60 [&>*]:md:tw-flex-1',
      className,
    )}
  >
    {children}
  </span>
)

const FilterRow = ({ isLoading }) => {
  if (isLoading) {
    return (
      <FilterRowWrapper>
        {new Array(5).fill(0).map((_, index) => (
          <Shimmer key={index} />
        ))}
      </FilterRowWrapper>
    )
  }

  return (
    <FilterRowWrapper>
      <CustomDatePicker
        value={null}
        handleOnChange={() => {}}
        placeholder='Due Date'
        wrapperClassName='[&>div>div>div]:tw-h-[52px] [&>div>div>div]:!tw-flex [&>div>div>div]:tw-items-center'
      />

      <CustomSelect
        options={[
          { label: 'Suberu', value: 'Suberu' },
          { label: 'Lamidi', value: 'Lamidi' },
        ]}
        placeholder='Due date status'
        wrapperClassName='[&>div>div]:tw-h-[52px]'
      />

      <CustomSelect
        options={[
          { label: 'Sabi Ventures', value: 'sabiwan' },
          { label: 'Lamidi Enterprises', value: 'lams' },
        ]}
        placeholder='Vendor'
        wrapperClassName='[&>div>div]:tw-h-[52px]'
      />

      <Input
        type='number'
        placeholder='Min Amount'
        value=''
        // onChange={(e) =>
        //   setUrlState?.({ search_key: e.target.value || undefined })
        // }
        style={{ height: 52 }}
      />

      <Input
        type='number'
        placeholder='Max Amount'
        value=''
        // onChange={(e) =>
        //   setUrlState?.({ search_key: e.target.value || undefined })
        // }
        style={{ height: 52 }}
      />
    </FilterRowWrapper>
  )
}

export default function Bills() {
  const { activeTab } = useActiveTab({ defaultTab: PAYMENT_STATUS.UNPAID })

  return (
    <div className='page-content'>
      <Head title='Bills' />
      <PageHeader />
      <TabHeader activeTab={activeTab} />
      <Body isLoading={false} />
    </div>
  )
}
