export function getDays(days) {
  if (typeof days === 'string' || typeof days === 'number') {
    days = parseFloat(days)
  }

  return days
}

export function formatDays(days) {
  if (!days) {
    return null
  }

  const _days = getDays(days)

  return `${_days} day${Number(_days) === 1 ? '' : 's'}`
}
