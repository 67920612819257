import { Paperclip } from '@phosphor-icons/react'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Card, CardBody, Col, Row, Spinner } from 'reactstrap'
import styled from 'styled-components'
import toastr from 'toastr'
import { ActionsDropdown } from 'ui'

import StyledTd from '../../../components/Table/StyledTd'
import StyledTh from '../../../components/Table/StyledTh'
import TableComp from '../../../components/Table/TableComp'
import TableH from '../../../components/Table/TableH'
import { PermissionTooltip } from '../../../components/permission-tooltip'
import Button from '../../../components/ui/button'
import { userTypes } from '../../../helpers/enum'
import { useFetch, usePermissions, useResize } from '../../../helpers/hooks'
import permissions from '../../../helpers/permissions'
import { downloadFile, removeRequestDoc } from '../../../services/api'
import { PERMISSION_GROUP } from '../../CompanySetting/manage-role'
import TabEmpty from '../components/tab/tab-empty'
import { RequestFormModal } from './documents-request-form-modal'

const Documents = ({
  docs,
  isContract,
  contractId,
  isAdmin,
  loading,
  onUpdate,
}) => {
  const [open, setOpen] = useState(false)
  const user = useSelector((state) => state?.Account?.user)
  const { hasAccess } = usePermissions()

  const actionButton =
    user?.type === userTypes.COMPANY ? (
      <PermissionTooltip
        showing={
          !hasAccess([
            permissions.RequestContractsDocuments,
            permissions.createDocument,
          ])
        }
        id='add-work-btn-tooltip'
        area={PERMISSION_GROUP.DOCUMENTS.name}
      >
        <Button
          type='button'
          disabled={
            !hasAccess(
              permissions.RequestContractsDocuments,
              permissions.createDocument,
            )
          }
          onClick={() => setOpen(true)}
          className='font-size-14'
        >
          Request Document
        </Button>
      </PermissionTooltip>
    ) : null

  return (
    <>
      {loading ? (
        <Row
          style={{ minHeight: '80vh' }}
          className='justify-content-center align-items-center'
        >
          <Spinner type='grow' className='mr-2' color='primary' />
        </Row>
      ) : docs?.length > 0 ? (
        <Row className='p-0 m-0'>
          <Col xs='12' className='p-0 m-0'>
            <Card className='p-0 m-0'>
              <CardBody className='p-0 m-0'>
                <TableH title='Documents' leftSide={actionButton} />

                <DocsList
                  isAdmin={isAdmin}
                  docs={docs}
                  isContract={isContract}
                  contractId={contractId}
                  onUpdate={onUpdate}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      ) : (
        <TabEmpty
          icon={
            <Paperclip size={250} color='var(--primary)' weight='duotone' />
          }
          title='No documents added'
          subtitle='Submitted compliance documents will be shown here'
        >
          {actionButton}
        </TabEmpty>
      )}

      {!open ? null : (
        <RequestFormModal
          show={open}
          hide={() => setOpen(false)}
          contractId={contractId}
          onUpdate={onUpdate}
        />
      )}
    </>
  )
}

const DocsList = ({ isAdmin, docs, onUpdate }) => {
  return (
    <StyledTableCont>
      <div className={!isAdmin && 'table-with-dropdown'}>
        <div className='table-responsive'>
          <TableComp>
            <thead className='thead-light'>
              <tr>
                <StyledTh>Name</StyledTh>
                <StyledTh className='text-right' />
              </tr>
            </thead>
            <tbody className='w-100'>
              {React.Children.toArray(
                docs.map((doc, index) => (
                  <DocLine
                    doc={doc}
                    isAdmin={isAdmin}
                    onUpdate={onUpdate}
                    key={index}
                  />
                )),
              )}
            </tbody>
          </TableComp>
        </div>
      </div>
    </StyledTableCont>
  )
}

const DocLine = ({ doc, isAdmin, onUpdate }) => {
  const isMobile = useResize()
  const { hasAccess } = usePermissions()
  const downloadDoc = useFetch({
    action: downloadFile,
    onComplete: (data) => {
      const url = window.URL.createObjectURL(new Blob([data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `${doc?.name}.${data?.type?.split('/')[1]}`)
      document.body.appendChild(link)
      link.click()
    },
  })
  const deleteDoc = useFetch({
    action: removeRequestDoc,
    onComplete: () => {
      onUpdate()
    },
    onError: (err) => {
      toastr.error(err)
    },
  })
  const handleDownload = () => {
    downloadDoc.startFetch(doc?.link)
  }
  const handleDelete = () => {
    deleteDoc.startFetch({
      request_id: doc?.id,
    })
  }
  const actions = []
  if (doc?.can_delete && hasAccess(permissions.DeleteContractsDocuments)) {
    actions.push({
      label: 'Remove',
      onClick: handleDelete,
    })
  }
  if (doc?.link && hasAccess(permissions.DownloadContractsDocuments)) {
    actions.push({
      label: 'Download',
      onClick: handleDownload,
    })
  }
  return (
    <tr>
      <StyledTd>
        {isMobile ? doc?.name?.slice(0, 30) + '...' : doc?.name}
      </StyledTd>
      {!isAdmin && (
        <StyledTd className='tw-flex tw-justify-end'>
          {actions.length > 0 && <ActionsDropdown data={actions} />}
        </StyledTd>
      )}
    </tr>
  )
}

const StyledTableCont = styled.div`
  background: #ffffff;
  box-shadow: 0px 1px 0px #dfe1e6;
  border-radius: 4px;
`

export default Documents
