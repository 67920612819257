import { ListChecks } from '@phosphor-icons/react'
import React from 'react'
import { FormProvider, useForm, useWatch } from 'react-hook-form'

import Button from '../../components/ui/button'
import Loader from '../../components/ui/loader'
import {
  TODO_TYPE,
  getReviewTypeIcon,
} from '../Activity/center-overview-client'
import { EmptyState } from './empty-state'
import { ReviewCenterBulkEdit } from './review-center-bulk-edit'
import { ReviewDetails } from './review-details'
import { ReviewItemsView } from './review-items-view'
import {
  ReviewLayout,
  defaultFooterHeight,
  getContentMaxHeight,
} from './review-list-layout'
import { SearchFiltersBar } from './search-filters-bar'

function getItemTypePendingText(type) {
  switch (type) {
    case TODO_TYPE.contracts:
      return 'Pending contracts will be listed here'
    case TODO_TYPE.payments:
      return 'Payments to be reviewed will be listed here'
    case TODO_TYPE.expenses:
      return 'Expenses to be reviewed will be listed here'
    case TODO_TYPE.timeOff:
      return 'Time Off requests will be listed here'
    case TODO_TYPE.documents:
      return 'Document requests will be listed here'
    case TODO_TYPE.works:
      return 'Work submissions will be listed here'
    case TODO_TYPE.invoices:
      return 'Pending invoices will be listed here'
    default:
      return 'Pending items will be listed here'
  }
}

const reviewContext = React.createContext({
  isSelecting: false,
  toggleSelecting: () => {},
  activeItem: null,
  setUrlState: () => {},
  refreshReviewList: () => {},
  activeTab: '',
  reviewItems: [],
})

export function useReviewContext() {
  const context = React.useContext(reviewContext)

  if (!context) {
    throw new Error(
      'useReviewContext must be used within a ReviewContextProvider',
    )
  }

  return context
}

export function ReviewCenterColumns({
  isLoading = false,
  reviewItems = [],
  refreshReviewList,
  fetchNextPage,
  isFetchingNextPage,
  urlState,
  setUrlState,
}) {
  const { selectedItem: selectedId, tab: activeTab } = urlState

  const activeItem = reviewItems.find(
    (item) => String(item.id) === String(selectedId),
  )?.raw

  const filters = {
    page: Number(urlState.page),
    search_key: urlState.search_key,
  }

  const noFilters = Object.entries(filters)
    .filter(([key]) => key !== 'page')
    .every(([, value]) => !value)

  const methods = useForm()
  const { control, reset } = methods

  const isSelecting = urlState?.selecting === '1'
  function toggleSelecting() {
    const isSelecting = urlState?.selecting === '1'

    if (isSelecting) {
      reset()
    }

    setUrlState({ selecting: isSelecting ? undefined : '1' })
  }

  const selectedItems = useWatch({ control, name: 'selectedItems' })?.filter(
    Boolean,
  )

  const detailsMaxHeight = getContentMaxHeight({
    footerHeight: defaultFooterHeight,
    isSelecting,
    topActionsHeight: 0,
  })

  const contextValue = {
    isSelecting,
    toggleSelecting,
    activeItem,
    setUrlState,
    refreshReviewList,
    activeTab,
    reviewItems,
  }

  const isEmpty =
    !isLoading && (reviewItems?.length === 0 || !reviewItems) && noFilters

  return (
    <ReviewLayout.Wrapper isSelecting={isSelecting}>
      {isEmpty ? (
        <EmptyState
          pictureElement={getReviewTypeIcon(activeTab, {
            size: 120,
            weight: 'duotone',
          })}
          title='You are all set!'
          textElement={getItemTypePendingText(activeTab)}
        />
      ) : (
        <reviewContext.Provider value={contextValue}>
          <FormProvider {...methods}>
            <ReviewLayout.Grid>
              <ReviewLayout.Column colSpan={6} maxHeight={detailsMaxHeight}>
                {isLoading && noFilters ? (
                  <Loader minHeight='100%' />
                ) : (
                  <ReviewList
                    reviewItems={reviewItems}
                    isLoadingFilters={isLoading && !noFilters}
                    filters={filters}
                    setUrlState={setUrlState}
                    fetchNextPage={fetchNextPage}
                    isFetchingNextPage={isFetchingNextPage}
                    // The `key` prop is needed for the animation, do not remove
                    key={activeTab}
                  />
                )}
              </ReviewLayout.Column>
              <ReviewLayout.Column maxHeight={detailsMaxHeight}>
                {isLoading ? <Loader minHeight='100%' /> : <ReviewDetails />}
              </ReviewLayout.Column>
            </ReviewLayout.Grid>

            <ReviewCenterBulkEdit selectedItems={selectedItems} />
          </FormProvider>
        </reviewContext.Provider>
      )}
    </ReviewLayout.Wrapper>
  )
}

const DISABLED_MULTISELECT_TYPES = [TODO_TYPE.documents, TODO_TYPE.contracts]

function ReviewList({
  reviewItems,
  isLoadingFilters,
  filters,
  fetchNextPage,
  isFetchingNextPage,
}) {
  const { activeItem, setUrlState, isSelecting, toggleSelecting, activeTab } =
    useReviewContext()

  const isFilteredEmpty = reviewItems.length === 0 && !isLoadingFilters

  return (
    <>
      <div className='tw-sticky tw-top-0 tw-z-[1] tw-flex tw-items-center tw-justify-between tw-gap-4 tw-border-b tw-border-surface-30 tw-bg-white tw-p-6'>
        <SearchFiltersBar filters={filters} setUrlState={setUrlState} />

        {DISABLED_MULTISELECT_TYPES.includes(activeTab) ? null : (
          <Button
            type='button'
            color={isSelecting ? 'light' : 'primary'}
            outline
            icon={isSelecting ? null : <ListChecks size={16} />}
            onClick={() => toggleSelecting()}
          >
            {isSelecting ? 'Cancel' : 'Select'}
          </Button>
        )}
      </div>

      <div className='tw-flex tw-flex-grow tw-flex-col tw-gap-4 tw-overflow-auto tw-p-6'>
        {isLoadingFilters ? (
          <Loader className='tw-flex-grow' />
        ) : isFilteredEmpty ? (
          <div className='tw-flex tw-flex-grow tw-flex-col tw-items-center tw-justify-center tw-gap-4'>
            <div className='tw-text-primary-100'>
              {getReviewTypeIcon(activeTab, { size: 120, weight: 'duotone' })}
            </div>

            <p className='tw-mb-0 tw-text-sm tw-text-text-80'>
              No items found, please update your search
            </p>
          </div>
        ) : (
          <ReviewItemsView
            items={reviewItems}
            activeItem={activeItem}
            onClickItem={(item) => setUrlState({ selectedItem: item.raw?.id })}
            fetchNextPage={fetchNextPage}
            isFetchingNextPage={isFetchingNextPage}
            isSelecting={isSelecting}
          />
        )}
      </div>
    </>
  )
}
