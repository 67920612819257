import React, { useState } from 'react'
import { Carousel, CarouselIndicators, CarouselItem } from 'reactstrap'
import TimeOffBG from '../../assets/images/banner/time-off.svg'
import SlackBG from '../../assets/images/banner/slack.svg'
import ApprovalsBG from '../../assets/images/banner/approvals.svg'
import BgCheckBG from '../../assets/images/banner/bg-check.svg'

import {
  ArrowLeft,
  ArrowRight,
  ArrowSquareOut,
  FlowArrow,
  SealCheck,
  SlackLogo,
  TreePalm,
  X,
} from '@phosphor-icons/react'
import Button from '../../components/ui/button'
import { track } from '../../utils/analytics'
import IconButton from '../../components/ui/icon-button'
import { useSelector } from 'react-redux'
import {
  SIDEBAR_BREAKDOWN,
  useCustomResize,
  useFetch,
} from '../../helpers/hooks'
import { cn } from 'ui'
import FEATURE_FLAGS from '../../config/feature-flags'
import GenericBanner from './generic-banner'
import { getBanners } from '../../services/api'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'

const bannersLocalStorageKey = 'client-features-banners'

const BANNER_EVENTS = {
  GET_STARTED: 'Banner.get_started',
  LEARN_MORE: 'Banner.learn_more',
  DISMISS: 'Banner.dismiss',
}

const BANNERS_TYPES = {
  SLACK_INTEGRATIONS: 'Slack',
  TIME_OFF_POLICIES: 'Time_off',
  BACKGROUND_CHECKS: 'Background_check-checks',
  APPROVAL_FLOWS: 'Approval_flows',
}

const banners = [
  {
    type: BANNERS_TYPES.TIME_OFF_POLICIES,
    title: 'Time Off Policies',
    body: 'Create custom leave policies for PTO, sick leave, or religious observances. Track balances, manage carryovers and set rules for time off requests.',
    desktopBG:
      'tw-to-[rgba(17,78,247,0.20)] tw-from-40% tw-from-[rgba(17,78,247,0.10)]',
    mobileTitle: 'Customize and Manage Time Off Policies Effortlessly',
    mobileBody:
      "Simplify the management of employee leave by creating personalized time off policies tailored to your team's specific needs.",
    mobileBGColors:
      'tw-from-[rgba(17,78,247,0.10)] tw-from-49% tw-to-[rgba(17,78,247,0.20)]',
    icon: <TreePalm size={72} color='white' />,
    iconBGColor: 'tw-bg-primary',
    getStartedLink: '/settings/time-off-policies',
    learnMoreLinkL:
      'https://help.remotepass.com/en/articles/9573740-default-time-off-time-off-policies',
  },
  {
    type: BANNERS_TYPES.BACKGROUND_CHECKS,
    title: 'Background checks',
    body: 'Run background checks on your entire workforce — contractors, EOR employees, and local employees — globally, directly from your RemotePass account.',
    mobileTitle: 'Conduct Background Checks Quickly and Efficiently',
    mobileBody:
      'Save time and effort with our easy-to-use background check feature. In just two clicks, you can initiate thorough background checks on your team members.',
    desktopBG:
      'tw-from-[rgba(97,229,237,0.16)] tw-via-[rgba(97,229,237,0.08)]  tw-to-[rgba(97,229,237,0.24)]',
    mobileBGColors:
      'tw-from-[rgba(218,142,19,0.22)] tw-to-[rgba(218,142,19,0.32)]',
    icon: <SealCheck size={72} color='white' />,
    iconBGColor: 'tw-bg-[#DA8E13]',
    getStartedLink: '/documents',
    learnMoreLinkL:
      'https://help.remotepass.com/en/articles/9330637-how-to-run-a-background-check-for-contractors-and-employees',
  },
  {
    type: BANNERS_TYPES.SLACK_INTEGRATIONS,
    title: 'Slack Integration',
    body: 'Streamline your workflow! Receive notifications and reminders for payments, expenses, and time-off requests directly in Slack, and approve them instantly.',
    mobileTitle: 'Stay on Top of Every Task with Instant Slack Alerts',
    mobileBody:
      'Never miss an important update or task whether it’s approval requests, reminders, or notifications, stay informed in real-time and keep your workflows smooth and efficient.',
    desktopBG:
      'tw-from-[rgba(101,220,134,0.08)] tw-via-[rgba(101,220,134,0.04) tw-to-[rgba(101,220,134,0.32)]',
    mobileBGColors:
      'tw-from-[rgba(101,220,134,0.22)] tw-to-[rgba(101,220,134,0.32)]',
    icon: <SlackLogo size={72} color='white' />,
    iconBGColor: 'tw-bg-[#65DC86]',
    getStartedLink: '/profile-settings?tab=notifications',
    learnMoreLinkL:
      'https://help.remotepass.com/en/articles/9795083-how-to-receive-notifications-and-approve-items-through-slack',
  },
  {
    type: BANNERS_TYPES.APPROVAL_FLOWS,
    title: 'Stay in Control with Custom Approval Flows',
    body: 'Easily set up and manage approval sequences to streamline your processes and ensure smooth operations across your team.',
    desktopBG:
      'tw-from-[rgba(255,69,57,0.08)] tw-via-[rgba(255,69,57,0.04)] tw-to-[rgba(255,69,57,0.32)]',
    mobileTitle: 'Stay in Control with Custom Approval Flows',
    mobileBody:
      'Easily set up and manage approval sequences to streamline your processes and ensure smooth operations across your team.',
    mobileBGColors:
      'tw-from-[rgba(247,17,21,0.11)] tw-to-[rgba(247,17,21,0.16)]',
    icon: <FlowArrow size={72} color='white' />,
    iconBGColor: 'tw-bg-[#F71115]',
    getStartedLink: '/settings/approvals',
    learnMoreLinkL:
      'https://help.remotepass.com/en/articles/8769367-default-approvals-approval-flows',
  },
]

function getBannerImage(item) {
  switch (item.type) {
    case BANNERS_TYPES.SLACK_INTEGRATIONS:
      return SlackBG
    case BANNERS_TYPES.TIME_OFF_POLICIES:
      return TimeOffBG
    case BANNERS_TYPES.BACKGROUND_CHECKS:
      return BgCheckBG
    case BANNERS_TYPES.APPROVAL_FLOWS:
      return ApprovalsBG
  }
}

export function ClientBanners({ className }) {
  const { data: bannerData } = useFetch({
    action: getBanners,
    autoFetch: !FEATURE_FLAGS.CLIENT_NEW_BANNER,
  })

  return !FEATURE_FLAGS.CLIENT_NEW_BANNER ? (
    <GenericBanner bannerData={bannerData} />
  ) : (
    <NewBanner className={className} />
  )
}

export function NewBanner({ className }) {
  const [activeIndex, setActiveIndex] = useState(0)
  const [animating, setAnimating] = useState(false)
  const localhostDismissedBanners = localStorage.getItem(bannersLocalStorageKey)
  const [dismissedBanners, setDismissedBanners] = useState(
    localhostDismissedBanners ?? [],
  )
  const activeBanners = banners.filter(
    (banner) => !dismissedBanners?.includes(banner.type),
  )

  if (activeBanners.length === 0) return null

  function next() {
    if (animating) return
    const nextIndex =
      activeIndex === activeBanners.length - 1 ? 0 : activeIndex + 1
    setActiveIndex(nextIndex)
  }

  function previous() {
    if (animating) return
    const nextIndex =
      activeIndex === 0 ? activeBanners.length - 1 : activeIndex - 1
    setActiveIndex(nextIndex)
  }

  function goToIndex(newIndex) {
    if (animating) return
    setActiveIndex(newIndex)
  }
  return (
    <>
      <Carousel
        activeIndex={activeIndex}
        className={cn('tw-mb-8', className)}
        next={next}
        interval={0}
        previous={previous}
      >
        {activeBanners.map((banner) => (
          <CarouselItem
            onExiting={() => setAnimating(true)}
            onExited={() => setAnimating(false)}
            key={banner?.type}
          >
            <BannerItem
              item={banner}
              dismissBanner={(banner) => {
                setDismissedBanners((prev) => [...prev, banner.type])
                localStorage.setItem(
                  bannersLocalStorageKey,
                  localStorage.getItem(bannersLocalStorageKey) + banner.type,
                )
                goToIndex(0)
              }}
            />
          </CarouselItem>
        ))}
        {activeBanners?.length > 1 ? (
          <CarouselIndicators
            items={activeBanners}
            activeIndex={activeIndex}
            onClickHandler={goToIndex}
            className='!tw-bottom-[-40px] tw-my-2 [&>li]:!tw-size-2 [&>li]:tw-rounded-[50%] [&>li]:!tw-bg-secondary-70'
          />
        ) : null}
        {activeBanners?.length > 1 ? (
          <>
            <IconButton
              className='!tw-absolute !tw-left-[-16px] !tw-top-[calc(50%-16px)] !tw-flex !tw-h-8 !tw-w-8 tw-items-center tw-justify-center !tw-rounded-full !tw-border-0 !tw-bg-white !tw-text-base'
              style={{
                'box-shadow':
                  '0px 0px 5.333px 0px rgba(0, 0, 0, 0.04), 0px 10.667px 21.333px 0px rgba(0, 0, 0, 0.08)',
              }}
              icon={<ArrowLeft size={16} className='tw-text-text-80' />}
              onClick={previous}
            ></IconButton>

            <IconButton
              className='!tw-absolute !tw-right-[-16px] !tw-top-[calc(50%-16px)] !tw-flex !tw-h-8 !tw-w-8 tw-items-center tw-justify-center !tw-rounded-full !tw-border-0 !tw-bg-white !tw-text-base'
              style={{
                'box-shadow':
                  '0px 0px 5.333px 0px rgba(0, 0, 0, 0.04), 0px 10.667px 21.333px 0px rgba(0, 0, 0, 0.08)',
              }}
              icon={<ArrowRight size={16} className='tw-text-text-80' />}
              onClick={next}
            ></IconButton>
          </>
        ) : null}
      </Carousel>
    </>
  )
}

export function BannerItem({ item, dismissBanner }) {
  const client = useSelector((state) => state.userProfile.userProfile)
  const isMobile = useCustomResize({ minWidth: SIDEBAR_BREAKDOWN })

  function handleOnGetStartedClick() {
    track(BANNER_EVENTS.GET_STARTED, {
      source: item.type,
      user_type: 'client',
      country: client?.country?.name,
    })
  }
  function handleOnLearnMoreClick() {
    track(BANNER_EVENTS.LEARN_MORE, {
      source: item.type,
      user_type: 'client',
      country: client?.country?.name,
    })
  }
  function handleDismissBanner() {
    track(BANNER_EVENTS.DISMISS, {
      user_type: 'client',
      country: client?.country?.name,
    })
    dismissBanner(item)
  }
  return isMobile ? (
    <MobileBannerItem
      item={item}
      handleDismissBanner={handleDismissBanner}
      handleOnGetStartedClick={handleOnGetStartedClick}
      handleOnLearnMoreClick={handleOnLearnMoreClick}
    />
  ) : (
    <DesktopBannerItem
      item={item}
      handleDismissBanner={handleDismissBanner}
      handleOnGetStartedClick={handleOnGetStartedClick}
      handleOnLearnMoreClick={handleOnLearnMoreClick}
    />
  )
}
function DesktopBannerItem({
  item,
  handleOnLearnMoreClick,
  handleOnGetStartedClick,
  handleDismissBanner,
}) {
  return (
    <div
      className={cn(
        'tw-flex tw-h-[140px] tw-justify-between tw-bg-white tw-bg-gradient-to-r',
        item?.desktopBG,
      )}
    >
      <div className='tw-flex tw-items-center tw-gap-8 tw-p-8'>
        <div className='tw-flex tw-flex-col tw-items-start tw-gap-1 xl:tw-gap-2'>
          <div className='tw-text-xl tw-font-bold lg:tw-text-2xl'>
            {item.title}
          </div>
          <div className='tw-max-w-[564px] tw-text-text-100'>{item.body}</div>
        </div>
        <div className='tw-flex tw-flex-col tw-gap-4'>
          <Button
            textClassName='!tw-px-8 !tw-font-normal'
            tag={Link}
            to={item.getStartedLink}
            onClick={() => {
              handleOnGetStartedClick()
            }}
          >
            Get Started
          </Button>
          <Button
            textClassName='tw-flex tw-items-center tw-gap-1 tw-text-text-80 !tw-font-light'
            className='!tw-p-0 hover:!tw-no-underline'
            color='link'
            tag='a'
            href={item.learnMoreLinkL}
            target='_blank'
            onClick={() => {
              handleOnLearnMoreClick()
            }}
            iconRight={<ArrowSquareOut size={16} className='tw-text-text-80' />}
            rel='noreferrer'
          >
            Learn more
          </Button>
        </div>
      </div>

      <img src={getBannerImage(item)} alt='' className='tw-h-full' />

      <IconButton
        className='tw-absolute tw-right-2 tw-top-2 tw-z-20'
        icon={<X size={20} className='tw-text-text-100' />}
        color='link'
        onClick={() => {
          handleDismissBanner()
        }}
        circle
      />
    </div>
  )
}
function MobileBannerItem({
  item,
  handleOnLearnMoreClick,
  handleOnGetStartedClick,
  handleDismissBanner,
}) {
  return (
    <div>
      <div
        className={cn(
          'tw-relative tw-flex tw-flex-col tw-overflow-hidden tw-bg-white tw-bg-gradient-to-r tw-p-6',
          item.mobileBGColors,
        )}
      >
        <div
          className={cn(
            'tw-absolute tw-bottom-[-30px] tw-right-[-30px] tw-z-0 tw-flex tw-h-[120px] tw-w-[120px] tw-items-center tw-justify-center tw-rounded-full',
            item.iconBGColor,
          )}
        >
          {item.icon}
        </div>
        <div className='tw-z-10 tw-flex tw-h-full tw-flex-col tw-items-start tw-justify-between tw-gap-3'>
          <div className='tw-flex tw-flex-col tw-items-start tw-gap-3'>
            <Button
              textClassName='tw-flex tw-items-center tw-gap-1 tw-text-text-80'
              className='!tw-p-0 hover:!tw-no-underline'
              color='link'
              tag='a'
              href={item.learnMoreLinkL}
              target='_blank'
              onClick={() => {
                handleOnLearnMoreClick()
              }}
              rel='noreferrer'
              iconRight={
                <ArrowSquareOut size={16} className='tw-text-text-80' />
              }
            >
              Learn more
            </Button>
            <div className='tw-text-2xl tw-font-bold'>{item.mobileTitle}</div>
            <div className='tw-max-w-[564px]'>{item.mobileBody}</div>
          </div>
          <Button
            tag={Link}
            to={item.getStartedLink}
            onClick={() => {
              handleOnGetStartedClick()
            }}
          >
            Get Started
          </Button>
        </div>
      </div>
      <IconButton
        className='tw-absolute tw-right-2 tw-top-2 tw-z-20'
        icon={<X size={20} className='tw-text-text-100' />}
        color='link'
        onClick={() => {
          handleDismissBanner()
        }}
        circle
      />
    </div>
  )
}
