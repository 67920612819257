import { LockKeyOpen } from '@phosphor-icons/react'
import React from 'react'
import { Popover, PopoverBody } from 'reactstrap'

import { cn } from 'ui'
import BadgeX from '../../Table/BadgeX'

export default function CardButton({
  label,
  description,
  icon,
  isActive,
  onClick,
  id,
  isDisabled = false,
  horizontal = false,
  isNew,
  noPadding = false,
  className = 'text-left',
  spaced,
  optionIndex,
  lastOptionIndex,
}) {
  const [showAccessModal, setShowAccessModal] = React.useState(false)

  const showPopover = id && isDisabled?.title

  return (
    <button
      className={cn(
        'tw-relative tw-flex tw-w-full tw-gap-3 tw-border-2 tw-border-transparent',
        { 'tw-rounded': spaced },
        { 'tw-rounded-l': !spaced && optionIndex === 0 },
        { 'tw-rounded-r': !spaced && optionIndex === lastOptionIndex },
        'tw-ring-1 tw-ring-surface-30',
        isActive
          ? 'tw-cursor-default tw-bg-primary-10 tw-text-primary-100 tw-ring-2 tw-ring-primary-100 focus-visible:tw-ring-4 focus-visible:tw-ring-primary-20 focus-visible:tw-ring-offset-2 focus-visible:tw-ring-offset-primary-100'
          : 'focus-visible:tw-ring-4 focus-visible:tw-ring-surface-20 focus-visible:tw-ring-offset-1 focus-visible:tw-ring-offset-surface-30',
        {
          'tw-bg-transparent': !isActive && !isDisabled,
          '!tw-bg-surface-10': !!isDisabled,
          'tw-flex-col tw-items-start': horizontal,
          'tw-p-6': !noPadding,
        },
        className,
      )}
      id={id}
      type='button'
      onClick={!isDisabled ? onClick : () => setShowAccessModal(true)}
      disabled={!!isDisabled && !showPopover}
      style={{ pointerEvents: !!isDisabled && !showPopover ? 'none' : 'auto' }}
    >
      {!isDisabled || !showPopover ? null : (
        <Popover
          placement='bottom'
          isOpen={showAccessModal}
          toggle={() => setShowAccessModal(false)}
          target={id}
          trigger='legacy'
          style={{ boxShadow: 'rgba(149, 157, 165, 0.2) 0px 16px 24px' }}
          className='border-0 rounded-0'
          innerClassName='border border-gray-b rounded'
        >
          <PopoverBody className='p-0'>
            <div className='d-flex p-3 gap-16 align-items-start justify-content-start'>
              <LockKeyOpen
                size={20}
                weight='duotone'
                color='var(--primary)'
                className='flex-shrink-0'
              />

              <div>
                <h3 className='font-size-16 text-gray-h'>
                  {isDisabled?.title || 'Unlock Access'}
                </h3>

                {!isDisabled?.description ? null : (
                  <p className='text-gray-600 mt-2 mb-0 font-size-12'>
                    {isDisabled.description}
                  </p>
                )}
              </div>
            </div>

            {!isDisabled?.chatMessage ? null : (
              <div className='p-3 border-top border-gray-b'>
                <button
                  type='button'
                  className='btn btn-primary rounded btn-sm btn-block py-2'
                  style={{ color: 'white' }}
                  onClick={() =>
                    window.Intercom(
                      'showNewMessage',
                      isDisabled?.chatMessage || 'I want to unlock access',
                    )
                  }
                >
                  Proceed
                </button>
              </div>
            )}
          </PopoverBody>
        </Popover>
      )}

      {!isNew ? null : (
        <BadgeX
          className={cn('tw-absolute tw-uppercase', {
            '!tw-bg-surface-90': !!isDisabled,
          })}
          style={{ right: '1.5rem', top: '1.5rem' }}
          pill
          size='sm'
          color={!isDisabled ? 'primary' : 'secondary'}
        >
          New
        </BadgeX>
      )}

      {!icon ? null : (
        <span
          className={cn(
            'tw-mb-1 tw-flex tw-size-9 tw-shrink-0 tw-items-center',
            isActive ? '' : 'tw-text-text-30',
          )}
        >
          {icon}
        </span>
      )}

      <div
        className={cn({
          'tw-text-primary-100': isActive,
          'tw-text-black': !isActive && !isDisabled,
          'tw-text-text-60': !!isDisabled,
        })}
      >
        {!label ? null : (
          <h5
            className={cn(
              { 'tw-font-bold': (!spaced && isActive) || spaced },
              { 'tw-font-light': !spaced && !isActive },
              'tw-mb-0 tw-text-base tw-text-current',
            )}
          >
            {label}
          </h5>
        )}

        {!description ? null : (
          <p
            className={cn('tw-mb-0 tw-mt-2 tw-text-sm/[22px]', {
              'tw-text-text-60': !isActive,
            })}
          >
            {description}
          </p>
        )}
      </div>
    </button>
  )
}
