import { PlusCircle, TrashSimple } from '@phosphor-icons/react'
import cx from 'classnames'
import { addDays, format, isPast } from 'date-fns'
import React, { useEffect, useMemo, useState } from 'react'
import {
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
  useWatch,
} from 'react-hook-form'
import { useSelector } from 'react-redux'
import { Card, Row } from 'reactstrap'
import { toastr } from 'toastr'
import { useDebounceValue } from 'usehooks-ts'
import * as yup from 'yup'

import ControlledDatePicker from '../../../components/ControlledDatePicker'
import ControlledInput from '../../../components/ControlledInput'
import ControlledSelect from '../../../components/ControlledSelect'
import DynamicForm from '../../../components/Forms/dynamic-form'
import ControlledRadioList from '../../../components/controlled-radio-list'
import Button from '../../../components/ui/button'
import DataTable from '../../../components/ui/data-table'
import { FlagIcon } from '../../../components/ui/flag-icon'
import IconButton from '../../../components/ui/icon-button'
import { CONTRACT_TYPES } from '../../../core/config/contract-types'
import { useFetch } from '../../../helpers/hooks'
import {
  employmentTermOptions,
  employmentTermOptionsDe,
} from '../../../helpers/lists'
import {
  deGetCountryJurisdictions,
  deSupportedCountries,
  updateProrata,
} from '../../../services/api'
import { getCurrencyFormatter } from '../../../utils/formatters/currency'
import {
  DATE_PICKER_FORMAT_2,
  datePickerDateFormat,
} from '../../../utils/formatters/date-picker-date-format'
import {
  mapCountryToOption,
  mapCurrencyToOption,
  mapListToOption,
} from '../../../utils/map-to-option'
import { getEOSPlansDefaultValues } from '../../CompanySetting/components/company-details'
import { InfoTooltip } from '../../CompanySetting/manage-role'
import { FormCol } from '../CreateContract/components/form-col'
import LabelContent from '../CreateContract/components/label-content'
import NoticePeriodField from '../CreateContract/components/notice-period-field'
import ProbationPeriodField from '../CreateContract/components/probation-period-field'
import { isDefinite } from '../CreateContract/create-contract-v3'
import {
  FormDataProvider,
  dataKeys,
  useFormData,
} from '../CreateContract/utils/use-form-data-context'
import useTriggerField from '../CreateContract/utils/use-trigger-field'
import validateSchema from '../CreateContract/utils/validate-schema'
import FormsModal from '../components/Forms/FormsModal'
import { getDepartmentsList } from '../../../services/api-org-charts'
import { userTypes } from '../../../helpers/enum'

export default function DeDetails({
  editing,
  setDeChanges,
  isAmending,
  contract,
}) {
  const [editFormSection, setEditFormSection] = useState(null)
  const [prevData, setPrevData] = useState(null)

  const { currencies = [] } = useSelector(
    (state) => state.Layout?.staticData ?? {},
  )
  const user = useSelector((state) => state.Account?.user)

  const { data: departments } = useFetch({
    action: getDepartmentsList,
    autoFetch: user?.type === userTypes.COMPANY,
    onError: (err) => {
      toastr.error(err)
    },
  })

  const selectedDepartment = departments?.find(
    (d) => d.id === contract?.department_id,
  )

  // default is set in a useEffect
  // Validation occurs when saving changes
  const formMethods = useForm()

  const { watch, reset } = formMethods

  useEffect(() => {
    const currencyId = contract?.currency?.id

    function getDateValue(date) {
      // 'Nov 30, -0001' is a quirk of the backend, when sending incorrectly formatted date
      return date === 'Nov 30, -0001' || date === ''
        ? null
        : datePickerDateFormat(date)
    }

    reset({
      ...contract,
      start_date: getDateValue(contract?.start_date),
      end_date: getDateValue(contract?.end_date),
      currency_id: currencyId,
      country_id: contract?.jurisdiction_country?.id,
      jurisdiction_id: contract?.jurisdiction_id ?? contract?.jurisdiction?.id,
      occurrence_id: contract?.occurrence?.id,
      ...getEOSPlansDefaultValues(contract),
    })
  }, [contract, currencies, reset])

  function formatAllowances(allowances, formatter) {
    return allowances?.length > 0
      ? allowances.map((allowance) => ({
          id: allowance?.id,
          title: allowance?.name,
          value: formatter.format(allowance?.amount),
          frequency: 'Monthly',
        }))
      : []
  }

  const contractData = useMemo(
    () => (editing ? watch() : contract),
    [editing, contract, watch],
  )

  const formatter = getCurrencyFormatter(contractData?.currency?.code)

  const overviewInfos = useMemo(() => {
    const eosPlanFields =
      contract.jurisdiction_eos_plan_fields.flatMap((plan) => plan.fields) ?? []
    return [
      {
        title: 'Country',
        value: contractData?.jurisdiction_country?.name,
        countryCode: contractData?.jurisdiction_country?.currency,
      },
      { title: 'Term', value: contractData?.employment_term },
      { title: 'Type', value: contractData?.employment_type },
      { title: 'Title', value: contractData?.name },
      { title: 'Seniority', value: contractData?.seniority },
      // { title: 'Department', value: contractData?.department }, // TODO to be added later
      {
        title: 'Nationality',
        value: contractData?.contractor?.citizen?.name,
        countryCode: contractData?.contractor?.citizen?.currency,
      },
      { title: 'Employee ID', value: contractData?.employee_identifier },
      { title: 'Visa Number', value: contractData?.visa_number },
      { title: 'Department', value: selectedDepartment?.name },
      ...eosPlanFields,
    ]
  }, [contractData, departments])

  const compensationInfos = [
    {
      title: 'First payment',
      value: !contractData?.prorata_amount
        ? '-'
        : formatter.format(contractData?.prorata_amount),
      frequency: !contractData?.first_payment_date
        ? null
        : 'Once on: ' +
          format(new Date(contractData?.first_payment_date), 'MMM d, yyyy'),
    },
    {
      title: 'Base salary',
      value: contractData?.amount ? formatter.format(contractData?.amount) : '',
      frequency: 'Monthly',
    },
    ...formatAllowances(contractData?.allowances, formatter),
  ].filter(Boolean)

  const columns = useMemo(
    () => [
      { Header: 'Title', accessor: 'title', className: 'px-4 py-3' },
      { Header: 'Amount', accessor: 'value', className: 'px-4 py-3' },
      { Header: 'Frequency', accessor: 'frequency', className: 'px-4 py-3' },
    ],
    [],
  )

  const termsInfos = [
    {
      title: 'Probation period',
      value: `${contractData?.probation_period ?? '-'} Days`,
    },
    { title: 'Notice', value: `${contractData?.notice_period ?? '-'} Days` },
  ]

  const isContractProbationPeriodPast = isPast(
    addDays(new Date(contractData?.start_date), contractData?.probation_period),
  )

  function closeModal() {
    setEditFormSection(null)
    reset(prevData)
  }

  function openModal(section) {
    // parse and stringify to create a deep copy
    setPrevData(JSON.parse(JSON.stringify(watch())))
    setEditFormSection(section)
  }

  return (
    <>
      <Card className='p-4 mb-md-4 mb-3 rp-shadow-2'>
        <SectionTitle
          editing={editing}
          onClickEdit={() => {
            openModal(deDetailsSections.overview.key)
          }}
        >
          {deDetailsSections.overview.title}
        </SectionTitle>

        <div className='tw-grid tw-grid-cols-4 tw-gap-4'>
          {overviewInfos.map((info) => {
            return (
              <ContractInfoItem
                key={info.title}
                title={info.title}
                value={info.value}
                countryCode={info.countryCode}
                className='tw-text-wrap'
              />
            )
          })}
        </div>
      </Card>

      <Card className='pb-3 mb-md-4 mb-3 rp-shadow-2'>
        <SectionTitle
          editing={editing}
          onClickEdit={() => openModal(deDetailsSections.compensation.key)}
          hideRule
          className='p-4'
        >
          {deDetailsSections.compensation.title}
        </SectionTitle>

        <DataTable
          columns={columns}
          data={compensationInfos}
          headClassName='px-4 py-3'
        />
      </Card>

      <Card className='p-4 mb-md-4 mb-3 rp-shadow-2'>
        <SectionTitle
          editing={editing}
          onClickEdit={() => {
            openModal(deDetailsSections.terms.key)
          }}
        >
          {deDetailsSections.terms.title}
        </SectionTitle>

        <div
          className='gap-16'
          style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)' }}
        >
          {termsInfos.map((info) => {
            return (
              <ContractInfoItem
                key={info.title}
                title={info.title}
                value={info.value}
                countryCode={info.countryCode}
              />
            )
          })}
        </div>
      </Card>

      <FormProvider {...formMethods}>
        <FormDataProvider>
          {!editFormSection ? null : (
            <DeEditingForms
              editFormSection={editFormSection}
              contract={contractData}
              departments={departments}
              originalContract={contract}
              closeModal={closeModal}
              isContractProbationPeriodPast={isContractProbationPeriodPast}
              applyChanges={(newData) => {
                setEditFormSection(null)
                if (isContractProbationPeriodPast) {
                  delete newData.probation_period
                }
                setDeChanges((prev) => ({ ...prev, ...newData }))
              }}
              isAmending={isAmending}
            />
          )}
        </FormDataProvider>
      </FormProvider>
    </>
  )
}

const dateInputFormat = 'LL/dd/yyyy'

const overviewSchema = (contract) => {
  return yup.object().shape({
    name: yup
      .string()
      .typeError('Title is required')
      .required('Title is required'),
    country_id: yup
      .string()
      .typeError('Country is required')
      .required('Country is required'),
    jurisdiction_id: yup
      .string()
      .typeError('Jurisdiction is required')
      .required('Jurisdiction is required'),
    employment_term: yup
      .string()
      .typeError('Term is required')
      .required('Term is required'),
    seniority: yup
      .string()
      .typeError('Seniority level is required')
      .required('Seniority level is required'),
    start_date: yup
      .date()
      .typeError('Start date is required')
      .required('Start date is required'),
    end_date: yup
      .date()
      .typeError('End date must be a date')
      .when(
        ['employment_term', 'start_date'],
        ([employmentTerm, startDate], schema) => {
          if (isDefinite(employmentTerm)) {
            return schema
              .typeError('End date is required')
              .required('End date is required')
              .min(
                startDate,
                'End date must be after start date ' +
                  format(startDate, dateInputFormat),
              )
          } else {
            return schema.nullable().notRequired()
          }
        },
      ),
    employee_identifier: yup.string().when([], {
      is: () => contract?.employee_identifier !== null,
      then: (schema) => schema.required('Employee ID is required'),
      otherwise: (schema) => schema.nullable().notRequired(),
    }),
  })
}

const compensationSchema = yup.object().shape({
  amount: yup
    .number()
    .typeError('Salary is required')
    .required('Salary is required'),
  currency_id: yup
    .string()
    .typeError('Currency is required')
    .required('Currency is required'),
  allowances: yup.array().of(
    yup.object().shape({
      id: yup.string(),
      name: yup.string().required('Title is required'),
      amount: yup
        .number()
        .required('Amount is required')
        .min(1, 'Amount must be greater than 0'),
    }),
  ),
})

const termsSchema = yup.object().shape({
  probation_period: yup
    .number()
    .typeError('Probation period is required')
    .required('Probation period is required'),
  notice_period: yup
    .number()
    .typeError('Notice period is required')
    .required('Notice period is required'),
})

const deDetailsSections = {
  overview: {
    key: 'overview',
    title: 'Overview',
    schema: (contract) => overviewSchema(contract),
  },
  compensation: {
    key: 'compensation',
    title: 'Compensation',
    schema: compensationSchema,
  },
  terms: {
    key: 'terms',
    title: 'Terms',
    schema: termsSchema,
  },
}

function DeEditingForms({
  editFormSection,
  closeModal,
  contract,
  departments,
  originalContract,
  applyChanges,
  isAmending,
  isContractProbationPeriodPast,
}) {
  const { setItem } = useFormData()

  const {
    control,
    formState: { errors },
    setValue,
    watch,
    setError,
    setFocus,
    clearErrors,
    trigger,
  } = useFormContext()

  useTriggerField({ watch, trigger })

  function handleConfirm() {
    const schema =
      typeof deDetailsSections[editFormSection].schema === 'function'
        ? deDetailsSections[editFormSection].schema(originalContract)
        : deDetailsSections[editFormSection].schema

    if (!schema) return

    const data = watch()

    validateSchema(schema, data, {
      setFocus,
      setError,
      clearErrors,
      onValidSchema: () => {
        applyChanges(data)
      },
    })
  }

  const { data: supportedCountries, isLoading: gettingSupportedCountries } =
    useFetch({
      action: deSupportedCountries,
      autoFetch: true,
      onError: (err) => {
        toastr.error(err)
      },
    })

  const { country_id: countryId } = useWatch({ control })

  const { data: jurisdictions, isLoading: loadingJurisdictions } = useFetch(
    {
      action: deGetCountryJurisdictions,
      body: { country_id: countryId },
      autoFetch: !!countryId,
      onComplete: (data) => {
        const foundJurisdiction = data?.find(
          (j) => j.id === contract?.jurisdiction_id,
        )

        if (contract?.jurisdiction_id && foundJurisdiction?.id) {
          setValue('jurisdiction_id', contract.jurisdiction_id)

          setItem(dataKeys.jurisdictionId, foundJurisdiction?.currencies)
          setItem(dataKeys.jurisdiction, foundJurisdiction)
        }
      },
      onError: (err) => {
        toastr.error(err)
      },
    },
    [countryId],
  )

  const supportedCountryOptions = supportedCountries?.map((c) =>
    mapCountryToOption(c, 'id'),
  )

  return (
    <FormsModal
      title={
        <div className='tw-flex tw-items-center tw-gap-2'>
          Edit {editFormSection}
          {editFormSection === deDetailsSections.compensation.key && (
            <InfoTooltip id='head-view-col'>
              Salary and allowance changes will take effect in the next payment
              cycle
            </InfoTooltip>
          )}
        </div>
      }
      isOpen={!!editFormSection}
      toggle={closeModal}
      onConfirm={handleConfirm}
      compact={editFormSection !== deDetailsSections.compensation.key}
    >
      {editFormSection === deDetailsSections.overview.key ? (
        <OverviewForm
          control={control}
          errors={errors}
          departments={departments}
          data={{
            supportedCountryOptions,
            gettingSupportedCountries,
            jurisdictions,
            loadingJurisdictions,
            jurisdiction_eos_plan_fields:
              contract?.jurisdiction_eos_plan_fields,
          }}
          isAmending={isAmending}
        />
      ) : editFormSection === deDetailsSections.compensation.key ? (
        <DePaymentForm
          control={control}
          errors={errors}
          contractData={{
            is_first_payment_editable: contract?.is_first_payment_editable,
          }}
        />
      ) : editFormSection === deDetailsSections.terms.key ? (
        <TermsForm
          isContractProbationPeriodPast={isContractProbationPeriodPast}
          control={control}
          errors={errors}
        />
      ) : null}
    </FormsModal>
  )
}

function OverviewForm({ control, errors, data, isAmending, departments }) {
  const { seniorities = [], job_titles: titles = [] } = useSelector(
    (state) => state.Layout?.staticData ?? {},
  )

  const { setValue, watch } = useFormContext()

  const {
    name: watchingJobTitle,
    employment_term: employmentTerm,
    type: contractType,
  } = useWatch({ control })

  const isDefiniteContract = isDefinite(employmentTerm)
  const isDeContract = contractType === CONTRACT_TYPES.DIRECT_EMPLOYEE

  return (
    <Row className='mx-n3'>
      <FormCol md={12}>
        <ControlledInput
          label={<LabelContent required>Title:</LabelContent>}
          control={control}
          name='name'
          error={errors?.name}
          placeholder='i.e. Website Design, Content generation'
          autoComplete={titles}
          watchState={watchingJobTitle}
        />
      </FormCol>
      {isAmending ? null : (
        <FormCol md={12}>
          <ControlledDatePicker
            control={control}
            name='start_date'
            error={errors?.start_date}
            placeholder='Start date'
            dateFormat={DATE_PICKER_FORMAT_2}
            label={<LabelContent required>Start Date:</LabelContent>}
          />
        </FormCol>
      )}
      <FormCol md={12}>
        <ControlledSelect
          options={departments?.map(mapListToOption)}
          control={control}
          name='department_id'
          label={<LabelContent>Department (Optional)</LabelContent>}
          placeholder='Department'
        />
      </FormCol>
      <FormCol md={12}>
        <ControlledSelect
          options={[
            {
              label: 'Yes',
              value: true,
            },
            {
              label: 'No',
              value: false,
            },
          ]}
          control={control}
          name='is_head_of_department'
          label={<LabelContent>Is head of department</LabelContent>}
          placeholder='Department'
        />
      </FormCol>

      {isDefiniteContract && (
        <FormCol md={12}>
          <ControlledDatePicker
            control={control}
            name='end_date'
            error={errors?.end_date}
            placeholder={isDefiniteContract ? 'End date' : '(Optional)'}
            dateFormat={DATE_PICKER_FORMAT_2}
            minDate={new Date()}
            label={
              <LabelContent required={isDefiniteContract}>
                End Date:
              </LabelContent>
            }
          />
        </FormCol>
      )}

      <FormCol md={12}>
        <ControlledRadioList
          control={control}
          name='employment_term'
          error={errors?.employment_term}
          horizontal
          label={<LabelContent required>Employment Term:</LabelContent>}
          options={
            isDeContract ? employmentTermOptionsDe : employmentTermOptions
          }
          isFancy
        />
      </FormCol>
      <FormCol md={12}>
        <ControlledSelect
          control={control}
          name='seniority'
          error={errors?.seniority}
          label={<LabelContent required>Seniority:</LabelContent>}
          options={seniorities?.map((s) => ({
            label: s,
            value: s,
          }))}
        />
      </FormCol>

      {isDeContract || isAmending ? null : (
        <FormCol md={12}>
          <ControlledInput
            control={control}
            name='department (optional)'
            error={errors?.department}
            label={<LabelContent>Department</LabelContent>}
            placeholder='i.e. Marketing, Sales, Tech, etc.'
          />
        </FormCol>
      )}
      <FormCol md={12}>
        <ControlledInput
          control={control}
          name='employee_identifier'
          label={
            <LabelContent>
              Employee ID {data?.employee_identifier ? '' : '(optional)'}
            </LabelContent>
          }
          placeholder='Employee ID'
        />
      </FormCol>
      <FormCol md={12}>
        <ControlledInput
          control={control}
          name='visa_number'
          label={<LabelContent>Visa Number (optional)</LabelContent>}
          placeholder='Visa Number'
        />
      </FormCol>

      <div className='tw-w-full'>
        {data?.jurisdiction_eos_plan_fields?.length === 0
          ? null
          : data?.jurisdiction_eos_plan_fields?.map((plan) => (
              <DynamicForm
                key={plan?.jurisdiction_id}
                control={control}
                fields={plan.fields?.map((field) => ({
                  ...field,
                  id: `${field.name}-${plan.jurisdiction_id}-${plan.contribution_plan_id}`,
                  is_full_row: true,
                }))}
                newUI={false}
                conditions={[]}
                setValue={setValue}
                watch={watch}
                colClassName='!tw-px-4'
              />
            ))}
      </div>
    </Row>
  )
}

function DePaymentForm({ control, contractData }) {
  const {
    allowances,
    amount,
    currency,
    frequency,
    start_date: startDate,
    first_payment_date: firstPaymentDate,
  } = useWatch({ control })

  const { setValue } = useFormContext()

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'allowances',
  })
  const totalAllowances = allowances?.reduce(
    (acc, curr) => acc + Number(curr?.amount),
    0,
  )
  const calculatedAmount = Number(amount) + Number(totalAllowances)

  const formatter = getCurrencyFormatter(currency?.code)
  const [debouncedAmount] = useDebounceValue(amount, 500)
  useFetch(
    {
      action: updateProrata,
      autoFetch: contractData?.is_first_payment_editable,
      body: {
        amount: debouncedAmount,
        frequency_id: frequency?.id,
        start_date: startDate,
        first_payment_date: firstPaymentDate,
      },
      onComplete: (result) => {
        if (contractData?.is_first_payment_editable) {
          setValue('prorata_amount', result?.amount)
        }
      },
    },
    [debouncedAmount, firstPaymentDate],
  )

  return (
    <Row className='mx-n3'>
      <FormCol md={12}>
        <h4 className='tw-text-base tw-font-semibold'>Compensation</h4>
        <div className='tw-flex tw-flex-col tw-gap-4'>
          <div className='tw-flex tw-items-start tw-gap-4'>
            <ControlledInput
              label={<LabelContent required>Title</LabelContent>}
              wrapperClassName='tw-grow tw-shrink-0'
              name='salary_title'
              value='Base Salary'
              disabled
            />
            <ControlledInput
              label={<LabelContent required>Amount</LabelContent>}
              name='amount'
            />
            <ControlledSelect
              name='currency_id'
              label={<LabelContent required>Currency</LabelContent>}
              className='tw-w-[200px]'
              control={control}
              disabled
              options={[mapCurrencyToOption(currency, 'id')]}
            />
            <div>
              {/* placeholder */}
              <label>
                <LabelContent className='tw-invisible' required>
                  Action
                </LabelContent>
              </label>
              <IconButton
                circle={false}
                icon={<TrashSimple color='var(--red-100)' size={24} />}
                outline
                disabled
                color='light'
              />
            </div>
          </div>
          {fields?.map((item, index) => (
            <div key={item.id} className='tw-flex tw-items-start tw-gap-4'>
              <ControlledInput
                label={<LabelContent required>Title</LabelContent>}
                wrapperClassName='tw-grow tw-shrink-0'
                name={`allowances.${index}.name`}
              />
              <ControlledInput
                label={<LabelContent required>Amount</LabelContent>}
                name={`allowances.${index}.amount`}
                type='number'
              />
              <ControlledSelect
                name='currency_id'
                label={<LabelContent required>Currency</LabelContent>}
                className='tw-w-[200px]'
                control={control}
                disabled
                options={[mapCurrencyToOption(currency, 'id')]}
              />
              <div>
                {/* placeholder */}
                <label>
                  <LabelContent className='tw-invisible' required>
                    Action
                  </LabelContent>
                </label>
                <IconButton
                  circle={false}
                  icon={<TrashSimple color='var(--red-100)' size={24} />}
                  outline
                  onClick={() => remove(index)}
                  color='light'
                />
              </div>
            </div>
          ))}
          <button
            className='tw-flex tw-items-center tw-gap-2 tw-rounded-lg tw-border tw-border-dashed tw-border-primary tw-bg-primary-20 tw-p-4 tw-text-left tw-font-bold tw-text-primary'
            onClick={() =>
              append({
                title: '',
                amount: 0,
                currency_id: null,
              })
            }
          >
            <PlusCircle size={24} />
            Add Allowance
          </button>
          <div className='tw-flex tw-items-center tw-justify-between tw-border-b tw-border-surface-30 tw-bg-surface-10 tw-p-6'>
            <span>Total monthly compensation</span>
            <span className='tw-font-bold'>
              {formatter.format(calculatedAmount)}
            </span>
          </div>
          <hr className='tw-mx-[-16px] tw-my-6' />
          <h4 className='tw-text-base tw-font-semibold'>First payment</h4>
          <div className='tw-flex tw-gap-4'>
            <ControlledInput
              label={
                <div className='tw-flex tw-items-center tw-gap-2'>
                  <LabelContent>First payment amount (Optional)</LabelContent>
                  {!contractData?.is_first_payment_editable ? (
                    <InfoTooltip id='first-payment-date'>
                      Editing is not possible once salary has been paid
                    </InfoTooltip>
                  ) : null}
                </div>
              }
              disabled={!contractData?.is_first_payment_editable}
              wrapperClassName='tw-flex-grow tw-shrink-0 tw-flex-1'
              name='prorata_amount'
            />
            <ControlledDatePicker
              label={
                <div className='tw-flex tw-items-center tw-gap-2'>
                  <LabelContent>First payment date (Optional)</LabelContent>
                  {!contractData?.is_first_payment_editable ? (
                    <InfoTooltip id='first-payment-date'>
                      Editing is not possible once salary has been paid
                    </InfoTooltip>
                  ) : null}
                </div>
              }
              disabled={!contractData?.is_first_payment_editable}
              wrapperClassName='tw-flex-grow tw-shrink-0 tw-flex-1'
              name='first_payment_date'
            />
          </div>
        </div>
      </FormCol>
    </Row>
  )
}

function TermsForm({ control, errors, isContractProbationPeriodPast }) {
  return (
    <Row className='mx-n3'>
      <FormCol md={12}>
        <ProbationPeriodField
          control={control}
          errors={errors}
          disabled={isContractProbationPeriodPast}
        />
      </FormCol>

      <FormCol md={12}>
        <NoticePeriodField control={control} errors={errors} />
      </FormCol>
    </Row>
  )
}

function SectionTitle({
  children,
  editing,
  onClickEdit,
  canEdit = true,
  hideRule = false,
  className,
}) {
  return (
    <div
      className={cx(
        'font-size-20 text-gray-h',
        hideRule ? '' : 'mb-1',
        className,
      )}
    >
      <div className='d-flex justify-content-between align-items-baseline'>
        {children}

        {!editing || !canEdit ? null : (
          <Button color='link' className='px-1 py-0' onClick={onClickEdit}>
            Edit
          </Button>
        )}
      </div>

      {hideRule ? null : <hr />}
    </div>
  )
}

function ContractInfoItem({ title, value, countryCode, className, style }) {
  return (
    <div className={cx('d-flex flex-column gap-6', className)} style={style}>
      {!title ? null : (
        <div className='text-size-14 text-gray-600'>{title}</div>
      )}
      <div className='align-items-center d-flex gap-8 text-gray-h text-size-14 rp-capitalize'>
        {!countryCode ? null : <FlagIcon code={countryCode} size='16px' />}
        {value ?? '-'}
      </div>
    </div>
  )
}
