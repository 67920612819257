import cx from 'classnames'
import React from 'react'
import { Badge, Card, CardBody, Col, Container, Row } from 'reactstrap'

import { useCustomResize } from '../../helpers/hooks'
import Button from '../ui/button'

const StepContainer = ({
  title,
  description,
  cardClassName = '',
  children,
  index,
  total,
  loading,
  onNext,
  onBack,
  noBack,
  backText = 'Back',
  nextText = 'Continue',
  nextType = 'button',
  nextFormId,
  disableNext = false,
  minHeight,
  isFlat,
  noFooter,
  footerContent,
  cardStyles,
}) => {
  const isMobile = useCustomResize({ minWidth: 768 })

  return (
    <>
      <Container fluid>
        {title && (
          <Row
            className={`${
              !isMobile
                ? 'justify-content-center'
                : 'justify-content-between align-items-center'
            } py-4 gap-8`}
          >
            <h1
              className={cx('rp-font-semibold mb-0 text-gray-h text-balance', {
                h2: isMobile,
              })}
            >
              {title}
            </h1>
            {isMobile && total > 1 && !isNaN(index) && (
              <Badge className='bg-soft-primary text-primary p-2'>{`${
                index + 1
              }/${total}`}</Badge>
            )}
          </Row>
        )}
        {!description ? null : (
          <Row className='justify-content-center px-4 pb-4 pt-0'>
            {description}
          </Row>
        )}
      </Container>

      <Card
        style={{
          maxWidth: 820,
          margin: '0 auto',
          marginBottom: !noFooter && isMobile ? '6rem' : 0,
          ...cardStyles,
        }}
        className={cx(
          'rp-shadow-2',
          { 'step-container-content': isMobile },
          cardClassName,
        )}
      >
        <Col className='p-0'>
          <CardBody
            className={`${isFlat ? 'p-0' : 'p-4'}`}
            style={{ minHeight }}
          >
            <Col className='p-0'>{children}</Col>
          </CardBody>

          {!noFooter && (
            <div
              className={`modal-footer justify-content-between ${
                isMobile ? 'fixed-bottom bg-white' : ''
              }`}
            >
              {!noBack && index !== 0 ? (
                <Button onClick={onBack} type='button' color='light' outline>
                  {backText}
                </Button>
              ) : (
                <div />
              )}
              {footerContent}
              <Button
                disabled={disableNext || loading}
                onClick={onNext}
                type={nextType}
                formId={nextFormId}
                loading={loading}
              >
                {nextText}
              </Button>
            </div>
          )}
        </Col>
      </Card>
    </>
  )
}

export default StepContainer
