import { X } from '@phosphor-icons/react'
import React from 'react'
import { useHistory } from 'react-router'
import { Col } from 'reactstrap'

import { HeaderLogo } from '../Common/header-logo'

const ModalHeader = ({ children, noExit, action }) => {
  const history = useHistory()

  const onClick = () => {
    history.replace('/')
  }

  return (
    <div className='tw-sticky tw-top-0 tw-z-[1020] tw-mx-auto tw-flex tw-h-[--header-height] tw-flex-nowrap tw-items-center tw-justify-between tw-border-b tw-border-surface-30 tw-bg-white tw-px-6 tw-py-4'>
      <Col xs={2} className='tw-flex tw-items-center !tw-pl-0 !tw-pr-2'>
        <HeaderLogo />
      </Col>

      <Col className='!tw-px-0'>{children}</Col>

      {!noExit && (
        <Col
          xs={2}
          className='d-flex align-items-center justify-content-end px-0'
        >
          <button
            type='button'
            className='rp-btn-nostyle d-flex py-1'
            onClick={action || onClick}
          >
            <X weight='bold' size={18} />
          </button>
        </Col>
      )}
    </div>
  )
}

export default ModalHeader
