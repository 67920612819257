import { Minus, Money, Plus } from '@phosphor-icons/react'
import * as moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { Col, Row, Table, UncontrolledTooltip } from 'reactstrap'
import Collapse from 'reactstrap/lib/Collapse'
import Input from 'reactstrap/lib/Input'
import toastr from 'toastr'
import { Avatar } from 'ui'

import Toggle from '../../components/Forms/Toggle/Toggle'
import { StyledH6 } from '../../components/Typo'
import Button from '../../components/ui/button'
import { usePermissions, useResize } from '../../helpers/hooks'
import permissions from '../../helpers/permissions'
import { updateToPayList } from '../../store/payment/actions'
import { getCurrencyFormatter } from '../../utils/formatters/currency'
import ContractRef from '../AdminPanel/components/ContractRef'
import { getPaymentIds } from '../payInvoices'
import { userTypes } from '../../helpers/enum'
import { CheckItem } from '../../components/ui/check-item'

function PaymentList({
  data,
  onInvoiceSelected,
  toggleSelectAll,
  withStatus,
  withAdvance,
  onAdvanceSet,
  isAdmin,
}) {
  const [opened, setOpened] = useState(-1)
  const [list, setList] = useState(data)
  const [expanded, setExpanded] = useState(-1)
  const { hasAccess } = usePermissions()
  const history = useHistory()
  const user = useSelector((state) => state.Account?.user)
  const dispatch = useDispatch()
  const isMobile = useResize()
  useEffect(() => {
    setList(data)
  }, [data])

  const handleInvoiceChecked = (i, subPaymentId = null) => {
    onInvoiceSelected(i, subPaymentId)
  }
  const handleClickPay = (e) => {
    const ids = getPaymentIds([e])
    dispatch(updateToPayList(ids))
    history.push('/pay-invoices')
  }

  const isAllSelected = list.every((item) => item?.selected)
  const showSelectControl =
    user?.type === userTypes.COMPANY &&
    hasAccess(permissions.PrepareTransactions)

  return (
    <>
      {showSelectControl &&
        !isAdmin &&
        typeof toggleSelectAll === 'function' && (
          <div className='tw-flex tw-justify-end tw-p-6'>
            <CheckItem
              name='select-all-toggle'
              labelId='select-all-toggle'
              label={isAllSelected ? 'Unselect all' : 'Select all'}
              className='tw-flex-row-reverse'
              checked={isAllSelected}
              onChange={toggleSelectAll}
            />
          </div>
        )}

      <Row className='p-0 m-0'>
        {isMobile ? (
          <Col className='p-3 m-0'>
            {list?.map((item, index) => (
              <PaymentListCard
                key={item?.contract_ref + '-' + index}
                index={index}
                item={item}
                onAdvanceSet={onAdvanceSet}
                list={list}
                setList={(data) => setList(data)}
                handleClickPay={handleClickPay}
                opened={opened}
                setOpened={(key) => setOpened(key)}
                withAdvance={withAdvance}
                user={user}
                hasAccess={hasAccess}
                handleInvoiceChecked={handleInvoiceChecked}
                isAdmin={isAdmin}
              />
            ))}
          </Col>
        ) : (
          <Col xs='12' className='p-0 m-0'>
            <div className='table-responsive bg-white'>
              <Table className='table table-centered table-nowrap text-muted'>
                <thead className='thead-light'>
                  <tr
                    style={{
                      borderTop: 'hidden',
                      borderBottom: '1px solid #E7E8F2',
                    }}
                  >
                    <th
                      style={{ fontWeight: '500' }}
                      className='text-dark font-size-14'
                    />
                    <th
                      style={{ fontWeight: '500' }}
                      className='text-dark font-size-14'
                    >
                      Contract Ref
                    </th>
                    <th
                      style={{ fontWeight: '500' }}
                      className='text-dark font-size-14'
                    >
                      Name
                    </th>
                    <th
                      style={{ fontWeight: '500' }}
                      className='text-dark font-size-14'
                    >
                      Contract Name
                    </th>
                    <th
                      style={{ fontWeight: '500' }}
                      className='text-dark font-size-14'
                    >
                      Total
                    </th>

                    {withStatus && (
                      <th className='border-top-0 text-muted'>
                        Payment Status
                      </th>
                    )}

                    <th
                      style={{ fontWeight: '500' }}
                      className='text-dark font-size-14'
                    />
                  </tr>
                </thead>
                <tbody>
                  {list?.map((item, index) => {
                    const formatter = getCurrencyFormatter(item.currency?.code)

                    const isPaymentNotProcessable = item?.payments?.some(
                      (payment) => payment.is_processable === false,
                    )
                    const rowIndexId = `invoiceToPay${index}`

                    return (
                      <React.Fragment key={item?.contract_ref + '_' + index}>
                        <tr>
                          <td>
                            {item?.payments?.length !== 0 && (
                              <button
                                className='tw-p-1'
                                onClick={() => {
                                  setExpanded(expanded === index ? -1 : index)
                                }}
                              >
                                {expanded === index ? (
                                  <Minus weight='bold' size={20} />
                                ) : (
                                  <Plus weight='bold' size={20} />
                                )}
                              </button>
                            )}
                          </td>
                          <td
                            className='text-primary font-size-14'
                            style={{ fontWeight: '400' }}
                          >
                            <ContractRef
                              isAdmin={isAdmin || false}
                              contractId={item.contract_ref}
                            />
                          </td>
                          <td
                            className='text-dark font-size-14'
                            style={{ fontWeight: '400' }}
                          >
                            <Col>
                              <Row className='align-items-center flex-nowrap'>
                                <Avatar
                                  photo={item.payments?.[0]?.contractor?.photo}
                                  name={
                                    item.payments?.[0]?.contractor?.first_name
                                  }
                                  flag={item.payments?.[0]?.contractor?.flag}
                                />
                                <div
                                  style={{ fontWeight: '600', marginLeft: 10 }}
                                >
                                  {item.payments?.[0]?.contractor?.first_name}{' '}
                                  {item.payments?.[0]?.contractor?.last_name}
                                </div>
                              </Row>
                            </Col>
                          </td>
                          <td
                            className='text-dark font-size-14'
                            style={{ fontWeight: '400' }}
                          >
                            <div className='tw-overflow-hidden tw-whitespace-nowrap'>
                              {item.contract_name}
                            </div>
                          </td>
                          <td className='text-dark font-size-14'>
                            {formatter.format(item.total_amount)}
                          </td>
                          {showSelectControl && (
                            <td>
                              {withAdvance ? (
                                item.can_pay ? (
                                  <>
                                    <Button
                                      onClick={() => {
                                        handleClickPay(item)
                                      }}
                                      size='sm'
                                      outline
                                      icon={<Money size={14} weight='bold' />}
                                      id='pay-tooltip'
                                    >
                                      Pay
                                    </Button>
                                    <UncontrolledTooltip
                                      placement='top'
                                      target='pay-tooltip'
                                    >
                                      Click to pay
                                    </UncontrolledTooltip>
                                  </>
                                ) : (
                                  <>
                                    <Button
                                      color='link'
                                      size='sm'
                                      id='pay-tooltip'
                                      onClick={() => {
                                        setOpened(index)
                                      }}
                                      className='!tw-text-sm'
                                    >
                                      Off-cycle Payment
                                    </Button>

                                    <UncontrolledTooltip
                                      placement='top'
                                      target='pay-tooltip'
                                    >
                                      Click to pay
                                    </UncontrolledTooltip>

                                    <Collapse isOpen={index === opened}>
                                      <Row className='mt-2 flex-nowrap'>
                                        <Col sm={7}>
                                          <Input
                                            type='text'
                                            onKeyPress={(e) => {
                                              if (!/[0-9.]/.test(e.key)) {
                                                e.preventDefault()
                                              }
                                            }}
                                            step='any'
                                            onChange={(event) => {
                                              if (
                                                parseFloat(event.target.value) >
                                                item.amount
                                              ) {
                                                toastr.error(
                                                  'Amount should be less or equal ' +
                                                    item.amount,
                                                )
                                                return
                                              }
                                              setList(
                                                list.map((p) => ({
                                                  ...p,
                                                  advance: parseFloat(
                                                    event.target.value,
                                                  ),
                                                })),
                                              )
                                            }}
                                            value={item.advance}
                                            placeholder='Amount'
                                          />
                                        </Col>
                                        <Link
                                          className='btn btn-primary'
                                          color='primary'
                                          onClick={() => {
                                            onAdvanceSet(item)
                                          }}
                                        >
                                          Pay
                                        </Link>
                                      </Row>
                                    </Collapse>
                                  </>
                                )
                              ) : (
                                !isAdmin && (
                                  <>
                                    <CheckItem
                                      key={index}
                                      labelId={rowIndexId}
                                      name={`invoice_to_pay_${index}`}
                                      onChange={() => {
                                        handleInvoiceChecked(index)
                                      }}
                                      checked={item.selected}
                                      indeterminate={
                                        item.selected_payment_works?.length &&
                                        item.selected_payment_works?.length <
                                          item?.payments.flatMap((p) =>
                                            p.works?.map(
                                              (w) => w.payment_item_id,
                                            ),
                                          ).length
                                      }
                                      disabled={isPaymentNotProcessable}
                                      className='tw-w-min'
                                    />
                                    {isPaymentNotProcessable && (
                                      <UncontrolledTooltip target={rowIndexId}>
                                        Please complete the DEWS details for the
                                        entity and contract to proceed with the
                                        payment.
                                      </UncontrolledTooltip>
                                    )}
                                  </>
                                )
                              )}
                            </td>
                          )}
                        </tr>

                        {expanded === index && (
                          <>
                            {item?.payments?.map((payment) => {
                              const formatter = getCurrencyFormatter(
                                payment.currency?.code,
                              )

                              return (
                                <>
                                  {payment?.works?.map((t) => (
                                    <tr
                                      className='font-size-14 bg-gray-bg'
                                      key={'_trans' + t?.payment_item_id}
                                    >
                                      <td
                                        className='p-0'
                                        style={{ height: 100 }}
                                      >
                                        <div
                                          style={{
                                            borderLeft:
                                              '4px solid var(--primary)',
                                          }}
                                          className='h-100'
                                        />
                                      </td>
                                      <td
                                        colSpan={3}
                                        className='text-dark font-size-14'
                                      >
                                        <p className='rp-font-bold'>
                                          {t?.name}
                                        </p>
                                        <p className='mb-0'>{t?.details}</p>
                                      </td>
                                      <td
                                        colSpan={
                                          user?.type === userTypes.COMPANY
                                            ? 1
                                            : 2
                                        }
                                        className='text-dark font-size-14'
                                      >
                                        <div>
                                          <p>{formatter.format(t?.amount)}</p>
                                          <StyledH6
                                            className={`${
                                              moment(payment?.due_date).diff(
                                                new Date(),
                                              ) < 0
                                                ? 'text-danger'
                                                : 'text-dark'
                                            } font-size-14 font-weight-normal mb-0`}
                                          >
                                            Due date : {payment?.due_date}
                                          </StyledH6>
                                        </div>
                                      </td>
                                      {user?.type === userTypes.COMPANY && (
                                        <td>
                                          <CheckItem
                                            className='tw-w-min'
                                            labelId={'id_' + t?.payment_item_id}
                                            onChange={() => {
                                              handleInvoiceChecked(
                                                index,
                                                t?.payment_item_id,
                                              )
                                            }}
                                            checked={item?.selected_payment_works?.includes(
                                              t?.payment_item_id,
                                            )}
                                            disabled={!payment.is_processable}
                                          />
                                          {!payment.is_processable && (
                                            <UncontrolledTooltip
                                              target={
                                                'id_' + t?.payment_item_id
                                              }
                                            >
                                              Please complete the DEWS details
                                              for the entity and contract to
                                              proceed with the payment.
                                            </UncontrolledTooltip>
                                          )}
                                        </td>
                                      )}
                                    </tr>
                                  ))}
                                </>
                              )
                            })}
                          </>
                        )}
                      </React.Fragment>
                    )
                  })}
                </tbody>
              </Table>
            </div>
          </Col>
        )}
      </Row>
    </>
  )
}

const PaymentListCard = (props) => {
  const {
    item,
    index,
    handleInvoiceChecked,
    user,
    hasAccess,
    withAdvance,
    setOpened,
    handleClickPay,
    isAdmin,
  } = props
  const formatter = getCurrencyFormatter(item.currency?.code)

  return (
    <div className='bg-white border mb-3 position-relative rounded rp-shadow-2'>
      <div className='p-3 border-bottom d-flex justify-content-between align-items-center'>
        <div>
          <p
            style={{ fontWeight: 'bold' }}
            className='text-dark font-size-14 mb-0'
          >{`${item?.payments?.[0].contractor?.first_name}  ${item?.payments?.[0].contractor?.last_name}`}</p>
          <ContractRef
            isAdmin={isAdmin || false}
            contractId={item.contract_ref}
          />
        </div>
        <div className='d-flex justify-content-between'>
          {user?.type === userTypes.COMPANY &&
            hasAccess(permissions.PrepareTransactions) &&
            !withAdvance &&
            !isAdmin && (
              <Toggle
                id={`invoiceToPay${index}`}
                check={item.selected}
                change={() => handleInvoiceChecked(index)}
              />
            )}
        </div>
      </div>
      <div className='p-3'>
        <div className='d-flex mb-3 align-items-center justify-content-between align-items-center'>
          <StyledH6 className='text-dark font-weight-normal mb-0 font-size-14'>
            Contract Name
          </StyledH6>
          <StyledH6 className='font-weight-normal mb-0 text-dark font-size-14'>
            {item?.contract_name}
          </StyledH6>
        </div>
        <div className='d-flex mb-0 align-items-center justify-content-between align-items-center'>
          <StyledH6 className='text-dark font-weight-normal mb-0 font-size-14'>
            Total
          </StyledH6>
          <StyledH6 className='text-dark font-size-14 font-weight-normal mb-0'>
            {formatter.format(item.total_amount)}
          </StyledH6>
        </div>
        {!!withAdvance && (
          <Button
            className='tw-mt-4'
            onClick={
              item.can_pay ? () => handleClickPay(item) : () => setOpened(index)
            }
            color='light'
            outline
            block
            icon={<Money />}
            type='button'
          >
            {item.can_pay ? 'Pay' : 'Off-cycle Payment'}
          </Button>
        )}
      </div>
    </div>
  )
}

export default PaymentList
