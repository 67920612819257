import { Plus } from '@phosphor-icons/react'
import React from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { Card, CardBody } from 'reactstrap'

import CustomDateRange from '../../../components/Forms/custom-date-range'
import Loader from '../../../components/ui/loader'
import FEATURE_FLAGS from '../../../config/feature-flags'
import { CONTRACT_TYPES } from '../../../core/config/contract-types'
import { CONTRACT_STATUS, userTypes } from '../../../helpers/enum'
import { useFetch } from '../../../helpers/hooks'
import {
  getContractPaidAndUnPaidDays,
  getContractTimeOff,
  getTimeOffPolicies,
} from '../../../services/api-time-off-policies'
import { AddTimeOff } from '../../time-off/add-time-off'
import { useTimeOffFetch } from '../../time-off/helpers'
import TimeOffDaysCount from '../../time-off/time-off-days-count'
import TimeOffEmpty from '../../time-off/time-off-empty'
import TimeOffList, { timeOffFiltersKeys } from '../../time-off/time-off-list'
import TabCardHeader from '../components/tab/tab-card-header'
import { AssignPolicyAction } from './assign-time-off-policy'
import { TimeOffStats } from './time-off-stats'

export function TimesOffTab({ contract }) {
  const location = useLocation()
  const isAdmin = location.pathname?.startsWith('/admin/')

  const isEmployee = contract.type === CONTRACT_TYPES.FULL_TIME

  const user = useSelector((state) => state.Account?.user)
  const isClient = user?.type === userTypes.COMPANY

  const contractId = contract?.id

  const {
    timeOffs,
    gettingTimeOffList,
    updateTimeOff,
    paginator,
    filters,
    handleFiltersChange,
    willAutoFetch,
    doneFetching,
  } = useTimeOffFetch({
    isAdmin,
    isEmployee,
    isContractDetails: true,
    contractId,
    autoFetch: !!contractId,
  })

  const {
    data: policies,
    isLoading: isLoadingPolicies,
    startFetch: refreshContractPolicies,
  } = useFetch(
    {
      action: getContractTimeOff,
      body: { contract_id: contractId },
      autoFetch: !!contractId,
    },
    [contractId],
  )

  const { data: companyPolicies, isLoading: fetchingCompanyPolicies } =
    useFetch({ action: getTimeOffPolicies, autoFetch: isClient }, [isClient])

  const { data: paidAndUnPaidDays, isLoading: gettingPaidDays } = useFetch(
    {
      action: getContractPaidAndUnPaidDays,
      autoFetch: contractId,
      body: contractId,
    },
    [contractId],
  )

  const timeOffFilters = Object.entries(filters).filter(([key]) => {
    return timeOffFiltersKeys.includes(key)
  })
  const hasFilters =
    timeOffFilters.length > 0 &&
    timeOffFilters.filter(([, value]) => !!value).length > 0

  const isListEmpty =
    !timeOffs || timeOffs?.list?.length <= 0 || timeOffs?.length <= 0

  const isPageEmpty = !hasFilters && isListEmpty

  const timeOffsList = timeOffs?.list || []

  const isLoadingTimeOff = willAutoFetch ? !doneFetching : gettingTimeOffList

  const isPoliciesEmpty = policies?.length <= 0

  return (
    <Card className='rp-shadow-2'>
      <TabCardHeader
        title='Time off'
        extra={
          gettingTimeOffList ? null : FEATURE_FLAGS.TIME_OFF_POLICIES ? (
            <div className='tw-flex tw-flex-wrap tw-gap-2 md:tw-flex-nowrap'>
              {!isClient || isPoliciesEmpty ? null : (
                <AssignPolicyAction
                  contract={contract}
                  onSuccess={() => {
                    updateTimeOff()
                    refreshContractPolicies()
                  }}
                />
              )}

              {(contract &&
                contract.status.id === CONTRACT_STATUS.TERMINATED.value) ||
              isPageEmpty ? null : (
                <AddTimeOff
                  contract={contract}
                  timeOffs={timeOffsList}
                  onSubmit={() => {
                    updateTimeOff()
                    refreshContractPolicies()
                  }}
                  btnProps={{ icon: <Plus size={20} />, className: '!tw-px-6' }}
                />
              )}
            </div>
          ) : isPageEmpty ? null : (
            <div className='tw-flex tw-flex-wrap tw-gap-2 md:tw-flex-nowrap'>
              <TimeOffDaysCount
                className='tw-inline-flex'
                timesOff={timeOffs}
              />

              {isClient ? null : (
                <CustomDateRange
                  wrapperStyles={{ maxWidth: 240, minWidth: 220 }}
                  wrapperClassName='w-100'
                  name='dateRange'
                  value={[filters.start_date, filters.end_date]}
                  placeholder='Select date range'
                  clearable
                  handleClear={() => {
                    handleFiltersChange('start_date', null, { action: 'clear' })
                    handleFiltersChange('end_date', null, { action: 'clear' })
                  }}
                  onChange={(newDates) => {
                    const start = newDates?.[0]
                    const end = newDates?.[1]

                    handleFiltersChange('start_date', start, {
                      action: 'clear',
                    })
                    handleFiltersChange('end_date', end, { action: 'clear' })
                  }}
                />
              )}
            </div>
          )
        }
      />

      <TimeOffStats
        contract={contract}
        policies={policies}
        isLoadingPolicies={isLoadingPolicies}
        refreshPolicies={refreshContractPolicies}
        className={{
          'tw-border-b-0': !contract?.contractor,
          'tw-mb-0 tw-border-b-0': isPoliciesEmpty,
        }}
        companyPolicies={companyPolicies}
        fetchingCompanyPolicies={fetchingCompanyPolicies}
        updateTimeOff={updateTimeOff}
      />

      {contract?.contractor ? (
        isLoadingTimeOff ? (
          <Loader minHeight='30rem' />
        ) : isPoliciesEmpty ? null : isPageEmpty &&
          !gettingTimeOffList &&
          !hasFilters ? (
          <TimeOffEmpty
            contract={contract}
            timeOffs={timeOffsList}
            onSubmit={updateTimeOff}
          />
        ) : (
          <CardBody className='px-md-0'>
            <TimeOffList
              timeOffsList={timeOffsList}
              paginator={paginator}
              filters={filters}
              handleFiltersChange={handleFiltersChange}
              update={() => {
                updateTimeOff()
                refreshContractPolicies()
              }}
              contract={contract}
              gettingTimeOffList={gettingTimeOffList || gettingPaidDays}
              paidDays={paidAndUnPaidDays?.days_paid}
              unPaidDays={paidAndUnPaidDays?.days_unpaid}
            />
          </CardBody>
        )
      ) : null}
    </Card>
  )
}
