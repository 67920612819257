import {
  Check,
  CheckCircle,
  DotsThreeCircleVertical,
  Repeat,
  XCircle,
} from '@phosphor-icons/react'
import React, { useMemo } from 'react'

import { getDateAndTime } from './payment-approvals'

export function TimelineType2({ flowTimeline, title }) {
  const timelineItems =
    flowTimeline?.map((item) => {
      return {
        status: item.status,
        description: item.actor,
        ...getDateAndTime(item.date),
        isReminderSent: item.reminder,
      }
    }) ?? []

  return (
    <>
      {title ?? <h4 className='tw-mb-4 tw-mt-8 tw-font-semibold'>Timeline</h4>}

      <div className=''>
        {timelineItems.length <= 0 ? (
          <div className='tw-p-4 tw-text-text-80'>No timeline items</div>
        ) : (
          timelineItems.map((item, index) => {
            return (
              <TimelineItem
                key={index}
                {...item}
                onClickReminder={item.sendReminder ? () => {} : null}
                isLast={timelineItems.length - 1 === index}
              />
            )
          })
        )}
      </div>
    </>
  )
}

function TimelineItem({
  description,
  date,
  time,
  status,
  isLast,
  isReminderSent,
}) {
  const icon = useMemo(() => {
    switch (String(status)?.toLowerCase()) {
      case 'approved':
      case 'created':
      case 'expense created':
      case 'added on': {
        return <CheckCircle className='tw-h-6 tw-w-6 tw-text-systemGreen-100' />
      }
      case 'pending':
      case 'pending approval': {
        return (
          <DotsThreeCircleVertical className='tw-h-6 tw-w-6 tw-text-systemGold-100' />
        )
      }
      case 'declined':
      case 'cancelled':
      case 'rejected': {
        return <XCircle className='tw-h-6 tw-w-6 tw-text-systemRed-100' />
      }
      case 'reverted': {
        return <Repeat className='tw-h-6 tw-w-6 tw-text-secondary-100' />
      }
      default: {
        return null
      }
    }
  }, [status])

  return (
    <div className='tw-relative tw-flex tw-items-center tw-gap-4 tw-text-sm'>
      {isLast ? null : (
        <div className='tw-absolute tw-left-[13px] tw-top-1/2 tw-h-full tw-w-px tw-bg-surface-30'></div>
      )}
      <div className='tw-relative tw-shrink-0 tw-rounded-full tw-bg-white tw-p-px'>
        {icon}
      </div>

      <div className='tw-flex tw-flex-grow tw-items-center tw-justify-between tw-gap-2 tw-border-b tw-border-surface-30 tw-py-4'>
        <div className=''>
          {!status ? null : (
            <div className='tw-font-semibold tw-capitalize tw-text-black'>
              {status}
            </div>
          )}
          {!description ? null : (
            <div className='tw-text-text-60'>{description}</div>
          )}
        </div>

        <div>
          {!date ? null : <div className='tw-text-text-100'>{date}</div>}
          {!time ? null : <div className='tw-text-text-60'>{time}</div>}
          {isReminderSent && (
            <span className='tw-flex tw-items-center tw-gap-2 tw-text-sm tw-text-primary-50'>
              Reminder Sent
              <Check size={20} />
            </span>
          )}
        </div>
      </div>
    </div>
  )
}
