import React, { useEffect, useState } from 'react'
import ModalHeader from '../../components/ModalHeader'
import { Card, Col, Container, Row } from 'reactstrap'
import { useHistory } from 'react-router'
import { useFetch } from '../../helpers/hooks'
import { updateContractorType } from '../../services/api'
import { contractorTypes } from '../../helpers/enum'
import { useDispatch } from 'react-redux'
import { loginUserSuccessful } from '../../store/auth/register/actions'

const AccountType = () => {
  const [type, setType] = useState(null)
  const dispatch = useDispatch()
  const history = useHistory()
  const update = useFetch({
    action: updateContractorType,
    onComplete: (data) => {
      dispatch(loginUserSuccessful(data))
      setTimeout(() => {
        history.push('/activity')
      }, 400)
    },
  })
  useEffect(() => {
    if (type) {
      update.startFetch({ type })
    }
  }, [type])

  return (
    <div>
      <Container fluid>
        <ModalHeader noExit />
        <Row
          className='justify-content-center align-items-center'
          style={{ minHeight: '70vh' }}
        >
          <Col md={6}>
            <Card className='p-5'>
              <h3 className='mb-4 mt-4 text-center'>
                Which account type would you like to use?
              </h3>
              <a
                onClick={() => {
                  history.push('/complete-company')
                }}
                style={{ borderRadius: 10 }}
                className='border d-flex align-items-center justify-content-between p-3 mb-3'
              >
                <Row className='p-0 m-0'>
                  <Col>
                    <Row className='ml-0 flex-nowrap'>
                      <p className='text-muted font-size-16 mb-0'>Company</p>
                      {update.isLoading && type === contractorTypes.ENTITY && (
                        <i className='bx bx-loader bx-spin font-size-16 mx-2 text-primary' />
                      )}
                    </Row>
                  </Col>
                </Row>
                <i className='bx bx-chevron-right font-size-24 align-middle mr-1 text-primary' />
              </a>

              <a
                onClick={() => {
                  setType(contractorTypes.INDIVIDUAL)
                }}
                style={{ borderRadius: 10 }}
                className='border d-flex align-items-center justify-content-between p-3 mb-3'
              >
                <Row className='p-0 m-0'>
                  <Col>
                    <Row className='ml-0 flex-nowrap'>
                      <p className='text-muted font-size-16 mb-0'>Individual</p>
                      {update.isLoading &&
                        type === contractorTypes.INDIVIDUAL && (
                          <i className='bx bx-loader bx-spin font-size-16 mx-2 text-primary' />
                        )}
                    </Row>
                  </Col>
                </Row>
                <i className='bx bx-chevron-right font-size-24 align-middle mr-1 text-primary' />
              </a>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default AccountType
