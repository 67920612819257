import React from 'react'

import {
  CircleHalf,
  ClockCountdown,
  ClockCounterClockwise,
  MinusCircle,
  Siren,
} from '@phosphor-icons/react'
import { cn } from 'ui'
import { DetailsInfoList } from '../pages/review-center/review-layout-details-components'
import { formatDays } from '../utils/formatters/format-days'
import Accordion from './Accordion'

export default function PolicyRequestSettings({
  bodyClassName,
  policy,
  className,
}) {
  const isAccrued = policy?.type?.is_accrued === 1

  return (
    <Accordion
      className={cn(
        'tw-border-b tw-border-b-surface-30 tw-px-0 tw-py-4',
        className,
      )}
      iconClassName='tw-fill-primary'
      contentWrapperClassName='tw-px-0 tw-py-0'
      label={
        <div className='tw-text-left'>
          <p className='tw-mb-0 tw-text-sm tw-font-semibold tw-text-black'>
            Requests settings
          </p>
          <span className='tw-text-sm tw-text-text-80'>
            View request rules for this policy
          </span>
        </div>
      }
      value={
        <DetailsInfoList
          items={[
            {
              icon: <CircleHalf size={24} />,
              label: 'Half day request',
              value: policy.can_request_half_day ? 'Yes' : 'No',
              tip: 'Can a worker request a half day off?',
            },
            {
              icon: <Siren size={24} />,
              label: 'Time off maximum',
              value: policy.request_max_days
                ? formatDays(policy.request_max_days)
                : 'No maximum',
              tip: 'Is there a maximum number of sequential days per time off request?',
            },
            {
              icon: <ClockCounterClockwise size={24} />,
              label: 'Retrospective request',
              value: policy.is_retrospective_enabled ? 'Yes' : 'No',
              tip: 'Can a worker submit a request for a past period?',
            },
            {
              icon: <ClockCountdown size={24} />,
              label: 'Request eligibility',
              value: policy.days_after_hiring
                ? formatDays(policy.days_after_hiring)
                : 'No waiting time',
              tip: 'Is there a waiting time to request time off after date of hire?',
            },
            isAccrued && {
              icon: <MinusCircle size={24} />,
              label: 'Negative Balance',
              value: policy.negative_balance_enabled ? 'Yes' : 'No',
              tip: 'Can a worker request time off without enough balance?',
            },
          ]}
          className={cn('tw-py-0', bodyClassName)}
        />
      }
    />
  )
}
