import React from 'react'
import { Button, Col, Row } from 'reactstrap'

import paymentConfirmation from '../../assets/images/payment_confirmation.svg'
import Loader from '../../components/ui/loader'
import { useFetch, useResize } from '../../helpers/hooks'
import { downloadTransactionDEWSFile } from '../../services/api'
import openFileV2 from '../../utils/file/open-v2'

const ConfirmationPage = ({
  onComplete,
  loading,
  confirmed,
  onDownload,
  payload,
}) => {
  const {
    has_contributions: hasContributions,
    id: transactionID,
    ref,
  } = JSON.parse(payload || '{}')
  const isMobile = useResize()
  const { startFetch: downloadDEWSFile } = useFetch({
    action: downloadTransactionDEWSFile,
    onComplete: (data) => {
      openFileV2(data, {
        name: `DEWS-${ref}.csv`,
        download: true,
      })
    },
  })

  return loading ? (
    <Loader minHeight='30rem' />
  ) : (
    <Row className='justify-content-center'>
      <Col className='p-0'>
        <Row
          className='justify-content-center align-items-center'
          style={{ minHeight: '75vh' }}
        >
          <Col>
            <Row
              className='justify-content-center'
              style={{ fontSize: '10rem' }}
            >
              {confirmed ? (
                <img
                  height={isMobile ? 200 : 400}
                  src={paymentConfirmation}
                  alt=''
                />
              ) : (
                <i className='bx bx-x-circle text-danger' />
              )}
            </Row>
            {confirmed && (
              <Row className='justify-content-center text-center font-size-16'>
                <Col>
                  <p className='text-muted'>
                    Your transaction is currently being processed. You will
                    receive a notification as soon as your transaction is
                    confirmed.
                  </p>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
        <div className='tw-flex tw-items-center tw-justify-center tw-gap-2'>
          <Button
            onClick={onComplete}
            className='my-2 rp-font-bold'
            color='primary'
          >
            Go to Transactions
          </Button>

          {!onDownload ? null : (
            <Button
              onClick={onDownload}
              color='light'
              className='tw-flex tw-items-center tw-justify-center tw-gap-4 !tw-border-0 !tw-bg-primary !tw-bg-opacity-10 !tw-text-primary'
            >
              Download Statement{' '}
              <i
                className='bx bx-download font-size-18'
                style={{
                  marginBottom: -5,
                }}
              />
            </Button>
          )}
          {!hasContributions ? null : (
            <Button
              onClick={() =>
                downloadDEWSFile({ transaction_id: transactionID })
              }
              color='light'
              className='tw-flex tw-items-center tw-justify-center tw-gap-4 !tw-border-0 !tw-bg-primary !tw-bg-opacity-10 !tw-text-primary'
            >
              Download DEWS file{' '}
              <i
                className='bx bx-download font-size-18'
                style={{
                  marginBottom: -5,
                }}
              />
            </Button>
          )}
        </div>
      </Col>
    </Row>
  )
}

export default ConfirmationPage
