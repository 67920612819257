import { format, parseISO } from 'date-fns'
import React, { useState } from 'react'
import cx from 'classnames'
import { getCurrencyFormatter } from '../../../../utils/formatters/currency'
import BadgeX from '../../../../components/Table/BadgeX'
import { Check } from '@phosphor-icons/react'
import { useFetch } from '../../../../helpers/hooks'
import { getCashbackHistory } from '../../../../services/api'
import Loader from '../../../../components/ui/loader'
import { CashBackIcon } from '../../../../components/ui/cashback-icon'
import DataTable from '../../../../components/ui/data-table'
import Pagination from '../../../../components/ui/pagination'
import BottomSheet from '../../../../components/ui/bottom-sheet/bottom-sheet'

const formatMonth = (date) => format(parseISO(date), 'MMMM')
const formatDate = (date) => format(parseISO(date), 'MMM dd, yyyy')

const formatAmount = (currency, amount) => {
  const formatter = getCurrencyFormatter(currency)
  return formatter.format(amount)
}
const getCollectedBadge = (isCredited) => {
  return isCredited ? (
    <BadgeX
      className='rounded-pill bg-primary-20 text-primary font-weight-bold p-2 font-size-10 rp-capitalize'
      rightIcon={<Check />}
      name='Collected'
    />
  ) : (
    <BadgeX
      className='rounded-pill bg-yellow-20 font-weight-bold p-2'
      textStyle={{ color: 'var(--yellow)' }}
      textClassName='font-size-10'
      name='NOT COLLECTED'
    />
  )
}

export default function CashbackHistoryModal({ isOpen, toggle }) {
  const [page, setPage] = useState(1)
  const columns = [
    {
      Header: 'Month',
      accessor: 'month',
      className: 'p-3',
      Cell: (data) => formatMonth(data?.rowData?.created_at),
    },
    {
      Header: 'Date',
      accessor: 'date',
      className: 'p-3',
      Cell: (data) => formatDate(data?.rowData?.created_at),
    },
    {
      Header: 'Amount',
      accessor: 'cashback_amount',
      className: 'p-3',
      Cell: (data) =>
        formatAmount(
          data?.rowData?.cashback_currency_code,
          data?.rowData?.cashback_amount,
        ),
    },
    {
      Header: 'Status',
      accessor: 'is_credited',
      className: 'p-3',
      Cell: (data) => getCollectedBadge(data?.rowData?.is_credited),
    },
  ]
  const { data, isLoading } = useFetch(
    {
      action: getCashbackHistory,
      body: { page: page - 1 },
      autoFetch: true,
    },
    [page],
  )
  return (
    <BottomSheet
      toggle={toggle}
      isOpen={isOpen}
      header='Cashback history'
      content={
        isLoading ? (
          <Loader className='w-100' />
        ) : data?.items?.length < 1 ? (
          <div
            className='d-flex align-items-center justify-content-center flex-column cbh-empty'
            style={{ height: 460 }}
          >
            <CashBackIcon size={194} color='var(--primary)' />
            <div className='font-size-24 font-weight-medium mb-2'>
              No cashback history
            </div>
            <div className='font-size-14 text-text-80'>
              Cashback collected will be listed here
            </div>
          </div>
        ) : (
          <>
            <DataTable
              className='text-text-black d-md-table d-none'
              headClassName='p-3'
              responsive
              columns={columns}
              data={data?.items}
            />
            <CashbackCards className='d-md-none d-block' items={data?.items} />
            {data?.total_pages_count > 1 && (
              <Pagination
                innerClass='align-items-center d-flex justify-content-center mt-3 pagination w-100'
                activePage={page}
                itemsCountPerPage={data?.limit}
                totalItemsCount={data?.total_items_count}
                onChange={(page) => setPage(page)}
              />
            )}
          </>
        )
      }
    />
  )
}

function CashbackCards({ items, className }) {
  return items?.map((item, index) => {
    const infoItems = [
      { label: 'Month', value: formatMonth(item?.created_at) },
      { label: 'Date', value: formatDate(item?.created_at) },
      {
        label: 'Amount',
        value: formatAmount(
          item?.cashback_currency_code,
          item?.cashback_amount,
        ),
      },
      { label: 'status', value: getCollectedBadge(item?.is_credited) },
    ]
    return (
      <div
        key={`cb-${index}`}
        className={cx(
          'p-3 border border-light d-flex flex-column mb-3',
          className,
        )}
        style={{ gap: '1rem', borderRadius: 6 }}
      >
        {infoItems.map((row, index) => (
          <div
            className='d-flex justify-content-between align-items-center font-size-16'
            key={`cb-card-${index}`}
          >
            <span className='text-text-80'>{row.label}</span>
            <span>{row.value}</span>
          </div>
        ))}
      </div>
    )
  })
}
