import React from 'react'
import { useSelector } from 'react-redux'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import toastr from 'toastr'

import { ModalCloseButton } from '../../../components/Common/modal-close-button'
import { PrimaryAlert } from '../../../components/ui/alert'
import Button from '../../../components/ui/button'
import Loader from '../../../components/ui/loader'
import { useFetch } from '../../../helpers/hooks'
import {
  getBackgroundCheckQuote,
  requestBackgroundCheck,
} from '../../../services/api-compliance'
import { getCurrencyFormatter } from '../../../utils/formatters/currency'

export function RequestBgCheckModal({
  toggle,
  isOpen,
  itemsToCheck = [],
  onSuccess,
}) {
  const userProfile = useSelector((state) => state?.userProfile?.userProfile)

  const { data: quote, isLoading: isFetchingQuote } = useFetch(
    {
      action: getBackgroundCheckQuote,
      autoFetch: itemsToCheck.length > 0 && isOpen,
      body: {
        contract_ids: itemsToCheck.map((item) => item.raw.id),
      },
    },
    [itemsToCheck, isOpen],
  )

  const totalCost = quote?.cost
  const formatter = getCurrencyFormatter()
  const formattedTotalCost = formatter.format(totalCost)

  const {
    startFetch: requestBackgroundCheckAction,
    isLoading: isRequestingBackgroundCheck,
  } = useFetch({
    action: requestBackgroundCheck,
    onComplete: (data) => {
      if (data?.success === false) {
        toastr.error(data?.message ?? 'Failed to request background check')
      } else {
        toastr.success('Background check requested successfully')
        toggle()
        onSuccess?.()
      }
    },
    onError: (error, response) => {
      toastr.error(response?.message ?? error)
    },
  })

  function handleRequest() {
    const body = {
      companyId: userProfile?.company?.id,
      companyName: userProfile?.company?.name,
      users: itemsToCheck.map((item) => ({
        id: item.raw.contractor?.id,
        email: item.raw.contractor?.email,
        contractRef: String(item.raw.ref),
        countryIso3: item.raw.contractor?.country?.iso3 ?? 'USA',
        name: item.raw.contractor?.first_name,
      })),
    }

    requestBackgroundCheckAction(body)
  }

  const headerElement = (
    <ModalHeader
      close={<ModalCloseButton toggle={toggle} />}
      cssModule={{
        'modal-title': 'tw-text-[32px] tw-font-bold modal-title',
      }}
    >
      Softcheck by Certn
    </ModalHeader>
  )

  if (isFetchingQuote) {
    return (
      <Modal isOpen={isOpen} toggle={toggle}>
        {headerElement}

        <ModalBody className='!tw-p-6'>
          <Loader minHeight='max(50vh, 550px)' />
        </ModalBody>
      </Modal>
    )
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      {headerElement}

      <ModalBody className='!tw-p-6'>
        <PrimaryAlert>
          The worker(s) will be notified to provide consent and you will be
          informed when the report is ready for download.
        </PrimaryAlert>

        <div className='tw-mb-2 tw-mt-6 tw-text-xl tw-font-semibold'>
          Request background check for
        </div>

        {itemsToCheck.map((item) => (
          <div
            className='tw-flex tw-justify-between tw-border-b tw-border-b-surface-30 tw-py-3'
            key={item.name}
          >
            <span className='tw-text-sm'>{item.name}</span>
            <span>{item.status}</span>
          </div>
        ))}

        <div className='tw-mb-2 tw-mt-6 tw-text-xl tw-font-semibold'>
          Total cost
        </div>
        <div className='tw-py-3'>
          <div className='tw-text-sm'>{formattedTotalCost}</div>
          <span className='tw-text-sm tw-text-text-80'>
            This amount will be added to your payments
          </span>
        </div>
      </ModalBody>

      <ModalFooter>
        <Button
          type='button'
          onClick={handleRequest}
          loading={isRequestingBackgroundCheck}
          disabled={isRequestingBackgroundCheck}
        >
          Proceed
        </Button>
      </ModalFooter>
    </Modal>
  )
}
