import { Spinner } from '@phosphor-icons/react'
import React from 'react'
import { Button as BsButton } from 'reactstrap'
import { cn } from 'ui'

export default function Button({
  icon,
  iconRight,
  text,
  loading,
  onClick,
  children,
  className,
  formId,
  color = 'primary',
  alignLeft,
  outline,
  textStyle,
  textClassName,
  ...btnProps
}) {
  return (
    <BsButton
      onClick={onClick}
      color={color}
      outline={outline}
      className={cn(
        '!tw-inline-flex tw-items-center tw-gap-2 !tw-font-bold',
        alignLeft ? 'tw-justify-start' : 'tw-justify-center',
        { '!tw-rounded': ['sm', 'xs', 'lg'].includes(btnProps?.size) },
        className,
      )}
      {...btnProps}
      style={{
        padding: btnProps?.size !== 'xs' ? null : '0.125rem 0.325rem',
        ...btnProps?.style,
      }}
      form={formId}
    >
      {!loading ? icon : <LoadingElement />}

      {!text && !children ? null : (
        <span
          className={textClassName}
          style={{ whiteSpace: 'nowrap', ...textStyle }}
        >
          {text ?? children}
        </span>
      )}

      {iconRight ?? null}
    </BsButton>
  )
}

export const LoadingElement = ({ size = 14 }) => (
  <Spinner
    size={size}
    className='tw-animate-[spin_2s_linear_infinite]'
    weight='bold'
  />
)
