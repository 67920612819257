import { yupResolver } from '@hookform/resolvers/yup'
import { Info, ListBullets } from '@phosphor-icons/react'
import cx from 'classnames'
import { addDays, format } from 'date-fns'
import React from 'react'
import { useForm } from 'react-hook-form'
import {
  Col,
  Container,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap'
import toastr from 'toastr'
import * as yup from 'yup'

import ControlledCurrencyInput from '../../../components/ControlledCurrencyInput'
import ControlledDatePicker from '../../../components/ControlledDatePicker'
import ControlledSelect from '../../../components/ControlledSelect'
import { getInputErrorMessage } from '../../../components/Forms/get-input-error-message'
import Alert from '../../../components/ui/alert'
import Button from '../../../components/ui/button'
import { FlagIcon } from '../../../components/ui/flag-icon'
import InputFeedback from '../../../components/ui/input-feedback'
import { useFetch } from '../../../helpers/hooks'
import { deScheduledTerminate, deTerminationCosts } from '../../../services/api'
import { getCurrencyFormatter } from '../../../utils/formatters/currency'
import isNill from '../../../utils/is-nill'
import styles from './de-termination-modal.module.css'
import { useSelector } from 'react-redux'
import { ModalCloseButton } from '../../../components/Common/modal-close-button'

const formSchema = yup.object().shape({
  termination_reason: yup.string().required('Termination reason is required'),
  termination_date: yup.date().required('Termination date is required'),
  last_payment_amount: yup
    .number()
    .typeError('Last payment amount is required')
    .required('Last payment amount is required'),
  payment_date: yup.date().required('Payment date is required'),
})

const dateFormat = 'yyyy-MM-dd'

const terminationFormId = 'termination-form'

export default function DeTerminationModal({
  isOpen,
  toggle,
  contract,
  onConfirm,
}) {
  const noticePeriod = contract?.notice_period
  const terminationReasons = useSelector(
    (state) => state?.Layout?.staticData?.de_termination_reasons ?? [],
  ).map((reason) => ({
    label: reason,
    value: reason.toLowerCase().replace(/\s+/g, '-'),
  }))

  const defaultTerminationDate = format(
    addDays(new Date(), noticePeriod),
    dateFormat,
  )

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: { termination_date: defaultTerminationDate },
    resolver: yupResolver(formSchema),
  })

  const { data: terminationData } = useFetch(
    {
      action: deTerminationCosts,
      autoFetch: isOpen && !!contract?.id,
      body: { contract_id: contract?.id },
    },
    [isOpen, contract?.id],
  )

  const { startFetch: scheduleTermination, isLoading: schedulingTermination } =
    useFetch({
      action: deScheduledTerminate,
      onComplete: (data) => {
        if (data?.success === false) {
          toastr.error(data?.message || 'Something went wrong')
        } else {
          toastr.success('Contract termination scheduled successfully')
          toggle()
          onConfirm?.()
        }
      },
      onError: (error) => {
        toastr.error(error?.message || 'Something went wrong')
      },
    })

  function onSubmit(data) {
    scheduleTermination({
      ...data,
      contract_id: contract?.id,
      termination_date: format(data.termination_date, dateFormat),
      payment_date: format(data.payment_date, dateFormat),
    })
  }

  const formatter = getCurrencyFormatter(contract?.currency?.code)

  function formatAmount(amount) {
    if (isNill(amount) || isNaN(amount)) return formatter.format('0')

    return formatter.format(amount)
  }

  function formatDays(days) {
    const number = Number(days).toFixed(1)

    return `${isNaN(number) ? '0' : number} day${number === 1 ? '' : 's'}`
  }

  const serviceItems = [
    {
      label: 'Gratuity compensation',
      value: formatAmount(terminationData?.gratuity_compensation_total),
    },
    {
      label: 'Remaining vacation leaves',
      value: formatDays(terminationData?.remaining_vacation_leaves),
    },
    {
      label: 'Vacation Leave compensation',
      value: formatAmount(terminationData?.vacation_leave_compensation_total),
    },
    {
      label: 'Total End of service benefits',
      value: formatAmount(terminationData?.severance_amount),
    },
  ]

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      className={styles.modalMedium}
      unmountOnClose
    >
      <ModalHeader close={<ModalCloseButton toggle={toggle} />}>
        Contract termination
      </ModalHeader>

      <ModalBody>
        <form
          className='mb-3'
          id={terminationFormId}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Container className='px-0'>
            <Row className='mx-0'>
              <Col xs={12} md={6} className='pl-0 pr-2'>
                <ControlledSelect
                  control={control}
                  name='termination_reason'
                  label='Select a Reason'
                  options={terminationReasons}
                  wrapperClassName='mb-3'
                  error={errors?.termination_reason?.message}
                />
              </Col>
              <Col xs={12} md={6} className='pl-2 pr-0'>
                <ControlledDatePicker
                  control={control}
                  name='termination_date'
                  label='Last date'
                  placeholder='Select last date'
                  dateFormat={dateFormat}
                  wrapperClassName='mb-3'
                  minDate={new Date()}
                  error={errors?.termination_date?.message}
                />
              </Col>
            </Row>
            <Row className='mx-0'>
              <Col xs={12} md={6} className='pl-0 pr-2'>
                <FormGroup>
                  <label htmlFor='last_payment_amount'>
                    Last payment amount
                  </label>

                  <InputGroup>
                    <ControlledCurrencyInput
                      control={control}
                      name='last_payment_amount'
                      id='last_payment_amount'
                      hideError={true}
                    />

                    <InputGroupAddon addonType='append'>
                      <InputGroupText style={{ minWidth: 80 }}>
                        <FlagIcon
                          className='mr-2'
                          code={contract?.currency?.code}
                        />
                        {contract?.currency?.code}
                      </InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>

                  {!errors?.last_payment_amount ? null : (
                    <InputFeedback className='tw-mt-1'>
                      {getInputErrorMessage(errors?.last_payment_amount)}
                    </InputFeedback>
                  )}
                </FormGroup>
              </Col>
              <Col xs={12} md={6} className='pl-2 pr-0'>
                <ControlledDatePicker
                  control={control}
                  name='payment_date'
                  label='Payment Date'
                  placeholder='Select payment date'
                  dateFormat={dateFormat}
                  wrapperClassName='mb-3'
                  minDate={new Date()}
                  error={errors?.payment_date?.message}
                />
              </Col>
            </Row>
          </Container>
        </form>

        <div className='p-4 rounded-lg bg-slate-50 border border-slate-100 mb-3'>
          <h4 className='font-size-14 text-gray-h rp-font-bold mb-2 d-flex align-items-center gap-8'>
            <ListBullets size={24} />
            End of Service Benefits summary
          </h4>

          <div className='mb-n3'>
            {serviceItems.map((item, index) => {
              return (
                <ServiceItem
                  key={index}
                  {...item}
                  borderBottom={index !== serviceItems.length - 1}
                />
              )
            })}
          </div>
        </div>

        <Alert
          color='danger'
          className='text-slate-700 font-size-14 p-4 rounded-lg border-slate-100'
          customIcon={false}
          innerTag='div'
        >
          <p
            className='rp-font-bold d-flex align-items-center gap-8 mb-2'
            style={{ color: 'black' }}
          >
            <Info size={24} className='text-danger' />
            Please note
          </p>

          <ul
            className='mb-0 pl-4 text-slate-600'
            style={{ lineHeight: '22px' }}
          >
            <li>This contract has a {noticePeriod} day notice period</li>
            <li>The current payment cycle will be canceled</li>
            <li>This action is not reversible</li>
          </ul>
        </Alert>
      </ModalBody>

      <ModalFooter>
        <Button
          color='light'
          outline
          onClick={toggle}
          disabled={schedulingTermination}
        >
          Cancel
        </Button>
        <Button
          type='submit'
          formId={terminationFormId}
          disabled={schedulingTermination}
          loading={schedulingTermination}
        >
          Proceed
        </Button>
      </ModalFooter>
    </Modal>
  )
}

function ServiceItem({ label, value, borderBottom = false }) {
  return (
    <div
      className={cx(
        'd-flex align-items-center justify-content-between font-size-14 py-3',
        { 'border-bottom border-slate-100': !!borderBottom },
      )}
    >
      <div className='text-slate-600'>{label}</div>
      <div>{value}</div>
    </div>
  )
}
