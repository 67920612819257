export const currencyLocal = 'en-US'

export function getCurrencyFormatter(
  currency = 'USD',
  locale = currencyLocal,
  options = {},
) {
  return {
    format: (value) => {
      return new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: currency || 'USD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        ...options,
      }).format(Number(value || 0))
    },
  }
}

export const usdFormatter = getCurrencyFormatter()
